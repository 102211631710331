program xdweb.com;

uses
  Vcl.Forms,
  WEBLib.Forms,
  uMain in 'uMain.pas'{*.html},
  x.fs in '\\q11.100.am1.com\dev\xlib\xServers\fs\x.fs.pas',
  x.ao in '\\q11.100.am1.com\dev\xlib\x.ao.pas',
  x.fs.client in '\\q11.100.am1.com\dev\xlib\xServers\fs\x.fs.client.pas',
  x.fs.types in '\\q11.100.am1.com\dev\xlib\xServers\fs\x.fs.types.pas',
  x.icons.all in '\\q11.100.am1.com\dev\xlib\x.icons.all.pas',
  x.fs.client.TV in '\\q11.100.am1.com\dev\xlib\xServers\fs\x.fs.client.TV.pas',
  x.Controls in '\\q11.100.am1.com\dev\xlib\xControls\x.Controls.pas',
  x.controls.ix in '\\q11.100.am1.com\dev\xlib\xControls\x.controls.ix.pas',
  uInfo in 'uInfo.pas'{*.html},
  xweb.forms in '\\q11.100.am1.com\dev\xlib\web\xweb.forms.pas',
  x.appstyler in '\\q11.100.am1.com\dev\xlib\x.appstyler.pas',
  x.appinfo in '\\q11.100.am1.com\dev\xlib\x.appinfo.pas',
  x.controls.extra in '\\q11.100.am1.com\dev\xlib\xControls\x.controls.extra.pas',
  xweb.xdata.client in '\\q11.100.am1.com\dev\xlib\xServers\Clients\xweb.xdata.client.pas',
  x.login.client in '\\q11.100.am1.com\dev\xlib\xServers\login\x.login.client.pas',
  x.Login in '\\q11.100.am1.com\dev\xlib\xServers\login\x.Login.pas',
  x.login.WebForm in '\\q11.100.am1.com\dev\xlib\xServers\login\Web\x.login.WebForm.pas'{*.html},
  x.xdata.connect in '\\q11.100.am1.com\dev\xlib\xServers\Clients\x.xdata.connect.pas',
  x.Login.Types in '\\q11.100.am1.com\dev\xlib\xServers\login\x.Login.Types.pas',
  x.login.userbox in '\\q11.100.am1.com\dev\xlib\xServers\login\x.login.userbox.pas',
  x.css in '\\q11.100.am1.com\dev\xlib\web\x.css.pas',
  dummy in 'dummy.pas',
  x.Status in '\\q11.100.am1.com\dev\xlib\xControls\x.Status.pas',
  x.css.boxShadow in '\\q11.100.am1.com\dev\xlib\web\x.css.boxShadow.pas',
  x.css.slide in '\\q11.100.am1.com\dev\xlib\web\x.css.slide.pas',
  xf.test in '\\q11.100.am1.com\dev\xlib\xControls\Forms\xf.test.pas'{*.html};

{$R *.res}
{$DEFINE WEBLIB}

begin

  Application.Initialize;

  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TMF, MF);
  Application.CreateForm(TFormInfo, FormInfo);
  Application.CreateForm(TLoginForm, LoginForm);
  Application.CreateForm(Txftest, xftest);
  Application.Run;

end.
