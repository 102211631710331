unit x.controls.ix;

interface

//  {$INCLUDE project.inc}

uses
  classes, rtti, system.Generics.Collections, uiTypes, x.cfg.classes, sysutils, x.controls, system.typinfo, system.types, x.fncNodes, x.icons.all,

  {$IFDEF fwfmx}
  {$DEFINE FMXLIB}
  FMX.Types, FMX.Controls, FMX.Forms, FMX.Graphics, FMX.Dialogs, FMX.objects, FMX.TMSMemo, FMX.StdCtrls, FMX.Layouts, FMX.skia, FMX.TMSFNCGraphicstypes,
  FMX.TMSFNCSplitter, x.styler,
  FMX.TMSFNCTreeViewBase, FMX.TMSFNCBitmapContainer, FMX.ListBox,
  FMX.TMSFNCTreeViewData, FMX.TMSFNCCustomTreeView, FMX.TMSFNCTreeView,
  FMX.TMSFNCObjectInspector, fmx.TMSFNCGraphics, fmx.tmsfnctypes, fmx.TMSFNCCustomControl, fmx.menus,
  {$ENDIF}
  {$IFDEF fwVCL}
  vcl.Controls, vcl.Forms, vcl.Graphics, vcl.Dialogs, vcl.StdCtrls, vcl.ExtCtrls, windows, messages, scGPControls, scgpExtControls, vcl.skia, scControls,
  vcl.tmsfncgraphicstypes, vcl.TMSFNCSplitter, vcl.TMSFNCBitmapContainer, x.styler, vcl.menus,

  VCL.TMSFNCTreeViewBase,
  VCL.TMSFNCTreeViewData, VCL.TMSFNCCustomTreeView, VCL.TMSFNCTreeView,
  VCL.TMSFNCObjectInspector, VCL.TMSFNCGraphics, vcl.tmsfnctypes, VCL.TMSFNCCustomControl, Liblogtest;
{$ENDIF}
{$IFDEF fwweb}
vcl.Controls, vcl.Forms, vcl.Graphics, vcl.Dialogs, vcl.StdCtrls, vcl.ExtCtrls,
vcl.tmsfncgraphicstypes, vcl.TMSFNCSplitter, vcl.tmsfncimage, vcl.TMSFNCGraphics, vcl.tmsfnctypes, vcl.TMSFNCCustomControl,

VCL.TMSFNCTreeViewBase, vcl.TMSFNCBitmapContainer,
VCL.TMSFNCTreeViewData, VCL.TMSFNCCustomTreeView, VCL.TMSFNCTreeView, vcl.menus,
VCL.TMSFNCObjectInspector, Liblogtest;

{$ENDIF}

type
  txix = class;

  txixEditEvent = procedure(EditVal: tvalue) of object;
  txixNodeEvent = procedure(sender: tobject; anode: ttmsfncTreeViewNode) of object;
  txixObjectEvent = procedure(sender: tobject; aObject: tObject) of object;
  txixDoubleEvent = procedure(sender: tobject; value: double) of object;
  txixGenericOPtion=(goNotify, goDoandNotify);
  TTMSFNCObjectInspectorGetGenericName = procedure(sender: tobject; aobject: tobject; acol: integer; var aName: string) of object;
  TTMSFNCObjectInspectorGenericAdded = procedure(sender: tobject; aObject, aObjectList: tobject; anode: ttmsfncTreeViewNode) of object;
  TTMSFNCObjectInspectorGenericRenamed = procedure(sender: tobject; aObject, aObjectList: tobject; var aNewName: string; aCanRename: boolean) of object;

  txIX = class(txIXClass)

  public
    //overrides - TTMSFNCTREEVIEW
    procedure SetFonts(ASetType: TTMSFNCAppearanceGlobalFontType); override;
    procedure ExportNotification(AState: TTMSFNCTreeViewExportState; ARow: Integer); override;
    procedure DoBeforeSortNodes(ASortColumn: Integer; ASortMode: TTMSFNCTreeViewNodesSortMode; var ACanSort: Boolean); override;
    procedure DoAfterSortNodes(ASortColumn: Integer; ASortMode: TTMSFNCTreeViewNodesSortMode); override;
    procedure DoBeforeDrawSortIndicator(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ASortIndex: Integer; ASortKind: TTMSFNCTreeViewNodesSortKind; var ADefaultDraw:
      Boolean); override;
    procedure DoAfterDrawSortIndicator(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ASortIndex: Integer; ASortKind: TTMSFNCTreeViewNodesSortKind); override;
    procedure DoBeforeCutToClipboard(var ACanCut: Boolean); override;
    procedure DoBeforeCopyToClipboard(var ACanCopy: Boolean); override;
    procedure DoBeforePasteFromClipboard(var ACanPaste: Boolean); override;
    procedure DoAfterCutToClipboard; override;
    procedure DoAfterCopyToClipboard; override;
    procedure DoAfterPasteFromClipboard; override;
    procedure DoBeforeReorderNode(AFromNode, AToNode: TTMSFNCTreeViewVirtualNode; var ACanReorder: Boolean); override;
    procedure DoAfterReorderNode(AFromNode, AToNode: TTMSFNCTreeViewVirtualNode); override;
    procedure DoBeforeDropNode(AFromNode, AToNode: TTMSFNCTreeViewVirtualNode; var ACanDrop: Boolean); override;
    procedure DoAfterDropNode(AFromNode, AToNode: TTMSFNCTreeViewVirtualNode); override;
    procedure DoBeforeDrawColumnEmptySpace(AGraphics: TTMSFNCGraphics; ARect: TRectF; ASpace: TTMSFNCTreeViewColumnEmptySpace; var AAllow: Boolean; var ADefaultDraw: Boolean);
    procedure DoAfterDrawColumnEmptySpace(AGraphics: TTMSFNCGraphics; ARect: TRectF; ASpace: TTMSFNCTreeViewColumnEmptySpace);
    procedure DoBeforeDrawGroupEmptySpace(AGraphics: TTMSFNCGraphics; ARect: TRectF; ASpace: TTMSFNCTreeViewGroupEmptySpace; var AAllow: Boolean; var ADefaultDraw: Boolean);
    procedure DoAfterDrawGroupEmptySpace(AGraphics: TTMSFNCGraphics; ARect: TRectF; ASpace: TTMSFNCTreeViewGroupEmptySpace);
    procedure DoBeforeDrawColumnHeader(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind; var AAllow: Boolean; var ADefaultDraw:
      Boolean);
      override;
    procedure DoAfterDrawColumnHeader(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind); override;
    procedure DoBeforeDrawColumn(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; var AAllow: Boolean; var ADefaultDraw: Boolean); override;
    procedure DoAfterDrawColumn(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer); override;
    procedure DoBeforeDrawNodeColumn(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; var AAllow: Boolean; var ADefaultDraw: Boolean); override;
    procedure DoAfterDrawNodeColumn(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer); override;
    procedure DoBeforeDrawGroup(AGraphics: TTMSFNCGraphics; ARect: TRectF; AGroup, AStartColumn, AEndColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind; var AAllow: Boolean; var
      ADefaultDraw: Boolean); override;
    procedure DoAfterDrawGroup(AGraphics: TTMSFNCGraphics; ARect: TRectF; AGroup, AStartColumn, AEndColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind); override;
    procedure DoBeforeDrawNode(AGraphics: TTMSFNCGraphics; ARect: TRectF; ANode: TTMSFNCTreeViewVirtualNode; var AAllow: Boolean; var ADefaultDraw: Boolean); override;
    procedure DoAfterDrawNode(AGraphics: TTMSFNCGraphics; ARect: TRectF; ANode: TTMSFNCTreeViewVirtualNode); override;
    procedure DoCustomizeFilterListBox(AColumn: Integer; AListBox: TListBox); override;
    procedure DoGetColumnText(AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind; var AText: string); override;
    procedure DoBeforeDrawColumnText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind; AText: string; var AAllow: Boolean); override;
    procedure DoAfterDrawColumnText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind; AText: string); override;
    procedure DoGetGroupText(AGroup: Integer; AKind: TTMSFNCTreeViewCacheItemKind; var AText: string); override;
    procedure DoBeforeDrawGroupText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AGroup, AStartColumn, AEndColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind; AText: string; var
      AAllow: Boolean); override;
    procedure DoAfterDrawGroupText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AGroup, AStartColumn, AEndColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind; AText: string); override;
    procedure DoNodeClick(ANode: TTMSFNCTreeViewVirtualNode); override;
    procedure DoNodeValueClick(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer); override;
    procedure DoNodeMouseLeave(ANode: TTMSFNCTreeViewVirtualNode); override;
    procedure DoNodeMouseEnter(ANode: TTMSFNCTreeViewVirtualNode); override;
    procedure DoNodeDblClick(ANode: TTMSFNCTreeViewVirtualNode); override;
    procedure DoGetNumberOfNodes(ANode: TTMSFNCTreeViewVirtualNode; var ANumberOfNodes: Integer); override;
    procedure DoGetNodeText(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; AMode: TTMSFNCTreeViewNodeTextMode; var AText: string); override;
    procedure DoGetNodeData(ANode: TTMSFNCTreeViewVirtualNode); override;
    procedure DoGetNodeTitle(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; AMode: TTMSFNCTreeViewNodeTextMode; var ATitle: string); override;
    procedure DoGetNodeTitleExpanded(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AExpanded: Boolean); override;
    procedure DoGetNodeTrimming(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATrimming: TTMSFNCGraphicsTextTrimming); override;
    procedure DoGetNodeHorizontalTextAlign(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AHorizontalTextAlign: TTMSFNCGraphicsTextAlign); override;
    procedure DoGetNodeVerticalTextAlign(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AVerticalTextAlign: TTMSFNCGraphicsTextAlign); override;
    procedure DoGetNodeWordWrapping(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AWordWrapping: Boolean); override;
    procedure DoGetNodeTitleTrimming(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATrimming: TTMSFNCGraphicsTextTrimming); override;
    procedure DoGetNodeTitleHorizontalTextAlign(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AHorizontalTextAlign: TTMSFNCGraphicsTextAlign); override;
    procedure DoGetNodeTitleVerticalTextAlign(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AVerticalTextAlign: TTMSFNCGraphicsTextAlign); override;
    procedure DoGetNodeTitleWordWrapping(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AWordWrapping: Boolean); override;
    procedure DoGetNodeExtraSize(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AExtraSize: Single); override;
    procedure DoGetNodeTitleExtraSize(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATitleExtraSize: Single); override;
    procedure DoGetColumnTrimming(AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind; var ATrimming: TTMSFNCGraphicsTextTrimming); override;
    procedure DoGetColumnHorizontalTextAlign(AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind; var AHorizontalTextAlign: TTMSFNCGraphicsTextAlign); override;
    procedure DoGetColumnVerticalTextAlign(AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind; var AVerticalTextAlign: TTMSFNCGraphicsTextAlign); override;
    procedure DoGetColumnWordWrapping(AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind; var AWordWrapping: Boolean); override;
    procedure DoGetNodeCheckType(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ACheckType: TTMSFNCTreeViewNodeCheckType); override;
    procedure DoGetNodeHeight(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AHeight: Double); override;
    procedure DoGetNodeIcon(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; ALarge: Boolean; var AIcon: TTMSFNCBitmap); override;
    procedure DoGetNodeIconSize(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; ALarge: Boolean; AIcon: TTMSFNCBitmap; var AIconWidth: Double; var AIconHeight: Double); override;
    procedure DoGetNodeSelectedColor(ANode: TTMSFNCTreeViewVirtualNode; var AColor: TTMSFNCGraphicsColor); override;
    procedure DoGetNodeSelectedStrokeColor(ANode: TTMSFNCTreeViewVirtualNode; var AColor: TTMSFNCGraphicsColor); override;
    procedure DoGetNodeSides(ANode: TTMSFNCTreeViewVirtualNode; var ASides: TTMSFNCGraphicsSides); override;
    procedure DoGetNodeRounding(ANode: TTMSFNCTreeViewVirtualNode; var ARounding: Integer; var ACorners: TTMSFNCGraphicsCorners); override;
    procedure DoGetNodeDisabledColor(ANode: TTMSFNCTreeViewVirtualNode; var AColor: TTMSFNCGraphicsColor); override;
    procedure DoGetNodeDisabledStrokeColor(ANode: TTMSFNCTreeViewVirtualNode; var AColor: TTMSFNCGraphicsColor); override;
    procedure DoGetNodeColor(ANode: TTMSFNCTreeViewVirtualNode; var AColor: TTMSFNCGraphicsColor); override;
    procedure DoGetNodeStrokeColor(ANode: TTMSFNCTreeViewVirtualNode; var AColor: TTMSFNCGraphicsColor); override;
    procedure DoGetNodeSelectedTextColor(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATextColor: TTMSFNCGraphicsColor); override;
    procedure DoGetNodeDisabledTextColor(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATextColor: TTMSFNCGraphicsColor); override;
    procedure DoGetNodeTextColor(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATextColor: TTMSFNCGraphicsColor); override;
    procedure DoGetNodeSelectedTitleColor(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATitleColor: TTMSFNCGraphicsColor); override;
    procedure DoGetNodeDisabledTitleColor(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATitleColor: TTMSFNCGraphicsColor); override;
    procedure DoGetNodeTitleColor(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATitleColor: TTMSFNCGraphicsColor); override;
    procedure DoIsNodeExpanded(ANode: TTMSFNCTreeViewVirtualNode; var AExpanded: Boolean); override;
    procedure DoIsNodeExtended(ANode: TTMSFNCTreeViewVirtualNode; var AExtended: Boolean); override;
    procedure DoIsNodeChecked(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AChecked: Boolean); override;
    procedure DoIsNodeVisible(ANode: TTMSFNCTreeViewVirtualNode; var AVisible: Boolean); override;
    procedure DoIsNodeEnabled(ANode: TTMSFNCTreeViewVirtualNode; var AEnabled: Boolean); override;
    procedure DoBeforeDrawNodeText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; AText: string; var AAllow: Boolean); override;
    procedure DoAfterDrawNodeText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; AText: string); override;
    procedure DoBeforeDrawNodeTitle(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; ATitle: string; var AAllow: Boolean); override;
    procedure DoAfterDrawNodeTitle(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; ATitle: string); override;
    procedure DoBeforeDrawNodeIcon(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; AIcon: TTMSFNCBitmap; var AAllow: Boolean); override;
    procedure DoAfterDrawNodeIcon(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; AIcon: TTMSFNCBitmap); override;
    procedure DoBeforeDrawNodeExtra(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; var AAllow: Boolean); override;
    procedure DoDrawNodeExtra({%H-}AGraphics: TTMSFNCGraphics; {%H-}ARect: TRectF; {%H-}AColumn: Integer; {%H-}ANode: TTMSFNCTreeViewVirtualNode); override;
    procedure DoAfterDrawNodeExtra(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode); override;
    procedure DoBeforeDrawNodeTitleExtra(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; var AAllow: Boolean); override;
    procedure DoDrawNodeTitleExtra({%H-}AGraphics: TTMSFNCGraphics; {%H-}ARect: TRectF; {%H-}AColumn: Integer; {%H-}ANode: TTMSFNCTreeViewVirtualNode); override;
    procedure DoAfterDrawNodeTitleExtra(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode); override;
    procedure DoBeforeDrawNodeCheck(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; ACheck: TTMSFNCBitmap; var AAllow: Boolean);
      override;
    procedure DoAfterDrawNodeCheck(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; ACheck: TTMSFNCBitmap); override;
    procedure DoBeforeDrawNodeExpand(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; AExpand: TTMSFNCBitmap; var AAllow: Boolean);
      override;
    procedure DoAfterDrawNodeExpand(AGraphics: TTMSFNCGraphics; ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode; AExpand: TTMSFNCBitmap); override;
    procedure DoBeforeSelectNode(ANode: TTMSFNCTreeViewVirtualNode; var ACanSelect: Boolean); override;
    procedure DoBeforeSelectAllNodes(var ACanSelect: Boolean); override;
    procedure DoBeforeUnSelectNode(ANode: TTMSFNCTreeViewVirtualNode; var ACanUnSelect: Boolean); override;
    procedure DoCustomizeInplaceEditor(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; AInplaceEditor: TTMSFNCTreeViewInplaceEditor); override;
    procedure DoBeforeSizeColumn(AColumn: Integer; AColumnSize: Double; var ANewColumnSize: Double; var AAllow: Boolean); override;
    procedure DoAfterSizeColumn(AColumn: Integer; AColumnSize: Double); override;
    procedure DoBeforeUpdateNode(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AText: string; var ACanUpdate: Boolean); override;
    procedure DoNodeChanged(ANode: TTMSFNCTreeViewVirtualNode); override;
    procedure DoAfterUpdateNode(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer); override;
    procedure DoUpdateNodeText({%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; {%H-}AText: string); override;
    procedure DoUpdateNodeTitle({%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; {%H-}ATitle: string); override;
    procedure DoBeforeExpandNode(ANode: TTMSFNCTreeViewVirtualNode; var ACanExpand: Boolean); override;
    procedure DoAfterExpandNode(ANode: TTMSFNCTreeViewVirtualNode); override;
    procedure DoBeforeCollapseNode(ANode: TTMSFNCTreeViewVirtualNode; var ACanCollapse: Boolean); override;
    procedure DoAfterCollapseNode(ANode: TTMSFNCTreeViewVirtualNode); override;
    procedure DoBeforeCheckNode(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ACanCheck: Boolean); override;
    procedure DoAfterCheckNode(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer); override;
    procedure DoBeforeUnCheckNode(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ACanUnCheck: Boolean); override;
    procedure DoAfterUnCheckNode(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer); override;
    procedure DoNodeAnchorClick(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; AAnchor: string); override;
    procedure DoNodeTitleAnchorClick(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; AAnchor: string); override;
    procedure DoColumnAnchorClick(AColumn: Integer; AAnchor: string); override;
    procedure DoGetHTMLTemplateValue(ANodeValue: TTMSFNCTreeViewNodeValue; AName: string; var AValue: string); override;
    procedure DoGetHTMLTemplate(ANodeValue: TTMSFNCTreeViewNodeValue; AColumnIndex: Integer; var AHTMLTemplate: string); override;

    // procedure HandleMouseUp(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single); override;
     //procedure HandleMouseDown(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single); override;
    procedure IXMouseDown(Sender: TObject; Button: TMouseButton; Shift:
      TShiftState; X, Y: Integer);
    // procedure MouseDown(Button: TTMSFNCMouseButton; Shift: TShiftState; X,  Y: Integer); override;
     //Overrides - TTMSFNCOBJECTINSPECTOR
    function GetDocURL: string; override;
    procedure UpdateFields;
    procedure LoadFields;
    procedure InternalRebuildList(AUpdate: Boolean = False);
    procedure Notification(AComponent: TComponent; AOperation: TOperation); override;
    procedure StopInternalEditing;
    procedure DoInplaceEditorTimer(Sender: TObject);
    procedure DoColorSelected(Sender: TObject; AColor: TTMSFNCGraphicsColor);
    procedure DoDateSelected(Sender: TObject; ADate: TDate);
    procedure DoTimeSelected(Sender: TObject; ATime: TTime);
    procedure DoFillKindSelected(Sender: TObject; AFillKind: TTMSFNCGraphicsFillKind);
    procedure DoStrokeKindSelected(Sender: TObject; AStrokeKind: TTMSFNCGraphicsStrokeKind);
    procedure DoComboChange(Sender: TObject);
    procedure DoGetInplaceEditor(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; {$IFDEF FMXLIB}var ATransparent: Boolean; {$ENDIF}var AInplaceEditorClass:
      TTMSFNCTreeViewInplaceEditorClass); override;
    procedure DoBeforeOpenInplaceEditor(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ACanOpen: Boolean); override;
    procedure DoAfterOpenInplaceEditor(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; AInplaceEditor: TTMSFNCTreeViewInplaceEditor; AInplaceEditorRect: TRectF); override;
    procedure DoBeforeOpenObjectEditor(AObject: TObject; APropertyInfo: TTMSFNCPropertyInfo; APropertyName: string; APropertyType: TTypeKind; APropertyValueObject: TObject; var
      ACanOpen: Boolean); override;

    procedure DoReadPropertyValue(AObject: TObject; APropertyInfo: TTMSFNCPropertyInfo; APropertyName: string; APropertyType: TTypeKind; var APropertyValue: string; var
      APropertyValueObject: TObject; var ACanRead: Boolean); override;
    procedure DoWritePropertyValue(AObject: TObject; APropertyInfo: TTMSFNCPropertyInfo; APropertyName: string; APropertyType: TTypeKind; var APropertyValue: string; var
      APropertyValueObject: TObject; var ACanWrite: Boolean); override;
    procedure DoPropertyValueChanged(AObject: TObject; APropertyInfo: TTMSFNCPropertyInfo; APropertyName: string; APropertyType: TTypeKind; APropertyValue: string;
      APropertyValueObject: TObject); override;
    procedure ReadObject(AObject: TObject; ANode: TTMSFNCTreeViewNode = nil); override;
    procedure ReadProperties(AObject: TObject; ANode: TTMSFNCTreeViewNode = nil); override;
    procedure ReadProperty(AObject: TObject; PropInfo: TTMSFNCPropertyInfo; ANode: TTMSFNCTreeViewNode = nil; AReplaceNode: TTMSFNCTreeViewNode = nil); override;
    procedure ReadGenericList(AList: TTMSFNCObjectList; ANode: TTMSFNCTreeViewNode = nil); override;
    procedure ReadCollection(ACollection: TCollection; ANode: TTMSFNCTreeViewNode = nil); override;
    procedure ReadBitmap(ABitmap: TTMSFNCBitmap; ANode: TTMSFNCTreeViewNode = nil); override;
    procedure ReadSingleObject(AObject: TObject; ANode: TTMSFNCTreeViewNode = nil); override;
    procedure Read(AObject: TObject); override;
    procedure ReadDB(AUpdate: Boolean = False); override;
    procedure WritePropInfoValue(AInstance: TObject; APropInfo: TTMSFNCPropertyInfo; AValue: string; AValueObject: TObject; ANode: TTMSFNCTreeViewNode); override;
    procedure DoCloseInplaceEditor(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; AInplaceEditor: TTMSFNCTreeViewInplaceEditor; ACancelled: Boolean; var ACanClose: Boolean);
      override;

    constructor create(aOwner: tcomponent); override;

    procedure UpdateDisplay; override;
    procedure UpdateColumnsCache; override;
    procedure doOnExit(sender: tobject);
    function FindObjectNode(aobject: tobject; StartRoot: ttmsfncTreeViewNode): ttmsfncTreeViewNode;
    function FindObjectPropNode(anode: ttmsfncTreeViewNode; aPropInfo: TTMSFNCPropertyInfo): ttmsfncTreeViewNode;
  protected
    procedure DoReadPropertyN(AObject: TObject; APropertyInfo: TTMSFNCPropertyInfo; APropertyName: string; APropertyType: TTypeKind; anode: ttmsfncTreeViewNode; var ACanRead: Boolean);

    //    procedure DoReadProperty(AObject: TObject; APropertyInfo: TTMSFNCPropertyInfo; APropertyName: string; APropertyType: TTypeKind; anode: ttmsfncTreeViewNode; var ACanRead: Boolean);
      //    override;

  private
    FexList: tStringlist;
    FLineStyle: txixLineStyle;
    FAutoWidth: boolean;
    FAutoHeight: boolean;
    FaObject: tObject;
    FisAutoSizing: boolean;
    FOnAfterAutoSize: tNotifyEvent;
    FFixedNodeHeight: integer;
    FixType: txixType;
    FNoSelectStyle: boolean;
    FnoLHS: boolean;
    FBGstyle: txBGStyle;
    FBGColor: TTMSFNCGraphicsColor;
    FLineColor: ttmsfncGraphicsColor;
    FTextColor: ttmsfncGraphicsColor;
    FSelTextColor: ttmsfncGraphicsCOlor;
    FSelNodeColor: ttmsfncGraphicsColor;
    FSelLineColor: ttmsfncGraphicsColor;
    FColTextLeft: string;
    FColTextRight: string;
    FtopFont: tFont;
    FShowChanges: boolean;
    FChangeTimeout: integer;
    FChangedColor: TTMSfncGraphicsColor;
    FBorderColor: ttmsfncGraphicsColor;
    FColLeftMargin: integer;
    FColVMargin: integer;
    fColHMargin: integer;
    FBRLineBreaks: boolean;
    FCopyonDblClick: boolean;
    FIconSize: integer;
    FFullWidth: boolean;
    FonEditing: txixEditEvent;
    FEditor: TTMSFNCTreeViewInplaceEditor;
    FEditValue: string;
    FonEdit: txixEditEvent;
    FonFinishEditing: tNotifyEvent;
    FExpanders: boolean;
    FAlternatingRows: boolean;
    FAlternatingColor: TTMSFNCGraphicsColor;
    FincList: tStringList;
    FIncMode: txIncMode;
    FSelectedObject: tObject;
    FActiveNode: ttmsfncTreeViewNode;
    FonActiveNodeCHange: txixNodeEvent;
    FRootExpandsChildren: boolean;
    FfeRoot: boolean;
    FfcRoot: boolean;
    FActiveObject: tObject;
    FOnActiveObjectChange: txixObjectEvent;
    FDisableTestSizing: boolean;
    FListAdd: string;
    FListWidth: integer;
    FAllowGenericRename: boolean;
    FonGenericRenamed: TTMSFNCObjectInspectorGenericRenamed;
    FShowListObjects: boolean;
    FonGetGenericName: TTMSFNCObjectInspectorGetGenericName;
    FShowListRemove: boolean;
    FShowLists: boolean;
    FonGenericAdded: TTMSFNCObjectInspectorGenericAdded;
    FListRemove: string;
    FShowListAdd: boolean;
    FListAddCol: integer;
    FListRemoveCOl: integer;
    FListCountCol: integer;
    FEnableAutoWidth: boolean;
    FEnableColAutoWidth: boolean;
    FEnableAutoHeight: boolean;
    FinitDone: boolean;
    FColSeperatorColor: TTMSFNCGraphicsColor;
    FFontSize: Integer;
    FshowColumns: boolean;
    FEnableMenu: boolean;
    FixStyle: txIXStyle;
    FTopHeight: integer;
    FNormalFontSize: integer;
    FctextHeight: single;
    FrootOption: txfstvRoot;
    FTopColor: ttmsfncGraphicsColor;
    FFixedHeight: integer;
    FExtendedListNode: boolean;
    FExtendedNodeColor: ttmsfncGraphicsColor;
    FExtendedNodeTextColor: ttmsfncGraphicsColor;
    FhMargin: integer;
    FvMargin: integer;
    FFullWidthCol: String;
    FReqColWidth: double;
    FonREqColWidthChange: txixDoubleEvent;
    FListAddOption: txixGenericOption;
    FListDeleteOption: txixGenericOPtion;
    FonGenericDeleted: TTMSFNCObjectInspectorGenericAdded;
    FonCancelEditing: tNotifyEvent;
    FRootName: string;
    procedure setaObject(const Value: tObject; aname: string); overload;
    procedure setaObject(const Value: tObject); overload;

    procedure WaitDone(sender: tobject);
    procedure SetFixedNodeHeight(const Value: integer);
    procedure SetIXType(const Value: txixType);
    procedure SetNoSelectStyle(const Value: boolean);
    procedure SetNoLHS(const Value: boolean);
    procedure SetBGStyle(const Value: txBGStyle);
    procedure setBGColor(const Value: TTMSFNCGraphicsColor);
    procedure SetLineColor(const Value: ttmsfncGraphicsColor);
    procedure SetColTextLeft(const Value: string);
    procedure SetColTextRight(const Value: string);
    procedure SetBorderColor(const Value: ttmsfncGraphicsColor);
    function GetEditProp: TTMSFNCPropertyInfo;
    function GetTypeKind: tTypeKind;
    function GetEditValue: string;
    procedure SetExpanders(const Value: boolean);
    procedure SetLineStyle(const Value: txixLineStyle);
    function GetSelectedObject: tObject;
    procedure SetActiveNode(const Value: ttmsfncTreeViewNode);
    function GetActiveObject: tObject;
    function GetGenericName(aobject: tobject): string;
    procedure TimerColAutoWidthTImer(sender: tobject);
    procedure SetcTextHeight(const Value: single);
    procedure SetEnableMenu(const Value: boolean);
    procedure SetFontSize(const Value: Integer);
    procedure setIXStyle(const Value: txIXStyle);
    procedure SetShowColumns(const Value: boolean);
    procedure SetTopHeight(const Value: integer);
    procedure SetFixedHeight(const Value: integer);
    function getFullWidthColIdx: integer;
    procedure SetReqColWidth(const Value: double);
    procedure IconsReady(sender: tobject);

    //procedure MouseDown(Button: TTMSFNCMouseButton; Shift: TShiftState; X,
    //  Y: Integer);
  public

    EditTimer: ttimer;
    UpdateTimer: tTimer;
    AutoSizeTimer: ttimer;
    icons: txIconsDL;
    expandicon, collapseicon, rooticonOpen, rootIconClosed: txIcon;
    NeededWidth: integer;
    TimerColAutoWidth: TTimer;
    Expanding: boolean;
    pm: tpopupMenu;
    procedure REsize; override;
    procedure FullCollapse;
    procedure FullExpand;

    {$IFNDEF fwweb}
    function ApplyBGStyle: boolean;
    function ApplyLineStyle: boolean;
    function ApplySelStyle: boolean;
    function ApplyTextStyle: boolean;
    function ApplyAllStyles: boolean;
    function ApplyBorder: boolean;
    {$ENDIF}
    procedure doAutoHeight(aTimed: integer = -1);
    procedure doAutoWidth(aTimed: integer = -1);
    procedure doColAutoWidth(aTimed: integer = -1);

    procedure onAutoSizeTimerTimer(sender: tobject);
    procedure onUpdateTimerTimer(sender: tobject);
    procedure UpdateValues;
    procedure TimedUpdateValues;
    procedure onEditTimer(sender: tobject);

    function cn(idx: integer): string;
    function col(idx: integer): ttmsfncTreeViewColumn; overload;
    function col(aname: string): ttmsfncTreeViewColumn; overload;
    procedure InitMenu;
    procedure pmclick(sender: tobject);
    procedure pmOpen(sender: tobject);
    function pmAdd(acaption: string): tmenuItem;
    function NewCol(atext, aname: string; aAllowShow: boolean; awidth: integer = -1): ttmsfncTreeViewColumn;
    procedure APplyColumnStyles;

    procedure DoAddItem(anode: ttmsfncTreeViewNode);
    procedure DoDeleteItem(anode: ttmsfncTreeViewNode);

    function CL: ttmsfncTreeViewColumn;
    function CR: ttmsfncTreeViewColumn;
    function CA: TTMSFNCTreeViewColumnsAppearance;
    function NA: TTMSFNCTreeViewNodesAppearance;
    procedure init;
    procedure ap(aprop: string);
    procedure ForceExpandRoot;
    procedure ForceCollapseRoot;
    function Find(aObjectData: tobject): ttmsfncTreeViewNode;
    function isAllowed(aprop: string): boolean;
    procedure doAutoSize(atimed: integer = -1);
    procedure doTimedAutoSize(WT: integer = 10);
    procedure doAllFontChange(sender: tobject);
    function GetNodeHeights: integer;
    function DrawnAll: boolean;
    function GetNodeHash(anode: ttmsfncTreeViewNode=nil): string;
    function FIndNodeHash(aHash: string): ttmsfncTreeViewNode;
    function SelectNodeHash(aHash: string): ttmsfncTreeViewNode;
    procedure Reload(NewObject: tobject; KeepSel: boolean=true);
     function FullPropName: string;
    //RTTI / lists

    function GetListType(ObjectList: TObject): TClass;
    function CreateListObject(aClass: tClass): tobject;
    function AddtoObjectList(ObjectList: tobject): tobject;
    function RemoveFromObjectList(ObjectList: tobject; aItem: tobject): Integer;
    function SP(aObject: tobject; aPropName: string; avalue: tvalue): boolean;
    function GP(aObject: tobject; aPropName: string): tValue;
    procedure ScrolltoActive;
    procedure SetColIcon(acol: integer; aIcon: string);
    procedure APplyDefaultStyle;
    procedure SetEditValue(s: string);
    procedure InternalEditing(sender: tobject);
    // procedure WaitDone(sender: tobject);
    property FixedNodeHeight: integer read FFixedNodeHeight write SetFixedNodeHeight;
    property EditProp: TTMSFNCPropertyInfo read GetEditProp;
  published
    property exList: tStringlist read FexList write FexList;
    property incList: tStringList read FincList write FincList;
    property LineStyle: txixLineStyle read FLineStyle write SetLineStyle;
    property AutoWidth: boolean read FAutoWidth write FAutoWidth;
    property AutoHeight: boolean read FAutoHeight write FAutoHeight;
    property aObject: tObject read FaObject write setaObject;
    property RootName: string read FRootName write FRootName;
    property isAutoSizing: boolean read FisAutoSizing write FisAutoSizing;

    property ixType: txixType read FixType write SetIXType;
    property NoSelectStyle: boolean read FNoSelectStyle write SetNoSelectStyle;
    property BGstyle: txBGStyle read FBGstyle write SetBGStyle;
    property BGColor: TTMSFNCGraphicsColor read FBGColor write setBGColor;
    property TopColor: ttmsfncGraphicsColor read FTopColor write FTopColor;
    property AlternatingColor: TTMSFNCGraphicsColor read FAlternatingColor write FAlternatingColor;
    property LineColor: ttmsfncGraphicsColor read FLineColor write SetLineColor;
    property TextColor: ttmsfncGraphicsColor read FTextColor write FTextColor;
    property SelTextColor: ttmsfncGraphicsCOlor read FSelTextColor write FSelTextColor;
    property SelNodeColor: ttmsfncGraphicsColor read FSelNodeColor write FSelNodeColor;
    property SelLineColor: ttmsfncGraphicsColor read FSelLineColor write FSelLineColor;
    property BorderColor: ttmsfncGraphicsColor read FBorderColor write SetBorderColor;
    property ColTextLeft: string read FColTextLeft write SetColTextLeft;
    property ColTextRight: string read FColTextRight write SetColTextRight;
    property noLHS: boolean read FnoLHS write SetNoLHS;
    property topFont: tFont read FtopFont write FtopFont;
    property ShowChanges: boolean read FShowChanges write FShowChanges;
    property ChangeTimeout: integer read FChangeTimeout write FChangeTimeout;
    property ChangedColor: TTMSfncGraphicsColor read FChangedColor write FChangedColor;
    property ColHMargin: integer read fColHMargin write fColHMargin;
    property ColVMargin: integer read FColVMargin write FColVMargin;
    property BRLineBreaks: boolean read FBRLineBreaks write FBRLineBreaks;
    property CopyonDblClick: boolean read FCopyonDblClick write FCopyonDblClick;
    property IconSize: integer read FIconSize write FIconSize;
    property FullWidth: boolean read FFullWidth write FFullWidth;
    property FullWidthCol: String read FFullWidthCol write FFullWidthCol;
    property FullWidthColIdx: integer read getFullWidthColIdx;
    property ExtendedNodeColor: ttmsfncGraphicsColor read FExtendedNodeColor write FExtendedNodeColor;
    property ExtendedNodeTextColor: ttmsfncGraphicsColor read FExtendedNodeTextColor write FExtendedNodeTextColor;

    property EditKind: tTypeKind read GetTypeKind;
    property onEditing: txixEditEvent read FonEditing write FonEditing;
    property onEdit: txixEditEvent read FonEdit write FonEdit;
    property EditValue: string read GetEditValue write SetEditValue;
    property Editor: TTMSFNCTreeViewInplaceEditor read FEditor write FEditor;
    property onFinishEditing: tNotifyEvent read FonFinishEditing write FonFinishEditing;
    property onCancelEditing: tNotifyEvent read FonCancelEditing write FonCancelEditing;
    property Expanders: boolean read FExpanders write SetExpanders;
    property AlternatingRows: boolean read FAlternatingRows write FAlternatingRows;
    property IncMode: txIncMode read FIncMode write FIncMode;
    property SelectedObject: tObject read GetSelectedObject write FSelectedObject;
    property onAfterAutoSize: tNotifyEvent read FonAfterAutoSize write FonAfterAutoSize;
    property ActiveNode: ttmsfncTreeViewNode read FActiveNode write SetActiveNode;
    property onActiveNodeCHange: txixNodeEvent read FonActiveNodeCHange write FonActiveNodeCHange;
    property ActiveObject: tObject read GetActiveObject write FActiveObject;
    property OnActiveObjectChange: txixObjectEvent read FOnActiveObjectChange write FOnActiveObjectChange;
    property RootExpandsChildren: boolean read FRootExpandsChildren write FRootExpandsChildren;
    property feRoot: boolean read FfeRoot write FfeRoot;
    property fcRoot: boolean read FfcRoot write FfcRoot;
    property DisableTestSizing: boolean read FDisableTestSizing write FDisableTestSizing;
    property initDone: boolean read FinitDone write FinitDone;
    //Lists
    property ListAddOption: txixGenericOption read FListAddOption write FListAddOption;
    property ListDeleteOption: txixGenericOPtion read FListDeleteOption write FListDeleteOption;
    property ExtendedListNode: boolean read FExtendedListNode write FExtendedListNode;
    property onGetGenericName: TTMSFNCObjectInspectorGetGenericName read FonGetGenericName write FonGetGenericName;
    property onGenericAdded: TTMSFNCObjectInspectorGenericAdded read FonGenericAdded write FonGenericAdded;
    property onGenericDeleted: TTMSFNCObjectInspectorGenericAdded read FonGenericDeleted write FonGenericDeleted;
    property ShowLists: boolean read FShowLists write FShowLists;
    property ShowListObjects: boolean read FShowListObjects write FShowListObjects;
    property onGenericRenamed: TTMSFNCObjectInspectorGenericRenamed read FonGenericRenamed write FonGenericRenamed;
    property AllowGenericRename: boolean read FAllowGenericRename write FAllowGenericRename;

    property ShowListAdd: boolean read FShowListAdd write FShowListAdd;
    property ShowListRemove: boolean read FShowListRemove write FShowListRemove;
    property ListWidth: integer read FListWidth write FListWidth;
    property ListAdd: string read FListAdd write FListAdd;
    property ListRemove: string read FListRemove write FListRemove;
    property ListAddCol: integer read FListAddCol write FListAddCol;
    property ListRemoveCOl: integer read FListRemoveCOl write FListRemoveCOl;
    property ListCountCol: integer read FListCountCol write FListCountCol;
    //Autowidth/height
    property EnableColAutoWidth: boolean read FEnableColAutoWidth write FEnableColAutoWidth;
    property EnableAutoWidth: boolean read FEnableAutoWidth write FEnableAutoWidth;
    property EnableAutoHeight: boolean read FEnableAutoHeight write FEnableAutoHeight;
    property ctextHeight: single read FctextHeight write SetcTextHeight;
    property showColumns: boolean read FshowColumns write SetShowColumns;
    property ixStyle: txIXStyle read FixStyle write setIXStyle;
    property TopHeight: integer read FTopHeight write SetTopHeight;
    property FontSize: Integer read FFontSize write SetFontSize;
    property ColSeperatorColor: TTMSFNCGraphicsColor read FColSeperatorColor write FColSeperatorColor;
    property EnableMenu: boolean read FEnableMenu write SetEnableMenu;
    property rootOption: txfstvRoot read FrootOption write FrootOption;
    property NormalFontSize: integer read FNormalFontSize write FNormalFontSize;
    property FixedHeight: integer read FFixedHeight write SetFixedHeight;
    property hMargin: integer read FhMargin write FhMargin;
    property vMargin: integer read FvMargin write FvMargin;
    property ReqColWidth: double read FReqColWidth write SetReqColWidth;
    property onREqColWidthChange: txixDoubleEvent read FonREqColWidthChange write FonREqColWidthChange;

  end;

implementation

{ txIX }

procedure txix.IconsReady(sender: tobject);
procedure Loaded1;
var
 aIcon: ttmsfncBitmap;
begin
  aIcon :=icons.bc.findbitmap( expandIcon.bn);
  //aIcon.SetSize(16,16);
  na.expandnodeicon:=aIcon;
  na.ExpandNodeIconLarge:=aIcon;


end;
procedure Loaded2;
var
 aIcon: ttmsfncBitmap;
   begin
  aIcon :=icons.bc.findbitmap( collapseIcon.bn);
 // aIcon.SetSize(16,16);
  na.collapseNodeIcon.assign(aIcon);
  na.collapseNodeIcon.Assign(aIcon);
end;

var
 aIcon: ttmsfncBitmap;
 begin
 icons.geticon('openfolder-svg');
  icons.geticon('folder-svg');
  icons.geticon('file-svg');
  icons.geticon('up-svg');
   rootIconClosed:=icons.geticon('rootOrange-svg');
   rootIconOpen:=icons.geticon('root-svg');

      {$IFDEF fwweb}
      //beginupdate;

  expandIcon:=icons.geticon('expand-svg', @Loaded1);
  CollapseIcon:=icons.geticon('collapse-svg', @Loaded2);



  //endupdate;
  {$ELSE}
    expandIcon:=icons.geticon('expandwhite-svg');
  CollapseIcon:=icons.geticon('collapsewhite-svg');

   na.CollapseNodeIcon.LoadFromFile(collapseicon.fn);
   na.ExpandNodeIcon.LoadFromFile(expandicon.fn);
    na.CollapseNodeIconLarge.LoadFromFile(collapseicon.fn);
   na.ExpandNodeIconLarge.LoadFromFile(expandicon.fn);


 {  na.ExpandNodeIconLarge.svg.CustomFillColor:=gcWhite;
   na.ExpandNodeIcon.svg.CustomFillColor:=gcWhite;
   na.CollapseNodeIcon.svg.CustomFillColor:=gcWhite;
   na.CollapseNodeIconLarge.svg.CustomFillColor:=gcWhite;}


  {$ENDIF}

end;

procedure txix.FullCollapse;
begin
  beginupdate;
  Expanding := true;
  nodeman.ResetCW;
  CollapseAll;

  nodes[0] .expand(false);
  Expanding := false;
  EndUpdate;
  doColAutoWidth(0);

end;

procedure txix.FullExpand;
begin

  beginupdate;
  Expanding := true;
  ExpandAll;
  Expanding := false;
  EndUpdate;
  doColAutoWidth(0);
end;

function txIX.FullPropName: string;
var
 n: TTMSFNCTreeViewNode;
begin

  result:='';

 if  assigned(fActiveNode) then
 begin
 n:=fActiveNode;
 while assigned(n) do
 begin
   result:=result+' > '+n.text[0];
   n:=GetParentNode(n);
 end;
 end;

 if rootname<>'' then
 begin
 if result<>'' then

result:=rootname+' > '+result else result:=rootname;
 end;


end;

procedure txix.TimerColAutoWidthTImer(sender: tobject);
begin
  ttimer(sender).Enabled := false;
  doColAutoWidth;
end;

procedure txIX.onAutoSizeTimerTimer(sender: tobject);
begin
  ttimer(sender).Enabled := false;
  doAutoSize;
end;

procedure txix.doAutoHeight(aTimed: integer = -1);
begin
  if initdone = false then exit;

  if not enableautoHeight then exit;
  alog.send('Doing autoHeight');
  {$IFDEF fwfmx}

  height := trunc(GetNodeHeights + ca.TopSize + 2);
  {$ELSE}
  clientheight := trunc(GetNodeHeights + ca.TopSize + 2);
  {$ENDIF}
end;

procedure txix.doAutoWidth(aTimed: integer = -1);
var
  i                 : integer;
  ac                : ttmsfncTreeViewColumn;
  w                 : integer;
begin
  if initdone = false then exit;
  if not EnableAutoWidth then exit;
  alog.send('Doing autowidth');
  w := 0;

  for i := 1 to columns.count do
  begin
    ac := columns[i - 1] ;
    if ac.Visible then
      w := w + ac.data.width;
  end;
  if width <> w then width := w;

end;

procedure txix.doColAutoWidth(aTimed: integer = -1);
var
  i                 : integer;
  w                 : integer;
  cw: double;
  fw: double;
begin
  try

    if initdone = false then exit;
    if atimed <> -1 then
    begin
      if timercolautowidth.Enabled then exit;

      if atimed <> 0 then
        timerColAutoWidth.Interval := aTimed
      else
        timerColAutoWidth.Interval := timerColAutoWidth.tag;
      timerColAutoWidth.Enabled := true;
      exit;
    end;

    if EnableColAutoWidth = true then
    begin
      //basically stretch col legacy ix asssuming two cols
    //  alog.send('Doing ColAutoWidth');
      if FullWidth then
      begin

        if getcolumnwidth(0) <> columns[0] .data.cw then
        begin
          columns[0] .Width := columns[0] .data.cw + NA.expandWidth;

        end;
        columns[0] .data.resetcw;
        columns[1] .width := width - columns[0] .width - verticalScrollBar.width - 5;

      end
      else
      begin
        cw:=0;
        for i := 1 to columns.count do
        begin

          if columns[i - 1] .Visible then

            if getcolumnwidth(i - 1) <> columns[i - 1] .data.cw then
            begin
              if I = 1 then
                columns[i - 1] .Width := columns[i - 1] .data.cw + NA.ExpandWidth
              else
                columns[i - 1] .Width := columns[i - 1] .data.cw;
             cw:=cw+columns[i - 1] .Width;
            end;
          columns[i - 1] .data.resetcw;
        end;
        ReqColWidth:=cw;
        fw:=width;
        if verticalScrollBar.Visible then fw:=fw-verticalScrollBar.width;

         if FullWidthColIdx<>-1 then
         begin
           if cw<fw then
           begin
             columns[FullWidthColIdx].width:=columns[FullWidthColIdx].width+(fw-cw);
           end;
         end;
        //w := trunc(columns[0] .data.width + columns[1] .data.width);
      //  alog.send('Done colajutow');
        // NeededWidth := w;

      end;
    end;
  except
    on e: exception do
    begin
      alog.error('colAutoSize', e.message);
    end;

  end;
end;

function txix.FindObjectNode(aobject: tobject;
  StartRoot: ttmsfncTreeViewNode): ttmsfncTreeViewNode;
var
  i                 : integer;
  anode             : ttmsfncTreeViewNode;
begin
  result := nil;
  for i := 1 to StartRoot.nodes.count do
  begin
    anode := startRoot.nodes[i - 1] ;
    if anode.data.objectdata = aObject then
    begin
      result := anode;
      exit;
    end;
  end;

end;

function txix.FindObjectPropNode(
  anode: ttmsfncTreeViewNode;
  aPropInfo: TTMSFNCPropertyInfo): ttmsfncTreeViewNode;
var
  i                 : integer;
  n                 : ttmsfncTreeViewNode;
begin
  result := nil;
  for i := 1 to anode.nodes.count do
  begin
    n := anode.nodes[i - 1] ;
    if anode.DataPointer = aPropInfo then
    begin
      result := n;
      exit;
    end;

  end;

end;

function txix.GetGenericName(aobject: tobject): string;
var
  s                 : string;
begin
  { result:='';
   if assigned(onGetGenericName) then
   begin
    onGetGenericName(self, aObject, s);
    result:=s;
   end;      }

end;

procedure txix.ReadProperty(AObject: TObject; PropInfo: TTMSFNCPropertyInfo; ANode: TTMSFNCTreeViewNode = nil; AReplaceNode: TTMSFNCTreeViewNode = nil);
var
  pName             : string;
  n, nl, pn         : TTMSFNCTreeViewNode;
  k                 : TTypeKind;
  vls               : string;
  o                 : TObject;
  b                 : Boolean;
  I                 : integer;
  aList             : TTMSFNCObjectList;
  lInfo             : TTMSFNCPropertyInfo;
  gname             : string;
  s                 : string;
  ind               : integer;
  checkNode         : ttmsfncTreeViewNode;
begin
  if not Assigned(PropInfo) or IsWriteOnly(PropInfo) then
    Exit;

  pName := GetPropInfoName(PropInfo);
  k := GetPropInfoType(PropInfo);

  b := True;

  if Assigned(AReplaceNode) then
    checkNode := AReplaceNode
  else
    checknode := anode;

  doReadProperty(aObject, propinfo, pname, k, b);
  DoReadPropertyN(AObject, PropInfo, pName, k, checknode, b);

  if not b then
    Exit;

  if (((Mode = oimProperties) and not (k = tkMethod)) or ((Mode = oimEvents) and (k = tkMethod))) and not (k = tkUnknown) then
  begin
    if Assigned(AReplaceNode) then
      n := AReplaceNode
    else
      n := AddNode(ANode);

    n.Text[0] := pName;
    n.DataPointer := PropInfo;
    n.DataObject := AObject;
    alog.send('Read Property', pname);
    if k in [tkClass] then
    begin
      b := True;
      o := GetObjectProp(AObject, pName);
      vls := '';
      DoReadPropertyValue(AObject, PropInfo, pName, k, vls, o, b);
      if not b then
        Exit;

      if Assigned(o) then
      begin
        if IsAssignableProperty(AObject, PropInfo) then
        begin
          if IsDescendingClass(o.ClassType, ['TComponent'] ) then
            n.Text[1] := (o as TComponent).Name;
        end
        else
        begin

          if (isGenericList(o.ClassType) and showLists = true) then
          begin
            aList := TTMSFNCObjectList(o);
            // s:=Inttostr(aList.count) + ' items             ';
           //  ind:=Canvas.TextWidth(s)+trunc(NodesAppearance.LevelIndent)+1;
            // s:=s+  '<a href="add"><FONT color="gcLightGreen">Add</FONT></a>';
            // n.Text[1] := s;

            n.data.ObjectData := o;
            n.data.readonly := true;
            n.data.isListObject := true;
            n.data.objectlist := o;
            if ExtendedListNode then n.extended := true;

            for I := 0 to AList.Count - 1 do
            begin
              nl := FindObjectNode(alist[i] , n);
              if not assigned(nl) then
              begin
                nl := AddNode(n);
                // gname:=GetGenericName( TObject(AList[I]));
                // if gname='' then gname:= 'Item ' + Inttostr(I);
                 //s:='<a href="Delete"><FONT  color="gcred">X</FONT></a>';  //<BODY bgcolor="gcDarkRed"> </BODY>     '<IND x="'+Inttostr(ind) + '">
                nl.data.ObjectData := TObject(AList[I] );
                nl.data.objectList := o;
                nl.data.objectIndex := i - 1;
                nl.data.isListItem := true;
                //nl.text[1]:=gName;
                //nl.text[0]:=gname;
               // nl.text[1]:=s;
                nl.data.readOnly := true;
                // nl.DataObject:= alist[i];
                // nl.DataPointer:=propinfo;
               // nl.DataPointer:=
                if ShowListObjects = true then ReadSingleObject(TObject(AList[I] ), nl);
              end;
            end;
            //            ReadGenericList(TTMSFNCObjectList(o),n);
          end
          else
          begin

            n.Text[1] := '(' + o.ClassName + ')';
            ReadObject(o, n);
          end;

        end;
      end;
    end
    else
    begin
      b := True;
      vls := ReadPropInfoValue(AObject, PropInfo);
      o := nil;
      DoReadPropertyValue(AObject, PropInfo, pName, k, vls, o, b);
      if not b then
        Exit;

      if vls <> '' then
        n.Text[1] := vls;
    end;
  end;
end;

function txix.GP(aObject: tobject; aPropName: string): tValue;
var
  ctxRtti           : TRttiContext;
  typeRtti          : TRttiType;
  atrbRtti          : TCustomAttribute;
  methodRtti        : TRttiMethod;
  parameterRtti     : TRttiParameter;
  aClass            : tclass;
  NewItem           : tobject;
  v                 : tvalue;
begin

  try
    ctxRtti := TRttiContext.Create;
    typeRtti := ctxRtti.GetType(aObject.ClassType);
    result := typeRtti.GetProperty(aPropName).getvalue(aObject);
    ctxRtti.Free;
  except
    on e: exception do
    begin

    end;

  end;

end;

function txix.SP(aObject: tobject; aPropName: string; avalue: tvalue): boolean;
var
  ctxRtti           : TRttiContext;
  typeRtti          : TRttiType;
  atrbRtti          : TCustomAttribute;
  methodRtti        : TRttiMethod;
  parameterRtti     : TRttiParameter;
  aClass            : tclass;
  NewItem           : tobject;
  v                 : tvalue;
begin

  try
    ctxRtti := TRttiContext.Create;
    typeRtti := ctxRtti.GetType(aObject.ClassType);
    typeRtti.GetProperty(aPropName).SetValue(aobject, avalue);
    ctxRtti.Free;
    result := true;
  except
    on e: exception do
    begin
      result := false;
    end;

  end;

end;

function txix.CreateListObject(aClass: tClass): tobject;
var
  someclass         : TClass;
  c                 : TRttiContext;
  t                 : TRttiType;
  v                 : TValue;
begin
  // Invoke RTTI
  c := TRttiContext.Create;
  t := c.GetType(aclass);
  v := t.GetMethod('Create').Invoke(t.AsInstance.MetaclassType, [] );
  result := v.AsObject;

  // free RttiContext record (see text below) and the rest
  c.Free;

end;

function txix.AddtoObjectList(ObjectList: tobject): tobject;
var
  ctxRtti           : TRttiContext;
  typeRtti          : TRttiType;
  atrbRtti          : TCustomAttribute;
  methodRtti        : TRttiMethod;
  parameterRtti     : TRttiParameter;
  aClass            : tclass;
  NewItem           : tobject;
  v                 : tvalue;
  v1, v2            : tvalue;
begin
  result := nil;

  ctxRtti := TRttiContext.Create;
  typeRtti := ctxRtti.GetType(ObjectList.ClassType);
  methodRtti := typeRtti.GetMethod('Add');
  for parameterRtti in methodRtti.GetParameters do
  begin
    if SameText(parameterRtti.Name, 'Value') then
    begin
      if parameterRtti.ParamType.IsInstance then
      begin
        aClass := parameterRtti.ParamType.AsInstance.MetaclassType;
        NewItem := CreateListObject(aclass);
        alog.send('NewItem', newitem);
        v1 := tvalue.from<tobject>(objectlist);
        //
        {$IFDEF fwweb}
        v1 := tvalue.from<tobject>(newitem);
        v := methodRtti.Invoke(objectlist, [v1] );
        {$ELSE}
        v := methodRtti.Invoke(objectlist, [NewItem] );
        {$ENDIF}

        result := newItem;
      end;
      break;
    end;
  end;
  ctxRtti.Free;
end;

function txix.RemoveFromObjectList(ObjectList: tobject; aItem: tobject): integer;
var
  ctxRtti           : TRttiContext;
  typeRtti          : TRttiType;
  atrbRtti          : TCustomAttribute;
  methodRtti        : TRttiMethod;
  parameterRtti     : TRttiParameter;
  aClass            : tclass;
  NewItem           : tobject;
  v, v1             : tvalue;
begin
  result := -1;

  ctxRtti := TRttiContext.Create;
  typeRtti := ctxRtti.GetType(ObjectList.ClassType);
  methodRtti := typeRtti.GetMethod('Remove');
  for parameterRtti in methodRtti.GetParameters do
  begin
    if SameText(parameterRtti.Name, 'Value') then
    begin
      if parameterRtti.ParamType.IsInstance then
      begin
        aClass := parameterRtti.ParamType.AsInstance.MetaclassType;

        {$IFDEF fwweb}
        v1 := tvalue.from<tobject>(newitem);
        v := methodRtti.Invoke(objectlist, [v1] );
        {$ELSE}
        v := methodRtti.Invoke(objectlist, [aitem] );
        {$ENDIF}

        result := typeRtti.GetProperty('count').getvalue(objectlist).AsInteger;
      end;
      break;
    end;
  end;
  ctxRtti.Free;
end;

function txIX.GetListType(ObjectList: TObject): TClass;
var
  ctxRtti           : TRttiContext;
  typeRtti          : TRttiType;
  atrbRtti          : TCustomAttribute;
  methodRtti        : TRttiMethod;
  parameterRtti     : TRttiParameter;
begin
  result := nil;

  ctxRtti := TRttiContext.Create;
  typeRtti := ctxRtti.GetType(ObjectList.ClassType);
  methodRtti := typeRtti.GetMethod('Add');
  for parameterRtti in methodRtti.GetParameters do
  begin
    if SameText(parameterRtti.Name, 'Value') then
    begin
      if parameterRtti.ParamType.IsInstance then
        result := parameterRtti.ParamType.AsInstance.MetaclassType;
      break;
    end;
  end;
  ctxRtti.Free;
end;

procedure txIX.ap(aprop: string);
begin
  exlist.add(aProp);

end;

function txIX.CA: TTMSFNCTreeViewColumnsAppearance;
begin
  result := columnsAppearance;
end;

function txIX.CL: ttmsfncTreeViewColumn;
begin
  result := columns[0] ;
end;

function txIX.cn(idx: integer): string;
begin

end;

function txIX.CR: ttmsfncTreeViewColumn;
begin
  result := columns[1] ;
end;

constructor txIX.create(aOwner: tcomponent);
begin
  inherited;
  cTextHeight := 0;
  init;
  //{$IFNDEF fwweb}   alog.MyCFG.enable:=false; {$ENDIF}

end;



procedure txIX.DoAfterCheckNode(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer);
begin
  inherited;

end;

procedure txIX.DoAfterCollapseNode(ANode: TTMSFNCTreeViewVirtualNode);
begin
  inherited;
  if Expanding = false then
    doColAutoWidth(0);
end;

procedure txIX.DoAfterCopyToClipboard;
begin
  inherited;

end;

procedure txIX.DoAfterCutToClipboard;
begin
  inherited;

end;

procedure txIX.DoAfterDrawColumn(AGraphics: TTMSFNCGraphics; ARect: TRectF;
  AColumn: Integer);
begin
  inherited;

end;

procedure txIX.DoAfterDrawColumnEmptySpace(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; ASpace: TTMSFNCTreeViewColumnEmptySpace);
begin

end;

procedure txIX.DoAfterDrawColumnHeader(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind);
begin
  inherited;

end;

procedure txIX.DoAfterDrawColumnText(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind;
  AText: string);
begin
  inherited;

end;

procedure txIX.DoAfterDrawGroup(AGraphics: TTMSFNCGraphics; ARect: TRectF;
  AGroup, AStartColumn, AEndColumn: Integer;
  AKind: TTMSFNCTreeViewCacheItemKind);
begin
  inherited;

end;

procedure txIX.DoAfterDrawGroupEmptySpace(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; ASpace: TTMSFNCTreeViewGroupEmptySpace);
begin

end;

procedure txIX.DoAfterDrawGroupText(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AGroup, AStartColumn, AEndColumn: Integer;
  AKind: TTMSFNCTreeViewCacheItemKind; AText: string);
begin
  inherited;

end;

procedure txIX.DoAfterDrawNode(AGraphics: TTMSFNCGraphics; ARect: TRectF;
  ANode: TTMSFNCTreeViewVirtualNode);
begin
  inherited;
  if not assigned(anode) then exit;
  if not assigned(anode.node) then exit;
  {$IFDEF fwweb}
  anode.node.data.DrawnHeight := arect.Bottom - arect.top;
  {$ELSE}

  anode.node.data.DrawnHeight := arect.Height;
  {$ENDIF}

end;

procedure txIX.DoAfterDrawNodeCheck(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode;
  ACheck: TTMSFNCBitmap);
begin
  inherited;

end;

procedure txIX.DoAfterDrawNodeColumn(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer);
begin
  inherited;

end;

procedure txIX.DoAfterDrawNodeExpand(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode;
  AExpand: TTMSFNCBitmap);
begin
  inherited;

end;

procedure txIX.DoAfterDrawNodeExtra(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode);
begin
  inherited;

end;

procedure txIX.DoAfterDrawNodeIcon(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode;
  AIcon: TTMSFNCBitmap);
begin
  inherited;

end;

procedure txIX.DoAfterDrawNodeText(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode;
  AText: string);
var
  tr                : trectf;
  w                 : integer;
begin
  inherited;
  if not assigned(ANode) then exit;
  if not assigned(ANode.Node) then exit;

  tr := RectF(ARect.Left + 4, ARect.Top + 2, 10000, ARect.bottom - 2);
  w := round(AGraphics.CalculateTextWidth(AText, tr, false, false));
  anode.node.data.DataInteger[AColumn] := w;
  columns[AColumn] .data.addCW(w, ARect);
  anode.node.data.DoneCW := true;
end;

procedure txIX.DoAfterDrawNodeTitle(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode;
  ATitle: string);
begin
  inherited;

end;

procedure txIX.DoAfterDrawNodeTitleExtra(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode);
begin
  inherited;

end;

procedure txIX.DoAfterDrawSortIndicator(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn, ASortIndex: Integer;
  ASortKind: TTMSFNCTreeViewNodesSortKind);
begin
  inherited;

end;

procedure txIX.DoAfterDropNode(AFromNode,
  AToNode: TTMSFNCTreeViewVirtualNode);
begin
  inherited;

end;

procedure txIX.DoAfterExpandNode(ANode: TTMSFNCTreeViewVirtualNode);
begin
  inherited;
  if Expanding = false then
    doColAutoWidth(500);
end;

procedure txIX.DoAfterOpenInplaceEditor(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; AInplaceEditor: TTMSFNCTreeViewInplaceEditor;
  AInplaceEditorRect: TRectF);
begin
  inherited;

end;

procedure txIX.DoAfterPasteFromClipboard;
begin
  inherited;

end;

procedure txIX.DoAfterReorderNode(AFromNode,
  AToNode: TTMSFNCTreeViewVirtualNode);
begin
  inherited;

end;

procedure txIX.DoAfterSizeColumn(AColumn: Integer; AColumnSize: Double);
begin
  inherited;

end;

procedure txIX.DoAfterSortNodes(ASortColumn: Integer;
  ASortMode: TTMSFNCTreeViewNodesSortMode);
begin
  inherited;

end;

procedure txIX.DoAfterUnCheckNode(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer);
begin
  inherited;

end;

procedure txIX.DoAfterUpdateNode(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer);
begin
  inherited;

end;

procedure txIX.doAllFontChange(sender: tobject);
begin
  cl.topfont.assign(fTopFont);
  cr.topfont.assign(fTopFont);
end;

procedure txIX.doAutoSize(atimed: integer = -1);
var
  i                 : integer;
  w                 : integer;
  ow, oh            : integer;
  scrollPos         : integer;
begin
  //if isAutoSizing then exit;
  alog.send('Doing AutoSize');
  if atimed <> -1 then
  begin
    if AutoSizeTimer.Enabled then exit;

    if atimed <> 0 then
      AutoSizeTimer.Interval := aTimed
    else
      AutoSizeTimer.Interval := AutoSizeTimer.tag;
    AutoSizeTimer.Enabled := true;
    exit;
  end;

  doColAutoWidth;
  doautoWidth;
  doAutoHeight;
  if assigned(onAfterAutoSize) then onAfterAutoSize(self);
  exit;

  try
    isAutoSizing := true;
    ow := width;
    oh := height;

    if DisableTestSizing = false then
    begin
      beginupdate;
      width := 5000;
      height := 5000;
      endupdate;
    end;

    {if DrawnAll = true then
      alog.send('all drawn')
    else
      alog.send('NOT ALL DRAWN');}

    if FUllwidth then
    begin
      if getcolumnwidth(0) <> columns[0] .data.cw then
      begin
        columns[0] .Width := columns[0] .data.cw + NA.expandWidth;

      end;
      columns[0] .data.resetcw;
      columns[1] .width := width - columns[0] .width - verticalScrollBar.width - 5;
    end
    else
    begin

      if autowidth then
      begin
        for i := 1 to columns.count do
        begin

          if columns[i - 1] .Visible then

            if getcolumnwidth(i - 1) <> columns[i - 1] .data.cw then
            begin
              if I = 1 then
                columns[i - 1] .Width := columns[i - 1] .data.cw + NA.ExpandWidth
              else
                columns[i - 1] .Width := columns[i - 1] .data.cw;

            end;
          columns[i - 1] .data.resetcw;
        end;
        w := trunc(columns[0] .data.width + columns[1] .data.width);

        if width <> w then width := w;
        alog.send('w', w);

      end;
    end;

    {if autowidth then
    begin
       alog.send('Col0Before', columns[0].Width);
     alog.send('Col1Before', columns[1].Width);
    Width:=5000;
    columns[0].width:=1000;
    columns[1].width:=1000;
     alog.send('Col0Before', columns[0].Width);
     alog.send('Col1Before', columns[1].Width);
     //     Beginupdate;
      AutoSizeColumn(0);
      //AutoSizeColumn(1);

    //  Endupdate;
         alog.send('Col0After', columns[0].Width);
     alog.send('Col1After', columns[1].Width);
     // cl.width:=150;
     // cr.width:=250;
     // width := trunc(GetTotalColumnWidth) + 100;

    end;        }
    if autoheight then
    begin
      //  Height:=5000;
     // if VerticalScrollBar.Visible then
      //begin
      //ExpandAll;
      //Scrollpos := verticalScrollBar.Position;

      //ScrollToBottom;
     // VerticalScrollBar.Position:=ScrollPos;
      //end;
      {$IFDEF fwfmx}

      height := trunc(GetNodeHeights + ca.TopSize + 2);
      {$ELSE}
      clientheight := trunc(GetNodeHeights + ca.TopSize + 2);
      {$ENDIF}

      if assigned(onAfterAutoSize) then onAfterAutoSize(self);

    end;
  finally
    isAutoSizing := false;
  end;
  if ((ow <> width) or (oh <> height)) then
    if assigned(onAfterAutoSize) then onAfterAUtoSize(self);

end;

procedure txIX.DoBeforeCheckNode(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var ACanCheck: Boolean);
begin
  inherited;

end;

procedure txIX.DoBeforeCollapseNode(ANode: TTMSFNCTreeViewVirtualNode;
  var ACanCollapse: Boolean);
var
  n                 : ttmsfncTreeViewNode;
  i                 : integer;
begin
  inherited;
  if not assigned(anode) then exit;
  if fcRoot then
  begin
    if anode.node = nodes[0] then
    begin
      aCanCollapse := true;
      fcRoot := false;
      exit;
    end;

  end;
  alog.send('DoBeforeCollapseNode');
  if anode.node = nodes[0] then
  begin

    if RootExpandsChildren then
    begin
      alog.send('Rootexpandschildren');
      ACanCollapse := false;
      beginupdate;
      for i := 1 to nodes[0] .nodes.count do
      begin
        n := nodes[0] .nodes[i - 1] ;
        n.collapse(false);
      end;
      endupdate;
      dotimedAutoSize;
    end;
  end
  else
    ACanCollapse := true;
end;

procedure txIX.DoBeforeCopyToClipboard(var ACanCopy: Boolean);
begin
  inherited;

end;

procedure txIX.DoBeforeCutToClipboard(var ACanCut: Boolean);
begin
  inherited;

end;

procedure txIX.DoBeforeDrawColumn(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; var AAllow, ADefaultDraw: Boolean);
begin
  inherited;

end;

procedure txIX.DoBeforeDrawColumnEmptySpace(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; ASpace: TTMSFNCTreeViewColumnEmptySpace; var AAllow,
  ADefaultDraw: Boolean);
begin

end;

procedure txIX.DoBeforeDrawColumnHeader(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind;
  var AAllow, ADefaultDraw: Boolean);
var

  Corners           : TTMSFNCGraphicsSides;
  tm                : Double;
  bmHW              : integer;
  CF                : Double;
  col               : ttmsfncTreeViewColumn;
  Iconheight, IconWidth: integer;
  vMargin, hMargin  : integer;
  tr                : trectf;
begin
  inherited;

  col := columns[aColumn] ;
  aGraphics.font.assign(col.topfont);
  vMargin := colVMargin;
  hMargin := colHMargin;
  { AGraphics.Font.Color := ca.topfont.Color;
      AGraphics.DefaultTextFontColor := afont.Color;
      AGraphics.stroke.Color := afont.Color;
      AGraphics.Font.Style := afont.Style;
      AGraphics.Font.Name := afont.Name;
      AGraphics.Font.size := afont.size;  }
  agraphics.fill.Assign(col.TopFill);
  aGraphics.stroke.assign(col.TopStroke);

  AGraphics.DrawRectangle(arect, gcrmNone);

  if not assigned(col.data.xicon) then
  begin
    aAllow := false;
    tr := RectF(arect.left + hMargin, arect.top
      , 10000, ARect.bottom);

    AGraphics.DrawText(tr, col.text, false, gtaLeading, gtaCenter);
    exit;
  end;

  AAllow := false;
  // ADefaultDraw:=false;
 // tm := stroke.Width * 1.8;

  {$IFDEF fwweb}
  IconHeight := trunc(aRect.bottom - aREct.top) - (2 * vmargin);
  {$ELSE}
  IconHeight := trunc(aRect.Height) - (2 * vmargin);
  {$ENDIF}

  IconWidth := IconHeight;

  tr := RectF(arect.left + hMargin + IconWidth + hmargin, arect.top
    , 10000, ARect.bottom);

  AGraphics.DrawBitmapWithName(arect.left + hMargin, arect.top + vMargin, arect.left + IconWidth + hMargin, arect.top + vMargin + iconHeight + vMargin,
    col.data.xicon.bn, true, 1, true, true, true, false);
  AGraphics.DrawText(tr, col.text, false, gtaLeading, gtaCenter);

  {if AColumn = 1 then
  begin
    Corners := [gsLeft];
    AGraphics.stroke.Kind := gskSolid;
    AGraphics.stroke.Color := gcDarkGray;

    AGraphics.stroke.Width := 30;
    AGraphics.stroke.Kind := gskSolid;
    // agraphics.Fill.Kind:=gfkSolid;
    // aGraphics.Fill.Color:=gcLightGray;
    // AGraphics.DrawRectangle(RectF(arect.left-trunc((1.5/100)*arect.left), arect.top+trunc((30/100)*arect.bottom), arect.right, ARect.Bottom-trunc((30/100)*arect.bottom)), corners, TTMSFNCGraphicsModifyRectMode.gcrmNone);
    // AGraphics.DrawRectangle(RectF(arect.left-trunc((1.1/100)*arect.left), arect.top+tm, arect.right, ARect.Bottom-tm-Arect.top), corners, TTMSFNCGraphicsModifyRectMode.gcrmNone);
    bmHW := 24;
    CF := (1.1 / 100) * ARect.Left;
    AGraphics.DrawBitmapWithName(ARect.Left - bmHW - CF, ARect.Top, ARect.Left + bmHW - CF, ARect.bottom, ResizeBitmap, true, 1, true, true, true, false)
    // agraphics.Fill.Kind:=gfkNone;
    // AGraphics.DrawRectangle(Arect, corners, gcrmNone);
  end;       }

end;

procedure txIX.DoBeforeDrawColumnText(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind;
  AText: string; var AAllow: Boolean);
begin
  inherited;
  aAllow := false;
end;

procedure txIX.DoBeforeDrawGroup(AGraphics: TTMSFNCGraphics; ARect: TRectF;
  AGroup, AStartColumn, AEndColumn: Integer;
  AKind: TTMSFNCTreeViewCacheItemKind; var AAllow, ADefaultDraw: Boolean);
begin
  inherited;

end;

procedure txIX.DoBeforeDrawGroupEmptySpace(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; ASpace: TTMSFNCTreeViewGroupEmptySpace; var AAllow,
  ADefaultDraw: Boolean);
begin

end;

procedure txIX.DoBeforeDrawGroupText(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AGroup, AStartColumn, AEndColumn: Integer;
  AKind: TTMSFNCTreeViewCacheItemKind; AText: string; var AAllow: Boolean);
begin
  inherited;

end;

procedure txIX.DoBeforeDrawNode(AGraphics: TTMSFNCGraphics; ARect: TRectF;
  ANode: TTMSFNCTreeViewVirtualNode; var AAllow, ADefaultDraw: Boolean);
begin
  inherited;

end;

procedure txIX.DoBeforeDrawNodeCheck(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode;
  ACheck: TTMSFNCBitmap; var AAllow: Boolean);
begin
  inherited;

end;

procedure txIX.DoBeforeDrawNodeColumn(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; var AAllow, ADefaultDraw: Boolean);
begin
  inherited;

end;

procedure txIX.DoBeforeDrawNodeExpand(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode;
  AExpand: TTMSFNCBitmap; var AAllow: Boolean);
begin
  inherited;

end;

procedure txIX.DoBeforeDrawNodeExtra(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode;
  var AAllow: Boolean);
begin
  inherited;

end;

procedure txIX.DoBeforeDrawNodeIcon(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode;
  AIcon: TTMSFNCBitmap; var AAllow: Boolean);
begin
  inherited;

end;

procedure txIX.DoBeforeDrawNodeText(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode;
  AText: string; var AAllow: Boolean);
var
  tr                : trectf;
  w                 : integer;
  col               : ttmsfncTreeViewColumn;
  aIcon             : txicon;
begin
  inherited;
  if not assigned(ANode) then exit;
  if not assigned(ANode.Node) then exit;

  if anode.node.extended then
    if acolumn = 0 then

    begin
      //tr := RectF(arect.right-IconWidth , arect.top
     //, 10000, ARect.bottom);
    {  IconSize := 48;
      col := columns[aColumn] ;
      aicon := icons.GetIcon('add-svg');
      hMargin := 6;
      VMargin := 6;

      AGraphics.DrawBitmapWithName(
      arect.right - Iconsize - hMargin, //Left
      arect.top + vMargin,              //Top
      arect.right - hMargin,           // Right
      arect.bottom - vMargin,         //Bottom    arect.top + vMargin + Iconsize + vMargin
        aicon.bn,
        true,                 //ApplyScale
        1,                    //Scale
        true,                 //Aspect Ration
        true,                 //Stretch
        true,                 //Center
        false);               //Cropping
                                                 }

      tr := RectF(arect.left + hMargin, arect.top
      , 10000, ARect.bottom);

    AGraphics.DrawText(tr, atext, false, gtaLeading, gtaCenter);



      AAllow := false;

    end;

  if cTextHeight = 0 then
    cTextHeight := agraphics.CalculateTextHeight(atext);
  tr := RectF(ARect.Left + 4, ARect.Top + 2, 10000, ARect.bottom - 2);
  w := round(AGraphics.CalculateTextWidth(AText, tr, false, false));
  anode.node.data.DataInteger[AColumn] := w;
  columns[AColumn] .data.addCW(w, ARect);
  anode.node.data.DoneCW := true;
end;

procedure txIX.DoBeforeDrawNodeTitle(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode;
  ATitle: string; var AAllow: Boolean);
begin
  inherited;

end;

procedure txIX.DoBeforeDrawNodeTitleExtra(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode;
  var AAllow: Boolean);
begin
  inherited;

end;

procedure txIX.DoBeforeDrawSortIndicator(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn, ASortIndex: Integer;
  ASortKind: TTMSFNCTreeViewNodesSortKind; var ADefaultDraw: Boolean);
begin
  inherited;

end;

procedure txIX.DoBeforeDropNode(AFromNode,
  AToNode: TTMSFNCTreeViewVirtualNode; var ACanDrop: Boolean);
begin
  inherited;

end;

procedure txIX.DoBeforeExpandNode(ANode: TTMSFNCTreeViewVirtualNode;
  var ACanExpand: Boolean);
var
  n                 : ttmsfncTreeViewNode;
  i                 : integer;
begin
  inherited;
  if not assigned(anode) then exit;
  if feRoot then
  begin
    aCanExpand := true;
    feRoot := false;
    exit;

  end;
  if anode.node = nodes[0] then
  begin

    if RootExpandsChildren then
    begin
      aCanExpand := false;
      beginupdate;
      for i := 1 to nodes[0] .nodes.count do
      begin
        n := nodes[0] .nodes[i - 1] ;
        n.expand(false);
      end;
      endupdate;
      dotimedAutoSize;
    end;
  end
  else
    aCanExpand := true;

end;

procedure txIX.DoBeforeOpenInplaceEditor(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var ACanOpen: Boolean);
begin
  inherited;
  if aColumn = 1 then
  begin
    if assigned(anode) then
      if assigned(anode.node) then

      begin
        aCanOpen := anode.node.data.readOnly = false;
      end
      else
        aCanOpen := true;
  end
  else if aColumn = 0 then
  begin
    aCanOpen := false;
    if anode.node.data.islistitem then
      if allowGenericRename then

        aCanOPen := true
      else
        aCanOpen := false;
  end;
end;

procedure txIX.DoBeforeOpenObjectEditor(AObject: TObject;
  APropertyInfo: TTMSFNCPropertyInfo; APropertyName: string;
  APropertyType: TTypeKind; APropertyValueObject: TObject;
  var ACanOpen: Boolean);
begin
  inherited;

  aCanOpen := false;
end;

procedure txIX.DoBeforePasteFromClipboard(var ACanPaste: Boolean);
begin
  inherited;

end;

procedure txIX.DoBeforeReorderNode(AFromNode,
  AToNode: TTMSFNCTreeViewVirtualNode; var ACanReorder: Boolean);
begin
  inherited;

end;

procedure txIX.DoBeforeSelectAllNodes(var ACanSelect: Boolean);
begin
  inherited;

end;

procedure txIX.DoBeforeSelectNode(ANode: TTMSFNCTreeViewVirtualNode;
  var ACanSelect: Boolean);
begin
  inherited;

end;

procedure txIX.DoBeforeSizeColumn(AColumn: Integer; AColumnSize: Double;
  var ANewColumnSize: Double; var AAllow: Boolean);
begin
  inherited;
  alog.send('COl ' + inttostr(acolumn) + ' old ' + inttostr(trunc(aColumnSize)), trunc(aNewColumnSize));
  alog.send('AAllow', aallow);
end;

procedure txIX.DoBeforeSortNodes(ASortColumn: Integer;
  ASortMode: TTMSFNCTreeViewNodesSortMode; var ACanSort: Boolean);
begin
  inherited;

end;

procedure txIX.DoBeforeUnCheckNode(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var ACanUnCheck: Boolean);
begin
  inherited;

end;

procedure txIX.DoBeforeUnSelectNode(ANode: TTMSFNCTreeViewVirtualNode;
  var ACanUnSelect: Boolean);
begin
  inherited;

end;

procedure txIX.DoBeforeUpdateNode(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var AText: string; var ACanUpdate: Boolean);

begin
  inherited;

end;

procedure txIX.DoCloseInplaceEditor(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; AInplaceEditor: TTMSFNCTreeViewInplaceEditor;
  ACancelled: Boolean; var ACanClose: Boolean);
var
  n                 : ttmsfncTreeViewNode;
  aNewName, EditName: string;
  CanRename         : boolean;
begin
  inherited;
  if ACancelled then alog.send('Cancelled');

  if acolumn = 1 then
  begin
    {if focused = false then
    begin
      alog.send('FOCUSED FALSE');
      aCanClose := false;
      exit;
    end;    }
    if aCancelled then
    begin
      aCanClose := true;
       if assigned(onCancelEditing) then onCancelEditing(self);
       ActiveNode:=nil;
      exit;
    end;

    if assigned(onFinishEditing) then onFinishEditing(self);
  end
  else if acolumn = 0 then
  begin
    alog.send('Editor close');
    if aCAncelled then exit;

    n := anode.node;
    if n.data.islistitem then
    begin
      EditName := TTMSFNCTreeViewEdit(aInPlaceEditor).text;
      aNewName := EditName;
      CanRename := true;
      alog.send('anewname', anewname);
      if assigned(onGenericRenamed) then
        onGenericRenamed(self, n.data.objectdata, n.data.objectList, aNewName, CanRename);
      alog.send('CanRename', CanRename);
      if CanRename then
      begin
        if aNewName <> EditName then TTMSFNCTreeViewEdit(aInPlaceEditor).text := aNewName;
        aCanClose := true;
        TimedUpdateValues;
      end
      else
        aCanClose := false;

    end
    else
      alog.error('CLose editor on on list item');
  end;

end;

procedure txIX.DoColorSelected(Sender: TObject;
  AColor: TTMSFNCGraphicsColor);
begin
  inherited;

end;

procedure txIX.DoColumnAnchorClick(AColumn: Integer; AAnchor: string);
begin
  inherited;

end;

procedure txIX.DoComboChange(Sender: TObject);
begin
  inherited;

end;

procedure txIX.DoCustomizeFilterListBox(AColumn: Integer;
  AListBox: TListBox);
begin
  inherited;

end;

procedure txIX.DoCustomizeInplaceEditor(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; AInplaceEditor: TTMSFNCTreeViewInplaceEditor);
var
  c                 : ttmsfncGraphicsColor;
  aEdit             : TTMSFNCTreeViewEdit;
  amemo             : TTMSFNCTreeViewMemo;
  aCombo            : TTMSFNCTreeViewComboBox;
begin
  inherited;
  alog.send('custom', acolumn);
  Editor := aInPlaceEditor;
  if NoSelectStyle then
    c := bgColor
  else
    c := SelNodeColor;
  if aColumn = 1 then
  begin
    if assigned(OnEdit) then OnEdit(tvalue.from<string>(anode.text[1] ));
    alog.send('GetinplaceEditor', AInplaceEditor.classname);

    self.BeginUpdate;
    if AInplaceEditor is TTMSFNCTreeViewComboBox then
    begin
      aCombo := TTMSFNCTreeViewComboBox(AInplaceEditor);
      alog.send('combo', aCombo.Items);

      {$IFDEF fwfmx}
      //    acombo.StyleElements := [] ;
        {  acombo.BevelEdges := [] ;
          acombo.bevelInner := tBevelcut.bvNone;
          acombo.BevelKind := tBevelKind.bknone;
          acombo.BevelOuter := tBevelCut.bvnone;
          acombo.color := c;
          acombo.font.assign(na.font);
          acombo.AutoDropDown := false;
          acombo.style := csDropDownList;
          acombo.AutoCloseUp := true;
          acombo.Ctl3D := false;
          acombo.top := acombo.top + 5;}
      {$ENDIF}
      {$IFDEF fwvcl}
      acombo.StyleElements := [] ;
      acombo.BevelEdges := [] ;
      acombo.bevelInner := tBevelcut.bvNone;
      acombo.BevelKind := tBevelKind.bknone;
      acombo.BevelOuter := tBevelCut.bvnone;
      acombo.color := c;
      acombo.font.assign(na.font);
            acombo.font.color:=SelTextColor;
      acombo.AutoDropDown := false;
      acombo.style := csDropDownList;

      acombo.AutoCloseUp := true;
      acombo.Ctl3D := false;
      //acombo.top := acombo.top + 5;
      {$ENDIF}
      {$IFDEF fwweb}

      {$ENDIF}

    end
    else if AInplaceEditor is TTMSFNCTreeViewEdit then
    begin
      aEdit := TTMSFNCTreeViewEdit(AInplaceEditor);
      aedit.OnChange := InternalEditing;
      alog.send('edit', aedit.text);
      {$IFDEF fwfmx}

      {$ENDIF}
      {$IFDEF fwvcl}
      aEdit.StyleElements := [] ;
      aEdit.BevelEdges := [] ;
      aEdit.bevelInner := tBevelcut.bvNone;
      aEdit.BevelKind := tBevelKind.bknone;
      aEdit.BevelOuter := tBevelCut.bvnone;
      aEdit.BorderStyle := bsNone;
      aEdit.color := c;
      aEdit.font.assign(na.font);
      aEdit.font.color:=SelTextColor;
      aEdit.top := aEdit.top + 5;
      aedit.height := aedit.height - 4;
      {$ENDIF}
      {$IFDEF fwweb}

      {$ENDIF}

    end
    else if AInplaceEditor is TTMSFNCTreeViewMemo then
    begin
      amemo := TTMSFNCTreeViewMemo(aInPlaceEditor);
      alog.send('memo', amemo.lines.text);

      {$IFDEF fwfmx}

      {$ENDIF}
      {$IFDEF fwvcl}
      amemo.StyleElements := [] ;
      amemo.BevelEdges := [] ;
      amemo.bevelInner := tBevelcut.bvNone;
      amemo.BevelKind := tBevelKind.bknone;
      amemo.BevelOuter := tBevelCut.bvnone;
      amemo.BorderStyle := bsNone;
      amemo.color := c;
      amemo.font.assign(na.font);
      amemo.top := TTMSFNCTreeViewEdit(AInplaceEditor).top + 5;
      {$ENDIF}
      {$IFDEF fwweb}

      {$ENDIF}

    end;

  end
  else if acolumn = 0 then
  begin
    alog.send('Custom col 0');
    self.BeginUpdate;

    aEdit := TTMSFNCTreeViewEdit(AInplaceEditor);
    {$IFDEF fwfmx}

    {$ENDIF}
    {$IFDEF fwvcl}
    aEdit.StyleElements := [] ;
    aEdit.BevelEdges := [] ;
    aEdit.bevelInner := tBevelcut.bvNone;
    aEdit.BevelKind := tBevelKind.bknone;
    aEdit.BevelOuter := tBevelCut.bvnone;
    aEdit.BorderStyle := bsNone;
    aEdit.color := c;
    aEdit.font.assign(na.font);
    aEdit.top := aEdit.top + 5;
    {$ENDIF}
    {$IFDEF fwweb}

    {$ENDIF}
  end;
  self.EndUpdate;
end;

//procedure DoCustomizeObjectEditor(AObject: TObject; APropertyInfo: TTMSFNCPropertyInfo; APropertyName: string;
//APropertyType: TTypeKind; APropertyValueObject: TObject; AEditor: TTMSFNCObjectInspectorEditor;
//AEditorForm: TTMSFNCCustomDesignerForm; AEditorObjectInspector: TTMSFNCObjectInspector); virtual;

procedure txIX.DoDateSelected(Sender: TObject; ADate: TDate);
begin
  inherited;

end;


procedure txIX.DoDrawNodeExtra(AGraphics: TTMSFNCGraphics; ARect: TRectF;
  AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode);
begin
  inherited;

end;

procedure txIX.DoDrawNodeTitleExtra(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AColumn: Integer; ANode: TTMSFNCTreeViewVirtualNode);
begin
  inherited;

end;

procedure txIX.DoFillKindSelected(Sender: TObject;
  AFillKind: TTMSFNCGraphicsFillKind);
begin
  inherited;

end;

procedure txIX.DoGetColumnHorizontalTextAlign(AColumn: Integer;
  AKind: TTMSFNCTreeViewCacheItemKind;
  var AHorizontalTextAlign: TTMSFNCGraphicsTextAlign);
begin
  inherited;

end;

procedure txIX.DoGetColumnText(AColumn: Integer;
  AKind: TTMSFNCTreeViewCacheItemKind; var AText: string);
begin
  inherited;

end;

procedure txIX.DoGetColumnTrimming(AColumn: Integer;
  AKind: TTMSFNCTreeViewCacheItemKind;
  var ATrimming: TTMSFNCGraphicsTextTrimming);
begin
  inherited;

end;

procedure txIX.DoGetColumnVerticalTextAlign(AColumn: Integer;
  AKind: TTMSFNCTreeViewCacheItemKind;
  var AVerticalTextAlign: TTMSFNCGraphicsTextAlign);
begin
  inherited;

end;

procedure txIX.DoGetColumnWordWrapping(AColumn: Integer;
  AKind: TTMSFNCTreeViewCacheItemKind; var AWordWrapping: Boolean);
begin
  inherited;

end;

procedure txIX.DoGetGroupText(AGroup: Integer;
  AKind: TTMSFNCTreeViewCacheItemKind; var AText: string);
begin
  inherited;

end;

procedure txIX.DoGetHTMLTemplate(ANodeValue: TTMSFNCTreeViewNodeValue;
  AColumnIndex: Integer; var AHTMLTemplate: string);
begin
  inherited;

end;

procedure txIX.DoGetHTMLTemplateValue(ANodeValue: TTMSFNCTreeViewNodeValue;
  AName: string; var AValue: string);
begin
  inherited;

end;

procedure txIX.DoGetInplaceEditor(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; {$IFDEF FMXLIB}var ATransparent: Boolean; {$ENDIF}var AInplaceEditorClass:
  TTMSFNCTreeViewInplaceEditorClass);
begin
  inherited;
  if acolumn = 0 then aInPlaceEditorClass := TTMSFNCTreeViewEdit;

  // if aInPlaceEditorClass = TTMSFNCTreeViewEdit then aInPlaceEditorClass := TTMSFNCTreeViewMemo;

end;

procedure txIX.DoGetNodeCheckType(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var ACheckType: TTMSFNCTreeViewNodeCheckType);
begin
  inherited;

end;

procedure txIX.DoGetNodeColor(ANode: TTMSFNCTreeViewVirtualNode;
  var AColor: TTMSFNCGraphicsColor);
begin
  inherited;
  if not assigned(anode) then exit;
  if not assigned(anode.node) then exit;

  if showChanges then
  begin
    if anode.node.data.changed then
    begin
      acolor := ChangedColor;
      // alog.send('Changed');
    end
    else
      aColor := BGColor;
  end
  else
  begin
    if anode.node.extended then
    begin
      aColor := ExtendedNodeColor;
    end
    else
    begin
      if AlternatingRows then
      begin
        if odd(anode.index) then
          aColor := AlternatingColor
        else
          aColor := BGColor;

      end
      else
        acolor := BGColor;
    end;
  end;

end;

procedure txIX.DoGetNodeData(ANode: TTMSFNCTreeViewVirtualNode);
begin
  inherited;

end;

procedure txIX.DoGetNodeDisabledColor(ANode: TTMSFNCTreeViewVirtualNode;
  var AColor: TTMSFNCGraphicsColor);
begin
  inherited;

end;

procedure txIX.DoGetNodeDisabledStrokeColor(
  ANode: TTMSFNCTreeViewVirtualNode; var AColor: TTMSFNCGraphicsColor);
begin
  inherited;

end;

procedure txIX.DoGetNodeDisabledTextColor(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var ATextColor: TTMSFNCGraphicsColor);
begin
  inherited;

end;

procedure txIX.DoGetNodeDisabledTitleColor(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var ATitleColor: TTMSFNCGraphicsColor);
begin
  inherited;

end;

procedure txIX.DoGetNodeExtraSize(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var AExtraSize: Single);
begin
  inherited;

end;

procedure txIX.DoGetNodeHeight(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var AHeight: Double);
begin
  inherited;
   if FixedHeight<>0 then aHeight:=fixedHeight;

end;

procedure txIX.DoGetNodeHorizontalTextAlign(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var AHorizontalTextAlign: TTMSFNCGraphicsTextAlign);
begin
  inherited;

end;

procedure txIX.DoGetNodeIcon(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; ALarge: Boolean; var AIcon: TTMSFNCBitmap);
begin

end;

procedure txIX.DoGetNodeIconSize(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; ALarge: Boolean; AIcon: TTMSFNCBitmap; var AIconWidth,
  AIconHeight: Double);
begin
  inherited;

end;

procedure txIX.DoGetNodeRounding(ANode: TTMSFNCTreeViewVirtualNode;
  var ARounding: Integer; var ACorners: TTMSFNCGraphicsCorners);
begin
  inherited;

end;

procedure txIX.DoGetNodeSelectedColor(ANode: TTMSFNCTreeViewVirtualNode;
  var AColor: TTMSFNCGraphicsColor);
begin
  inherited;
  if noSelectStyle then
    aColor := na.fill.color
  else
    aColor := SelNodeCOlor;

end;

procedure txIX.DoGetNodeSelectedStrokeColor(
  ANode: TTMSFNCTreeViewVirtualNode; var AColor: TTMSFNCGraphicsColor);
begin
  inherited;
  //if noSelectStyle then   acolor:=LineColor else
  //begin
  if anode = SelectedVirtualNode then
    aColor := selLineCOlor
  else
    aColor := LineColor;

  //end;
end;

procedure txIX.DoGetNodeSelectedTextColor(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var ATextColor: TTMSFNCGraphicsColor);
begin
  inherited;
  if noSelectStyle then

    ATextColor := TextColor
  else                                  //columns[aColumn].font.color else
    aTextColor := SelTextColor;
end;

procedure txIX.DoGetNodeSelectedTitleColor(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var ATitleColor: TTMSFNCGraphicsColor);
begin
  inherited;

end;

procedure txIX.DoGetNodeSides(ANode: TTMSFNCTreeViewVirtualNode;
  var ASides: TTMSFNCGraphicsSides);
begin
  inherited;

  if linestyle = lsBottom then
    ASides := [gsBottom]
  else

    aSides := [] ;
  { if anode.Index=0 then
   begin
      if linestyle=lsBottom then ASides:=[gsBottom, gsTop] else ASides:=[gsTop];
   end;}
end;

procedure txIX.DoGetNodeStrokeColor(ANode: TTMSFNCTreeViewVirtualNode;
  var AColor: TTMSFNCGraphicsColor);
begin
  inherited;
  if assigned(anode) = false then exit;
  if anode = SelectedVirtualNode then
    aColor := SelLineCOlor
  else
    aColor := LineCOlor;

end;

procedure txIX.DoGetNodeText(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; AMode: TTMSFNCTreeViewNodeTextMode; var AText: string);
var
  LT                : string;
  s                 : string;
  c                 : integer;
  ind               : integer;
  inds              : string;
  countText         : string;
  addText           : string;
  nameText          : string;
begin
  inherited;
  // alog.send('GetText', atext);
  if not assigned(anode) then exit;
  if not assigned(anode.node) then exit;
  LT := anode.node.data.lastText[acolumn] ;

  if LT <> '' then

    if LT <> aText then
    begin
      anode.node.data.changed := true;

    end;
  if BRLineBreaks then
  begin
    atext := stringReplace(atext, #13#10, '<BR>', [rfIgnoreCase, rfReplaceALL] ); //<HR>
  end;

  {if anode.node.data.isListObject = true then
  begin
    if aColumn = 1 then
    begin
      c := gp(anode.node.data.objectlist, 'count').AsInteger;
      s := Inttostr(c) + ' items';
      //   ind:=Canvas.TextWidth(s)+trunc(NodesAppearance.LevelIndent)+1;
      if showListAdd then
      begin
      Ind := ListWidth - Length(ListAdd) - Length(s);
      IndS := '<IND x="' + Inttostr(ind) + '">';
      s := s + '<a href="add"><FONT color="gcLightGreen">' + Inds + ListAdd + '</FONT></a>';
      end;
      atext := s;
    end;
  end;   }

  CountText := '';
  AddText := '';
  nameText := '';

  if ((anode.node.data.isListObject = true) or (anode.node.data.isListItem = true)) then
  begin
    if assigned(onGetGenericName) then
      onGetGenericName(self, anode.node.data.objectdata, AColumn, nameText);
  end;

  if anode.node.data.isListObject = true then
  begin
    if acolumn = listCountCol then
    begin
      c := gp(anode.node.data.objectlist, 'count').AsInteger;
      CountText := Inttostr(c) + ' items';
    end;
    if acolumn = listAddcol then
    begin
      Ind := ListWidth - Length(ListAdd) - Length(CountText) - length(nametext);
      if ind > 0 then

        IndS := '<IND x="' + Inttostr(ind) + '">';
      AddText := '<a href="add"><FONT color="gcLightGreen">' + Inds + ListAdd + '</FONT></a>';
    end;
    //if acolumn<>0 then
    s := nametext;
    if s <> '' then s := s + '  ';

    s := s + counttext + addtext;
    if s <> '' then atext := s;

  end;

  if anode.node.data.isListItem = true then
  begin

    if nameText <> '' then atext := nameText;

    if acolumn = listRemoveCol then
    begin
      ind := ListWidth - Length(ListRemove) - length(atext);
      if ind > 0 then
        indS := '<IND x="' + Inttostr(ind) + '">';
      s := '<a href="Delete"><FONT  color="gcred">' + Inds + ListRemove + '</FONT></a>';
      atext := atext + s;
    end;
  end;

  {if anode.node.data.isListItem = true then
  begin
    if acolumn = 0 then
    begin
      if assigned(onGetGenericName) then
        onGetGenericName(self, anode.node.data.objectdata, atext)
      else
        atext := '<i>unnamed</i>';

    end;
    if acolumn = 1 then
    begin
      if showlistremove then
      begin
      ind := ListWidth - Length(ListRemove);
      // alog.send('IND', ind);
      indS := '<IND x="' + Inttostr(ind) + '">';
      s := '<a href="Delete"><FONT  color="gcred">' + Inds + ListRemove + '</FONT></a>';
      atext := s;
      end;
    end;
  end;         }
end;

procedure txIX.DoGetNodeTextColor(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var ATextColor: TTMSFNCGraphicsColor);
begin
  inherited;
  if not assigned(anode) then exit;
  if not assigned(anode.node) then exit;

  if anode.node.extended then
    aTextColor := ExtendednodetextColor
  else

    ATextColor := Textcolor;
end;

procedure txIX.DoGetNodeTitle(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; AMode: TTMSFNCTreeViewNodeTextMode;
  var ATitle: string);
begin
  inherited;

end;

procedure txIX.DoGetNodeTitleColor(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var ATitleColor: TTMSFNCGraphicsColor);
begin
  inherited;

end;

procedure txIX.DoGetNodeTitleExpanded(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var AExpanded: Boolean);
begin
  inherited;

end;

procedure txIX.DoGetNodeTitleExtraSize(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var ATitleExtraSize: Single);
begin
  inherited;

end;

procedure txIX.DoGetNodeTitleHorizontalTextAlign(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var AHorizontalTextAlign: TTMSFNCGraphicsTextAlign);
begin
  inherited;

end;

procedure txIX.DoGetNodeTitleTrimming(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var ATrimming: TTMSFNCGraphicsTextTrimming);
begin
  inherited;

end;

procedure txIX.DoGetNodeTitleVerticalTextAlign(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var AVerticalTextAlign: TTMSFNCGraphicsTextAlign);
begin
  inherited;

end;

procedure txIX.DoGetNodeTitleWordWrapping(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var AWordWrapping: Boolean);
begin
  inherited;

end;

procedure txIX.DoGetNodeTrimming(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var ATrimming: TTMSFNCGraphicsTextTrimming);
begin
  inherited;

end;

procedure txIX.DoGetNodeVerticalTextAlign(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var AVerticalTextAlign: TTMSFNCGraphicsTextAlign);
begin
  inherited;

end;

procedure txIX.DoGetNodeWordWrapping(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var AWordWrapping: Boolean);
begin
  inherited;

end;

procedure txIX.DoGetNumberOfNodes(ANode: TTMSFNCTreeViewVirtualNode;
  var ANumberOfNodes: Integer);
begin
  inherited;

end;

procedure txIX.DoInplaceEditorTimer(Sender: TObject);
begin
  inherited;

end;

procedure txIX.DoIsNodeChecked(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var AChecked: Boolean);
begin
  inherited;

end;

procedure txIX.DoIsNodeEnabled(ANode: TTMSFNCTreeViewVirtualNode;
  var AEnabled: Boolean);
begin
  inherited;

end;

procedure txIX.DoIsNodeExpanded(ANode: TTMSFNCTreeViewVirtualNode;
  var AExpanded: Boolean);
begin
  inherited;

end;

procedure txIX.DoIsNodeExtended(ANode: TTMSFNCTreeViewVirtualNode;
  var AExtended: Boolean);
begin
  inherited;

end;

procedure txIX.DoIsNodeVisible(ANode: TTMSFNCTreeViewVirtualNode;
  var AVisible: Boolean);
begin
  inherited;

end;

procedure txIX.DoNodeAnchorClick(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; AAnchor: string);
var
  n, newNode        : ttmsfncTreeViewNode;
  aObject           : tobject;
  aclass            : tclass;
  c                 : integer;
begin
  inherited;
  alog.send('Anchor', aAnchor);
  n := anode.node;
  if assigned(n.data.ObjectData) then
    alog.send('ObjectData', n.data.ObjectData)
  else
    alog.send('ObjectData', 'NIL');
  if assigned(n.dataobject) then
    alog.send('DataObject', n.DataObject)
  else
    alog.send('DataObject', 'NIL');

  if AAnchor = 'add' then
  begin
    aObject := AddtoObjectList(n.data.ObjectData);
    NewNode := n.nodes.add;
    NewNode.data.isListItem := true;
    NewNode.data.objectList := n.data.objectdata;
    NewNode.data.objectdata := aObject;
    newnode.text[0] := '<i>unnamed</i>';

    if ShowListObjects = true then ReadSingleObject(aObject, NewNode);
    alog.send('made', aobject);
    ActiveNode := NewNode;
    if assigned(onGenericAdded) then onGenericAdded(self, aobject, n.data.ObjectData, NewNode);
    doTimedAutoSize;
  end
  else if aAnchor = 'Delete' then
  begin
    alog.send('Removal');
    c := Removefromobjectlist(n.data.objectlist, n.data.ObjectData);
    if c <> -1 then anode.node.GetParent.nodes.Delete(anode.node.index);

    doTimedAutoSize;
  end;

end;

procedure txIX.DoNodeChanged(ANode: TTMSFNCTreeViewVirtualNode);
begin
  inherited;

end;

procedure txIX.DoNodeClick(ANode: TTMSFNCTreeViewVirtualNode);
begin
  inherited;

end;

procedure txIX.DoNodeDblClick(ANode: TTMSFNCTreeViewVirtualNode);
begin
  inherited;
  if copyonDblClick then
    if assigned(anode) then
      if assigned(anode.node) then
      begin

      end;

end;

procedure txIX.DoNodeMouseEnter(ANode: TTMSFNCTreeViewVirtualNode);
begin
  inherited;

end;

procedure txIX.DoNodeMouseLeave(ANode: TTMSFNCTreeViewVirtualNode);
begin
  inherited;

end;

procedure txIX.DoNodeTitleAnchorClick(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; AAnchor: string);
begin
  inherited;

end;

procedure txIX.DoNodeValueClick(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer);
begin
  inherited;

end;

procedure txIX.doOnExit(sender: tobject);
begin
  unselectAllNodes;
  SelectedNode := nil;
  {$IFDEF fwfmx}

  {$ENDIF}
  {$IFDEF fwvcl}
  update;
  refresh;
  {$ENDIF}
  {$IFDEF fwweb}

  {$ENDIF}

end;

procedure txIX.DoPropertyValueChanged(AObject: TObject;
  APropertyInfo: TTMSFNCPropertyInfo; APropertyName: string;
  APropertyType: TTypeKind; APropertyValue: string;
  APropertyValueObject: TObject);
begin
  inherited;

end;

procedure txIX.DoReadPropertyN(AObject: TObject;
  APropertyInfo: TTMSFNCPropertyInfo; APropertyName: string;
  APropertyType: TTypeKind; anode: ttmsfncTreeViewNode;
  var ACanRead: Boolean);
var
  s                 : string;
  tail              : ttmsfncTreeViewNode;
begin
  inherited;
  // alog.send('DoReadPropertyValue');
  s := aPropertyinfo.name;
  if assigned(anode) then
  begin
    tail := anode;
    while assigned(tail) do
    begin
      if assigned(tail.data.ObjectData) then
        s := tail.data.objectdata.classname + '.' + s
      else

        s := tail.text[0] + '.' + s;
      tail := tail.GetParent;
    end;
  end;
  //if aPropertyinfo.tail<>nil then s:=aPropertyInfo.tail.Name+s;

  aCanREad := isAllowed(s);
 // if acanread = false then alog.send('NOT NOT ALLOWED ' + aPropertyName, s);
end;

procedure txIX.DoReadPropertyValue(AObject: TObject;
  APropertyInfo: TTMSFNCPropertyInfo; APropertyName: string;
  APropertyType: TTypeKind; var APropertyValue: string;
  var APropertyValueObject: TObject; var ACanRead: Boolean);
begin
  inherited;

end;

procedure txIX.DoStrokeKindSelected(Sender: TObject;
  AStrokeKind: TTMSFNCGraphicsStrokeKind);
begin
  inherited;

end;

procedure txIX.doTimedAutoSize(WT: integer);
begin
  doAutoSize(WT);

end;

procedure txIX.DoTimeSelected(Sender: TObject; ATime: TTime);
begin
  inherited;

end;

procedure txIX.DoUpdateNodeText(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; AText: string);
begin
  inherited;

end;

procedure txIX.DoUpdateNodeTitle(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; ATitle: string);
begin
  inherited;

end;

procedure txIX.DoWritePropertyValue(AObject: TObject;
  APropertyInfo: TTMSFNCPropertyInfo; APropertyName: string;
  APropertyType: TTypeKind; var APropertyValue: string;
  var APropertyValueObject: TObject; var ACanWrite: Boolean);
begin
  inherited;

end;

function txIX.DrawnAll: boolean;

  function doNodes(aNodes: ttmsfncTreeViewNodes): boolean;
  var
    n               : ttmsfnctreeviewnode;
    i               : integer;
  begin
    result := true;
    for i := 1 to anodes.count do
    begin
      n := anodes[i - 1] ;
      if n.data.DoneCW = false then
      begin
        result := false;
        //  alog.send('Not drawn', n.text[0] );
      end;
      doNodes(n.nodes);

    end;
  end;
var
  r                 : boolean;
begin
  result := true;
  r := doNodes(nodes);

end;

procedure txIX.ExportNotification(AState: TTMSFNCTreeViewExportState;
  ARow: Integer);
begin
  inherited;

end;

function txIX.Find(aObjectData: tobject): ttmsfncTreeViewNode;

  function doNodes(anodes: TTMSFNCTreeViewNodes; var r: ttmsfncTreeViewNode): ttmsfncTreeViewNode;
  var
    n               : ttmsfncTreeViewNode;
    i               : integer;
  begin
    result := nil;
    for i := 1 to anodes.count do
    begin
      n := anodes[i - 1] ;
      if ((n.data.ObjectData = aObjectData) or (n.DataObject = aobjectData)) then
      begin
        r := n;
        alog.send('found in do nodes', n.text[1] );
        exit;
      end
      else
        doNodes(n.nodes, r);
    end;

  end;
var
  n2                : ttmsfncTreeViewNode;
begin
  if not assigned(aobjectdata) then alog.send('aobjectdata nil');

  alog.send('FIND');
  n2 := nil;
  doNodes(nodes, n2);
  alog.send('here');
  result := n2;
  if n2 <> nil then

    alog.send('found', n2)
  else
    alog.send('NIL');
end;

function txIX.FIndNodeHash(aHash: string): ttmsfncTreeViewNode;
function doNodes(anodes: ttmsfncTreeViewNodes): ttmsfncTreeViewNode;
var
 n, r: ttmsfncTreeViewNode;
 i: integer;
begin
 result:=nil;
 for i := 1 to anodes.count do
   begin
     n:=anodes[i-1];
     // alog.send('Checking', getnodehash(n));
     if getNodeHash(n)=aHash then
     begin
      alog.send('Match', ahash);
       result:=n;
       exit;
     end;
     r:=doNodes(n.nodes);
     if assigned(r) then result:=r;

   end;

end;
begin
  result:=doNodes(nodes);
end;

procedure txIX.ForceCollapseRoot;
begin
  if nodes.count = 0 then exit;

  fcRoot := true;
  nodes[0] .Collapse(false);
end;

procedure txIX.ForceExpandRoot;
begin
  if nodes.count = 0 then exit;

  feRoot := true;
  nodes[0] .expand(false);
end;

function txIX.GetActiveObject: tObject;
var
  aObject           : tobject;
begin
  result := nil;
  if assigned(fActiveNode) then
  begin
    if assigned(fActiveNode.DataObject) then

      aObject := fActiveNode.DataObject
    else if assigned(fActiveNode.data.ObjectData) then
      aObject := fActiveNode.data.ObjectData;
    result := aObject;
  end;

end;

function txIX.GetDocURL: string;
begin

end;

function txIX.GetEditProp: TTMSFNCPropertyInfo;
begin
  {$IFDEF fwweb}
  Result := TTMSFNCPropertyInfo(SelectedNode.DataPointer);
  {$ELSE}
  Result := TTMSFNCPropertyInfo(SelectedNode.DataPointer);
  {$ENDIF}

end;

function txIX.GetEditValue: string;
begin
  if not assigned(editor) then exit;
  if editor is TTMSFNCTreeViewEdit then
    result := TTMSFNCTreeViewEdit(editor).text
  else if editor is TTMSFNCTreeViewMemo then
    result := TTMSFNCTreeViewMemo(editor).text;

end;

function txIX.getFullWidthColIdx: integer;
var
 acol: ttmsfncTreeViewColumn;
begin
  result:=-1;
  if FullWidthCol<>'' then
  begin
    acol:=col(fullwidthCol);
    if assigned(acol) then result:=acol.index;

  end;

end;

function txIX.GetNodeHash(anode: ttmsfncTreeViewNode): string;
var
 n: ttmsfncTreeViewNode;
 i: integer;
begin
n:=anode;
result:='';
//alog.send('Getting hash for', anode.index);
if not assigned(n) then n:=ActiveNode;
if not assigned(n) then exit;
for i := 1 to columns.count do
  begin
    result:=result+n.VirtualNode.text[i-1];
  end;
 // alog.send('GotHash', result);


end;

function txIX.GetNodeHeights: integer;

  procedure doNodes(anodes: ttmsfncTreeviewnodes; var c: double);
  var
    i               : integer;
    n               : ttmsfncTreeViewNode;
  begin
    for i := 1 to anodes.count do
    begin
      n := anodes[i - 1] ;
      //     r:=r+n.data.drawnheight;
      if assigned(n.VirtualNode) then

        c := c + n.VirtualNode.Height;
      doNodes(n.nodes, c);
    end;
  end;
var
  n                 : ttmsfncTreeViewNode;
  i                 : integer;
  r                 : double;
begin
  r := 0;
  donodes(nodes, r);

  {for i := 1 to nodes.count do
  begin
    n := nodes[i - 1] ;
    //     r:=r+n.data.drawnheight;
    if assigned(n.VirtualNode) then

      r := r + n.VirtualNode.Height;
  end;             }
  result := trunc(r);

end;

function txIX.GetSelectedObject: tObject;
begin
  result := nil;
  if not assigned(fActiveNode) then exit;
  //if assigned(selectedNode.data) then
  if assigned(fActiveNode.data.ObjectData) then
    result := selectedNode.data.ObjectData
  else
  begin
    if assigned(fActiveNode.DataObject) then result := fActiveNode.DataObject;

  end;

end;

function txIX.GetTypeKind: tTypeKind;
var
  p                 : TTMSFNCPropertyInfo;
begin
  p := TTMSFNCPropertyInfo(SelectedNode.DataPointer);
  result := GetPropInfoType(p);
end;

procedure txix.REsize;
begin
  inherited;

  try
    if initdone = false then exit;
    alog.send('Resize');
    docolAutowidth(0);
  except
    on e: exception do
    begin
      alog.error('resize', e.message);
    end;

  end;
end;

procedure txIX.init;
begin
  try
    ///////////////////////////////////////////////////////////////Timers
    TimerColAutoWidth := tTimer.create(nil);
    timerColAutoWidth.Enabled := false;
    TimerColAutoWidth.tag := 50;
    TimerColAutoWidth.OnTimer := TimerColAutoWidthTImer;

    AutoSizeTimer := tTimer.create(nil);
    AutoSizeTimer.Enabled := false;
    AutoSizeTimer.ontimer := onAutoSizeTimerTimer;
    AutoSizeTimer.tag := 600;

    UpdateTimer := tTimer.create(nil);
    UpdateTimer.Enabled := false;
    UpdateTimer.ontimer := onUpdateTimerTimer;
    UpdateTimer.interval := 600;

    EditTImer := tTimer.create(nil);
    Edittimer.Enabled := false;
    EditTImer.ontimer := OneditTimer;
    EditTimer.interval := 600;
    ///////////////////////////////////////////////////////////////Icons
    icons := txIcons.create;
      icons.onReady:=IconsReady;
    icons.load(icons.uri, 'txfstv', false);
    BitmapContainer := icons.bc;

    ///////////////////////////////////////////////////////////////AutoSizing
    EnableColAutoWidth := true;

    // FixedHeight:=0;

    parent := txWinControlClass(owner);
    Initialize;
    DoubleBuffered := true;
    //onExit:=doOnExit;
   ///////////////////////////////////////////////////////////////Lists
    listAddCol := -1;
    ListRemoveCol := -1;
    ListCountCol := 1;
    ShowLists := true;
    showListObjects := true;
    ShowListAdd := true;
    ShowListRemove := true;
    ListAdd := 'Add';
    ListRemove := 'Remove';
    ListWidth := 0;

    ///////////////////////////////////////////////////////////////Columns
    TopHeight := 60;
    ColHMargin := 9;
    colVMargin := 9;
    width := 1000;
    Height := 1000;
    ca.Stretch := false;
    ca.StretchAll := false;
    exList := tstringlist.create;
    exlist.CaseSensitive := false;
    exlist.Sorted := true;
    exlist.Duplicates := dupIgnore;
    IncList := tstringlist.create;
    IncList.CaseSensitive := false;
    IncList.Sorted := true;
    IncList.Duplicates := dupIgnore;
    IncMode := txIncMode.xEx;
    OnMouseDown := IXmousedown;
      cl.Filtering.Enabled:=true;

    interaction.lookup.enabled:=true;
    interaction.Lookup.Column:=0;
    interaction.Lookup.CaseSensitive:=false;
    interaction.Lookup.VisibleNodesOnly:=true;
    interaction.Lookup.autoselect:=true;
    interaction.Lookup.RootNodesOnly:=false;
    interaction.Lookup.Incremental:=false;
    interaction.Lookup.AutoExpand:=true;

    //beginupdate;
    CL.UseDefaultAppearance := false;
    CR.UseDefaultAppearance := false;
    cl.name := 'colleft';
    cr.name := 'colright';
    // CL.EditorType:=TTMSFNCTreeViewColumnEditorType.tcetEdit;
    cl.CustomEditor := true;
    ca.FillEmptySpaces := true;
    ca.OptimizedColumnDisplay := true;

    AllowGenericREname := true;
    TopFont := tFont.Create;

    {$IFDEF fwfmx}
    topfont.OnChanged := DoAllFontChange;
    {$ELSE}
    topfont.OnChange := DoAllFontChange;
    {$ENDIF}

    LineColor := gcblack;
    SelLineColor := gcBlack;
    BGStyle := bgTrans;
    BGColor := gcWhite;
    textColor := gcBlack;
    SelTextColor := gcBlack;
    SelNodeColor := gcWhite;

    cL.data.AllowShow := true;
    cR.data.allowshow := true;

    Interaction.ColumnSizing := true;
    Interaction.ReadOnly := true;

    {NodesAppearance.ExpandNodeIcon := nil;
    NodesAppearance.ExpandNodeIconLarge := nil;
    NodesAppearance.ExpandNodeIcon := nil;
    NodesAppearance.ExpandNodeIconLarge := nil;}

    AdaptToStyle := false;
    //GlobalFont.Size:=10;
   // NodesAppearance.font.Size := 10;

    NA.ShowFocus := false;
    // NA.HeightMode := TTMSFNCTreeViewNodeHeightMode.tnhmVariable;
    NA.Stroke.kind := gskSolid;
    NA.stroke.width := 0.5;
    NA.stroke.Color := GCblack;
    NA.showlines := false;
    FixedNodeHeight := 50;
    // NA.ExpandWidth := 0;
    // NA.ExpandHeight := 0;
    na.SelectionArea := TTMSFNCTreeViewSelectionArea.tsaFull;
    Stroke.kind := gskSolid;
    stroke.width := 0.5;
    stroke.Color := GCblack;
    stroke.kind := gsknone;
    CA.TopSize := 0;
    ColumnStroke.kind := gskNone;
    ca.TopStroke.kind := gskNone;
    NA.ColumnStroke.kind := gskNone;
    {
    CL.TopFont.size := 12;
    CL.topfont.style := [tFontStyle.fsBold] ;
    CR.TopFont.size := 12;
    CR.topfont.style := [tFontStyle.fsBold] ; }

    CL.TopStroke.kind := gskNone;
    CR.TopStroke.kind := gskNone;
    CL.BottomStroke.kind := gskNone;
    CR.BottomStroke.kind := gskNone;

    CL.Stroke.kind := gskNone;
    CR.Stroke.kind := gskNone;

    LineStyle := lsBottom;
    AutoHeight := false;
    AutoWidth := false;

    VerticalScrollBar.Enabled := false;
    horizontalScrollBar.Enabled := false;
    // VerticalScrollBarVisible := false;
    HorizontalScrollBarVisible := false;
    verticalScrollBar.PageSize := 55;
    verticalScrollbar.LargeChange := 55;
    ChangedColor := gcLightGreen;

    IconSize := 32;
    initmenu;
    initDone := true;
    ApplyColumnStyles;

  except
    on e: exception do
    begin
      alog.error('init', e.message);
    end;

  end;
  //endupdate;
 // cl.Width:=1000;
 // cr.width:=1000;
 // Width:=2000;
   // doublebuffered:=true;
end;

procedure txIX.InitMenu;
var
  aItem             : TMenuItem;
begin
  pM := tPopupMenu.Create(self);
  //  popupMenu:=pm;
  pm.OnPopup := pmOPen;
  pmadd('Expand All');
  pmadd('Collapse All');
  pmadd('-');
  pmAdd('Show Columns');
  pmadd('Col auto width');
  pmadd('-');
  pmadd('Bigger Text');
  pmadd('Normal Text');
  pmadd('Smaller Text');
  pmadd('-');
  pmadd('Dont click');
  pmadd('-');
  pmadd('Add Item');
  pmadd('Delete Item');



  EnableMenu := true;
end;

procedure txIX.InternalEditing(sender: tobject);
begin
  if assigned(onediting) then onEditing(tvalue.from<string>(EditValue));

end;

procedure txIX.InternalRebuildList(AUpdate: Boolean);
begin
  inherited;
  alog.send('InternalRebuildList');
end;

function txIX.isAllowed(aprop: string): boolean;
begin
  //alog.send('IsAllowsed', aprop);
  if incmode = txIncMode.xEx then
  begin
    result := exList.indexof(aprop) = -1;
  end
  else if incmode = txIncMode.xInc then
  begin
    result := incList.indexof(aprop) <> -1;
  end;
end;

procedure txIX.IXMouseDown(Sender: TObject; Button: TMouseButton;
  Shift: TShiftState; X, Y: Integer);
var
  n                 : TTMSFNCTreeViewVirtualNode;
  p,p2: tpoint;
begin

  //alog.send('MouseDown');
  n := XYToNode(x, y);
  if assigned(n) then
    ActiveNode := n.node
  else
    alog.send('No node');

  if button = mbRight then
  begin
    if EnableMenu then
    begin
    {$IFnDEF fwweb}
       p2:=tPoint.create(x,y);
    {$ENDIF}
      p2.x:=x;
      p2.y:=y;
      p:=self.ClientToScreen(p2);
      pm.popup(p.x, p.y);
    end;
  end;

end;

procedure txIX.LoadFields;
begin
  inherited;

end;

function txIX.NA: TTMSFNCTreeViewNodesAppearance;
begin
  result := NodesAppearance;
end;

function txIX.NewCol(atext, aname: string; aAllowShow: boolean;
  awidth: integer): ttmsfncTreeViewColumn;
begin
  result := columns.add;
  result.text := aText;
  result.name := aname;
  result.data.AllowShow := aAllowShow;
  result.UseDefaultAppearance := false;
  result.Sorting := TTMSFNCTreeViewColumnSorting.tcsNormal;
  if awidth <> -1 then result.width := awidth;

  ApplyColumnStyles;
end;

procedure txIX.Notification(AComponent: TComponent;
  AOperation: TOperation);
begin
  inherited;

end;

procedure txIX.onEditTimer(sender: tobject);
begin
  EditTimer.Enabled := false;
  DoAutoSize;

end;

procedure txIX.onUpdateTimerTimer(sender: tobject);
begin
  UpdateTimer.enabled := false;
  UpdateValues;
end;

function txIX.pmAdd(acaption: string): tmenuItem;
var
  n                 : string;
begin
  n := StringReplace(acaption, ' ', '', [rfIgnorecase, rfReplaceAll] );
  n := lowercase(n);

  result := tmenuItem.create(pm);
  result.caption := aCaption;
  result.OnClick := pmclick;
  pm.items.add(result);
  if acaption <> '-' then result.name := n;
  result.tag := pm.items.count;
end;


procedure txIX.DoAddItem(anode: ttmsfncTreeViewNode);
var
  n, newNode        : ttmsfncTreeViewNode;
  aObject           : tobject;
  aclass            : tclass;
  c                 : integer;
begin
    if anode.data.islistitem then anode:=anode.GetParent;

    if listAddOption=goDoandNotify then
    begin
    aObject := AddtoObjectList(anode.data.ObjectData);
    NewNode := anode.nodes.add;
    NewNode.data.isListItem := true;
    NewNode.data.objectList := n.data.objectdata;
    NewNode.data.objectdata := aObject;
   // newnode.text[0] := '<i>unnamed</i>';

    if ShowListObjects = true then ReadSingleObject(aObject, NewNode);
    alog.send('made', aobject);
    ActiveNode := NewNode;
    if assigned(onGenericAdded) then onGenericAdded(self, aobject, n.data.ObjectData, NewNode);
    end else
    if listAddOption=goNotify then
    begin
     if assigned(onGenericAdded) then onGenericAdded(self, nil, n.data.ObjectData, anode);
    end;
    doColAutoWidth(0);


end;

procedure txIX.DoDeleteItem(anode: ttmsfncTreeViewNode);
var
  n, newNode        : ttmsfncTreeViewNode;
  aObject           : tobject;
  aclass            : tclass;
  c                 : integer;
begin

   if ListDeleteOption=godoandNotify then
   begin
    alog.send('Removal');
    c := Removefromobjectlist(anode.data.objectlist, anode.data.ObjectData);
    if c <> -1 then anode.GetParent.nodes.Delete(anode.index);
    if assigned(onGenericDeleted) then
    onGenericDeleted(self, nil, anode.data.objectlist,anode);
   end else
   if ListDeleteOption=goNotify then
   begin
        if assigned(onGenericDeleted) then
    onGenericDeleted(self, anode.data.ObjectData, anode.data.objectlist,anode);
   end;
    doColAutoWidth(0);


end;



procedure txIX.pmclick(sender: tobject);
var
  aitem             : tmenuitem;
  Item              : string;
begin
  alog.send('pmClick', sender);
  aItem := tmenuItem(sender);
  Item := lowercase(aitem.name);

  if item = 'expandall' then FullExpand;
  if item = 'collapseall' then
  begin
    FullCollapse;
  end;
  if item = 'showcolumns' then
  begin
    ShowColumns := not ShowColumns;
  end;
  if item = 'colautowidth' then
  begin
    doColAutoWidth;
  end;
  if item = 'biggertext' then
  begin
    FontSize := FontSize + 2;
    alog.send('Biggertext', fontsize);
  end;
  if item = 'smallertext' then
  begin
    FontSize := FontSize - 2;
    alog.send('smallertext', fontsize);
  end;
  if item = 'normaltext' then
  begin
    FontSize := NormalFontSize;
  end;
  if item = 'additem' then
  begin
   DoAddItem(activeNode);

  end;
  if item = 'deleteitem' then
  begin
    doDeleteItem(activeNode);

  end;



  if item = 'dontclick' then
  begin


  end;


end;

procedure txIX.pmOpen(sender: tobject);
var
 mAddItem, mDeleteItem, aitem: tmenuItem;
 ac: tComponent;
begin
 mAddItem:=tmenuItem(pm.FindComponent('additem'));
 mDeleteItem:=tmenuItem(pm.FindComponent('deleteitem'));


 if assigned(factivenode) then
 begin

   if ((factivenode.data.isListObject) or (factivenode.data.isListItem)) then
   begin
    for ac in pm.items do
       tMenuItem(ac).Visible:=false;

     if factivenode.data.isListItem then mDeleteItem.Visible:=true;
       mAddItem.Visible:=true;
   end else
   begin
       for ac in pm.items do
       tMenuItem(ac).Visible:=true;
        mAddItem.Visible:=false;
 mDeleteItem.Visible:=false;
   end;






 end;



end;

procedure txIX.Read(AObject: TObject);
begin
  inherited;

end;

procedure txIX.ReadBitmap(ABitmap: TTMSFNCBitmap;
  ANode: TTMSFNCTreeViewNode);
begin
  inherited;

end;

procedure txIX.ReadCollection(ACollection: TCollection;
  ANode: TTMSFNCTreeViewNode);
begin
  inherited;

end;

procedure txIX.ReadDB(AUpdate: Boolean);
begin
  inherited;

end;

procedure txIX.ReadGenericList(AList: TTMSFNCObjectList;
  ANode: TTMSFNCTreeViewNode);
begin
  inherited;

end;

procedure txIX.ReadObject(AObject: TObject; ANode: TTMSFNCTreeViewNode);
begin
  inherited;

end;

procedure txIX.ReadProperties(AObject: TObject;
  ANode: TTMSFNCTreeViewNode);
begin
  inherited;

end;

procedure txIX.ReadSingleObject(AObject: TObject;
  ANode: TTMSFNCTreeViewNode);
begin
  inherited;

end;

procedure txIX.Reload(NewObject: tobject; KeepSel: boolean);
var
 nh: string;
 n: ttmsfncTreeViewNode;
begin
 //alog.send('ActiveNode', activenode.index);
 //alog.send('activenodeText', activenode.text[0]);
 //alog.send('activenodeVText', activenode.virtualnode.text[0]);
 if assigned(activeNode) then

 if keepsel then nh:=getNodeHash(activeNode);
 alog.send('ActiveNodeHash', nh);
 SetaObject(NewObject);
 FullExpand;
 if keepsel then
  if nh<>'' then
  begin
    n:=findNodeHash(nh);
    if assigned(n) then
    begin
    alog.send('reselecting: '+n.text[0], nh);
    ActiveNode:=n;
    SelectNode(n);

    end


    else alog.send('Did not find hashed node');

  end;

end;

procedure txIX.ScrolltoActive;
begin
 if assigned(activeNode) then

 ScrollToNode(ActiveNode, true,TTMSFNCTreeViewNodeScrollPosition.tvnspMiddle);
end;

function txIX.SelectNodeHash(aHash: string): ttmsfncTreeViewNode;
var
 n: ttmsfncTreeViewNode;
begin
  n:=findNodeHash(aHash);
    if assigned(n) then
    begin
    alog.send('reselecting: '+n.text[0], aHash);
    ActiveNode:=n;
    SelectNode(n);
     result:=n;
    end else result:=nil;
end;




procedure txIX.SetActiveNode(const Value: ttmsfncTreeViewNode);
var
  old               : ttmsfncTreeViewNode;
  aObject           : tobject;
begin
  old := fActiveNode;

  FActiveNode := Value;
  // if old<>value then
  if assigned(onActiveNodeChange) then onActiveNodeChange(self, value);
  if not assigned(value) then
  begin
    fActiveObject:=nil;
   if assigned(onActiveObjectChange) then onActiveObjectChange(self, nil);
   exit;
  end;

  alog.send('NODE ' + value.text[0] + ' *******************');
  if assigned(value.data.objectdata) then
    alog.send('ObjectData', value.data.ObjectData)
  else
    alog.send('ObjectData', 'NIL');
  if assigned(value.dataobject) then
    alog.send('DataObject', value.dataobject)
  else
    alog.send('DataObject', 'NIL');
  if value.datapointer = nil then
    alog.send('DataPointer', 'NIL')
  else
  begin
    alog.send('DataPointer', TTMSFNCPropertyInfo(value.DataPointer).name);
  end;
  alog.send('isListItem', value.data.isListItem);
  alog.send('isListObject', value.data.isListObject);

  aObject := GetActiveObject;
  if fActiveObject <> aObject then
    if assigned(onActiveObjectChange) then onActiveObjectChange(self, aObject);

  fActiveObject := aObject;

end;

procedure txIX.setaObject(const Value: tObject);
begin
  FaObject := Value;
  //WaitThread:=txixWait.create(self);
  //WaitThread.OnTerminate:=WaitDone;
    UnSelectAllNodes;
    ActiveNode:=nil;
  &object := aobject;
  if DisableTestSizing = false then
  begin
    height := 10000;
    Width := 10000;
  end;
  {$IFDEF fwfmx}

  {$ENDIF}
  {$IFDEF fwvcl}

  update;
  Refresh;
  application.ProcessMessages;
  {$ENDIF}
  {$IFDEF fwweb}

  {$ENDIF}
  //    EditTimer.Enabled:=true;

  doautosize;
  setBgColor(bgColor);
  setBGStyle(bgStyle);
  {    exit;

  verticalscrollbar.Position:=verticalscrollbar.Max;
   VerticalScrollPositionChanged;
   HorizontalScrollBar.Position:=horizontalScrollbar.max;
   HorizontalScrollPositionChanged;
        application.ProcessMessages;
   verticalscrollbar.Position:=0;
   VerticalScrollPositionChanged;
            application.ProcessMessages;
         width:=width+1;
       cl.width:=cl.width+1;
       cr.width:=cr.width+1;
         setfocus;
 sleep(500);

   //doAutoSize;

  WaitThread.Start;        }

end;

procedure txIX.setaObject(const Value: tObject; aname: string);
begin
RootName:=aname;
setaObject(value);

end;

procedure txIX.setBGColor(const Value: TTMSFNCGraphicsColor);
begin

  FBGColor := Value;
  na.fill.color := value;
  cl.fill.color := value;
  cr.fill.color := value;
  cr.TopFill.color := value;
  cl.topfill.color := value;
  // ca.FillEmptySpaces := false;
  Fill.color := value;
  setBGStyle(bgStyle);

end;

procedure txIX.SetBGStyle(const Value: txBGStyle);
begin
  FBGstyle := Value;
  if value = BGTrans then
  begin
    na.fill.kind := gfkNone;
    cl.fill.kind := gfkNone;
    cr.fill.kind := gfkNone;
    cr.topfill.kind := gfkNone;
    cl.TopFill.kind := gfkNone;
    Fill.kind := gfkNone;
  end
  else if value = bgSingleColor then
  begin
    na.fill.kind := gfksolid;
    cl.fill.kind := gfksolid;
    cr.fill.kind := gfksolid;
    cr.topfill.kind := gfksolid;
    cl.TopFill.kind := gfksolid;
    fill.kind := gfksolid;
  end;
end;

procedure txIX.SetBorderColor(const Value: ttmsfncGraphicsColor);
begin
  FBorderColor := Value;
  Stroke.color := value;
end;

procedure txIX.SetColIcon(acol: integer; aIcon: string);
var
  aBitmap           : ttmsfncBitmapItem;
begin
  try
    if assigned(icons) then
    begin
      columns[acol] .data.icon := aIcon;
      columns[acol] .data.xicon := icons.geticon(aIcon);
      if not assigned(bitmapContainer) then bitmapContainer := icons.bc;

      //if not assigned(bitmapContainer) then bitmapContainer:=ttmsfncbitmapContainer.create;
      //bitmapcontainer.AddFromFile(columns[acol].data.xicon.fn, )
    end
    else
      alog.error('txix.setColIcon, icons not assigned');
  except
    on e: exception do
    begin
      alog.error('SetColIcon', e.message);
    end;

  end;

end;

procedure txIX.SetColTextLeft(const Value: string);
begin
  FColTextLeft := Value;
  if value <> '' then
    CL.text := value
  else
    CL.text := ' ';
end;

procedure txIX.SetColTextRight(const Value: string);
begin
  FColTextRight := Value;
  if value <> '' then
    CR.text := value
  else
    CR.text := ' ';
end;

procedure txIX.SetcTextHeight(const Value: single);
begin
  try
    FctextHeight := Value;
    if na.HeightMode = TTMSFNCTreeViewNodeHeightMode.tnhmFixed then
    begin
      if na.FixedHeight <> (value + 1.5) then na.fixedheight := (value + 1.5);

    end;
  except
    on e: exception do
    begin
      alog.error('setCtextHeight', e.message);
    end;

  end;

end;

procedure txIX.SetEditValue(s: string);
begin
  if not assigned(editor) then exit;
  if editor is TTMSFNCTreeViewEdit then
  begin
    TTMSFNCTreeViewEdit(editor).text := s;
  end
  else if editor is TTMSFNCTreeViewMemo then
  begin
    TTMSFNCTreeViewMemo(editor).lines.text := s;
  end;

end;

procedure txIX.SetEnableMenu(const Value: boolean);
begin
  FEnableMenu := Value;
end;

procedure txIX.SetExpanders(const Value: boolean);
begin
  FExpanders := Value;
  if value = false then
  begin
    NA.ExpandNodeIcon := nil;
    NA.ExpandNodeIconLarge := nil;
    NA.ExpandNodeIcon := nil;
    NA.ExpandNodeIconLarge := nil;
    NA.ExpandWidth := 0;
    NA.ExpandHeight := 0;
  end
  else
  begin
    //   NA.ExpandNodeIcon := nil;
   //NA.ExpandNodeIconLarge := nil;
  // NA.ExpandNodeIcon := nil;
  // NA.ExpandNodeIconLarge := nil;
    NA.ExpandWidth := 32;
    NA.ExpandHeight := 32;
  end;
end;

procedure txIX.SetFixedHeight(const Value: integer);
begin
  FFixedHeight := Value;
  if value <= 0 then
  begin
    //  NA.FixedHeight:=value;
    NA.HeightMode := TTMSFNCTreeViewNodeHeightMode.tnhmVariable

  end
  else
  begin
    NA.fixedHeight := value;
    NA.HeightMode := TTMSFNCTreeViewNodeHeightMode.tnhmFixed;
  end;
end;

procedure txIX.SetFixedNodeHeight(const Value: integer);
begin
  { FFixedNodeHeight := Value;
   if value = 0 then
     NA.HeightMode := TTMSFNCTreeViewNodeHeightMode.tnhmVariable
   else
   begin
     Na.HeightMode := TTMSFNCTreeViewNodeHeightMode.tnhmFixed;
     NA.FixedHeight := value;
   end;}

end;

procedure txIX.SetFonts(ASetType: TTMSFNCAppearanceGlobalFontType);
begin
  inherited;

end;

procedure txIX.SetFontSize(const Value: Integer);
begin
  ctextHeight := 0;
  beginupdate;
  if fFontSize = 0 then NormalFontSize := value;

  FFontSize := Value;
  NA.font.size := value;
  NA.ExtendedFont.Size := value;
  APplyColumnStyles;

  endupdate;
  doColAutoWidth;
end;

procedure txIX.setIXStyle(const Value: txIXStyle);
begin
  FixStyle := Value;
  if value = dark then
  begin
    BGColor := gcBlack;
    bgstyle := bgSingleColor;
    textcolor := gcWhite;

    SelNodeColor := gcDarkorange;
    topColor := 2105376;
    topfont.color := gcWhite;
    LineColor := 1315860;
    sellinecolor:= 1315860;
    colSeperatorCOlor := 5395026;
    ExtendedNodeColor := 2105376;
    ExtendedNodeTextColor := clwhite;
       na.stroke.color:=1315860;
     // na.ColumnStroke.Color:=1315860;
    ApplyColumnStyles;
  end
  else if value = light then
  begin
    BGColor := gcWhite;
    bgstyle := bgSingleColor;
    textcolor := gcBlack;

    SelNodeColor := gcSkyBlue;
    SelTextColor := gcblack;
    topColor := gcWhitesmoke;
    topfont.color := gcblack;
    LineColor := gcLightGray;
    colSeperatorCOlor := gcBlack;
    ApplyColumnStyles;
  end;
end;

{$IFNDEF fwweb}

function txIX.ApplyAllStyles: boolean;
var
  r                 : boolean;
begin
  result := true;

  r := applyBGStyle;
  if not r then result := false;
  exit;
  r := applyLineStyle;
  if not r then result := false;
  r := applySelStyle;
  if not r then result := false;
  r := applyTextStyle;
  if not r then result := false;

end;

function txIX.ApplyLineStyle: boolean;
var
  acol              : tcolorClass;
begin
  result := xstyler.getcolor(txcolor.xcLine, acol);
  if result then
  begin
    LineColor := acol;
    alog.send('Got Color', acol);
  end
  else
  begin
    alog.send('Could not get color');
  end;
end;

function txIX.ApplySelStyle: boolean;
var
  acol              : tcolorClass;
begin
  result := xstyler.getcolor(txcolor.xcHL, acol);
  if result then
  begin
    SelNodeColor := acol;
    alog.send('Got Color', acol);
  end
  else
  begin
    alog.send('Could not get color');
  end;
end;

function txIX.ApplyTextStyle: boolean;
var
  acol              : tcolorClass;
  r                 : boolean;
begin
  R := xstyler.getcolor(txcolor.xctText, acol);
  if R then textColor := acol;

  if result then
  begin
    textcolor := acol;
    alog.send('Got Color', acol);
  end
  else
  begin
    alog.send('Could not get color');
  end;
end;

function txIX.ApplyBGStyle: boolean;
var
  acol              : tcolorClass;
begin
  result := xstyler.getcolor(txcolor.xcHL, acol);
  if result then
  begin
    BGColor := acol;
    alog.send('Got Color', acol);
  end
  else
  begin
    alog.send('Could not get color');
  end;
end;

function txIX.ApplyBorder: boolean;
var
  acol              : tcolorClass;
begin
  result := xstyler.getcolor(txcolor.xcBorder, acol);
  if result then
  begin
    BorderColor := acol;
    alog.send('Got Color', acol);
  end
  else
  begin
    alog.send('Could not get color');
  end;
end;

{$ENDIF}

function txix.col(aname: string): ttmsfncTreeViewColumn;
var
  aColumn           : ttmsfncTreeViewColumn;
  i                 : integer;
begin
  result := nil;
  for I := 1 to columns.count do
  begin
    acolumn := columns[i - 1] ;
    if lowercase(acolumn.name) = lowercase(aname) then
    begin
      result := aColumn;
      exit;
    end;
  end;

end;

function txix.col(idx: integer): ttmsfncTreeViewColumn;
begin
  result := columns[idx] ;
end;

procedure txIX.APplyColumnStyles;
var
  c                 : ttmsfncTreeViewColumn;
  i                 : integer;
begin
  beginupdate;
  for i := 1 to columns.count do
  begin
    c := col(i - 1);
    c.fill.Color := FBGColor;
    c.topfill.color := fTopColor;

    c.topfont.assign(topFont);
    c.topfont.color := clwhite;
    c.font.size := FontSize;
    if fShowColumns then
    begin
      c.TopStroke.kind := gskNone;
      c.stroke.kind := gskNone;
      c.BottomStroke.kind := gskNone;
    end
    else
    begin
      c.TopStroke.kind := gskNone;
      c.BottomStroke.kind := gskNone;
      c.Stroke.kind := gskNone;
    end;
  end;
  endupdate;
end;

procedure txIX.APplyDefaultStyle;
begin

  if ixtype = ixInfo then
  begin
    Interaction.ColumnSizing := false;
    Interaction.ReadOnly := true;
    noSelectStyle := true;
    NA.ExpandWidth := 0;
    NA.ExpandHeight := 0;
    na.SelectionArea := TTMSFNCTreeViewSelectionArea.tsaFull;
    na.HeightMode := TTMSFNCTreeViewNodeHeightMode.tnhmVariable;
    BRLineBreaks := true;

    BGstyle := bgSingleColor;
    BGColor := gcWhite;
    linecolor := gcwhite;
    SelLineColor := gcwhite;
    NoSelectStyle := true;

    ColTextRight := '';

    topFont.Style := [tFontStyle.fsbold] ;

    {$IFDEF fwfmx}
    topfont.family := 'Microsoft YaHei UI';
    {$ELSE}
    topfont.name := 'Microsoft YaHei UI';
    topfont.color := gcDarkBlue;
    {$ENDIF}

    topfont.size := 11;

    stroke.kind := gskSolid;

  end
  else if ixtype = ixEdit then
  begin
    Interaction.ReadOnly := false;
    BRLineBreaks := false;
    noSelectStyle := False;
    topFont.Style := [tfontstyle.fsBold] ;
    {$IFDEF fwfmx}
    topfont.family := 'Microsoft YaHei UI';
    {$ELSE}
    topfont.name := 'Microsoft YaHei UI';
    topfont.color := gcBlack;
    {$ENDIF}
    topfont.size := 11;
    NA.font.size := 11;

    stroke.kind := gskSolid;
    BGstyle := bgSingleColor;
    BGColor := gcWhite;
    SelNodeColor := gcSkyblue;
    TextColor := gcBlack;
    LineStyle := txixLineStyle.lsBottom;
    linecolor := gcDarkGray;
    SelLineColor := gcWhite;
    na.SelectionArea := TTMSFNCTreeViewSelectionArea.tsaFromLevel;
    na.ExpandWidth := 18;
    na.expandheight := 18;

    FixedNodeHeight := 65;
  end;
end;

procedure txIX.SetIXType(const Value: txixType);
begin
  FixType := Value;
  if value = ixInfo then
  begin
    // Interaction.ColumnSizing := false;
    Interaction.ReadOnly := true;
    {  noSelectStyle := true;
      NA.ExpandWidth := 0;
      NA.ExpandHeight := 0;
      na.SelectionArea := TTMSFNCTreeViewSelectionArea.tsaFull;

      BGstyle := bgSingleColor;
      BGColor := gcWhite;
      linecolor := gcwhite;
      SelLineColor := gcwhite;
      NoSelectStyle := true;

      ColTextRight := '';

      topFont.Style := [fsbold] ;
      topfont.name := 'Microsoft YaHei UI';
      topfont.size := 11;
      topfont.color := gcDarkBlue;
      stroke.kind := gskSolid;   }

  end
  else if value = ixEdit then
  begin
    Interaction.ReadOnly := false;
    //  noSelectStyle := true;
  end;
end;

procedure txIX.SetLineColor(const Value: ttmsfncGraphicsColor);
begin
  FLineColor := Value;
  NA.stroke.color := value;
end;

procedure txIX.SetLineStyle(const Value: txixLineStyle);
begin
  FLineStyle := Value;
  if value = lsLeft then
  begin
    {CL.Stroke.Kind:=gskSolid;
    CL.Stroke.color:=LineColor;
     CL.Stroke.width:=1;
     na.ColumnStroke.kind:=gskSolid;
     na.ColumnStroke.color:=LineCOlor;
     na.ColumnStroke.width:=1;
      ColumnStroke.kind:=gskSolid;
     ColumnStroke.color:=LineCOlor;
     ColumnStroke.width:=1;}

  end
  else
  begin
    // NA.ColumnStroke.Kind:=gskNone;
  end;
end;

procedure txIX.SetNoLHS(const Value: boolean);
begin
  FnoLHS := Value;
  CL.Visible := not value;
end;

procedure txIX.SetNoSelectStyle(const Value: boolean);
begin
  FNoSelectStyle := Value;
end;

procedure txIX.SetReqColWidth(const Value: double);
begin
 if fREqColWidth<>value then
  if assigned(onReqColWidthChange) then onREqColWidthChange(self, value);

  FReqColWidth := Value;
end;

procedure txIX.SetShowColumns(const Value: boolean);
var
  acolumn           : ttmsfncTreeViewColumn;
  i                 : integer;
begin
  FshowColumns := Value;
  if value = true then
  begin
    ca.topsize := topheight;
    for i := 1 to columns.count do
    begin
      acolumn := columns[i - 1] ;
      alog.send(acolumn.text, acolumn.data);
      if acolumn.data.AllowShow then

        acolumn.Visible := true
      else
        acolumn.Visible := false;
    end;

    interaction.ColumnSizing := true;
  end
  else
  begin
    CA.TopSize := 0;

    interaction.ColumnSizing := false;
  end;
  APplyColumnStyles;
  DoColAutoWidth(0);
end;

procedure txIX.SetTopHeight(const Value: integer);
begin
  FTopHeight := Value;
  if showColumns then

    CA.TopSize := value;
end;

procedure txIX.StopInternalEditing;
begin
  inherited;
  if assigned(onEditing) then onEditing(tvalue.from<string>(editvalue));

end;

procedure txIX.TimedUpdateValues;
begin
  UpdateTimer.Enabled := true;
end;

procedure txIX.UpdateColumnsCache;
begin
  inherited;

end;

procedure txIX.UpdateDisplay;
begin
  inherited;
  //

end;

procedure txIX.UpdateFields;
begin
  inherited;

end;

procedure txIX.UpdateValues;

  procedure doNodes(anodes: ttmsfncTreeViewNodes);
  var
    i               : integer;
    anode           : ttmsfncTreeViewNode;
    P               : TTMSFNCPropertyInfo;
    s               : string;
    k               : tTypeKind;
    aObject         : tobject;
  begin
    for i := 1 to anodes.count do
    begin
      try
        anode := anodes[i - 1] ;
        // if assigned(anode.datapointer) then
       //  begin

        s := anode.text[1] ;
        if assigned(anode.DataObject) then
          aObject := aNode.DataObject
        else
          aObject := nil;
        // else
          // aObject := &object;
        if assigned(aNode.DataObject) then
        begin
          alog.send('Updating ' + anode.text[0] );
          p := TTMSFNCPropertyInfo(ANode.DataPointer);
          k := GetPropInfoType(p);
          if k <> tkClass then ReadProperty(aNode.DataObject, p, nil, anode);
        end;
        if s <> anode.text[1] then anode.data.changed := true;
        //  end;

      except
        on e: exception do
        begin
          alog.error('UpdateValues ' + anode.text[0] , e.message);
        end;

      end;
      doNodes(anode.nodes);
    end;
  end;
var
  anode             : ttmsfncTreeViewNode;
  P                 : TTMSFNCPropertyInfo;
  s                 : string;
  i                 : integer;
begin
  //beginUpdate;
  {for i := 1 to nodes.count do
  begin
    anode := nodes[i - 1] ;

    p := TTMSFNCPropertyInfo(ANode.DataPointer);
    s := anode.text[1] ;
    ReadProperty(&object, p, nil, anode);
    if s <> anode.text[1] then anode.data.changed := true;

    // ReadProperty(AObject: TObject; PropInfo: TTMSFNCPropertyInfo; ANode: TTMSFNCTreeViewNode = nil; AReplaceNode: TTMSFNCTreeViewNode = nil);
  end;     }
  alog.send('Update Values');
  doNodes(nodes);
  beginupdate;
  endupdate;
end;

procedure txIX.WaitDone(sender: tobject);
begin
  doAutoSize;
end;

procedure txIX.WritePropInfoValue(AInstance: TObject;
  APropInfo: TTMSFNCPropertyInfo; AValue: string; AValueObject: TObject;
  ANode: TTMSFNCTreeViewNode);
begin
  inherited;

end;

end.

