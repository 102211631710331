unit x.Controls;

interface
//{$INCLUDE project.inc }

uses
  classes, rtti, system.Generics.Collections, uiTypes, x.cfg.classes, sysutils,


  {$IFDEF fwfmx}
  FMX.Types, FMX.Controls, FMX.Forms, FMX.Graphics, FMX.Dialogs, FMX.objects, FMX.TMSMemo, FMX.StdCtrls, FMX.Layouts, FMX.skia, FMX.TMSFNCGraphicstypes,
  FMX.TMSFNCSplitter, system.skia, system.UIConsts,
  FMX.TMSFNCTreeViewBase,
  FMX.TMSFNCTreeViewData, FMX.TMSFNCCustomTreeView, FMX.TMSFNCTreeView, FMX.TMSFNCImage,
  FMX.TMSFNCObjectInspector, x.icons.all,   liblogtest, x.controls.FMXRectangle;
{$ENDIF}
{$IFDEF fwVCL}
vcl.Controls, vcl.Forms, vcl.Graphics, vcl.Dialogs, vcl.StdCtrls, vcl.ExtCtrls, windows, messages, scGPControls, scgpExtControls, vcl.skia, scControls,
vcl.tmsfncgraphicstypes, vcl.TMSFNCSplitter, vcl.tmsfncimage, system.skia, system.UIConsts,

VCL.TMSFNCTreeViewBase,
VCL.TMSFNCTreeViewData, VCL.TMSFNCCustomTreeView, VCL.TMSFNCTreeView,
VCL.TMSFNCObjectInspector, x.icons.all,       liblogtest;
{$ENDIF}
{$IFDEF fwweb}

weblib.forms,weblib.Controls, weblib.Graphics, weblib.Dialogs, weblib.StdCtrls, weblib.ExtCtrls,
 vcl.Controls, vcl.Forms, vcl.Graphics, vcl.Dialogs, vcl.StdCtrls, vcl.ExtCtrls,

vcl.tmsfncgraphicstypes, vcl.TMSFNCSplitter, vcl.tmsfncimage,

VCL.TMSFNCTreeViewBase,
VCL.TMSFNCTreeViewData, VCL.TMSFNCCustomTreeView, VCL.TMSFNCTreeView, VCL.TMSFNCCloudImage,
VCL.TMSFNCObjectInspector, x.icons.all, x.css,x.css.color, liblogtest, js, web;
{$ENDIF}


type
  txEditorMode=(xeEdit, xeNew);

  txSplitterclass = ttmsfncSplitter;

  txTextAlign = (xtLeading, xtCenter, xtTrailing, xtJustify);
  {$IFDEF fwfmx}
  txWinControlClass = tControl;
  txRectangleClass = txRectangleBase;
  txLabelClass = tskLabel;
  txFormClass = tCustomform;
  tximageclass = tskSVG;
  txTimerClass = tTimer;
  txScrollClass = tscrollbox;
  txColorClass = tAlphaColor;
  {$ENDIF}
  {$IFDEF fwvcl}
  txRectangleClass = tscgppanel;
  txLabelClass = tskLabel;
  txWinControlClass = tWinControl;
  txFormClass = tForm;
  tximageclass = tskSVG;
  txTimerClass = tTimer;
  txScrollClass = TscScrollBox;
  txColorClass = tAlphaColor;
  {$ENDIF}
  {$IFDEF fwweb}
  txRectangleClass = Tpanel;
  txLabelClass = TLabel; //tWebLabel;
  txWinControlClass = tWinControl;//twinCOntrol; //tcontrol
  txTimerClass = TTimer; //tWebTimer;
 txFormClass = tForm;
  txScrollClass = TScrollBox;//twebscrollbox;
  tximageclass = ttmsfnccloudimage;
  txColorClass = tColor;
  {$ENDIF}

  {$IFNDEF fwweb}


  txControlID = class
    Guid: int64;
    xName: string;
    Control: tcontrol;
    function GuidS: string;
  end;


  txControlLookupDic = tDictionary<tControl, txControlID>;


  txControlIDs = tDictionary<string, txControlID>;


  txControlLookup = class
    //Dic: txControlLookupDic;
    IDs: tXControlIDs;
    procedure clear;
    function GetGuid: int64;
    function GetID(var ac: tcontrol; doCreate: boolean = true): txControlID;
    constructor create;
    procedure Setname(var ac: tcontrol; aname: string);
    function Getname(var ac: tcontrol): string;
    function Find(aname: string; var ap: tcontrol): tControl;
  private
    Fcount: integer;
    FDeadCount: integer;
    function GetCount: integer;
    function GetDeadCOunt: integer;
  published
    property count: integer read GetCount;
    property DeadCount: integer read GetDeadCOunt;
  end;


  txControlHelper = class helper for tControl
  private

    function getxName: string;
    procedure SetxName(const Value: string);
    function getxGuid: txControlID;
    function getxid: txControlID;

  public
    function xFind(aname: string): tcontrol;
    procedure xClear;
    procedure Pad(ALlSides: integer);
    procedure Mar(allSides: integer);
    property xName: string read getxName write SetxName;
    property xid: txControlID read getxid;

  end;
  {$ENDIF}


  tControlCallback = procedure(aControl: tcontrol) of object;


  TxSide = (Top, Left, Bottom, Right);
  txCorner = (TopLeft, TopRight, BottomLeft, BottomRight);
  txCorners = set of txCorner;
  TxSides = set of TxSide;

  txlabel = class;

  txBorder = class
  private
    FRadius: integer;
    FonChanged: tNotifyEvent;
    FSides: TxSides;
    FCorners: txCorners;
    procedure SetRadius(const Value: integer);
    procedure SetSides(const Value: TxSides);
    procedure SetCorners(const Value: txCorners);
  public
    constructor create;
    property onChanged: tNotifyEvent read FonChanged write FonChanged;
  published
    property Radius: integer read FRadius write SetRadius;
    property Sides: TxSides read FSides write SetSides;
    property Corners: txCorners read FCorners write SetCorners;
  end;

  {$IFDEF fwfmx}
  txBrushKind = tBrushKind;
  {$ELSE}

  txBrushKind = (None, Solid, Gradient, Bitmap, Resource);

  {$ENDIF}

  txBrush = class;

  txBrushState = class
  private
    FKind: txBrushKind;
    FonChanged: tNotifyEvent;
    fColor: tcfgColorClass;
    FhtmlColor: string;
    Fenabled: boolean;
    function GetColor: tcfgColorClass;
    procedure SetColor(const Value: tcfgColorClass);
    procedure SetKind(const Value: txBrushKind);
    procedure SetHTMLColor(const Value: string);
  public
    owner: txBrush;
    procedure doChange;
    constructor create;
    property onChanged: tNotifyEvent read FonChanged write FonChanged;
  published
    property htmlColor: string read fHtmlColor write SetHTMLColor;
    property Color: tcfgColorClass read GetColor write SetColor;
    property Kind: txBrushKind read fKind write SetKind;
    property enabled: boolean read Fenabled write Fenabled;

  end;

  txBrush = class
  private
    FKind: txBrushKind;
    FonChanged: tNotifyEvent;
    fColor: tcfgColorClass;
    FNormal: txBrushState;
    FHot: txBrushState;
    FDown: txBrushState;
    FActive: txBrushState;
    FhtmlColor: string;
    function GetColor: tcfgColorClass;
    procedure SetColor(const Value: tcfgColorClass);
    procedure SetKind(const Value: txBrushKind);
    procedure SetActive(const Value: txBrushState);
    procedure SetHTMLColor(const Value: string);
    function GetHTMLColor: string;
    function GetKind: txBrushKind;
  public
    procedure StateChanged(sender: tobject);
    constructor create;
    property onChanged: tNotifyEvent read FonChanged write FonChanged;
  published
    property Color: tcfgColorClass read GetColor write SetColor;
    property Kind: txBrushKind read GetKind write SetKind;
    property htmlColor: string read GetHTMLColor write SetHTMLColor;
    property Normal: txBrushState read FNormal write FNormal;
    property Hot: txBrushState read FHot write FHot;
    property Down: txBrushState read FDown write FDown;
    property Active: txBrushState read FActive write SetActive;

  end;

  txStroke = class;
  txStrokeState = class
  private
    FThickness: Single;
    FKind: txBrushKind;
    FonChanged: tNotifyEvent;
    fColor: tcfgColorClass;
    FEnabled: boolean;
    FhtmlColor: string;
    function GetColor: tcfgColorClass;
    procedure SetColor(const Value: tcfgColorClass);
    procedure SetKind(const Value: txBrushKind);
    procedure SetThickness(const Value: Single);
    function GetHTMLColor: string;
    procedure SetHTMLColor(const Value: string);
  public
    owner: txStroke;
    constructor create;
    procedure DoChanged;
    property onChanged: tNotifyEvent read FonChanged write FonChanged;
    property Enabled: boolean read FEnabled write FEnabled;
  published
    property Thickness: Single read FThickness write SetThickness;
    property Kind: txBrushKind read FKind write SetKind;
    property Color: tcfgColorClass read GetColor write SetColor;
    property htmlColor: string read GetHTMLColor write SetHTMLColor;
  end;

  txStroke = class
  private
    FThickness: Single;
    FKind: txBrushKind;
    FonChanged: tNotifyEvent;
    fColor: tcfgColorClass;
    FActive: txStrokeState;
    FNormal: txStrokeState;
    FHot: txStrokeState;
    FDown: txStrokeState;
    FhtmlColor: string;

    function GetColor: tcfgColorClass;
    procedure SetColor(const Value: tcfgColorClass);
    procedure SetKind(const Value: txBrushKind);
    procedure SetThickness(const Value: Single);
    function GetKind: txBrushKind;
    function GetThickness: Single;
    function GetHTMLColor: string;
    procedure SetHTMLColor(const Value: string);
    procedure SetActive(const Value: txStrokeState);
  public
    constructor create;
    procedure StateChanged(sender: tobject);
    property onChanged: tNotifyEvent read FonChanged write FonChanged;
  published
    property Thickness: Single read GetThickness write SetThickness;
    property Kind: txBrushKind read GetKind write SetKind;
    property Color: tcfgColorClass read GetColor write SetColor;
    property htmlColor: string read GetHTMLColor write SetHTMLColor;
    property Active: txStrokeState read FActive write SetActive;
    property Normal: txStrokeState read FNormal write FNormal;
    property Hot: txStrokeState read FHot write FHot;
    property Down: txStrokeState read FDown write FDown;
  end;

  txPosition = class
  private
    Fx: Single;
    Fy: Single;
    FonChanged: tNotifyEvent;
    FControl: tControl;
    function GetX: Single;
    function GetY: Single;
    procedure SetX(const Value: Single);
    procedure SetY(const Value: Single);
  public
    property onChanged: tNotifyEvent read FonChanged write FonChanged;
    constructor create(ac: tControl);
  published
    property x: Single read GetX write SetX;
    property y: Single read GetY write SetY;
    property Control: tControl read FControl write FControl;
  end;

  txFont = class(tFont)
  private
    FonChanged: tNotifyEvent;
    fCol: txColorClass;
    FVCLcolor: tColor;
    procedure SetOnChanged(const Value: tNotifyEvent);
    procedure SetColor(const Value: txColorClass);
    procedure setVCLColor(const Value: tColor);
  public
    constructor create;
  published
    property color: txColorClass read fCol write SetColor;
    property VCLcolor: tColor read FVCLcolor write setVCLColor;
    {$IFDEF fwvcl}
    property OnChanged: tNotifyEvent read FonChanged write SetOnChanged;
    {$ENDIF}

  end;

  txAlign = (xNone, xLeft, xRight, xTop, xBottom, xClient);

  txRectangle = class(txRectangleClass)
  private
    FFill: txBrush;
    FStroke: txStroke;
    fPosition: txPosition;
    fxRadius, fyRadius: Single;
    fHitTest: boolean;
    fCanFocus: boolean;
    FxBorder: txBorder;
    FHotFill: txBrush;
    FHotStroke: txStroke;
    FHot: boolean;
    FDownFIll: txBrush;
    FDownStroke: txBrush;
    FDown: boolean;
    FPaneltext: string;
    FmButton: tMouseButton;
    {$IFDEF fwFMX}
    fmx, fmy: single;
    {$ELSE}
    fmx, fmy: integer;
    {$ENDIF}
    Fsx: integer;
    Ffy: integer;
    FEnableHot: boolean;
    procedure SetHot(const Value: boolean);
    procedure SetDown(const Value: boolean);
    procedure SetPanelText(const Value: string);
  public
    LastFill: txBrush;
    SavedFillColor: tcfgColorClass;
    DataObject: tobject;
    DataString: string;
    PanelTextlbl: txLabel;
    procedure createLabel;
    procedure FillChanged(sender: tobject); virtual;
    procedure StrokeChanged(sender: tobject); virtual;
    procedure BorderChanged(sender: tobject); virtual;
    procedure AfterCreate; virtual;
    constructor create(aOwner: tComponent); override;
    procedure MouseDown(Button: TMouseButton; Shift: TShiftState; {$IFDEF fwfmx}x, Y: Single{$ELSE}x, y: Integer{$ENDIF}); override;
    procedure MouseMove(Shift: TShiftState; {$IFDEF fwfmx}AFormX, AFormY: Single{$ELSE}AFormX, AFormY: Integer{$ENDIF}); override;
    procedure MouseUp(Button: TMouseButton; Shift: TShiftState; {$IFDEF fwfmx}AFormX, AFormY: Single{$ELSE}AFormX, AFormY: Integer{$ENDIF}); override;
    procedure resize; override;
    procedure SP(aControl: tComponent);

    {$IFDEF fwweb}
    procedure VisibleChanged; override;
    procedure UpdateElement; override;
    {$ENDIF}
    {$IFDEF fwvcl}
    procedure MouseEnter(var Message: TMessage); message CM_MOUSEENTER;
    procedure MouseLeave(var Message: TMessage); message CM_MOUSELEAVE;

    {$ELSE}
    procedure DoMouseEnter; override;
    procedure DoMouseLeave; override;
    {$ENDIF}
    procedure IntMouseEnter; virtual;
    procedure IntMouseLeave; virtual;
    procedure IntMouseMove; virtual;
    procedure IntMouseDown; virtual;
    procedure IntMouseUp; virtual;
    procedure IntResize; virtual;
  published
    property mButton: tMouseButton read FmButton write FmButton;
    {$IFDEF fwFMX}

    property mx: Single read Fmx write Fmx;
    property my: Single read Fmy write Fmy;
    {$ELSE}
    property mx: integer read Fmx write Fmx;
    property my: integer read Fmy write Fmy;
    {$ENDIF}
    property sx: integer read Fsx write Fsx;
    property fy: integer read Ffy write Ffy;
    property xBorder: txBorder read FxBorder write FxBorder;
    property HotFill: txBrush read FHotFill write FHotFill;
    property HotStroke: txStroke read FHotStroke write FHotStroke;
    property DownFIll: txBrush read FDownFIll write FDownFIll;
    property DownStroke: txBrush read FDownStroke write FDownStroke;
    property Hot: boolean read FHot write SetHot;
    property Down: boolean read FDown write SetDown;
    property EnableHot: boolean read FEnableHot write FEnableHot;

    property Fill: txBrush read FFill write FFill;
    property Stroke: txStroke read FStroke write FStroke;
    {$IFNDEF fwfmx}
    property Position: txPosition read fPosition write fPosition;
    property xRadius: Single read fxRadius write fxRadius;
    property yRadius: Single read fyRadius write fyRadius;
    property HitTest: boolean read fHitTest write fHitTest;
  //  property CanFocus: boolean read fCanFocus write fCanFocus;
    property Paneltext: string read FPaneltext write SetPanelText; //for errors etc will align client
    {$ENDIF}
  end;


  txLabel = class(txLabelClass)
  private
    Ftext: string;
    Fbold: boolean;
    FItalic: boolean;
    Funderline: boolean;
    FFontColor: tcfgColorClass;
    Ffont: txFont;
    {$IFNDEF fwweb}
    procedure FontChanged(sender: tobject);
    {$ENDIF}
    function GetBold: boolean;
    procedure SetBold(const Value: boolean);
    function getxText: string;
    procedure SetxText(const Value: string);
    function gethAlign: txTextAlign;
    function GetvAlign: txTextAlign;
    procedure setHAlign(const Value: txTextAlign);
    procedure setvAlign(const Value: txTextAlign);
    procedure SetItalic(const Value: boolean);
    procedure SetUnderline(const Value: boolean);
    procedure SetFontColor(const Value: tcfgColorClass);
    {$IFNDEF fwFMX}
    function getHitTest: boolean;
    procedure SetHitTest(const Value: boolean);

    // function GetText: string;
    // procedure SetText(const Value: string);
    {$ENDIF}
  public

    constructor create(aOwner: tComponent); override;
    procedure AfterCreate;
  published
    {$IFNDEF fwFMX}
    property HitTest: boolean read getHitTest write SetHitTest;
    {$ENDIF}
    {$IFNDEF fwweb}
    property font: txFont read Ffont write Ffont;
    {$ENDIF}
    property bold: boolean read GetBold write SetBold;
    property Italic: boolean read FItalic write SetItalic;
    property underline: boolean read Funderline write SetUnderline;
    property FontColor: tcfgColorClass read FFontColor write SetFontColor;
    property xText: string read getxText write SetxText;
    property vAlign: txTextAlign read GetvAlign write setvAlign;
    property hAlign: txTextAlign read gethAlign write setHAlign;
  end;

  txButton = class(txRectangle)
  private
    Ftext: string;
    FAutoSize: boolean;
    FRounded: boolean;
    FFlat: boolean;
    FBorderSize: integer;
    FEnableHot: boolean;
    FEnableDown: boolean;
    procedure lblClick(sender: tobject);
    procedure lblResize(sender: tobject);
    procedure SetText(const Value: string);
    procedure SetAutoSize(const Value: boolean);
    procedure SetRounded(const Value: boolean);
    procedure SetFlat(const Value: boolean);
    procedure SetBorderSize(const Value: integer);
  public
    fResizing: boolean;
    lbl: txLabel;
    procedure DoAutoSize;
    procedure IntMouseEnter; override;
    procedure IntMouseLeave; override;
    procedure IntMouseDown; override;
    procedure IntMouseUp; override;
    constructor create(aOwner: tcomponent); override;
    destructor destroy; override;
    procedure resize; override;
    property text: string read Ftext write SetText;
    property AutoSize: boolean read FAutoSize write SetAutoSize;
    property Rounded: boolean read FRounded write SetRounded;
    property Flat: boolean read FFlat write SetFlat;
    property BorderSize: integer read FBorderSize write SetBorderSize;
    //  property EnableHot: boolean read FEnableHot write FEnableHot;
    property EnableDown: boolean read FEnableDown write FEnableDown;
  published
  end;

  txxPlace=(toLeft, toRight, toTop, toBottom);
  txxForm = class(txFormClass)
  private
    FShowTimerInterval: integer;
  public
    fShowTimer: txTimerClass;
    {$IFNDEF fwweb}
    constructor CreateNew(AOwner: TComponent; {$IFDEF fwVCL}Dummy: Integer = 0{$ENDIF}{$IFDEF fwFMX}Dummy: NativeInt{$ENDIF}); override;
    {$ENDIF}
    procedure doShow; override;
    procedure InitTimer; virtual;
    procedure fShowTimerTimer(sender: tobject);
    procedure DoShowTimer; virtual;
    procedure ShowBy;    overload;
    procedure ShowBy(aForm: tForm; aPlace: txxPlace; DoMove: boolean); overload;
    procedure ShowCentre(sizePC: integer=0);
  published
    property ShowTimerInterval: integer read FShowTimerInterval write FShowTimerInterval;
  end;

  txScrollBox = class(txScrollClass)
  public
    constructor create(aOwner: tComponent);
  end;

  txSplitter = class(txSplitterClass)
  private
  public
  published
  end;

  txImage = class(txImageClass)
  private
    Ficon: txIcon;
    procedure setIcon(const Value: txIcon);

  public
   constructor create(aOwner: tcomponent); override;
  published
    property icon: txIcon read Ficon write setIcon;
  end;

   {$IFNDEF fwfmx}
   tBounds = class
  public
    Left, Right, Top, Bottom: integer;
  end;
  {$ENDIF}



  tHackControl = class(tControl)
  published
    property onMouseEnter;
    property OnMouseLeave;
    property onMouseDown;
    //  property onEnter;
  end;

  {$IFDEF fwVCL}
  tHackWinControl = class(tWinControl)
    property onenter;
  end;
  {$ENDIF}

  {$IFDEF fwfmx}

function AlignRight: tAlignLayout;
function AlignLeft: tAlignLayout;
function AlignNone: tAlignLayout;
function AlignTop: tAlignLayout;
function AlignBottom: tAlignLayout;
function AlignClient: tAlignLayout;
procedure AllControls(aParent: tfmxObject; aProc: tControlCallback; Recurse: boolean = true);

{$ELSE}
function AlignRight: tAlign;
function AlignLeft: tAlign;
function AlignNone: tAlign;
function AlignTop: tAlign;
function AlignBottom: tAlign;
function AlignClient: tAlign;
procedure AllControls(aParent: tComponent; aProc: tControlCallback; Recurse: boolean = true);
{$ENDIF}


{$IFNDEF fwfmx}

function xBounds(aMargins: tMargins): tBounds; overload;
function xBounds(aPadding: tPadding): tBounds; overload;
  {$ELSE}
  function xBounds(aBounds: tBounds): tBounds;
{$ENDIF}

function posX(ac: tControl): integer;
function posY(ac: tControl): integer;
procedure SetPosX(ac: tControl; Value: Single);
procedure SetPosY(ac: tControl; Value: Single);

{$IFNDEF fwweb}
var
  xLookup: txControlLookup;
{$ENDIF}

implementation

{$IFNDEF fwweb}uses x.styler;{$ENDIF}

{$IFNDEF fwfmx}

function xBounds(aMargins: tMargins): tBounds; overload;
begin
  result := tBounds.create;
  result.Left := Trunc(aMargins.Left);
  result.Right := Trunc(aMargins.Right);
  result.Top := Trunc(aMargins.Top);
  result.Bottom := Trunc(aMargins.Bottom);

end;

function xBounds(aPadding: tPadding): tBounds; overload;
begin
  result := tBounds.create;
  result.Left := Trunc(aPadding.Left);
  result.Right := Trunc(aPadding.Right);
  result.Top := Trunc(aPadding.Top);
  result.Bottom := Trunc(aPadding.Bottom);

end;
{$ELSE}

function xBounds(aBounds: tBounds): tBounds;
begin
  result := aBounds;
end;
{$ENDIF}

procedure SetPosX(ac: tControl; Value: Single);
begin
  {$IFDEF fwfmx}
  ac.Position.x := Value;
  {$ELSE}
  ac.Left := Trunc(Value);

  {$ENDIF}
end;

procedure SetPosY(ac: tControl; Value: Single);
begin
  {$IFDEF fwfmx}
  ac.Position.y := Value;
  {$ELSE}
  ac.Top := Trunc(Value);
  {$ENDIF}
end;

function posX(ac: tControl): integer;
begin
  {$IFDEF fwfmx}
  result := Trunc(ac.Position.x);
  {$ELSE}
  result := ac.Left;
  {$ENDIF}
end;

function posY(ac: tControl): integer;
begin
  {$IFDEF fwfmx}
  result := Trunc(ac.Position.y);
  {$ELSE}
  result := ac.Top;
  {$ENDIF}
end;

{$IFDEF fwfmx}

procedure AllControls(aParent: tfmxObject; aProc: tControlCallback; Recurse: boolean = true);
  procedure doControls(aObject: tfmxObject; doRecurse: boolean);
  var
    i: integer;
  begin
    if aobject is tControl then aproc(tcontrol(aobject));

    for I := 1 to aobject.ChildrenCount do
    begin
      if aobject.Children.Items[i - 1] is tControl then aproc(tcontrol(aobject.children.items[i - 1] ));
      if doRecurse then doCOntrols(aobject.children.items[i - 1] , doRecurse);

    end;
  end;
begin
  doControls(aParent, Recurse);
end;
{$ELSE}

procedure AllControls(aParent: tComponent; aProc: tControlCallback; Recurse: boolean = true);
  procedure doControls(aObject: tComponent; doRecurse: boolean);
  var
    i: integer;
    ac: tControl;
    awc: twinControl;
    af: tForm;
  begin
    if aobject is tWinControl then
    begin
      awc := twinControl(aobject);
      for i := 1 to awc.ControlCount do
      begin
        aProc(awc);
        if doRecurse then doControls(awc.controls[i - 1] , doRecurse);

      end;
    end
    else if aobject is tControl then
    begin
      ac := tControl(aobject);
      aproc(ac);
      if doRecurse then doControls(ac, doRecurse);
    end
    else if aobject is tForm then
    begin
      af := tform(aobject);
      for i := 1 to af.ControlCount do
      begin
        aProc(awc);
        if doRecurse then doControls(af.controls[i - 1] , doRecurse);
      end;

    end;

  end;
begin
  doControls(aParent, Recurse);
end;
{$ENDIF}

{$IFDEF fwfmx}

function AlignRight: tAlignLayout;
begin
  result := tAlignLayout.right;

end;

function AlignLeft: tAlignLayout;
begin
  result := tAlignLayout.left;
end;

function AlignNone: tAlignLayout;
begin
  result := tAlignLayout.None;
end;

function AlignBottom: tAlignLayout;
begin
  result := tAlignLayout.Bottom;
end;

function AlignTop: tAlignLayout;
begin
  result := tAlignLayout.Top;
end;

function AlignClient: tAlignLayout;
begin
  result := tAlignLayout.client;
end;

{$ELSE}

function AlignRight: tAlign;
begin
  result := alRight;
end;

function AlignLeft: tAlign;
begin
  result := alLeft;
end;

function AlignNone: tAlign;
begin
  result := tAlign.alnone;
end;

function AlignBottom: tAlign;
begin
  result := tAlign.albottom;
end;

function AlignTop: tAlign;
begin
  result := tAlign.altop;
end;

function AlignClient: tAlign;
begin
  result := tAlign.alClient;
end;

{$ENDIF}
{$IFDEF fwweb}
{$ENDIF}
{ txBrush }

constructor txBrush.create;
begin
  Normal := txBrushState.create;
  Down := txBrushState.create;
  Hot := txBrushState.create;

  Normal.owner := self;
  DOwn.owner := self;
  Hot.owner := self;
  fActive := normal;
end;

function txBrushState.GetColor: tcfgColorClass;
begin
  result := fColor;
end;

procedure txBrushState.SetColor(const Value: tcfgColorClass);
begin
  fColor := Value;
  {$IFDEF fwweb} fhtmlColor := ColorToHTML(value); {$ENDIF}

  DoChange;

end;

procedure txBrushState.SetHTMLColor(const Value: string);
begin
  fhtmlColor := value;
   {$IFDEF fwweb}  fcolor := HTMLToColor(value);  {$ENDIF}
  dochange;
end;

procedure txBrushState.SetKind(const Value: txBrushKind);
begin
  FKind := Value;
  DoChange;

end;

function txBrush.GetColor: tcfgColorClass;
begin
  result := normal.color;
end;

function txBrush.GetHTMLColor: string;
begin
  result := normal.htmlcolor;
end;

function txBrush.GetKind: txBrushKind;
begin
  result := normal.kind;
end;

procedure txBrush.SetActive(const Value: txBrushState);
begin
  if value.enabled = false then exit;

  FActive := Value;
  if assigned(onChanged) then onChanged(factive);

end;

procedure txBrush.SetColor(const Value: tcfgColorClass);
begin
  normal.color := value;
end;

procedure txBrush.SetHTMLColor(const Value: string);
begin

  Normal.htmlColor := value;
end;

procedure txBrush.SetKind(const Value: txBrushKind);
begin
  Normal.kind := value;
end;

procedure txBrush.StateChanged(sender: tobject);
begin
  if sender = factive then

    if assigned(onChanged) then onChanged(sender);

end;

{ txStrokeState }

constructor txStrokeState.create;
begin

end;

procedure txStrokeState.DoChanged;
begin
  Enabled := true;
  Owner.StateChanged(self);
  if assigned(FonChanged) then
    FonChanged(self);

end;

function txStrokeState.GetColor: tcfgColorClass;
begin
  result := fColor;
end;

function txStrokeState.GetHTMLColor: string;
begin
  result := fHtmlColor;
end;

procedure txStrokeState.SetColor(const Value: tcfgColorClass);
begin
  fColor := Value;
  {$IFDEF fwweb}  fhtmlColor := ColorToHTML(value);{$ENDIF}
  DoChanged;

end;

procedure txStrokeState.SetHTMLColor(const Value: string);
begin
  fHtmlColor := value;
 {$IFDEF fwweb}  fcolor := HTMLToCOlor(value); ;{$ENDIF}
  DoChanged;
end;

procedure txStrokeState.SetKind(const Value: txBrushKind);
begin
  FKind := Value;

  doChanged;
end;

procedure txStrokeState.SetThickness(const Value: Single);
begin
  FThickness := Value;
  doChanged;
end;

{ txStroke }

constructor txStroke.create;
begin
  fThickness := 0;
  Normal := txStrokeState.create;
  Down := txStrokeState.create;
  hot := txStrokeState.create;

  Normal.owner := self;
  Down.owner := self;
  Hot.owner := self;

  fActive := normal;
end;

function txStroke.GetColor: tcfgColorClass;
begin
  result := Normal.color;
  // if assigned(FonChanged) then fOnChanged(self);
end;

function txStroke.GetHTMLColor: string;
begin
  result := normal.htmlColor;
end;

function txStroke.GetKind: txBrushKind;
begin
  result := normal.kind;
end;

function txStroke.GetThickness: Single;
begin
  result := normal.Thickness;
end;

procedure txStroke.SetActive(const Value: txStrokeState);
begin
  if value.enabled = false then exit;
  FActive := Value;
  if assigned(onChanged) then onChanged(FActive);

end;

procedure txStroke.SetColor(const Value: tcfgColorClass);
begin
  Normal.color := value;
end;

procedure txStroke.SetHTMLColor(const Value: string);
begin

end;

procedure txStroke.SetKind(const Value: txBrushKind);
begin
  Normal.kind := value;
end;

procedure txStroke.SetThickness(const Value: Single);
begin
  Normal.Thickness := value;
end;

procedure txStroke.StateChanged(sender: tobject);
begin
  if sender = factive then

    if assigned(onChanged) then onChanged(sender);

end;

{ txPosition }

constructor txPosition.create(ac: tControl);
begin
  FControl := ac;
end;

function txPosition.GetX: Single;
begin

  {$IFDEF fwfmx}
  {$ENDIF}
  {$IFDEF fwvcl}
  result := Control.Left;
  {$ENDIF}
  {$IFDEF fwweb}
  {$ENDIF}
end;

function txPosition.GetY: Single;
begin
  {$IFDEF fwfmx}

  {$ENDIF}
  {$IFDEF fwvcl}
  result := Control.Top;
  {$ENDIF}
  {$IFDEF fwweb}
  {$ENDIF}
end;

procedure txPosition.SetX(const Value: Single);
begin
  {$IFDEF fwfmx}
  {$ENDIF}
  {$IFDEF fwvcl}
  Control.Left := Trunc(Value);
  {$ENDIF}
  {$IFDEF fwweb}
  {$ENDIF}
end;

procedure txPosition.SetY(const Value: Single);
begin
  {$IFDEF fwfmx}
  {$ENDIF}
  {$IFDEF fwvcl}
  Control.Top := Trunc(Value);
  {$ENDIF}
  {$IFDEF fwweb}
  {$ENDIF}
end;

{ txRectangle }

constructor txRectangle.create(aOwner: tComponent);
begin
  inherited;

  EnableHot := true;
  FFill := txBrush.create;
  FStroke := txStroke.create;
  FStroke.onChanged := StrokeChanged;
  FFill.onChanged := FillChanged;
  FxBorder := txBorder.create;
  {$IFDEF fwweb}
   FxBorder.onChanged := StrokeChanged;
{$ELSE}
  FxBorder.onChanged := BorderChanged;
  {$ENDIF}
  HotFill := txBrush.create;
  HotStroke := txStroke.Create;

  DownFill := txBrush.create;
  DownStroke := txBrush.Create;

  Margins.Left := 0;
  Margins.Top := 0;
  Margins.Bottom := 0;
  Margins.Right := 0;
  AfterCreate;

end;

procedure txRectangle.createLabel;
begin
  PanelTextlbl := txLabel.create(self);
  PanelTextlbl.parent := self;
  PanelTextlbl.align := AlignClient;
  PanelTextlbl.FontColor := gcBlack;
  PanelTextlbl.bold := true;

  PanelTextlbl.hAlign := txTextALign.xtCenter;
  PanelTextlbl.vAlign := txTextAlign.xtCenter;

end;

procedure txRectangle.IntMouseDown;
begin

end;

procedure txRectangle.IntMouseEnter;
begin
  if EnableHot then hot := true;

end;

procedure txRectangle.IntMouseLeave;
begin
  if EnableHot then hot := false;

end;

procedure txRectangle.IntMouseMove;
begin

end;

procedure txRectangle.IntMouseUp;
begin

end;

procedure txRectangle.IntResize;
begin

end;

procedure txRectangle.MouseDown(Button: TMouseButton; Shift: TShiftState;
  {$IFDEF fwfmx}x, Y: Single{$ELSE}x, y: Integer{$ENDIF});
begin
  // alog.send('MouseDown');
  inherited;
  mButton := button;
  mx := x;
  my := y;
  intMouseDown;
end;

procedure txRectangle.MouseMove(Shift: TShiftState; {$IFDEF fwfmx}AFormX, AFormY: Single{$ELSE}AFormX, AFormY: Integer{$ENDIF});
begin
  inherited;
  mx := aFormX;
  my := aFormY;
  IntMouseMove;
end;

procedure txRectangle.MouseUp(Button: TMouseButton; Shift: TShiftState;
  {$IFDEF fwfmx}AFormX, AFormY: Single{$ELSE}AFormX, AFormY: Integer{$ENDIF});
begin
  inherited;
  mButton := button;
  mx := aFormX;
  my := aFormY;
  IntMouseUp;
end;

procedure txRectangle.resize;
begin
  inherited;
  IntResize;
end;

procedure txRectangle.SetDown(const Value: boolean);
begin
  FDown := Value;
  if value = true then
  begin
    fill.active:=fill.down;
  end
  else
  begin
    if hot then
      fill.Active := fill.hot
    else
      fill.active := fill.Normal;

  end;
end;

procedure txRectangle.SetHot(const Value: boolean);
begin
//  alog.send('SetHot', value);
  {  if value = true then
    begin
      if fHot = false then SavedFillColor := Fill.color;
      fill.color := HotFill.color;
    end
    else
    begin
      fill.color := SavedFillColor;
    end;}
  if value = true then
  begin
    fill.active := fill.Hot;
    fstroke.active := stroke.hot;
  end
  else
  begin
    fill.active := fill.normal;
    fstroke.active := stroke.normal;
  end;

  FHot := Value;
end;

procedure txRectangle.SetPanelText(const Value: string);
begin
  fPanelText := Value;
  if value = '' then
  begin
    if not assigned(PanelTextlbl) then
      exit
    else
      PanelTextlbl.visible := false;

  end;

  if not assigned(PanelTextlbl) then CreateLabel;
  PanelTextlbl.Visible := true;
  PanelTextlbl.xtext := value;

end;

procedure txRectangle.SP(aControl: tComponent);
begin
  parent := txWinControlClass(aControl);
end;

{$IFDEF fwfmx}

procedure txRectangle.AfterCreate;
begin
  inherited;

end;

procedure txRectangle.FillChanged(sender: tobject);
begin
 alog.send('Fill Changed');
 beginupdate;
     if FFill.Active.Kind = txBrushKind.None then
  begin
    fmxfill.kind:=tBrushKind.none;
  end
  else
  begin
   fmxfill.kind:=tBrushKind.solid;
    fmxfill.color:=ffill.active.color;
  end;
  endupdate;
end;

procedure txRectangle.StrokeChanged(sender: tobject);
begin
   if FStroke.Active.Kind = txBrushKind.None then
  begin
    fmxStroke.kind:=tBrushKind.none;
  end
  else
  begin
      fmxStroke.kind:=tBrushKind.solid;
    fmxstroke.Color:=fStroke.Active.color;
 fmxStroke.Thickness:=fStroke.active.Thickness;
  end;
end;

procedure txRectangle.BorderChanged(sender: tobject);
var
  aSides: tsides;
  aCorners: TCorners;
begin
  aSides := [] ;
  if TxSide.Left in FxBorder.Sides then
    aSides := aSides + [tside.Left] ;
  if TxSide.Right in FxBorder.Sides then
    aSides := aSides + [tside.Right] ;
  if TxSide.Top in FxBorder.Sides then
    aSides := aSides + [tside.Top] ;
  if TxSide.Bottom in FxBorder.Sides then
    aSides := aSides + [tside.Bottom] ;
  Sides := aSides;

  aCorners := [] ;
  if txCorner.TopLeft in FxBorder.corners then
    aCorners := aCorners + [tCorner.TopLeft] ;
  if txCorner.TopRight in FxBorder.corners then
    aCorners := aCorners + [tCorner.topRight] ;
  if txCorner.BottomLeft in FxBorder.corners then
    aCorners := aCorners + [tCorner.BottomLeft] ;
  if txCorner.BottomRight in FxBorder.corners then
    aCorners := aCorners + [tCorner.BottomRight] ;
  Sides := aSides;
  Corners := aCorners;

  xRadius := xBorder.Radius;
  yRadius := xBorder.Radius;
end;
{$ENDIF}

{$IFDEF fwvcl}
procedure txRectangle.MouseEnter(var Message: TMessage);
begin
  inherited;
  IntMouseEnter;
end;

procedure txRectangle.MouseLeave(var Message: TMessage);
begin
  inherited;
  IntMouseLeave;
end;

{$ELSE}
procedure txRectangle.DoMouseEnter;
begin
  inherited;
  alog.send('Mouse Enter');
  IntMouseEnter;
end;

procedure txRectangle.DoMouseLeave;
begin
  inherited;
  IntMouseLeave;
end;
{$ENDIF}
{$IFDEF fwvcl}

procedure txRectangle.AfterCreate;
begin
  inherited;
  fPosition := txPosition.create(self);
  Fill.Kind := txBrushKind.None;
  Stroke.Kind := txBrushKind.None;
  AlignWithMargins := true;
  // StyleElements:=[];

  caption := '';
  FrameWidth := 1;
  //StorePaintBuffer := true;

  BackgroundStyle := TscGPPanelBGStyle.gppbsColor; //?

  FluentUIOpaque:=true;
 // DrawTextMode := scdtmGDIPlus;
  ScaleFrameWidth := false;
  // BevelEdges:=[];
  // Color:=clWhite;
   //xBorder.sides:=[];

end;


procedure txRectangle.FillChanged(sender: tobject);
begin
  if FFill.Active.Kind = txBrushKind.None then
  begin
    FillColorAlpha := 0;
    // FrameColorAlpha:=0;
    // xborder.Sides:=[];
  end
  else
  begin

    //  FrameColor:=xstyler.tc(fFill.Color);
     // FillColor := xStyler.tc(FFill.Color);
    FillColor := ffill.Active.color;
    FillColor2:=ffill.active.Color;
    //    FillColorAlpha := tAlphaColorRec(fFill.color).A;
     // FrameColorAlpha:= tAlphaColorRec(fFill.color).A;
    FillColorAlpha := 255;
    //  FrameColorAlpha:=255;
  end;
end;

procedure txRectangle.StrokeChanged(sender: tobject);
begin

  if FStroke.Active.Kind = txBrushKind.None then
  begin
    FrameColorAlpha := 0;
  end
  else
  begin
    FrameColorAlpha := 255;
    FrameColor := FStroke.Active.Color;
    FrameWidth := Trunc(FStroke.Active.Thickness);
  end;
end;

procedure txRectangle.BorderChanged(sender: tobject);
var
  aSides: TscGPPanelFrameSides;
  aCorners: TscGPCorners;
begin

  aSides := [] ;
  aCorners := [] ;
  // TscGPPanelFrameSide = (gppfsLeft, gppfsTop, gppfsRight, gppfsBottom);
              //  TscGPCorner = (gpcrTopLeft, gpcrTopRight, gpcrBottomLeft, gpcrBottomRight);
  if txCorner.topleft in FxBorder.Corners then
    aCorners := aCorners + [TscGPCorner.gpcrTopLeft] ;
  if txCorner.topright in FxBorder.Corners then
    aCorners := aCorners + [TscGPCorner.gpcrTopRight] ;
  if txCorner.BottomLeft in FxBorder.Corners then
    aCorners := aCorners + [TscGPCorner.gpcrBottomLeft] ;
  if txCorner.BottomRight in FxBorder.Corners then
    aCorners := aCorners + [TscGPCorner.gpcrBottomRight] ;
  RoundedCorners := aCorners;

  if txSide.Left in FxBorder.Sides then
    aSides := aSides + [TscGPPanelFrameSide.gppfsLeft] ;
  if txSide.Right in FxBorder.Sides then
    aSides := aSides + [TscGPPanelFrameSide.gppfsTop] ;
  if txSide.Top in FxBorder.Sides then
    aSides := aSides + [TscGPPanelFrameSide.gppfsRight] ;
  if txSide.Bottom in FxBorder.Sides then
    aSides := aSides + [TscGPPanelFrameSide.gppfsBottom] ;
  FrameSides := aSides;

  FrameRadius := xBorder.Radius;
end;

{$ENDIF}

{$IFDEF fwweb}

procedure txRectangle.AfterCreate;
begin
  inherited;
  ElementClassName := 'xxx';
  borderStyle := bsNone;

end;

procedure txRectangle.FillChanged(sender: tobject);
var
  htmlColor: string;
begin
  if FFill.Active.Kind = txBrushKind.None then
  begin
    htmlcolor := 'rgba(255,255,255,0)';
    xsetBG(self, htmlColor);
  end
  else
  begin
    htmlColor := ColorToHTML(ffill.Active.color);
    xsetBG(self, ffill.Active.htmlColor);
  end;
  //UpdateElement;
end;

procedure txRectangle.StrokeChanged(sender: tobject);
var
 sLeft, sTop, sRight, sBottom: string;
 i: integer;
 borders: string;
 None: string;
begin
  none:='none';
 if fStroke.Active.kind=txBrushKind.solid then

 borderS:=FloatTostr((fstroke.Active.Thickness)) + 'px solid ' + ColorToHtml(fstroke.active.color) else
 BorderS:=none;




 if TxSide.Left in xBorder.sides then sLeft:=BorderS else sLeft:=none;
 if TxSide.right in xBorder.sides then sRight:=BorderS else sLeft:=none;
 if TxSide.top in xBorder.sides then stop:=BorderS else sLeft:=none;
 if TxSide.bottom in xBorder.sides then sBottom:=BorderS else sLeft:=none;

 tjsHtmlElement(self.elementhandle).style.setProperty('border-left', sleft);
  tjsHtmlElement(self.elementhandle).style.setProperty('border-right', sRight);
   tjsHtmlElement(self.elementhandle).style.setProperty('border-top', sTop);
   tjsHtmlElement(self.elementhandle).style.setProperty('border-bottom', sBottom);

   tjsHtmlElement(self.elementhandle).style.setProperty('border-radius', inttostr(xborder.Radius) + 'px');


end;

procedure txRectangle.UpdateElement;
begin
  inherited;
  { if assigned(fFill) then if Assigned(fFill.active) then if visible then

    FillChanged(fill.active);}
end;

procedure txRectangle.VisibleChanged;
begin
  inherited;
  alog.send('VISIBLE CHANGED');
end;

procedure txRectangle.BorderChanged(sender: tobject);

begin

end;

{$ENDIF}


{ txBorder }

constructor txBorder.create;
begin
  FRadius := 0;
  FSides := [Left, Right, Top, Bottom] ;

end;

procedure txBorder.SetCorners(const Value: txCorners);
begin
  FCorners := Value;
  if assigned(onChanged) then
    onChanged(self);
end;

procedure txBorder.SetRadius(const Value: integer);
begin
  FRadius := Value;
  if assigned(onChanged) then
    onChanged(self);

end;

procedure txBorder.SetSides(const Value: TxSides);
begin
  FSides := Value;
  if assigned(onChanged) then
    onChanged(self);

end;



{$IFDEF fwfmx}

procedure txLabel.AfterCreate;
begin
  Font := txFont.create;
  Font.onchanged := FontChanged;
end;
{$ENDIF}
{$IFDEF fwvcl}

procedure txLabel.AfterCreate;
begin
  Font := txFont.create;
  Font.onchanged := FontChanged;

end;
{$ENDIF}

{$IFDEF fwweb}

procedure txLabel.AfterCreate;
begin
  AutoSize := true;
  WordWrap := false;
end;
{$ENDIF}

{$IFNDEF fwfmx}
function txLabel.getHitTest: boolean;
begin
  result := enabled;
end;

procedure txLabel.SetHitTest(const Value: boolean);
begin
  enabled := Value;

end;

{$ENDIF}
{$IFDEF fwweb}
{$ENDIF}

constructor txLabel.create(aOwner: tComponent);
begin
  inherited;
  AfterCreate;

end;

{$IFNDEF fwweb}
procedure txLabel.FontChanged(sender: tobject);
var
  fontStyle: TFontStyles;
begin

  {$IFDEF fwfmx}
  textSettings.font.Families := font.Family;
  if tfontstyle.fsbold in font.style then
    textSettings.font.Weight := TFontWeight.bold
  else
    textsettings.font.Weight := tFontWeight.Regular;

  {$ENDIF}
  {$IFDEF fwvcl}
  // fontStyle:=font.style;
  textSettings.font.Families := font.Name;
  if tfontstyle.fsbold in font.style then
    textSettings.font.Weight := TSkFontComponent.tskFontWeight.bold
  else
    textsettings.font.Weight := TSkFontComponent.tskFontWeight.Regular;
  {$ENDIF}

  textSettings.font.size := font.size;

  if tFontStyle.fsItalic in font.style then
  begin
    // StyleElements:=[];
    textSettings.decorations.style := TSkTextDecorationStyle.Wavy;
    alog.send('wavy');
  end
  else
    textSettings.decorations.style := TSkTextDecorationStyle.Solid;

  if tFontStyle.fsunderline in font.style then
  begin
    alog.send('Underline');
    //   StyleElements:=[];
    textSettings.Decorations.Decorations := textSettings.Decorations.Decorations + [TSkTextDecoration.underline] ;
  end;
  textsettings.FontColor := Font.color;

end;
{$ENDIF}

function txLabel.GetBold: boolean;
begin

  {$IFDEF fwfmx}
  result := textsettings.font.Weight = tFontWeight.bold;
  {$ENDIF}
  {$IFDEF fwvcl}
  result := textsettings.font.Weight = TSkFontComponent.tskFontWeight.bold;
  {$ENDIF}
  {$IFDEF fwweb}
  {$ENDIF}
end;

procedure txLabel.setHAlign(const Value: txTextAlign);
begin

  {$IFDEF fwfmx}
  if Value = txTextAlign.xtLeading then
    textsettings.HorzAlign := TSkTextHorzAlign.Leading;
  if Value = txTextAlign.xtTrailing then
    textsettings.HorzAlign := TSkTextHorzAlign.Trailing;
  if Value = txTextAlign.xtCenter then
    textsettings.HorzAlign := TSkTextHorzAlign.Center;
  if Value = txTextAlign.xtJustify then
    textsettings.HorzAlign := TSkTextHorzAlign.Justify;
  {$ENDIF}
  {$IFDEF fwvcl}
  if Value = txTextAlign.xtLeading then
    textsettings.HorzAlign := TSkTextHorzAlign.Leading;
  if Value = txTextAlign.xtTrailing then
    textsettings.HorzAlign := TSkTextHorzAlign.Trailing;
  if Value = txTextAlign.xtCenter then
    textsettings.HorzAlign := TSkTextHorzAlign.Center;
  if Value = txTextAlign.xtJustify then
    textsettings.HorzAlign := TSkTextHorzAlign.Justify;

  {$ENDIF}
  {$IFDEF fwweb}
  {$ENDIF}
end;

procedure txLabel.setvAlign(const Value: txTextAlign);
begin
  {$IFDEF fwfmx}
  if Value = txTextAlign.xtLeading then
    textsettings.VertAlign := ttextalign.Leading;
  if Value = txTextAlign.xtTrailing then
    textsettings.VertAlign := ttextalign.Trailing;
  if Value = txTextAlign.xtCenter then
    textsettings.VertAlign := ttextalign.Center;

  {$ENDIF}
  {$IFDEF fwvcl}
  if Value = txTextAlign.xtLeading then
    textsettings.VertAlign := TSkTextVertAlign.Leading;
  if Value = txTextAlign.xtTrailing then
    textsettings.VertAlign := TSkTextVertAlign.Center;
  if Value = txTextAlign.xtCenter then
    textsettings.VertAlign := TSkTextVertAlign.Trailing;

  {$ENDIF}
  {$IFDEF fwweb}
  {$ENDIF}
end;

function txLabel.gethAlign: txTextAlign;
begin
  {$IFDEF fwfmx}
  if textsettings.HorzAlign = TSkTextHorzAlign.Leading then
    result := txTextAlign.xtLeading;
  if textsettings.HorzAlign = TSkTextHorzAlign.Trailing then
    result := txTextAlign.xtTrailing;
  if textsettings.HorzAlign = TSkTextHorzAlign.Center then
    result := txTextAlign.xtCenter;
  if textsettings.HorzAlign = TSkTextHorzAlign.Justify then
    result := txTextAlign.xtJustify;

  {$ENDIF}
  {$IFDEF fwvcl}
  if textsettings.HorzAlign = TSkTextHorzAlign.Leading then
    result := txTextAlign.xtLeading;
  if textsettings.HorzAlign = TSkTextHorzAlign.Trailing then
    result := txTextAlign.xtTrailing;
  if textsettings.HorzAlign = TSkTextHorzAlign.Center then
    result := txTextAlign.xtCenter;
  if textsettings.HorzAlign = TSkTextHorzAlign.Justify then
    result := txTextAlign.xtJustify;
  {$ENDIF}
  {$IFDEF fwweb}
  {$ENDIF}
end;

function txLabel.GetvAlign: txTextAlign;
begin
  {$IFDEF fwfmx}
  if textsettings.VertAlign = ttextalign.Leading then
    result := txTextAlign.xtLeading;
  if textsettings.VertAlign = ttextalign.Trailing then
    result := txTextAlign.xtTrailing;
  if textsettings.VertAlign = ttextalign.Center then
    result := txTextAlign.xtCenter;

  {$ENDIF}
  {$IFDEF fwvcl}
  if textsettings.VertAlign = TSkTextVertAlign.Leading then
    result := txTextAlign.xtLeading;
  if textsettings.VertAlign = TSkTextVertAlign.Trailing then
    result := txTextAlign.xtTrailing;
  if textsettings.VertAlign = TSkTextVertAlign.Center then
    result := txTextAlign.xtCenter;

  {$ENDIF}
  {$IFDEF fwweb}
  {$ENDIF}
end;

function txLabel.getxText: string;
begin
  {$IFDEF fwfmx}
  result := text;
  {$ELSE}
  result := caption;
  {$ENDIF}
end;

procedure txLabel.SetItalic(const Value: boolean);
begin
  FItalic := Value;

end;

procedure txLabel.SetUnderline(const Value: boolean);
begin
  Funderline := Value;
end;

procedure txLabel.SetBold(const Value: boolean);
begin
  if value = true then font.style := font.style + [tFontStyle.fsbold] ;

  {$IFDEF fwfmx}
  if Value then
    textsettings.font.Weight := tFontWeight.bold
  else
    textsettings.font.Weight := tFontWeight.regular;
  {$ENDIF}
  {$IFDEF fwvcl}
  if Value = true then
    textsettings.font.Weight := TSkFontComponent.tskFontWeight.bold
  else
    textsettings.font.Weight := TSkFontComponent.tskFontWeight.regular;
  {$ENDIF}
  {$IFDEF fwweb}
  {$ENDIF}
end;

procedure txLabel.SetFontColor(const Value: tcfgColorClass);
{$IFDEF fwvcl}
var

  c: tAlphaColor;
  cr: tAlphaColorRec;
  {$ENDIF}
begin
  FFontColor := Value;

  {$IFDEF fwfmx}
  self.TextSettings.FontColor := value;
  {$ENDIF}
  {$IFDEF fwvcl}
  c := ColorToRGB(value);
  CR := TAlphaColorREc(c);
  CR.A := 255;

  textsettings.fontcolor := tAlphaColor(CR);
  {$ENDIF}
  {$IFDEF fwweb}

  {$ENDIF}

end;

procedure txLabel.SetxText(const Value: string);
begin

  {$IFDEF fwVCL}
  caption := Value;
  {$ENDIF}
  {$IFDEF fwFMX}
  text := Value;
  {$ENDIF}
  {$IFDEF fwWeb}
  caption := Value;
  {$ENDIF}

  AutoSize := true;
end;

{ txButton }

constructor txButton.create(aOwner: tcomponent);

{$IFNDEF fwweb}var
  acol: tcolorclass;
  {$ENDIF}
begin
  inherited;
  lbl := txLabel.Create(self);
  lbl.parent := self;
  lbl.HitTest := false;
  enableHot := true;
  enableDown := true;

  {$IFNDEF fwweb}

  if assigned(xStyles.CurrentStyle) then
  begin
    xstyler.getcolor(txcolor.xcButtonStroke, acol);
    Stroke.color := acol;
    xstyler.getcolor(txcolor.xcButtonNormal, acol);
    fill.color := acol;
    xStyler.GetColor(txColor.xcButtonHot, acol);
    HotFill.color := acol;
    XStyler.GetColor(txColor.xcButtonDown, acol);
    DownFill.color := acol;
    xstyler.getcolor(txcolor.xctButtonNormal, acol);
    lbl.FontColor := acol;
  end
  else
  begin
    lbl.FontColor := gcBlack;
    stroke.color := gcBlack;
    fill.color := gcWhite;
  end;
  {$ENDIF}

  Stroke.kind := txbrushkind.Solid;
  fill.kind := txbrushkind.Solid;
  onClick := lblClick;
  lbl.OnClick := lblClick;
  lbl.AutoSize := true;
  // lbl.TextSettings.font.Size:=14;
  // lbl.OnResize:=lblResize;
  lbl.margins.Left := 12;
  lbl.margins.right := 12;
  lbl.margins.top := 3;
  lbl.margins.bottom := 3;
  text := 'Button';
  BorderSize := 1;

  DoAutoSize;

end;

procedure txButton.lblClick(sender: tobject);
begin
  if assigned(onclick) then onClick(self);

end;

procedure txButton.lblResize(sender: tobject);
begin
  width := lbl.width + lbl.margins.left + lbl.margins.right;
  Height := lbl.Height + lbl.margins.top + lbl.margins.bottom;
end;

procedure txButton.resize;
begin
  inherited;
  try
    // if fResizing then exit;

    fResizing := true;
    SetPosX(lbl, (Width - lbl.width) / 2);
    SetPosY(lbl, (height - lbl.height) / 2);
  finally
    fResizing := false;
  end;
end;

procedure txButton.SetAutoSize(const Value: boolean);
begin
  FAutoSize := Value;
end;

procedure txButton.SetBorderSize(const Value: integer);
begin
  FBorderSize := Value;
  Stroke.Thickness := value;
end;

procedure txButton.SetFlat(const Value: boolean);
begin
  FFlat := Value;
  if value = true then
    fill.kind := txBrushKind.None
  else
    fill.kind := txBrushKind.Solid;

end;

procedure txButton.SetRounded(const Value: boolean);
begin
  FRounded := Value;
  if value = true then
  begin
    xBorder.Radius := 9;
    xborder.Corners := [txCorner.topleft, txcorner.TopRight, txCorner.BottomLeft, txCorner.BottomRight] ;
  end

  else
  begin

    xborder.radius := 0;
  end;

end;

procedure txButton.DoAutoSize;
begin
  Width := lbl.width + lbl.margins.left + lbl.margins.right;
  Height := lbl.height + lbl.margins.bottom + lbl.margins.top;
end;

destructor txButton.Destroy;
begin
  if assigned(lbl) then lbl.free;

  inherited;
end;

procedure txButton.IntMouseDown;
begin
  inherited;
  if EnableDown then

    Down := true;
end;

procedure txButton.IntMouseEnter;
begin
  inherited;
  if EnableHot then

    Hot := true;
  //alog.send('hello');
  //Stroke.Thickness:=BorderSize+1;

end;

procedure txButton.IntMouseLeave;
begin
  inherited;
  //alog.send('bye');
  if EnableHot then

    Hot := false;
  // Stroke.Thickness:=BorderSize;
end;

procedure txButton.IntMouseUp;
begin
  inherited;
  if EnableDown then

    Down := false;
end;

procedure txButton.SetText(const Value: string);
begin
  Ftext := Value;

  lbl.xtext := value;
  if AutoSize then
  begin
    DoAutoSize;
  end
  else
    Resize;
end;

{ txControlHelper }
{$IFNDEF fwweb}

function txControlHelper.getxGuid: txControlID;
begin

end;

function txControlHelper.getxid: txControlID;
begin
  result := xLookup.getid(self, false);
end;

function txControlHelper.getxName: string;
begin
  result := xlookup.getname(self);
end;

procedure txControlHelper.Mar(allSides: integer);
begin
  margins.left := allSides;
  margins.top := allsides;
  margins.bottom := allSides;
  margins.right := allSides;
end;

procedure txControlHelper.Pad(ALlSides: integer);
begin
  if self is txWinControlClass then
  begin
    txWinControlClass(self).Padding.left := allSides;
    txWinControlClass(self).padding.top := allsides;
    txWinControlClass(self).padding.bottom := allSides;
    txWinControlClass(self).Padding.right := allSides;
  end;
end;

procedure txControlHelper.SetxName(const Value: string);
begin
  xLookup.Setname(self, value);
end;

procedure txControlHelper.xClear;
begin

end;

function txControlHelper.xFind(aname: string): tcontrol;
begin
  result := xlookup.find(aname, self);
end;

{ txControlLookup }

procedure txControlLookup.clear;
begin
  //dic.clear;
end;

constructor txControlLookup.create;
begin
  //dic := txControlLookupDic.create;
  ids := txControlIDs.create;
end;

function txControlLookup.Find(aname: string; var ap: tcontrol): tControl;
var
  FullName: string;
  aid, pid: txControlID;
  apID: string;
  apair: tpair<tcontrol, txControlID>;
begin
  result := nil;
  if assigned(ap) then
  begin

    pid := getID(ap);
    FullName := lowercase(pid.guidS + '_' + aname);
  end
  else
  begin
    alog.error('parent not assigned');
    //fullName := aname;
    exit;
  end;
  if ids.TryGetValue(fullname, aid) then result := aid.control;

  { if assigned(ap) then
   begin
     if dic.TryGetValue(ap, aid) then
     begin
       apID := aid.guids + '_';
       FullName := apID;
     end;
     FullName := FullName + aname;
     Alog.send('Find ' + fullname);
     for apair in dic do
     begin
       if lowercase(apair.value.xname) = lowercase(fullName) then
       if assigned(apair.key) then

       begin
         result := apair.key;
         exit;
       end;
     end;

   end;
    alog.send('NOT FOUND', fullname);}
end;

function txControlLookup.GetCount: integer;
begin
  result := ids.count;
end;

function txControlLookup.GetDeadCOunt: integer;
var
  apair: tpair<string, txControlID>;
  c: integer;
begin
  c := 0;
  for apair in ids do
  begin
    if not assigned(apair.value.control) then c := c + 1;

  end;
  result := c;
end;

function txControlLookup.GetGuid: int64;
asm
dw $310F // opcode for RDTSC
end;

function txControlLookup.GetID(var ac: tcontrol; doCreate: boolean = true): txControlID;
var
  apair: tpair<string, txControlID>;
begin
  for apair in IDs do
  begin
    if apair.value.control = ac then
    begin
      result := apair.value;
      //  alog.send('found guid record ' + ac.name, result.guids);
      exit;
    end;
  end;
  if doCreate then
  begin
    result := txControlID.create;
    result.Guid := GetGuid;
    result.control := ac;
    IDs.add(result.guidS, result);
    //  alog.send('Created guid record ' + ac.name, result.guids);
  end
  else
    alog.send('Not found');

end;

function txControlLookup.Getname(var ac: tcontrol): string;
var
  aid: txControlID;
  n: string;
  aname: string;
begin
  result := '';

  aid := getID(ac);
  result := aid.xname;
  {

  if dic.TryGetValue(ac, aid) then
  begin
    aname := aid.xname;
    if pos('_', aname) <> 0 then aname := copy(aname, pos('_', aname) + 1, length(aname));
    result := aname;
  end;}
end;

procedure txControlLookup.Setname(var ac: tcontrol; aname: string);
var
  aid, pid: txControlID;
  n: string;
  FullName: string;
  ap: tcontrol;
begin
  if assigned(ac.parent) then
  begin
    {if dic.TryGetValue(tControl(ac.parent), aid) = false then
    begin

      aid := txControlID.create;
      aid.guid := getGuid;
      dic.add(tcontrol(ac.parent), aid);
    end;}
    ap := tControl(ac.parent);
    pid := getID(ap);
    FullName := lowercase(pid.guidS + '_' + aname);
  end
  else
  begin
    alog.error('parent not assigned');
    //fullName := aname;
    exit;
  end;

  if ids.TryGetValue(FullName, aid) = false then
  begin
    aid := txControlID.Create;
    aid.control := ac;
    aid.xname := lowercase(aname);
    ids.add(lowercase(fullname), aid);

  end;
  {
if dic.TryGetValue(ac, aid) = false then
begin
  aid := txControlID.create;
  aid.guid := GetGuid;

  dic.add(ac, aid);
end;}
  aid.Control := ac;
  aid.xname := lowercase(aname);
  //alog.send('Guid', fullname);
end;

{ txControlID }

function txControlID.GuidS: string;
begin
  result := IntToStr(guid);
end;
{$ENDIF}

{ txForm }
{$IFNDEF fwweb}
constructor txxForm.CreateNew(AOwner: TComponent; {$IFDEF fwVCL}Dummy: Integer = 0{$ENDIF}{$IFDEF fwFMX}Dummy: NativeInt{$ENDIF});
begin
  inherited;
  {$IFDEF fwVCL}
  position := tPosition.poDesigned;
  DefaultMonitor := tDefaultMonitor.dmDesktop;
  Width := 800;
  Height := 600;

  Scaled := True;
  doublebuffered := true;
  InitTimer;
  {$ENDIF}
end;
{$ENDIF}

procedure txxForm.doShow;
begin
  inherited;
  if showTimerInterval <> 0 then
  begin
    fShowTimer.Interval := ShowTimerInterval;
    fShowTimer.tag := fShowTimer.tag + 1;
    if fShowTimer.tag = 1 then fShowTimer.Enabled := true;

  end;
end;

procedure txxForm.DoShowTimer;
begin

end;

procedure txxForm.initTimer;
begin
  fShowTimer := txTimerClass.create(nil);
  fShowTimer.Enabled := false;
  ShowTimerInterval := 10;
  fShowtimer.ontimer := fShowTimerTimer;
end;

procedure txxForm.ShowBy(aForm: tForm; aPlace: txxPlace; DoMove: boolean);
var
 dLeft, dTop, dRight, dBottom: integer;
 m: integer;
 DesktopRight, DesktopLeft, DesktopBottom: integer;
 Good: boolean;
begin
 m:=20;


 {$IFDEF fwvcl}
 DesktopRight:=screen.DesktopLeft+Screen.DesktopWidth;
 DesktopLeft:=screen.desktopleft;
 DesktopBottom:=screen.DesktopTop+screen.DesktopHeight;
  if aplace=toRight then
  begin
    dLeft:=aform.left+aform.width+m;
    dTop:=aForm.top;
    dRight:=dLeft+aform.width+m;
    if dright>DesktopRight then good:=false else good:=true;
     dBottom:=dTop+height;
  end;
  if good=false then
  begin
    dleft:=aform.left-m-width;
    dtop:=aform.top;
    if dleft< DesktopLeft then good:=false else good:=true;
         dBottom:=dTop+height;
  end;

  if good then
  begin
    if dBottom>DesktopBottom then dtop:=DesktopBottom-m-height;
    Left:=dleft;
    top:=dTop;
    show;
  end else ShowCentre;


 {$ENDIF}




end;

procedure txxForm.ShowCentre(sizePC: integer);
 var
  dLeft, dTop: integer;
  dw,dh: integer;
begin
  alog.send('Show Centre');
   {$IFDEF fwvcl}
   var mon: tMonitor;
   mon:=screen.MonitorFromPoint(mouse.CursorPos);
   if sizepc<>0 then
   begin
     dw:=trunc((sizePC/100)*mon.Width);
     dh:=trunc((sizePC/100)*mon.height);
   end else
   begin
     dh:=height;
     dw:=width;
   end;
    dLeft:=mon.left+trunc((mon.width-dw)/2);
    dtop:=mon.top+trunc((mon.height-dh)/2);
    Width:=dw;
    Height:=dh;
    Left:=dLeft;
    top:=dtop;
    show;
   {$ENDIF}
   {$IFDEF fwfmx}

   {$ENDIF}



end;

procedure txxForm.ShowBy;
begin
   {$IFDEF fwvcl} showby(application.mainform, toRight, true);  {$ENDIF}
end;

procedure txxForm.fShowTimerTimer(sender: tobject);
begin
  fShowTimer.Enabled := false;
  doShowTimer;

end;

{ txImage }

constructor txImage.create(aOwner: tcomponent);
begin
  inherited;

  {$IFDEF fwfmx}

  {$ENDIF}
  {$IFDEF fwvcl}

  {$ENDIF}
  {$IFDEF fwweb}
  fill.kind := gfkNone;
  stroke.kind := gskNone;
  Stretch := true;
  ASpectRatio := true;
  Center := true;
  {$ENDIF}

  {   fill.kind:=gfkNone;
     stroke.kind:=gskNone;
     Stretch:=true;
     AspectRatio:=true;       }
    // if aOwner is txWinControlClass then

   //  parent:=txWinControlClass(aowner);
end;

procedure txImage.setIcon(const Value: txIcon);
begin
  Ficon := Value;

  {$IFNDEF fwweb}
  svg.Source := value.SVG;
  {$ELSE}

  {bitmapContainer:=value.icons.bc;
  if bitmaps.count=0 then bitmaps.add;
   beginupdate;

 bitmaps[0].BitmapName:=value.bn;
   endupdate;
   alog.send('BITMAP NAME: ' + value.bn); }
  url := value.url;

  {$ENDIF}

  { alog.send('Imgwidth', width);
   alog.send('ImgHeight', height);
   bitmap.LoadFromFile(Value.fn); }
end;

{ txScrollBox }

constructor txScrollBox.create(aOwner: tComponent);
begin
  inherited;

  {$IFDEF fwfmx}

  {$ENDIF}
  {$IFDEF fwvcl}
  MouseWheelSupport := true;
  BorderStyle := bsNone;
  BevelEdges := [] ;
  BevelInner := tBevelCut.bvNone;
  BevelOuter := tBevelCut.bvNone;
  BevelKind := tBevelKind.bkNone;
  {$ENDIF}
  {$IFDEF fwweb}

  {$ENDIF}

end;

{ txFont }

constructor txFont.create;
begin
  inherited;
  alog.send('txfont.create');
  {$IFDEF fwweb}
  Color := gcBlack;
  {$ELSE}

  color := tAlphaColorRec.Black;

  {$ENDIF}

end;

procedure txFont.SetColor(const Value: txColorClass);
begin
  fcol := Value;
  {$IFNDEF fwweb}
  fVCLColor := AlphaColorToColor(value);
  {$ENDIF}

end;

procedure txFont.setVCLColor(const Value: tColor);
var
  c: longint;
begin
  FVCLcolor := Value;
 // c := ColorToRGB(value);
  {$IFDEF fwweb}   //Ndef?
 // fAlphaColor := c;
  {$ENDIF}

end;

procedure txFont.SetOnChanged(const Value: tNotifyEvent);
begin
  FonChanged := Value;
  {$IFDEF fwvcl}onChange := value;
  {$ENDIF}

end;

{ txBrushState }

constructor txBrushState.create;
begin
  fkind := txbrushkind.Solid;
  fColor := gcWhite;
  fEnabled:=true;
  {$IFDEF fwweb}fhtmlcolor := colortohtml(fcolor);{$ENDIF}
end;

procedure txBrushState.doChange;
begin
  Enabled := true;
  owner.StateChanged(self);
  if assigned(FonChanged) then
    FonChanged(self);

end;

initialization
  {$IFNDEF fwweb}
  xLookup := txControlLookup.create;
  {$ENDIF}

end.

