unit x.cfg.classes;

interface

uses

  System.SysUtils, System.Types, System.UITypes, System.Classes, System.Variants, rtti, System.Generics.Collections,
  System.TypInfo, x.rtti, x.icons.all,

  {$IFNDEF fwweb}
  System.UIConsts,
  {$ENDIF}

  {$IFDEF fwfmx}
  FMX.Types, FMX.Controls, FMX.Forms, FMX.Graphics, FMX.Dialogs, FMX.objects, FMX.TMSMemo, FMX.StdCtrls, FMX.Layouts,
  FMX.edit, FMX.Skia, FMX.colors, FMX.EditBox, FMX.SpinBox, FMX.TMSFNCCustomTreeView, FMX.memo, x.controls.FMXRectangle,
  FMX.TMSFNCTreeView, FMX.TMSFNCObjectInspector, FMX.TMSFNCToolBar, FMX.TMSFNCGraphicsTypes, fmx.listbox, fmx.tmsfnchtmltext,
{$ENDIF}
{$IFDEF fwvcl}
VCL.Controls, VCL.Forms, VCL.Graphics, VCL.Dialogs, VCL.StdCtrls, vcl.extctrls, vcl.comctrls, vcl.tmsfnchtmltext,
VCL.Skia, VCL.TMSFNCCustomTreeView,
VCL.TMSFNCTreeView, VCL.TMSFNCObjectInspector, VCL.TMSFNCToolBar, Vcl.Samples.Spin,  vcl.TMSFNCGraphicsTypes, scControls,
scGPControls, scgpExtControls,
{$ENDIF}
{$IFDEF fwweb}
vcl.Controls, vcl.Forms, vcl.Graphics, vcl.Dialogs, vcl.StdCtrls, vcl.ExtCtrls, vcl.ComCtrls,//vcl.Samples.Spin,
vcl.tmsfncgraphicstypes, vcl.TMSFNCSplitter, vcl.tmsfncimage,

VCL.TMSFNCTreeViewBase,
  VCL.TMSFNCTreeViewData, VCL.TMSFNCCustomTreeView, VCL.TMSFNCTreeView,
  VCL.TMSFNCObjectInspector,
{$ENDIF}
liblogtest;

type

  {$IFDEF fwfmx}

  // Controls
  tcfgEditClass = tEdit;
  tcfgButtonClass = tButton;
  tCFGCheckBoxClass = tCheckBox;
  // TCFGColorClass = tColorCOmboBox;
  TCFGSpinClass = TSpinBox;
  TCFGTrackClass = TTrackBar;
  tCFGMemoClass = tMemo;
  tcfgComboClass = tComboBox;
  tcfgColorClass = tAlphaColor;
        txTVClass = ttmsfncTreeView;
  txIXClass = ttmsfncObjectInspector;
  {$ENDIF}

  {$IFDEF fwvcl}

  // Controls
  tcfgEditClass = tEdit;
  tcfgButtonClass = tButton;
  tCFGCheckBoxClass = tCheckBox;
  // TCFGColorClass = tColorBox;
  TCFGSpinClass = tSpinEdit;
  TCFGTrackClass = TTrackBar;
  tCFGMemoClass = tMemo;
  tcfgComboClass = tscGPComboBox;
  tcfgColorClass = tColor;
     txTVClass = ttmsfncTreeView;
  txIXClass = ttmsfncObjectInspector;
  {$ENDIF}

  {$IFDEF fwWeb}

  // Controls
  tcfgEditClass = tCustomEdit;
  tcfgButtonClass = tButton;
  tCFGCheckBoxClass = tCheckBox;
  // TCFGColorClass = tColorBox;
 // TCFGSpinClass = tSpinEdit;
  TCFGTrackClass = TTrackBar;
  tCFGMemoClass = tCustomMemo;
  tcfgComboClass = tCustomComboBox;
  tcfgColorClass = tColor;
  txTVClass = ttmsfncTreeView;
  txIXClass = ttmsfncObjectInspector;
    {$ENDIF}
  txixType = (ixInfo, ixEdit);

  txIXLineStyle = (lsNone, lsBottom, lsLeft);
  txBGStyle = (bgTrans, bgSingleColor);
  txIncMode=(xInc, xEx);
  txIXStyle=(Light, Dark, Theme);
  txfstvRoot=(roPathName, roServerName);

implementation

end.

