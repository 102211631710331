unit xf.test;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, VCL.TMSFNCTypes, VCL.TMSFNCUtils,
  VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, Vcl.Controls,
  VCL.TMSFNCCustomControl, VCL.TMSFNCPanel, x.controls, x.cfg.classes;

type
  Txftest = class(txFormClass)
    TMSFNCPanel1: TTMSFNCPanel;
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  xftest: Txftest;

implementation

{$R *.dfm}

procedure Txftest.LoadDFMValues;
begin
  inherited LoadDFMValues;

  TMSFNCPanel1 := TTMSFNCPanel.Create(Self);

  TMSFNCPanel1.BeforeLoadDFMValues;
  try
    Name := 'xftest';
    Left := 0;
    Top := 0;
    Color := clBtnFace;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -6;
    Font.Name := 'Segoe UI';
    Font.Style := [];
    TMSFNCPanel1.SetParentComponent(Self);
    TMSFNCPanel1.Name := 'TMSFNCPanel1';
    TMSFNCPanel1.Left := 6;
    TMSFNCPanel1.Top := 25;
    TMSFNCPanel1.Width := 200;
    TMSFNCPanel1.Height := 275;
    TMSFNCPanel1.Margins.Left := 3;
    TMSFNCPanel1.Margins.Top := 3;
    TMSFNCPanel1.Margins.Right := 3;
    TMSFNCPanel1.Margins.Bottom := 3;
    TMSFNCPanel1.ParentDoubleBuffered := False;
    TMSFNCPanel1.DoubleBuffered := True;
    TMSFNCPanel1.TabOrder := 0;
    TMSFNCPanel1.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel1.SectionsAppearance.Font.Height := -22;
    TMSFNCPanel1.SectionsAppearance.Font.Name := 'Segoe UI';
    TMSFNCPanel1.SectionsAppearance.Font.Style := [];
    TMSFNCPanel1.Header.Text := 'Header';
    TMSFNCPanel1.Header.Size := 55.708332061767580000;
    TMSFNCPanel1.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.Header.Font.Color := 4539717;
    TMSFNCPanel1.Header.Font.Height := -26;
    TMSFNCPanel1.Header.Font.Name := 'Segoe UI';
    TMSFNCPanel1.Header.Font.Style := [fsBold];
    TMSFNCPanel1.Header.Fill.Color := 16380654;
    TMSFNCPanel1.Header.ButtonSize := 39.791667938232420000;
    TMSFNCPanel1.Header.ButtonSpacing := 7.958333492279053000;
    TMSFNCPanel1.Header.DropDownWidth := 198.958328247070300000;
    TMSFNCPanel1.Header.DropDownHeight := 238.750000000000000000;
    TMSFNCPanel1.Footer.Text := 'Footer';
    TMSFNCPanel1.Footer.Size := 55.708332061767580000;
    TMSFNCPanel1.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.Footer.Font.Color := 4539717;
    TMSFNCPanel1.Footer.Font.Height := -26;
    TMSFNCPanel1.Footer.Font.Name := 'Segoe UI';
    TMSFNCPanel1.Footer.Font.Style := [];
    TMSFNCPanel1.Footer.Fill.Color := 16380654;
    TMSFNCPanel1.Footer.ButtonSize := 39.791667938232420000;
    TMSFNCPanel1.Footer.ButtonSpacing := 7.958333492279053000;
    TMSFNCPanel1.Footer.DropDownWidth := 198.958328247070300000;
    TMSFNCPanel1.Footer.DropDownHeight := 238.750000000000000000;
  finally
    TMSFNCPanel1.AfterLoadDFMValues;
  end;
end;

end.
