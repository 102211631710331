unit x.json;

interface
                           //These can be done in one unit uising the fwvcl etc directives
uses
 {$IFDEF fwfmx}
 // fmx.xPersistence,
   x.json.web,
   FMX.Controls,
 {$ENDIF}
{$IFDEF fwvcl}
 // vcl.xPersistence,
  vcl.Controls,
    x.json.vcl,
 {$ENDIF}
 {$IFDEF fwweb}
 // weblib.xPersistence,
  //weblib.controls,
  vcl.controls,
   x.json.web, types,  JS, web,
 {$ENDIF}
x.persistence, system.classes, sysutils,  x.strUtils, system.Generics.Collections,  x.json.types, x.json.globals, rtti;

{$IFDEF fwweb}
const soFromBeginning=soBeginning;
{$ENDIF}

type
  tXJOps = class
  private
    Fmode: tXJMode;
    Fprops: xExcludePropertyListArray;
    FaObject: tobject;
    FItems: txjItems;

    fSaver: tXJSaver;
    Flog: tstringlist;
    FKeepList: boolean;
    FUseMeta: boolean;
    FaClass: tClass;
    FUIName: String;
    FPropList: tStringList;
    FNoClassType: boolean;
    function GetSaver: tXJSaver;

    function MakeArray: xExcludePropertyListArray;
    function AddItem(aJson: string; aObject: tobject; aname: string): txjItem;
    function GetItem(aaObject: tobject): txjItem;
    function checklist: tstringlist;
    function checkProp(aPropName: string): String;
    function GetLogfn: string;
    function getModes: string;
  protected
     procedure dolog(s: string);
     procedure AfterAdd(aitem: txjItem); virtual;
  public
    slp, slpFinal: tstringlist;
    fJson: string;


    procedure clear;
    procedure reset;
    procedure LogBefore(ainfo: string='');
    procedure LogAfter(ainfo: string='');
    procedure ap(aPropName: string);
    procedure apo(aPropName: string);
    function ApFrom(aPropname: string; aobject: tobject): tStringList;
    function apd: integer;
      {$IFDEF fwweb}

  {$ELSE}
  constructor create(aaClass: tClass); overload;
   {$ENDIF}
    constructor create(aaObject: tobject = nil); overload;

    property aObject: tobject read FaObject write FaObject;
    property aClass: tClass read FaClass write FaClass;

    function SaveObject(aaObject: tobject; afn: string = ''; aname: string = ''): string;
    procedure SaveAll(afn: string = '');

    function LoadObject(aaObject: tobject; afn: string; aname: string = ''): String;
    function LoadFromJson(aaObject: tobject; j: string): string;
    function LoadMeta(afn: string): integer;
  //  function JsonS(aaObject: tobject = nil; aname: string = ''): string;
    function json(aaObject: tobject = nil; aname: string = ''): txjItem;

    property Items: txjItems read FItems write FItems;

    property mode: tXJMode read Fmode write Fmode;
    property modeS: string read getModes;
    property props: XExcludePropertyListArray read Fprops write Fprops;

    property Saver: tXJSaver read GetSaver;
    property log: tstringlist read Flog write Flog;
     property UIName: String read FUIName write FUIName;
    property NoClassType: boolean read FNoClassType write FNoClassType;
  published
    property KeepList: boolean read FKeepList write FKeepList;
    property UseMeta: boolean read FUseMeta write FUseMeta;
    property PropList: tStringList read FPropList write FPropList;
  end;

type
  tXJOptionsClasses = tDictionary<tClass, tXJOps>;

type
  tXJOptionsObjects = tDictionary<tobject, tXJOps>;

  type
  tXJOptionsList = tobjectlist<tXJOps>;


type
  TXjSON = class
  private

    fOpsClasses: tXJOptionsClasses;
    FOpsObjects: tXJOptionsObjects;

  public
    constructor create;
    function GetOps(aClass: tClass): tXJOps; overload;
    function GetOps(aObject: tobject): tXJOps; overload;
    function GetOps: tXJOps; overload;

  published
    property OpsClasses: tXJOptionsClasses read fOpsClasses write fOpsClasses;
    property OpsObjects: tXJOptionsObjects read FOpsObjects write FOpsObjects;

  end;

  // function xJson(aobject: tobject): string;
//function xJsonInc(aObject: tobject; aProps: TTMSFNCExcludePropertyListArray): string;
//function xJsonEx(aObject: tobject; aProps: TTMSFNCExcludePropertyListArray): string;

 //New

 //aJson to aObject. Object must be created.
 function xjO(aObject: tobject;aJson: string): string;  overload;

   //If object is nil it will be created of class specified
 function xjO(aObject: tobject; aclassName: string; aJson: string): string;  overload;
     //If object is nil it will be created of class specified
 function xjO(aObject: tobject; aclass: tClass; aJson: string): string;  overload;
     //If object is nil it will be created of class specified
 function xjO(var aObject: tobject; aType: tRTTIType; aJson: string): string;  overload;



 {$IFDEF fwweb}
 function JOJ(sJO: TJSObject): string;
  //function xjv<T>(aValue: jsValue): T;

    function xjO(aObject: tobject;aJson: tJSObject): string;  overload;


 function xjO(var aObject: tobject; aclassName: string; aJson: tJSObject): string;  overload;

 function xjO(var  aObject: tobject; aclass: tClass; aJson: tJSObject): string;  overload;

 function xjO(var aObject: tobject; aType: tRTTIType; aJson: tJSObject): string;  overload;
 {$ENDIF}




 //aObject to Json
 function xj(aobject: tobject; anoClass: boolean=true): string; overload;

function xjtoObject(j: string; aobject: tobject; anoClass: boolean=true): boolean;
function xjFNtoObject(afn: string; aobject: tobject): boolean;


var

  xJsonLog: boolean = true;
  xJsonLogOnlyExcluded: boolean = false;
  xJsonLogEvents: boolean = false;
  xJsonExComps: boolean=true;
  dot: char = '.';
  xJson: TXjSON;
   xjGlobals: txjGlobalSettings;

implementation

uses
   {$IFDEF fwweb}
     ioUtils, liblogtest;
  {$ELSE}
 quick.Commons, ioutils, liblogtest;
   {$ENDIF}

  function xjo(aObject: tobject; aJson: string): string;
  begin
    xjtoObject(aJson, aObject);
  end;
  //If object is nil it will be created of class specified
 function xjO(aObject: tobject; aclassName: string; aJson: string): string;  overload;
 begin

 end;
     //If object is nil it will be created of class specified
  function xjO(aObject: tobject; aclass: tClass; aJson: string): string;  overload;
  var
   aType: trttiType;
   ctx: tRTTIContext;
  begin
 //  atype:=ctx.getType(typeinfo(aclass));
  // xjo(aobject, atype, ajson);


  end;

      //If object is nil it will be created of class specified
 function xjO(var aObject: tobject; aType: tRTTIType; aJson: string): string;  overload;
 var
  ai: trttiInstanceType;
  J: String;
  {$IFDEF fwfmx}
    f: tvalue;
  {$ENDIF}
  {$IFDEF fwvcl}
     f: tvalue;
  {$ENDIF}
  {$IFDEF fwweb}
     f: tvalue;
  {$ENDIF}



 begin
  ai:=atype.asinstance;
    j:=aJson;


  {$IFDEF fwWeb}
    alog.send('atype', atype.QualifiedName);
     alog.send('atype', atype);
    alog.send('ai', ai.QualifiedName);
    alog.send('ai', ai);



    alog.send('ai.basetype', ai.basetype.QualifiedName);
     alog.send('ai.basetype', ai.basetype);

     alog.send('atype', atype.getmethods);
     alog.send('ai', ai.getmethods);
     alog.send('ai.basetype', ai.basetype.getmethods);



   // if ai.basetype.met then   }

//      f:= ai.GetMethod('create$1').Invoke(ai.MetaclassType,[]);
    f:= ai.basetype.GetMethod('create$1').Invoke(ai.MetaclassType,[]);
    
     aobject:=f.asobject;
    //  console.log('xjo', aobject);
  {$ENDIF}
  {$IFDEF fwvcl}
     f:= ai.basetype.GetMethod('create').Invoke(ai.MetaclassType,[]);
      aobject:=f.asobject;
  {$ENDIF}
  {$IFDEF fwfmx}
     f:= ai.basetype.GetMethod('create').Invoke(ai.MetaclassType,[]);
      aobject:=f.asobject;
  {$ENDIF}

     xjo(aobject, j);


 end;

  {$IFDEF fwweb}

{  function xjv<T>(aValue: jsValue): T;
  begin

  end;    }


   function JOJ(sJO: TJSObject): string;
 begin
  result:=tjsJson.stringify(sJO);
 end;

    function xjO(aObject: tobject;aJson: tJSObject): string;  overload;

    begin
     result:=xjo(aObject, JOJ(aJson));

    end;

 function xjO(var aObject: tobject; aclassName: string; aJson: tJSObject): string;  overload;
  begin
      result:=xjo(aobject, aClassName, JOJ(aJson));
  end;

 function xjO(var aObject: tobject; aclass: tClass; aJson: tJSObject): string;  overload;
  begin
     result:=xjo(aobject, aClass, JOJ(aJson));
  end;

 function xjO(var aObject: tobject; aType: tRTTIType; aJson: tJSObject): string;  overload;
 begin
  result:=xjo(aobject, atype, JOJ(aJson));
 end;

 {$ENDIF}

function xj(aobject: tobject; anoClass: boolean=true): string; overload;
var
 ops: txjOps;
begin
 ops:=xjson.GetOps(aObject);
 ops.NoClassType:=anoClass;
 result:=ops.json(aobject).json;

end;
function xjtoObject(j: string; aobject: tobject; anoClass: boolean=true): boolean;
var
 ops: txjOps;
begin
try
 ops:=xjson.GetOps;
 ops.NoClassType:=anoClass;
 ops.aObject:=aObject;
 ops.LoadFromJson(aObject, j) ;
 result:=true;
except
 on e: exception do
 begin
   result:=false;
 end;

end;
end;


    {$IFDEF fwweb}
     function xjFNtoObject(afn: string; aobject: tobject): boolean;
     begin

     end;
  {$ELSE}
 function xjFNtoObject(afn: string; aobject: tobject): boolean;
var
 j: string;
begin
 try
 result:=false;
 if fileexists(afn) then
 begin
   j:=tFile.ReadAllText(afn);
   result:=xjToObject(j, aobject);
 end;
 except
  on e: exception do
  begin
    result:=false;
  end;

 end;
end;
   {$ENDIF}



{
function xJsonInc(aObject: tobject; aProps: TTMSFNCExcludePropertyListArray): string;

var
  p: TTMSFNCPersistence;
  s: tStringStream;
  Writer: TTMSFNCWriter;
{$IFDEF WEBLIB}
 { d, t: string;
{$ENDIF}
//{$IFNDEF WEBLIB}
{  d, t: char;
{{$ENDIF}
{begin
  s := tStringStream.create(''{$IFDEF WEBLIB}//, TEncoding.Ansi{$ENDIF});
 { p := TTMSFNCPersistence.create;
  try
    // ep := p.ExcludeProperties;
    p.ExcludeProperties := [];
    p.AMSaveSettingsToStream(aObject, 2, aProps, xJsonLog, xJsonLogOnlyExcluded, xJsonLogEvents, s);
    // p.ExcludeProperties := ep;
    Result := s.DataString;
  finally
    p.Free;
    s.Free;
  end;
  exit;

 { // s := tStringStream.Create(''{$IFDEF WEBLIB}{, TEncoding.Ansi{$ENDIF}//);
  {
    Writer := TTMSFNCWriter.Create(s);
    writer.IncProps:=aProps;
    writer.Mode:=2;
    writer.log:=true;
    t := FormatSettings.ThousandSeparator;
    d := FormatSettings.DecimalSeparator;
    try
    Writer.IOReference := TTMSFNCPersistence.IOReference;
    Writer.RootObject := TTMSFNCPersistence.RootObject;
    Writer.OnCustomWriteProperty := nil;
    FormatSettings.DecimalSeparator := '.';
    FormatSettings.ThousandSeparator := ',';
    Writer.Write(aobject);
    finally
    FormatSettings.DecimalSeparator := d;
    FormatSettings.ThousandSeparator := t;
    result := s.datastring;
    alog.send('Log', writer.LogText);
    Writer.Free;
    end; }
//end;

{function xJsonEx(aObject: tobject; aProps: TTMSFNCExcludePropertyListArray): string;

var
  p: TTMSFNCPersistence;
  s: tStringStream;
  Writer: TTMSFNCWriter;
{$IFDEF WEBLIB}
 { d, t: string;
{$ENDIF}
{{$IFNDEF WEBLIB}
 { d, t: char;
{{$ENDIF}
{begin
  s := tStringStream.create(''{$IFDEF WEBLIB}//, {TEncoding.Ansi{$ENDIF}//);
 { p := TTMSFNCPersistence.create;
  try
    // ep := p.ExcludeProperties;
    p.ExcludeProperties := [];
    p.AMSaveSettingsToStream(aObject, 1, aProps, xJsonLog, xJsonLogOnlyExcluded, xJsonLogEvents, s);
    // p.ExcludeProperties := ep;
    Result := s.DataString;
  finally
    p.Free;
    s.Free;
  end;
  exit;




  // s := tStringStream.Create(''{$IFDEF WEBLIB}//,{ {TEncoding.Ansi{$ENDIF}//);

  { Writer := TTMSFNCWriter.Create(s);
    writer.ExProps:=aProps;
    writer.Mode:=1;
    writer.log:=true;
    t := FormatSettings.ThousandSeparator;
    d := FormatSettings.DecimalSeparator;
    try
    // Writer.IOReference := TTMSFNCPersistence.IOReference;
    // Writer.RootObject := TTMSFNCPersistence.RootObject;
    //Writer.OnCustomWriteProperty := nil;
    FormatSettings.DecimalSeparator := '.';
    FormatSettings.ThousandSeparator := ',';
    Writer.Write(aobject);
    alog.Send('S Size', s.Size);
    alog.Send('s', s.datastring);
    finally
    FormatSettings.DecimalSeparator := d;
    FormatSettings.ThousandSeparator := t;
    result := s.datastring;
    alog.send('Log', writer.LogText);
    Writer.Free;
    end; }
//end;

{
  function xJson(aobject: tobject): string;

  var
  p: TTMSFNCPersistence;
  s: tStringStream;
  Writer: TTMSFNCWriter;
  {$IFDEF WEBLIB }
// d, t: string;
// {$ENDIF}
// {$IFNDEF WEBLIB}
// d, t: Char;
// {$ENDIF}
// begin
// s := tStringStream.Create(''{$IFDEF WEBLIB}, TEncoding.Ansi{$ENDIF});
// p := TTMSFNCPersistence.Create;
// try
// ep := p.ExcludeProperties;
// p.ExcludeProperties := [];
// p.AMSaveSettingsToStream(aobject,0,[],xJsonLog, xJsonLogOnlyExcluded,xJsonLogEvents, s );
// p.ExcludeProperties := ep;
// Result := s.DataString;
{ finally
  p.Free;
  s.Free;
  end;
  exit;

  {Writer := TTMSFNCWriter.Create(s);
  writer.mode:=0;
  writer.log:=true;
  t := FormatSettings.ThousandSeparator;
  d := FormatSettings.DecimalSeparator;
  try
  Writer.IOReference := TTMSFNCPersistence.IOReference;
  Writer.RootObject := TTMSFNCPersistence.RootObject;
  Writer.OnCustomWriteProperty := nil;
  FormatSettings.DecimalSeparator := '.';
  FormatSettings.ThousandSeparator := ',';
  Writer.Write(aobject);
  finally
  FormatSettings.DecimalSeparator := d;
  FormatSettings.ThousandSeparator := t;
  result := s.datastring;

  Writer.Free;
  end; }
// end;

{ tXJOps }

function tXJOps.AddItem(aJson: string; aObject: tobject; aname: string): txjItem;
var
  aitem: txjItem;
  ac: tcontrol;
begin
 aitem:=GetItem(aObject);



  aitem.json := aJson;
  if aname<>'' then aitem.name := aname;
  aitem.aObject := aObject;
  aitem.XJO:=self;
  aitem.IsComp := aObject is tcontrol;

  aitem.classname := aObject.classname;
  if aitem.IsComp then
  begin
    ac := tcontrol(aObject);
    if ac.name <> '' then
     //if aname='' then
       if aitem.name='' then

      aitem.name := ac.name;

    if ac.Parent <> nil then
      aitem.ParentName := ac.Parent.name;
    if ac.Owner <> nil then
      aitem.OwnerName := ac.Owner.name;
  end;

  //if keeplist then Items.Add(aitem);
  Result := aitem;
  AfterAdd(aitem);
end;

procedure tXJOps.AfterAdd(aitem: txjItem);
begin

end;

procedure tXJOps.ap(aPropName: string);
var
  tails: tstringlist;
begin
  slp.Add(checkProp(aPropName));
  { if mode=xjinc then
    begin
    tails:=txStr.sep(aPropName, dot);
    if tails.count<>0 then slp.addstrings(tails);
    end; }

end;

function tXJOps.apd: integer;
var
  ctx: TRttiContext;
  objType: TRttiType;
  Prop: TRttiProperty;
begin
 result:=0;
 if aobject=nil then exit;

  ctx := TRttiContext.Create;
  objType := ctx.GetType(aobject.classinfo);
   for Prop in objType.GetProperties do
   begin
     if prop.PropertyType.TypeKind <>tkMethod then
     begin
       ap(prop.Name);
       inc(result);

     end;

   end;

end;

function tXJOps.ApFrom(aPropname: string; aobject: tobject): tStringList;
var
  ctx: TRttiContext;
  objType: TRttiType;
  Prop: TRttiProperty;
begin
 result:=tStringlist.create;
 if aobject=nil then exit;

  ctx := TRttiContext.Create;
  objType := ctx.GetType(aobject.classinfo);
   for Prop in objType.GetProperties do
   begin
     if prop.PropertyType.TypeKind <>tkMethod then
     begin
       ap(aPropname + '.' +prop.Name);
        result.Add(aPropname + '.' + prop.name);

     end;

   end;

end;

procedure tXJOps.apo(aPropName: string);
begin
  ap(checkProp(aPropName + '*'));
end;

function tXJOps.checklist: tstringlist;
var
  I: integer;
  tails: tstringlist;
  aPropName: string;
begin
  slpFinal.clear;
  slpFinal.AddStrings(slp);
  slpFinal.Delimiter := dot;
  if mode = xjinc then
  begin
    tails := txStr.Spider(slpFinal);
    if tails.count <> 0 then
      slpFinal.AddStrings(tails);

    { for i := 1 to slpfinal.count do
      begin
      aPropName:=slpFinal[i-1];
      tails:=txStr.sep(aPropName, dot);
      if tails.count<>0 then slpfinal.addstrings(tails);
      end; }
  end;
  if slpFinal.indexof('root') <> -1 then
    slpFinal.delete(slpFinal.indexof('root'));

  Result := slpFinal;
  //alog.send('slpFinal', slpFinal);
end;

function tXJOps.checkProp(aPropName: string): String;
var
  s: string;
begin
  s := lowercase(aPropName);
  if pos('root.', s) <> 1 then
    aPropName := 'root.' + aPropName;
  Result := aPropName;
end;

procedure tXJOps.clear;
begin
  Items.clear;
end;

       {$IFDEF fwweb}

  {$ELSE}
 constructor tXJOps.create(aaClass: tClass);
begin
  aClass := aaClass;
  create(nil);
end;
   {$ENDIF}



constructor tXJOps.create(aaObject: tobject);
begin
  aObject := aaObject;
  slp := tstringlist.create;
  slp.Sorted := true;
  slp.Duplicates := tDuplicates.dupIgnore;
  slp.CaseSensitive := false;
   proplist:=tstringlist.Create;
  slpFinal := tstringlist.create;
  slpFinal.Sorted := true;
  slpFinal.Duplicates := dupIgnore;
  slpFinal.CaseSensitive := false;
  Items := txjItems.create;
  Items.OwnsObjects := false;
  log := tstringlist.create;
  Keeplist:=true;
  UseMeta:=true;
  mode:=txjmode.xjEx;
end;

procedure tXJOps.dolog(s: string);
begin
  alog.send(s);
  if xJsonLog then
    log.Add(s);

end;

function tXJOps.GetItem(aaObject: tobject): txjItem;
var
 aItem: txjItem;
begin
 Result:=nil;
 for aItem in items do
   begin
     if aitem.aobject=aaobject then
     begin
       result:=aitem;
       exit;
     end;
   end;
   result:=txjItem.create;
   result.aobject:=aaObject;
   Items.add(result);
end;

function tXJOps.GetLogfn: string;
begin

    {$IFDEF fwweb}

  {$ELSE}
  Result:=combinepaths(Path.EXEPATH, 'ixLogs', '\');
 forceDirectories(result);
 result:=Combinepaths(result, 'Last.txt' ,'\');
   {$ENDIF}



end;

function tXJOps.getModes: string;
begin

end;

function tXJOps.GetSaver: tXJSaver;
begin

  if fSaver = nil then
  begin

     {$IFDEF fwWeb}
fSaver := txjSaverweb.create;
    fSaver.Items := Items;
    alog.send('Created Web saver', fSaver);
 {$IFEND}
   {$IFDEF fwvcl}
fSaver := txjSavervcl.create;
    fSaver.Items := Items;
    alog.send('Created VCL saver', fSaver);
 {$IFEND}


    {$IFDEF fwfmx}
  // fSaver := txjSaverweb.create;
   // fSaver.Items := Items;
   // alog.send('Created saver', fSaver);
 {$IFEND}



  end;
  Result := fSaver;

end;

function tXJOps.json(aaObject: tobject; aname: string): txjItem;
var
  p: xPersistence;
  s: tStringStream;
  aitem: txjItem;
  logResult: tstringlist;
begin
  if aaObject = nil then
    aaObject := aObject;
  if aaObject = nil then
    exit;
  s := tStringStream.create(''{$IFDEF WEBLIB}, TEncoding.Ansi{$ENDIF});
  p := xPersistence.create;


  if noClassType then   p.ClassTypeVariable:='';

  try
    // ep := p.ExcludeProperties;
    p.ExcludeProperties := [];
    MakeArray;

    LogBefore('json');
    logResult := p.AMSaveSettingsToStream(aaObject, ord(mode), props, xJsonLog, xJsonLogOnlyExcluded, xJsonLogEvents, xJsonExCOmps, s);

    if xJsonLog then
      alog.send('XJWRITERlog', logResult);
    proplist.Assign(p.proplist);
    // p.ExcludeProperties := ep;
    // Result := s.DataString;
    fJson := s.DataString;
    if aname = '' then
      aname := Inttostr(Items.count);
    Result := AddItem(s.DataString, aaObject, aname);
    result.proplist:=tstringlist.create;
    result.proplist.Assign(p.proplist);
    REsult.ParseLog:=tStringlist.create;
    result.ParseLog.AddStrings(LogResult);
    LogAfter('json');
    //LogResult.SaveToFile(GetLogFN);
    { aItem:=txjItem.Create;
      aitem.json:=s.DataString;
      aitem.aObject:=aaObject;
      aitem.name:=aname;
      items.Add(aitem);
      result:=aItem; }
  finally
    p.Free;
    s.Free;
  end;

end;

{function tXJOps.JsonS(aaObject: tobject = nil; aname: string = ''): string;
var
  p: xPersistence;
  s: tStringStream;
  aitem: txjItem;
  logResult: tstringlist;
begin
  if aaObject = nil then
    aaObject := aObject;
  if aaObject = nil then
    exit;
  s := tStringStream.create(''{$IFDEF WEBLIB}//, TEncoding.Ansi{$ENDIF});
 { p := xPersistence.create;
  try
    // ep := p.ExcludeProperties;
    p.ExcludeProperties := [];
    MakeArray;
    logResult := p.AMSaveSettingsToStream(aaObject, ord(mode), props, xJsonLog, xJsonLogOnlyExcluded, xJsonLogEvents,xJsonExComps, s);
    if xJsonLog then
      alog.send('xJwriterLog', logResult);

    // p.ExcludeProperties := ep;
    Result := s.DataString;
    fJson := Result;
    if aname = '' then
      aname := Inttostr(Items.count);
    aitem := txjItem.create;
    aitem.json := Result;
    aitem.aObject := aaObject;
    aitem.name := aname;
    Items.Add(aitem);
  finally
    p.Free;
    s.Free;
  end;

end;   }

//toDO: temove JsonS or whats its doing. Update Loadobject as below.
function tXJOps.LoadFromJson(aaObject: tobject; j: string): string;
var
  Item: txjItem;
     gc: boolean;
  s: tStringStream;
  savedCV: string;
  SavedIO: tobject;
  p: xPersistence;
   logResult: tstringlist;
begin
  try

    if j <> '' then
    begin
    try
     p := xPersistence.create;
     SavedCV:=XPersistence.ClassTypeVariable;
     SavedIO:=xpersistence.IOReference;
     p.RootObject:=aaObject;

      if NoClassType then

      p.ClassTypeVariable:='';
    p.IOReference := aaObject;
     // Item := txjItem.create;
     // Item.aObject := aaObject;
     Item:=GetItem(aaObject);
      Item.json := j;
      p.ExcludeProperties := [];
    MakeArray;


      //if item.name='' then Item.name := afn;
      //Items.Add(Item);
      gc:=gExcludeComponents;
      gExcludeComponents:=false;
        logBefore('LoadFromJson');
      s := tStringStream.create(j{$IFDEF WEBLIB}, TEncoding.Ansi{$ENDIF});
      s.Seek(0, soFromBeginning);


      LogResult:=p.amLoadSettingsFromStream(aaObject, ord(mode), props, xJsonLog, xJsonLogOnlyExcluded, xJsonLogEvents, xJsonExCOmps, s);

         if xJsonLog then
      alog.send('xjREADERlog', logResult);
      p.ClassTypeVariable:=savedCV;
      p.IOReference:=SavedIO;
      // TTMSFNCPersistence.LoadSettingsFromFile(aaobject, afn);
      Result := j;
      gExcludeComponents:=gc;
          logAfter('LoadFromJson');
    finally
      p.Free;
      s.Free;
    end;

    end
    else
      alog.error(' tXJOps.LoadObject - no json');
  except
    on e: exception do
    begin
      alog.error(' tXJOps.LoadObject', e.message);
      Result := '';
    end;
  end;
end;

function tXJOps.LoadMeta(afn: string): integer;
begin
 result:=saver.LoadMeta(afn);
end;
                     //needs work
function tXJOps.LoadObject(aaObject: tobject; afn: string; aname: string = ''): String;
var
  Item: txjItem;
  j: string;
  s: tStringStream;
begin
  try
    j := Saver.LoadItemFromFile(afn, aname);
    if j <> '' then
    begin
     // Item := txjItem.create;
     // Item.aObject := aaObject;
     Item:=GetItem(aaObject);
      Item.json := j;
      //if item.name='' then Item.name := afn;
      //Items.Add(Item);
      s := tStringStream.create(j{$IFDEF WEBLIB}, TEncoding.Ansi{$ENDIF});
      s.Seek(0, soFromBeginning);
      if noClassType then xpersistence.ClassTypeVariable:='';
      xPersistence.LoadSettingsFromStream(aaObject, s);
      // TTMSFNCPersistence.LoadSettingsFromFile(aaobject, afn);
      Result := j;
    end
    else
      alog.error(' tXJOps.LoadObject - no json');
  except
    on e: exception do
    begin
      alog.error(' tXJOps.LoadObject', e.message);
      Result := '';
    end;
  end;
end;

procedure tXJOps.LogAfter(ainfo: string);
begin
  alog.Send('END OPERATION ' + aInfo);
end;

procedure tXJOps.LogBefore(ainfo: string);
begin

    {$IFDEF fwweb}

  {$ELSE}
      alog.Send('START OPERATION ' + aInfo);
 alog.Send('mode', alog.enum<tXJMode>(mode));
 //alog.send('inc/ex props', string.join(',',props));
 alog.Send('Props', props);
   {$ENDIF}



end;

function tXJOps.MakeArray:XExcludePropertyListArray;
var
  I: integer;
  s: String;
begin
  checklist;
  SetLength(Fprops, slpFinal.count);
  for I := 1 to slpFinal.count do
    props[I - 1] := slpFinal[I - 1];

end;

procedure tXJOps.reset;
begin
  clear;
  slp.clear;
  props := [];
end;

procedure tXJOps.SaveAll(afn: string);
var
 aItem: txjItem;
begin
 for aitem in items do
   json(aitem.aobject, aitem.name);
  if afn = '' then
    Saver.SaveAllToFiles
  else
    Saver.SaveAllToSingleFile(afn);

end;

function tXJOps.SaveObject(aaObject: tobject; afn: string = ''; aname: string = ''): string;
var
  Item: txjItem;
begin
  item:=json(aaObject, aname);
  //Item := Items[Items.count - 1];
  Saver.SaveItemToFile(Item, afn, aname);
end;

{ TXjSON }

constructor TXjSON.create;
begin
  fOpsClasses := tXJOptionsClasses.create;
  FOpsObjects := tXJOptionsObjects.create;

end;

function TXjSON.GetOps(aClass: tClass): tXJOps;
begin
  if OpsClasses.TryGetValue(aClass, Result) = false then
  begin
    Result := tXJOps.create;
    OpsClasses.Add(aClass, Result);
  end;

end;

function TXjSON.GetOps(aObject: tobject): tXJOps;
begin
  if OpsObjects.TryGetValue(aObject, Result) = false then
  begin
    Result := tXJOps.create(aObject);
    OpsObjects.Add(aObject, Result);
  end;

end;

function TXjSON.GetOps: tXJOps;
begin
  Result := tXJOps.create;
end;

initialization

xJson := TXjSON.create;
xjGlobals:=txjGLobalsettings.create;
end.
