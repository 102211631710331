unit uMain;

interface

//{$INCLUDE project.inc}

uses
  System.SysUtils, System.Classes, xweb.webman, JS, Web, WEBLib.Graphics, WEBLib.Controls, WEBLib.Menus, WEBLib.StdCtrls, WEBLib.ExtCtrls, WEBLib.WebCtrls,
  WEBLib.Forms, WEBLib.Dialogs, x.fs, x.fs.types,  x.cfg.classes,
  Vcl.Controls, Vcl.StdCtrls, rtti, xweb.xdata.client, xdata.Web.Connection,
  VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics,
  VCL.TMSFNCGraphicsTypes, VCL.TMSFNCCustomControl, VCL.TMSFNCTreeViewBase,
  VCL.TMSFNCTreeViewData, VCL.TMSFNCCustomTreeView, VCL.TMSFNCTreeView, system.Generics.Collections,
  VCL.TMSFNCCustomComponent, VCL.TMSFNCBitmapContainer, VCL.TMSFNCSplitter,
  VCL.TMSFNCCustomScrollControl, VCL.TMSFNCListEditor, Vcl.Menus,  x.icons.all,
  VCL.TMSFNCPanel,
  VCL.TMSFNCNavigationPanel, VCL.TMSFNCProgressBar,
  VCL.TMSFNCObjectInspector, VCL.TMSFNCToolBar, x.fs.client, xweb.cookies, types,
  x.ao,  x.dateUtils, x.fs.client.tv,
  VCL.TMSFNCHTMLText, VCL.TMSFNCLabelEdit, VCL.TMSFNCListBox,
  VCL.TMSFNCEdit,
  xweb.forms, uinfo, Vcl.Imaging.pngimage,
  VCL.TMSFNCImage, x.json, x.login.WebForm, x.login.client, x.login.types, x.login.userbox,  x.css, x.status, x.xdata.connect, x.controls,
  x.css.boxShadow, x.css.slide, VCL.TMSFNCTabSet,xf.test, liblogtest;

type
  tFolders = tDictionary<string, ttmsfncTreeViewnode>;
type
  TMF = class(TForm)
    pm: TPopupMenu;
    miConnect: TMenuItem;
    miGetList: TMenuItem;
    miTree: TMenuItem;
    Showtimer: TTimer;
    bc: TTMSFNCBitmapContainer;
    pmtvr: TPopupMenu;
    pmTV: TPopupMenu;
    miServer: TMenuItem;
    pConnect: TPanel;
    SortDirs1: TMenuItem;
    GetFilesd1: TMenuItem;
    WebTimer1: TTimer;
    pEdt: TPanel;
    edt: TTMSFNCLabelEdit;
    lb: TTMSFNCListBox;
    lbServers: TTMSFNCListBox;
    bcOK: TButton;
    bcCancel: TButton;
    eServer: TTMSFNCEdit;
    WebLabel1: TLabel;
    lbcError: TLabel;
    pmain: THTMLDiv;
    ptv: THTMLDiv;
    ptvr: THTMLDiv;
    pd: THTMLDiv;
    spl: TTMSFNCSplitter;
    spr: TTMSFNCSplitter;
    tvr: TTMSFNCTreeView;
    tv: TTMSFNCTreeView;
    pnlD: TPanel;
    lbSel: TLabel;
    ix: TTMSFNCObjectInspector;
    ps: THTMLDiv;
    bUpload: TButton;
    fp: TFilePicker;
    ptop: THTMLDiv;
    ph: THTMLDiv;
    es: TEdit;
    tbLeft: TTMSFNCToolBarButton;
    tbup: TTMSFNCToolBarButton;
    tbRight: TTMSFNCToolBarButton;
    lbSubSel: TLabel;
    sb: TPanel;
    lbBottom: TLabel;
    tbl: THTMLDiv;
    tbTree: TTMSFNCToolBarButton;
    tbList: TTMSFNCToolBarButton;
    tbDetail: TTMSFNCToolBarButton;
    bcfg: TTMSFNCToolBarButton;
    imgUser: TTMSFNCImage;
    buser: TTMSFNCToolBarButton;
    pdh: TPanel;
    pdLink: TLinkLabel;
    pdIcon: TTMSFNCImage;
    pdBtnDetail: TTMSFNCImage;
    pdView: TPanel;
    tempdiv: THTMLDiv;
    tabs: TTMSFNCTabSet;
    pmEmpty: TPopupMenu;
    procedure WebFormCreate(Sender: TObject);
    [Async]
    procedure bcallClick(Sender: TObject); async;
    procedure bDetailsClick(Sender: TObject);
    [Async]
    procedure blistClick(Sender: TObject); async;
    [ASync] procedure bUploadClick(Sender: TObject); async;
    procedure lbSubSelClick(Sender: TObject);
    procedure miTreeClick(Sender: TObject);
    procedure pdoldResize(Sender: TObject);
    procedure plItemClick(Sender: TObject; AItemIndex: Integer);
    procedure pmPopup(Sender: TObject);
    procedure ptopoldResize(Sender: TObject);
    procedure spdDblClick(Sender: TObject);
    procedure spdMouseDown(Sender: TObject; Button: TMouseButton; Shift:
      TShiftState; X, Y: Integer);
    procedure tvNodeClick(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode);
    procedure tvNodeDblClick(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode);
    procedure tvrGetNodeColor(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode;
      var AColor: TTMSFNCGraphicsColor);
    procedure tvrGetNodeTextColor(Sender: TObject; ANode:
      TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATextColor:
      TTMSFNCGraphicsColor);
    procedure tvrMouseDown(Sender: TObject; Button: TMouseButton; Shift:
      TShiftState; X, Y: Integer);
    procedure tvrMouseLeave(Sender: TObject);
    procedure tvrMouseUp(Sender: TObject; Button: TMouseButton; Shift: TShiftState;
      X, Y: Integer);
    procedure tvrNodeDblClick(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode);
    procedure tvrNodeMouseEnter(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode);
    procedure tvrNodeMouseLeave(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode);
    [Async]
    procedure WebButton1Click(Sender: TObject); async;
    [Async]
    procedure bcCancelClick(Sender: TObject); async;
    [Async] procedure bcfgClick(Sender: TObject); async;
    procedure bcOKClick(Sender: TObject);
    [Async]
    procedure buserClick(Sender: TObject); async;
    procedure edtEditEnd(Sender: TObject);
    procedure edtEditStart(Sender: TObject);
    procedure eServerKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
    [Async]
    procedure WebButton3Click(Sender: TObject); async;
    [Async]
    procedure WebButton4Click(Sender: TObject); async;
    procedure esKeyUp(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure GetFilesd1Click(Sender: TObject);
    [Async] procedure imgUserClick(Sender: TObject); async;
    [Async] procedure lbSelDblClick(Sender: TObject); async;
    procedure lbServersItemClick(Sender: TObject; AItem: TTMSFNCListBoxItem);
    procedure lbServersItemDblClick(Sender: TObject; AItem: TTMSFNCListBoxItem);
    procedure miServerClick(Sender: TObject);
    procedure pdBtnDetailClick(Sender: TObject);
    procedure ptvoldResize(Sender: TObject);
    procedure ShowtimerTimer(Sender: TObject);
    procedure SortDirs1Click(Sender: TObject);
    procedure spClick(Sender: TObject);
    procedure spDblClick(Sender: TObject);
    procedure spdClick(Sender: TObject);
    procedure sprMouseUp(Sender: TObject; Button: TMouseButton; Shift: TShiftState;
      X, Y: Integer);
    procedure tbDetailClick(Sender: TObject);
    procedure tbListClick(Sender: TObject);
    procedure tbTreeClick(Sender: TObject);
    procedure tvAfterSelectNode(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode);
    procedure tvGetNodeColor(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode;
      var AColor: TTMSFNCGraphicsColor);
    procedure tvGetNodeHeight(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode;
      AColumn: Integer; var AHeight: Double);
    procedure tvGetNodeTextColor(Sender: TObject; ANode:
      TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATextColor:
      TTMSFNCGraphicsColor);
    procedure tvMouseLeave(Sender: TObject);
    procedure tvMouseUp(Sender: TObject; Button: TMouseButton; Shift: TShiftState;
      X, Y: Integer);
    procedure tvNodeMouseEnter(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode);
    procedure tvNodeMouseLeave(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode);
    procedure tvrGetNodeHeight(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode;
      AColumn: Integer; var AHeight: Double);
    procedure WebFormDOMContentLoaded(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure WebTimer1Timer(Sender: TObject);
    procedure wlClick(Sender: TObject);
  private
    FrootFolder: string;
    FrootURL: string;
    Furl: string;
    Fnode: ttmsfncTreeViewnode;
    FShowFilesInTree: boolean;
    FshowTV: boolean;
    FSelFile: tbsFile;
    FlastClick: ttmsfncTreeViewNode;
    FMouseNode: ttmsfncTreeViewNode;
    FShowDetails: boolean;
    FSearchMode: boolean;
    FserverURI: string;
    FTreeMouseNode: ttmsfncTreeViewNode;
    FShowConnect: boolean;
    { aXObjectDictionary:  XObjectDictionary;
   aXObjectList:XObjectList;
  aXStringList: XStringList;
  axIntegerList: XIntegerList;
  axDoubleList: XDoubleList ;    }
    sm: TTMSFNCTreeViewNodesSortMode;
    FedtExpanded: boolean;
    FlbFirst: ttmsfncListBoxItem;
    Fstext: string;
    Fshowing: boolean;
    FShowTimerDone: boolean;
    // function getsvc: ifs;
    procedure setURL(const Value: string);
    procedure SetNode(const Value: ttmsfncTreeViewnode);
    procedure SetShowFilesInTree(const Value: boolean);
    procedure SetShowTV(const Value: boolean);
    procedure SetSelFile(const Value: tbsFile);
    procedure SetShowDetails(const Value: boolean);
    procedure SetSearchMode(const Value: boolean);
    procedure SetShowConnect(const Value: boolean);
    procedure SedEdtExpanded(const Value: boolean);
    function GetSText: string;
    { Private declarations }
  public
    xdc: txdatawebConnection;
    xdClient: txxdatawebclient;
    fsvc: ifs;
    fS: txfs;
    FList: tbsFileList;
    Folders: tfolders;
    PathMaps: tStringList;
    cookies: txCookies;
    ServerList: tstringlist;
    Icons: txIcons;
    tt: txTime;
    fstv: txFSTV;
    userbox: txUserBox;
    StatusBar: txStatusBar;
    procedure LoginReady(sender: tobject);
    procedure UserLogin(aUser: txUser);
    procedure UpdateSearchList;
    function NavBtn: ttmsfncToolBarButton;
    procedure NavBtnClick(sender: tobject);
    procedure fsStatus(aStatus: txDataStatus);
    procedure edtChange(Sender: TObject; var Key: Word; Shift: TShiftState);
    [Async] procedure PathChanged(sender: txfsTV; S: string); async;
    [Async]
    //    function GetDir: string;
    procedure UpdateTV; async;
    [ASync]
    procedure GetFiles; async;
    procedure xConnected(Sender: TObject);
    procedure xError(acode: integer; aMsg: string; aobject: tobject);
    procedure xRequest(Args: TXDataWebConnectionRequest);
    procedure xResponse(Args: TXDataWebConnectionResponse);
    //  function Service<T>: T;
     // procedure GetServiceInterface(p: Pointer; guid: tGuid; iName: string; out Intf);
     // property svc: ifs read getsvc;
    property rootFolder: string read FrootFolder write FrootFolder;
    property rootURL: string read FrootURL write FrootURL;
    property url: string read Furl write setURL;
    property node: ttmsfncTreeViewnode read Fnode write SetNode;
    property ShowFilesInTree: boolean read FShowFilesInTree write SetShowFilesInTree;
    property showTV: boolean read FshowTV write SetShowTV;
    property SelFile: tbsFile read FSelFile write SetSelFile;
    property lastClick: ttmsfncTreeViewNode read FlastClick write FlastClick;
    property MouseNode: ttmsfncTreeViewNode read FMouseNode write FMouseNode;
    property TreeMouseNode: ttmsfncTreeViewNode read FTreeMouseNode write FTreeMouseNode;
    property ShowDetails: boolean read FShowDetails write SetShowDetails;
    property SearchMode: boolean read FSearchMode write SetSearchMode;
    property serverURI: string read FserverURI write FserverURI;
    property ShowConnect: boolean read FShowConnect write SetShowConnect;
    property edtExpanded: boolean read FedtExpanded write SedEdtExpanded;
    property lbFirst: ttmsfncListBoxItem read FlbFirst write FlbFirst;
    property stext: string read GetSText write Fstext;
    property showing: boolean read Fshowing write Fshowing;
    property ShowTimerDone: boolean read FShowTimerDone write FShowTimerDone;

  protected procedure LoadDFMValues; override; end;

var
  MF: TMF;

implementation

{$R *.dfm}

procedure TMF.SedEdtExpanded(const Value: boolean);
begin
  FedtExpanded := Value;
  if value = true then
    pedt.height := Trunc(height / 2)
  else
    pedt.height := pedt.tag;
  lb.Visible := value;

end;

{function TMF.Service<T>: T;
var
  tp: TRttiInterfaceType;
  rt: tRTTIContext;
begin

  rt := tRTTIContext.create;
  tp := TRttiInterfaceType(rt.GetType(typeInfo(T)));
  // console.Log('T', tp.handle.name);
  GetServiceInterface(typeInfo(T), tp.guid, tp.handle.name, Result);

end;     }

procedure TMF.SetNode(const Value: ttmsfncTreeViewnode);
  procedure doNodes(anodes: ttmsfncTreeViewNodes);
  var
    i: integer;
    n, n2: ttmsfncTreeViewNode;
    afile: tbsfile;
    doItem: boolean;
  begin

    for i := 1 to anodes.count do

    begin

      n := anodes[i - 1] ;
      if searchmode = false then
        doItem := true
      else
      begin
        if assigned(n.VirtualNode) = false then
          doItem := false
        else
          DoItem := n.virtualnode.Visible;

      end;
      if doItem then
      begin
        n2 := tvr.nodes.add;
        n2.text[0] := n.text[0] ;
        n2.CollapsedIconNames[0, false] := n.CollapsedIconNames[0, false] ;
        n2.exPandedIconNames[0, false] := n.exPandedIconNames[0, false] ;
        n2.DataString := n.DataString;

        if assigned(n.DataObject) then
        begin
          n2.DataObject := n.DataObject;
          if n.DataObject is tbsFile then
          begin
            afile := tbsfile(n.dataobject);
            n2.text[1] := afile.ext;
            n2.text[2] := afile.writetimes;
            n2.text[3] := '0';
          end;
        end
        else
        begin
          n2.dataobject := n;
          n2.text[1] := 'Folder';
          n2.text[3] := '1';

        end;
      end;

      //if searchMode then doNodes(n.nodes);
    end;

  end;
var
  upNode, pnode, n: ttmsfncTreeViewNode;
  afile: tbsFile;
  i: integer;
begin
  aFile := nil;
  if not assigned(value) then exit;

  // if tvr.DataObject=value then exit;

  if assigned(value.DataObject) then
  begin
    if value.DataObject is tbsfile then
    begin
      afile := tbsFile(value.dataobject);
    end;

    pnode := value.GetParent;
    if not assigned(pnode.DataObject) then fnode := pnode;

  end
  else

    fnode := value;

  //replaced value with fnode

  if not assigned(fnode.DataObject) then
  begin
    url := fnode.DataString;
    tvr.BeginUpdate;
    tvr.DataObject := fnode;
    tvr.nodes.clear;

    if fnode <> tv.nodes[0] then
    begin
      upNode := tvr.nodes.add;
      upNode.text[0] := '..';

      upNode.DataObject := fnode.getparent;
      upNode.CollapsedIconNames[0, false] := 'Folder';
      upNode.exPandedIconNames[0, false] := 'Folder';
      Upnode.text[3] := '3';
      UpNode.tag := -1;
    end;

    doNodes(fnode.nodes);
    {
    for i := 1 to anode.node.nodes.count do

      begin
        n:=anode.node.nodes[i-1];
         n2:=tvr.nodes.add;
         n2.text[0]:=n.text[0];
         n2.CollapsedIconNames[0, false]:=n.CollapsedIconNames[0, false];
         n2.exPandedIconNames[0, false]:=n.exPandedIconNames[0, false];

         n2.DataObject:=n;
      end;    }
    tvr.sort(3, false, false, nsmDescending, true);
    tvr.endupdate;

    if assigned(afile) then
    begin
      for i := 1 to tvr.nodes.count do
      begin
        n := tvr.nodes[i - 1] ;
        if n.DataObject = afile then tvr.selectnode(n);
      end;

      SelFile := afile;

    end
    else

      SelFile := nil;
    // tvr.SortColumn:=3;

    // tvr.SetFocus;
  end;
end;

procedure TMF.SetSearchMode(const Value: boolean);
begin
  FSearchMode := Value;
  if value = false then
  begin
    node := fnode;
    exit;
  end;
  node := tv.nodes[0] ;

end;

procedure TMF.SetSelFile(const Value: tbsFile);
begin
  FSelFile := Value;

  if assigned(value) then
  begin
    //console.log('Selfile', value);
    //console.log('fn', value.fn);
    pdIcon.bitmaps[0].BitmapName:=fstv.ItemIcon;
    pdlink.Caption:='<a href="' + value.url+'" download="'+fstv.nodefn + '">'+fstv.nodefn+'</a>';
     //  pdlink.Caption:=fstv.node.text[0];
    lbSel.caption := fstv.nodefn;
    lbSubSel.caption := value.url;
    ix.&Object := value;
    showdetails := true;

  end
  else
    ShowDetails := false;
end;

procedure TMF.SetShowConnect(const Value: boolean);
var
  i, idx: integer;
  s: string;
  aitem: ttmsfncListBoxItem;
begin
  FShowConnect := Value;
  if value = true then
  begin
    pConnect.left := trunc((width - pConnect.width) / 2);
    pConnect.top := trunc((height - pConnect.height) / 2);
    if cookies.get('ServerList', s) then ServerList.text := s;
    pConnect.Visible := true;
    pMain.enabled := false;
    ps.enabled := false;

    bcOK.Enabled := true;
    pConnect.BringToFront;
    if serverURI = '' then
      if ServerList.count <> 0 then fServerURI := ServerList[0] ;
    lbservers.items.clear;
    for i := 1 to serverlist.count do
    begin
      aitem := lbServers.items.add;
      aitem.text := ServerList[i - 1] ;

      if lowercase(serverlist[i - 1] ) = lowercase(ServerURI) then
      begin
        lbServers.ItemIndex := aitem.index;
        eServer.text := serverlist[i - 1] ;
      end;

    end;

    //  eServer.text:=ServerURI;
  end
  else
  begin
    pConnect.Visible := false;
    pMain.enabled := true;
    ps.enabled := true;

  end;

end;

procedure TMF.SetShowDetails(const Value: boolean);
begin
  FShowDetails := Value;
  if value = false then
    pd.width := 0
  else
    pd.width := pd.tag;
  tbdetail.DownState := value;
  //  if assigned(fstv.node) then SelFile:=await(fstv.bs);

end;

procedure TMF.SetShowFilesInTree(const Value: boolean);
var
  f: TTMSFNCTreeViewFilterdata;
begin
  FShowFilesInTree := Value;
  if value = false then
  begin
    f := tv.filter.add;
    f.Column := 1;
    f.Condition := '1';
    f.CaseSensitive := false;
    tv.ApplyFilter;
  end
  else
  begin
    if tv.filter.HasFilter(1) then tv.filter.clear;

  end;
end;

procedure TMF.SetShowTV(const Value: boolean);
begin
  FshowTV := Value;

  if value = true then
    ptv.width := ptv.tag
  else
    ptv.width := 0;

end;

procedure TMF.setURL(const Value: string);
var
  i: integer;
  s, v: string;
  //aitem: TTMSFNCCustomListEditorItem;
  bc: integer;
  abtn: ttmsfncToolbarButton;
begin
  // Furl := Value;

      {

     for i := 1 to pb.ControlCount do
        pb.controls[0].destroy;
  //  pb.BeginUpdate;
     abtn:=NavBtn;
     abtn.text:=rootURL;
     abtn.tag:=1;
     // weblabel1.caption:=rootURL;
        // weblabel1.beginupdate;
         //webLabel1.endupdate;
       //  abtn.width:=weblabel1.width+25;

   {  pl.BeginUpdate;
     pl.ItemAppearance.font.size:=11;
   pl.items.clear;}
 {          bc:=1;
   v:=value;
     v:=stringReplace(v, rooturl, '', [rfIgnorecase]);

      //aitem:=pl.items.add;
        // aitem.text:=rooturl;
     //    v:=stringreplace(v, ':\\', '', [rfReplaceAll]);

         if v[1]='\' then v:=copy(v,2, length(v));

   for i := 1 to length(v) do
     begin
       if v[i]<>'/' then s:=s+v[i] else
       begin
        if s<>'' then
        begin
         {aitem:=pl.items.add;
         aitem.text:=s;
         aitem.value:=copy(v,1,i-1);
         aitem.value:=stringReplace(aitem.value, '/','\', [rfIgnoreCase, rfReplaceAll]);
         if aitem.value[1]='\' then aitem.value:=copy(aitem.value,2,length(aitem.value));}
    {     bc:=bc+1;
         abtn:=NavBtn;
         abtn.tag:=bc;
         abtn.text:=s;
       //  weblabel1.caption:=s;
       //  weblabel1.beginupdate;
       //  webLabel1.endupdate;
       //  abtn.width:=weblabel1.width+25;
         abtn.DataString:=copy(v,1,i-1);
         abtn.dataString:=stringReplace(abtn.dataString, '/','\', [rfIgnoreCase, rfReplaceAll]);
         if abtn.dataString[1]='\' then abtn.DataString:=copy(abtn.DataString,2,length(abtn.dataString));
        end;

         s:='';

       end;
     end;
     if s<>'' then
     begin
      {aitem:=pl.items.add;
         aitem.text:=s;}
      {   abtn:=NavBtn;
         abtn.text:=s;
         // weblabel1.caption:=s;
         //weblabel1.beginupdate;
         //webLabel1.endupdate;
         //abtn.width:=weblabel1.width+25;
         abtn.tag:=bc+1;

     end;
     abtn.DownState:=true;
     pb.Realign;
 //    pb.EndUpdate;

    //     pl.SelectedItemIndex:=pl.items.count-1;
      //   pl.EndUpdate;    }

end;

procedure TMF.UpdateSearchList;
begin
  lb.BitmapContainer := fstv.icons.bc;
  lb.ItemsAppearance.font.size := edt.edit.font.size;
  lbfirst.text := sText;
  if fstv.gotPath(stext) = false then
  begin

    lbfirst.bitmapname := fstv.icons.geticon('search2-svg').bn;
  end
  else
  begin
    lbfirst.bitmapname := fstv.itemicon(stext);
  end;
  lb.selecteditem := lbfirst;

end;

procedure TMF.UpdateTV;
var
  afile: tbsFile;
  aList: tStringlist;
  i: integer;
  n, nr: ttmsfnctreeviewnode;
  LastN: ttmsfncTreeViewNode;
  anodes: ttmsfnctreeviewnodes;
  s: string;
  urlpos: string;
  rootNode: ttmsfncTreeViewNode;
begin
  tv.beginUpdate;
  tvr.BeginUpdate;
  Folders.clear;
  anodes := tv.nodes;
  LastN := nil;
  alist := tStringlist.create;
  rootNode := tv.nodes.add;
  rootNode.text[0] := rooturl;
  rootNode.text[1] := '1';
  rootNode.DataString := url;
  rootnode.Extended := true;
  rootnode.ExpandedIconNames[0, false] := 'Server';
  rootnode.CollapsedIconNames[0, false] := 'Server';

  anodes := rootNode.nodes;

  for afile in fList.files do
  begin
    aFile.strip(alist, rootFolder);
    anodes := rootNode.nodes;
    urlPos := rooturl;
    for i := 1 to alist.count do
    begin
      urlPos := urlPos + '/' + alist.ValueFromIndex[i - 1] ;
      if folders.TryGetValue(alist.names[i - 1] , n) = false then
      begin
        n := anodes.add;
        n.datastring := urlPos;
        Folders.add(alist.names[i - 1] , n);
        //  console.log(alist.names[i-1]);
        if i <> alist.count then
        begin
          n.CollapsedIconNames[0, false] := 'Folder';
          n.expandedIconNames[0, false] := 'OpenFolder';
          n.text[1] := '1';
        end
        else
        begin
          n.CollapsedIconNames[0, false] := 'File';
          n.expandedIconNames[0, false] := 'File';
          n.text[1] := '0';
          {  nr:=tvr.nodes.add;
            nr.DataObject:=LastN;
              nr.CollapsedIconNames[0,false]:='File';
            nr.expandedIconNames[0, false]:='File';
            nr.text[0]:=alist.ValueFromIndex[i-1];
            nr.DataObject:=afile;    }

        end;

        s := alist.ValueFromIndex[i - 1] ;
        if i <> alist.count then

          n.text[0] := s
        else
        begin
          n.text[0] := s;
          n.DataObject := afile;
        end;
        LastN := n;
      end;
      anodes := n.nodes;

    end;

  end;
  tv.endupdate;
  tvr.EndUpdate;
  // ShowFilesIntree:=false;
    // tv.ExpandAll;
  Node := tv.nodes[0] ;
  //    showTV:=false;
end;

procedure TMF.UserLogin(aUser: txUser);
begin
  if assigned(userbox) then
  begin
    userbox.UpdateDisplay;
 // WebFormResize(nil);
  end;
  if SHowTimerDone=false then exit;

  if xlogin.HasUser then
  begin
   if fstv.live then
    begin
    pmain.Visible:=true ;
    pEdt.Visible:=true;

    end
    else showConnect:=true;
  end else
  begin
    pmain.Visible:=false;
    pEdt.Visible:=false;
    userbox.Showlogin;
  end;
   webformResize(nil);
end;

{procedure TMF.GetServiceInterface(p: Pointer; guid: tGuid; iName: string; out Intf);
var

  v: tVirtualInterface;
begin
  v := tVirtualInterface.create(p);
  v.QueryInterface(guid, Intf);

  v.OnInvoke

:= procedure(Method: TRttiMethod; const Args: TArray<TValue>; out Result: TValue)procedure onResult(Response: string);
  begin

  end;

  procedure onError(Error: string);
  begin

  end;
var
  s: string;
  ActionName: string;
  // response: txdataClientResponse;
begin
  ActionName := iName + '.' + Method.name;
 // console.log('Action', ActionName);
  // RawInvoke(ActionName, Args, @onResult);
  Result := TValue.fromjsvalue('Yes!');

end;
end;     }

function TMF.GetSText: string;
begin
  Result := edt.edit.text;

end;

{function TMF.getsvc: ifs;
begin
  if not assigned(fSvc) then fsvc := xdClient.Service<ifs>;
  result := fSvc;

end; }

function TMF.NavBtn: ttmsfncToolBarButton;
begin
  result := ttmsfncToolBarButton.create(self);
  // result.parent:=pb;
  result.Appearance.NormalStroke.kind := gsknone;
  result.Appearance.HoverStroke.kind := gskNone;
  result.Appearance.DownStroke.Kind := gskNone;
  result.Appearance.DownStroke.color := gcwhite;

  result.Appearance.normalfill.kind := gfknone;
  result.Appearance.DownFill.kind := gfkSolid;
  result.Appearance.DownFill.color := gcLightBlue;
  result.Appearance.HoverFill.color := gcLightBlue;
  result.Appearance.HoverFill.kind := gfksolid;
  result.Appearance.ShowInnerStroke := false;
  result.Appearance.Rounding := 0;

  result.font.Size := 11;
  result.align := alLeft;
  result.Margins.top := 0;
  result.margins.left := 0;
  result.margins.Bottom := 0;
  result.margins.right := 6;
  result.AlignWithMargins := true;
  result.onclick := NavBtnClick;
end;

procedure TMF.NavBtnClick(sender: tobject);
var
  s: string;
  n: ttmsfncTreeViewnode;
  abtn: ttmsfncToolBarButton;
begin
  abtn := ttmsfncToolBarButton(sender);

  // console.log('Value', abtn.DataString);
  if abtn.tag = 1 then
  begin
    node := tv.nodes[0] ;
    exit;
  end;
  s := abtn.DataString + '\';
  if folders.TryGetValue(s, n) then
  begin
    tv.SelectNode(n);
    if n.Expanded = false then n.expand(false);

    node := n;
  end

  else
    console.log('Not found');

end;

procedure TMF.PathChanged(sender: txfsTV; S: string);
var
  s2: string;
begin
  {$IFNDEF WIN64}
  s2 := StringReplace(s, '\', '<b>\</b>', [rfIgnoreCase, rfReplaceAll] );
  edt.text := s2;
  if ShowDetails = true then SelFile := await(fstv.bs);
  {$ENDIF}
end;

procedure TMF.WebFormCreate(Sender: TObject);
var
  afile: tbsFile;
  alist: tbsFileList;
  s: string;
begin
  Showing := false;
  xLogin := txLogin.create;
  xlogin.onReady := loginready;
  xlogin.onLogin := UserLogin;
  xlogin.uri := DEF_X;

end;

procedure TMF.bcallClick(Sender: TObject);
var
  s: string;

begin
  {s := await(svc.GetServerDir);
  //Console.log('ServerDir', s);

  // GetDir;}
end;

procedure TMF.bDetailsClick(Sender: TObject);
begin
  ShowDetails := not ShowDetails;
end;

procedure TMF.blistClick(Sender: TObject);
var
  aList: tbsFileList;
  s: string;
begin
  //svc := xdClient.Service<ifs>;
{  lbBottom.Caption:='Loading';
  s:=await(svc.GetPathMap);
  console.log('PathMap', s);

  PathMap.PathMap.text:=s;
  PathMap.UpdatePathMap;
  if PathMap.count=0 then exit;
   rooturl:=pathmap.pm(0).url;
  // url:=rootURL;

  rootfolder:= PathMap.pm(0).path;
  //fList := await(svc.GetFileDir('C:\output\servers', '*.*', true));
  flist:=await(svc.getitems(rootFolder, '*.*', false, true, true));
  UpdateTV;
 // console.log('Files', aList);
  lbBottom.caption:=inttostr(fList.files.count) + ' files';
 // lbTop.caption:=rootfolder;  }
end;

procedure TMF.bUploadClick(Sender: TObject);
var
  aStream: tmemoryStream;
  fn: string;
  s: string;
begin
  { fn := extractFileName(fp.files[0] .name);
   console.log('Uploading', fn);
   //aStream:=await(tMemoryStream, fp.files[0].FileAsStream);
   s := await(string, fp.files[0] .FileAsText);

   await(fs.UploadText(fn, s));}

end;

{function TMF.GetDir: string;
var
  s: string;
  b: boolean;
  n: integer;
  d: tdatetime;
begin
  //  svc := xdClient.Service<ifs>;
  s := await(svc.GetServerDir);

  Result := s;

  console.log(s);
  b := await(svc.getboolean);
  d := await(svc.getdate);
  n := await(svc.getInteger);
  console.log(b);
  console.log(d);
  console.log(n);
end;    }

procedure TMF.GetFiles;
var
  aList: tbsFileList;
  s: string;
  tt: txTime;
begin
  //svc := xdClient.Service<ifs>;
 { tt := txTime.create;
  lbBottom.Caption := 'Loading';
  await(fs.GetPathMap);

  console.log('PathMap', fs.PathMap, fs.PathMap.count);

  // PathMap.PathMap.text:=s;
  // PathMap.UpdatePathMap;
  if fs.PathMap.count = 0 then exit;

  fs.ActiveRoot := fs.PathMap.pm(0);

  rooturl := fs.pathmap.pm(0).url;
  // url:=rootURL;

  rootfolder := fs.PathMap.pm(0).path;
  //fList := await(svc.GetFileDir('C:\output\servers', '*.*', true));
  console.log('Getting Files', rootfolder);
  tt.startTime := now;

  //flist:=await(fs.getitems(rootFolder, '*.*', false, true, true, false));
  flist := await(fs.getAll(rootFolder, false, true));
  tt.SetEnd;
  console.log('Time Taken: ' + tt.SimpleString);

  console.log('GotFiles, adding to TV');
  tt.starttime := now;

  ServerList.add(ServerURI);
  cookies.save(ServerList.text, 'ServerList');
  Cookies.save(ServerURI, 'Last');
  UpdateTV;
  tt.setend;

  console.log('Time Taken: ' + tt.SimpleString);
  console.log('Done');
  // console.log('Files', aList);
  lbBottom.caption := inttostr(fList.files.count) + ' files';
  // lbTop.caption:=rootfolder;  }
end;

procedure TMF.lbSubSelClick(Sender: TObject);
begin

  //window.location.replace( lbsubsel.Caption);
  //wb.URL := lbsubsel.Caption;
end;

procedure TMF.LoginReady(sender: tobject);
var
  afile: tbsFile;
  alist: tbsFileList;
  s: string;
begin

  tt := txTime.create;
  aoDisableAutoREg := true;
  disableList := true;
  RegisterClass(tbsFileList);
  RegisterClass(tbsFile);
  afile := tbsFile.create;
  alist := tbsFileList.create;
  xjGlobals.log := false;
  txicons.getxlibIcons;
  // ServerURI:='http://cfs.am1.com/fs2';
  Cookies := txCookies.create;

  if cookies.get('Last', s) then
    ServerURI := s
  else
  begin
    console.log('no cookie');
    ServerURI := '';
  end;
  console.log(s);

  ServerList := tStringList.create;
  ServerList.Sorted := true;
  ServerList.Duplicates := TDuplicates.dupIgnore;
  Serverlist.CaseSensitive := false;

  Folders := tFolders.create;
  PathMaps := tStringList.create;
  {tv.NodesAppearance.ExpandNodeIcon:=nil;
  tv.NodesAppearance.ExpandNodeIconLarge:=nil;
  tv.NodesAppearance.collapsenodeicon:=nil;
  tv.NodesAppearance.collapsenodeiconLarge:=nil; }
  tv.ColumnsAppearance.TopSize := 0;
  tvr.ColumnsAppearance.TopSize := 0;
  //showtv:=false;
  pd.tag := pd.width;
  // ix.ColumnsAppearance.TopSize:=0;

  showtimer.Enabled := true;
end;

procedure TMF.miTreeClick(Sender: TObject);
begin
  showTV := not showTV;
end;

procedure TMF.pdoldResize(Sender: TObject);
begin
  if fshowdetails = false then
  begin
    pd.width := 0;
    exit;
  end
  else
    pd.tag := pd.width;
  //if pd.width<120 then ShowDetails:=false;

end;

procedure TMF.plItemClick(Sender: TObject; AItemIndex: Integer);
var
  s: string;
  n: ttmsfncTreeViewnode;
begin
  {console.log('Value', pl.items[aitemindex].value);
  if AItemIndex=0 then
  begin
    node:=tv.nodes[0];
    exit;
  end;
  s:=pl.items[aitemindex].value + '\';
  if folders.TryGetValue(s, n) then node:=n else console.log('Not found');  }

end;

procedure TMF.pmPopup(Sender: TObject);
begin
  miTree.Checked := ShowTV;
end;

procedure TMF.ptopoldResize(Sender: TObject);
begin
  // pl.width:=ptop.width-pl.left;
end;

procedure TMF.spdDblClick(Sender: TObject);
begin
  if ShowDetails then showDetails := false;

end;

procedure TMF.spdMouseDown(Sender: TObject; Button: TMouseButton; Shift:
  TShiftState; X, Y: Integer);
begin
  if showDetails = false then
  begin
    ShowDetails := true;

  end;
end;

procedure TMF.tvNodeClick(Sender: TObject; ANode: TTMSFNCTreeViewVirtualNode);
  procedure doNodes(anodes: ttmsfncTreeViewNodes);
  var
    i: integer;
    n, n2: ttmsfncTreeViewNode;
    afile: tbsfile;
  begin
    for i := 1 to anodes.count do

    begin
      n := anodes[i - 1] ;
      n2 := tvr.nodes.add;
      n2.text[0] := n.text[0] ;
      n2.CollapsedIconNames[0, false] := n.CollapsedIconNames[0, false] ;
      n2.exPandedIconNames[0, false] := n.exPandedIconNames[0, false] ;

      if assigned(n.DataObject) then
      begin
        n2.DataObject := n.DataObject;
        if n.DataObject is tbsFile then
        begin
          afile := tbsfile(n.dataobject);
          n2.text[1] := afile.ext;
          n2.text[2] := afile.writetimes;
          n2.text[3] := '0';
        end;
      end
      else
      begin
        n2.dataobject := n;
        n2.text[1] := 'Folder';
        n2.text[3] := '1';
      end;

      doNodes(n.nodes);
    end;

  end;
begin

  if not assigned(anode) then exit;
  if not assigned(anode.node) then exit;

  Node := anode.node;

  exit;

  if tvr.DataObject = anode.node then exit;

  if not assigned(anode.node.DataObject) then
  begin
    tvr.BeginUpdate;
    tvr.DataObject := anode.node;
    tvr.nodes.clear;
    doNodes(anode.node.nodes);
    {
    for i := 1 to anode.node.nodes.count do

      begin
        n:=anode.node.nodes[i-1];
         n2:=tvr.nodes.add;
         n2.text[0]:=n.text[0];
         n2.CollapsedIconNames[0, false]:=n.CollapsedIconNames[0, false];
         n2.exPandedIconNames[0, false]:=n.exPandedIconNames[0, false];

         n2.DataObject:=n;
      end;    }
    tvr.endupdate;
    // tvr.SortColumn:=3;
    tvr.sort(3, false, false, nsmDescending, true);

  end;
end;

procedure TMF.tvNodeDblClick(Sender: TObject; ANode:
  TTMSFNCTreeViewVirtualNode);
begin
  if not assigned(anode) then exit;
  if not assigned(anode.node) then exit;
  if anode.Expanded then
    anode.Collapse(false)
  else
    anode.expand(false);

  //Node:=anode.node;
  exit;
end;

procedure TMF.tvrGetNodeColor(Sender: TObject; ANode:
  TTMSFNCTreeViewVirtualNode; var AColor: TTMSFNCGraphicsColor);
begin
  if not assigned(mousenode) then exit;
  if not assigned(mousenode.VirtualNode) then exit;

  if anode = mousenode.VirtualNode then
  begin

    aColor := gcLightblue;

  end;

end;

procedure TMF.tvrGetNodeTextColor(Sender: TObject; ANode:
  TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATextColor:
  TTMSFNCGraphicsColor);
begin
  // if anode.node=tvr.SelectedNode then aTextColor:=gcWhite else
  aTextColor := gcBlack;

end;

procedure TMF.tvrMouseDown(Sender: TObject; Button: TMouseButton; Shift:
  TShiftState; X, Y: Integer);
begin
  //console.log('mouse down');
end;

procedure TMF.tvrMouseLeave(Sender: TObject);
begin
  exit;
  mousenode := nil;
  tvr.BeginUpdate;
  tvr.EndUpdate;
end;

procedure TMF.tvrMouseUp(Sender: TObject; Button: TMouseButton; Shift:
  TShiftState; X, Y: Integer);
var
  anode, n: ttmsfncTreeViewNode;
  vnode: TTMSFNCTreeViewVirtualNode;
begin
  exit;
  // console.log('Mouse up');
   //anode:=tvr.selectedNode;
  vnode := tvr.xytonode(x, y);
  if not assigned(vnode) then
  begin
    console.log('no vnode');
    exit;
  end;
  anode := vnode.node;
  if not assigned(anode) then
  begin
    console.log('No anode');
    exit;
  end
  else
    console.log(anode.text[0] );
  tvr.SelectNode(anode);
  if not assigned(anode.DataObject) then
  begin
    console.log('No dataobject');
    exit;
  end;

  if anode.DataObject is ttmsfncTreeViewNode then
  begin

    if ((LastClick = anode) or (anode.tag = -1)) then

    begin
      n := ttmsfncTreeViewNode(anode.DataObject);

      tv.selectNode(n);
      if n.expanded = false then n.expand(false);

      node := ttmsfncTreeViewNode(anode.DataObject);
      LastClick := nil;
      exit;
    end;
  end
  else if anode.dataobject is tbsfile then

  begin
    SelFile := tbsFile(anode.DataObject);
  end;
  LastCLick := anode;
end;

procedure TMF.tvrNodeDblClick(Sender: TObject; ANode:
  TTMSFNCTreeViewVirtualNode);
begin
  if not assigned(anode) then exit;
  if not assigned(anode.node) then exit;
  if not assigned(anode.node.DataObject) then exit;

  if anode.node.DataObject is ttmsfncTreeViewNode then
  begin
    if ((LastClick = anode.node) or (anode.node.DataObject = tv.nodes[0] )) then

    begin
      node := ttmsfncTreeViewNode(anode.node.DataObject);
      LastClick := nil;
    end;
  end
  else if anode.node.dataobject is tbsfile then

  begin
    SelFile := tbsFile(anode.node.DataObject);
  end;
  LastCLick := anode.node;

end;

procedure TMF.tvrNodeMouseEnter(Sender: TObject; ANode:
  TTMSFNCTreeViewVirtualNode);
begin
  exit;
  if assigned(anode) then
    if assigned(anode.node) then
    begin
      MouseNode := anode.node;
      tvr.BeginUpdate;
      tvr.EndUpdate;
    end;
end;

procedure TMF.tvrNodeMouseLeave(Sender: TObject; ANode:
  TTMSFNCTreeViewVirtualNode);
begin
  exit;
  { if assigned(anode) then
   if assigned(anode.node) then
   begin }
  MouseNode := nil;
  tvr.BeginUpdate;
  tvr.EndUpdate;
  //end
end;

procedure TMF.WebButton1Click(Sender: TObject);
var
  p: Pointer;
  v: tVirtualInterface;
  fs: ifs;
  s: string;
begin
  // fs := Service<ifs>;
   // s:=fs.ToURL('hello');

  // console.log(s);
   { p:=TypeInfo(ifs);
     V := TVirtualInterface.Create(p);
     console.log(v);

     V.OnInvoke := procedure(Method: TRttiMethod; const Args: TArray<TValue>; out Result: TValue)
     var

     I: Integer;
     begin
     console.log('Invoked');
     end; }

end;

procedure TMF.bcCancelClick(Sender: TObject);
begin
  showConnect := false;

end;

procedure TMF.bcfgClick(Sender: TObject);
begin
  {if not assigned(FormInfo) then formInfo := tFormInfo.create(self);

  await(FormInfo.xshow(false,true, fbDialogSizeable));  }
  if not assigned(xftest) then xftest := txftest.create(self);

  await(xftest.xshow(false,true, fbDialogSizeable));

end;

procedure TMF.bcOKClick(Sender: TObject);
begin
  bcOK.enabled := false;
  fstv.parent := ptv;
  fstv.AlignWithMargins := true;
  fstv.margins.top := 8;
  fstv.margins.left := 1;
  fstv.margins.right := 1;
  fstv.margins.bottom := 0;
  fstv.align := alClient;
  ShowTV := true;
  fstv.uri := eServer.text;
  ShowCOnnect := false;
  ServerURI := eServer.text;
  ServerList.add(ServerURI);
  cookies.save(ServerList.text, 'ServerList');
  Cookies.save(ServerURI, 'Last');
     pdBtnDetail.bitmaps[0].bitmapname:=fstv.expandicon.bn;
  exit;

  fs.uri := eServer.text;
end;

procedure TMF.buserClick(Sender: TObject);
var
  s: string;
begin
  Icons := txIcons.create;
  icons.rootPath := 'txfstv';
  buser.BitmapContainer := icons.bc;
  console.log(icons.geticon('folder-svg').bn);
  buser.bitmaps[0] .bitmapname := icons.geticon('pc-svg').bn;

  {tt.start;
  s:=await(fs.GetAllNames('',true));
  tt.stop;}

end;

procedure TMF.edtChange(Sender: TObject; var Key: Word;
  Shift: TShiftState);
begin
  if key = VK_DOWN then
    edtexpanded := true
  else if key = VK_UP then
    edtexpanded := false
  else
    UpdateSearchList;
  // pedt.BringToFront;
end;

procedure TMF.edtEditEnd(Sender: TObject);
begin
  fstv.path := edt.text;
  edt.text := stringreplace(edt.text, '\', '<b>\</b>', [rfReplaceAll, rfIgnoreCase] );
  edtExpanded := false;
end;

procedure TMF.edtEditStart(Sender: TObject);
begin
  {  edt.edit.Lookup.displaylist.clear;
    edt.edit.lookup.displaylist.AddStrings(fstv.list);
    edt.edit.lookup.DisplayCount:=12;
    edt.edit.lookup.CaseSensitive:=false;}
   // edt.edit.lookup.History:=true;
  {$IFNDEF WIN64}
  edt.edit.showfocus := false;
  {$ENDIF}
  edt.edit.OnKeyUp := edtChange;
  //edt.edit.lookup.Enabled:=true;
  //edt.edit.CanFocus:=false;
  edt.edit.font.size := 14;
  edt.edit.text := TTMSFNCUtils.HTMLStrip(edt.edit.text);
  edt.AcceptButton.visible := false;
  edt.CancelButton.Visible := false;
  edt.edit.BorderStyle := bsNone;

  edt.edit.BevelEdges := [] ;
  edt.edit.BevelInner := tBevelCut.bvNone;
  edt.edit.BevelOuter := tBevelCut.bvNone;
  edt.edit.BevelKind := tBevelKind.bkNone;
  edt.edit.parentcolor := false;
  edt.edit.Color := rgb(245, 248, 253);
  lb.fill.color := edt.edit.color;
  //edt.edit.Margins.left:=12;
    //  edt.edit.Color:=clAliceBLue;
  edt.edit.AutoSelect := false;

  edt.BevelEdges := [] ;
  edt.BevelInner := tBevelCut.bvNone;
  edt.BevelOuter := tBevelCut.bvNone;
  edt.BevelKind := tBevelKind.bkNone;
  EdtExpanded := true;
  UpdateSearchList;
  //  edt.edit.elementhandle.style.setproperty('border', '5px solid white');
   // edt.elementhandle.style.setproperty('border', '5px solid white');
           //     edt.elementhandle.style.setproperty('box-sizing', 'border-box');
     //           edt.edit.elementhandle.style.setproperty('box-sizing', 'border-box');

      // edt.edit.elementhandle.style.setproperty('background-color', 'rgb(255,255,255)');
    //edt.elementhandle.style.setproperty('background-color', 'rgb(255,255,255)');

   // edt.BevelWidth:=0;
   // edt.Stroke.kind:=gskNone;
     //  edt.ShadowColor:=gcWhite;
       //edt.edit.brush.style:=TBrushstyle.bsClear;

end;

procedure TMF.eServerKeyDown(Sender: TObject; var Key: Word; Shift:
  TShiftState);
begin
  if key = vk_RETURN then
    bcOKClick(nil)
  else if key = VK_ESCAPE then
    bcCancelCLick(nil);

end;

procedure TMF.WebButton3Click(Sender: TObject);
var
  s: string;
begin
  // s := await(svc.GetPathMap);
  // console.log('Pathmap', s);
end;

procedure TMF.WebButton4Click(Sender: TObject);
var
  s: string;
  b: boolean;
  n: integer;
  d: tdatetime;

begin

  {s := 'a string';
  b := await(svc.getboolean);
  d := await(svc.getdate);
  n := await(svc.getInteger);
  console.log(b);
  console.log(d);
  console.log(n);
  console.log(s);   }
end;

procedure TMF.esKeyUp(Sender: TObject; var Key: Word; Shift: TShiftState);
var
  f: TTMSFNCTreeViewFilterdata;
begin
  if key = vk_ESCAPE then es.text := '';

  if es.text <> '' then
  begin
    tv.filter.BeginUpdate;
    f := tv.filter.add;
    f.Column := 0;
    f.Condition := es.text + '*';
    f.CaseSensitive := false;
    tv.expandall;
    tv.ApplyFilter;
    tv.filter.EndUpdate;
    SearchMode := true;

  end
  else
  begin
    // if tv.filter.HasFilter(0) then tv.filter.clear;
    tv.BeginUpdate;
    tv.filter.Clear;
    tv.EndUpdate;
    tv.CollapseAll;
    tv.expandnode(tv.nodes[0] , false);
    searchMode := false;
  end;
end;

procedure TMF.fsStatus(aStatus: txDataStatus);
begin
  statusbar.items[0].text:=astatus.StateString;
  if astatus.state=txDataState.xdsLive then
  begin
   if pmain.Visible=false then
   begin
    pmain.Visible:=true;
    pEdt.Visible:=true;
    webformResize(nil);
   end;
  end;

end;

procedure TMF.GetFilesd1Click(Sender: TObject);
begin
  webtimer1.Enabled := true;
end;

procedure TMF.imgUserClick(Sender: TObject);
begin
  {if not assigned(LoginForm) then LoginForm := tLoginForm.create(self);

  await(LoginForm.ShowModal);}

  // LoginForm.Left:=trunc((parent.width-width)/2);
// top:=trunc((parent.height-Height)/2);

end;

procedure TMF.lbSelDblClick(Sender: TObject);
begin
  {$IFNDEF WIN64}
  if assigned(fstv.node) then SelFile := await(fstv.bs);
  {$ENDIF}

end;

procedure TMF.lbServersItemClick(Sender: TObject; AItem: TTMSFNCListBoxItem);
begin
  eServer.text := aitem.text;
end;

procedure TMF.lbServersItemDblClick(Sender: TObject; AItem: TTMSFNCListBoxItem);
begin
  eServer.text := aitem.text;
  bcokClick(nil);
end;

procedure TMF.miServerClick(Sender: TObject);
begin
  ShowConnect := true;
end;

procedure TMF.pdBtnDetailClick(Sender: TObject);
begin
pdBtnDetail.bitmapContainer:=fstv.bitmapContainer;
 pdBtnDetail.bitmaps[0].bitmapname:=fstv.collapseIcon.bn;
 console.log( pdBtnDetail.bitmaps[0].bitmapname);
 console.log( pdBtnDetail.bitmaps[0].bitmap.width);
 ix.Visible:=not ix.Visible;
 if ix.Visible then pdbtnDetail.bitmaps[0].BitmapName:='up' else
       pdbtnDetail.bitmaps[0].BitmapName:='down';
end;

procedure TMF.ptvoldResize(Sender: TObject);
begin
  if fshowtv = false then
    ptv.width := 0
  else
    ptv.tag := ptv.width;

end;

procedure TMF.ShowtimerTimer(Sender: TObject);
var
  lc: string;
begin
  if showing = false then exit;

  ShowTimer.enabled := false;
  lc := 'rgb(240,240,240)';
  pConnect.Visible := false;
  xcss.tempDiv:=tempdiv;
       xcssBoxShadow.AddDefaults;
  //fs := txfs.create;

  //fs.onReady := xConnected;
  //fs.onerror := xError;

  pmain.elementhandle.style.setProperty('border-top', '1px inset rgb(240,240,240)');
  ps.elementhandle.style.setProperty('border-top', '1px solid rgb(240,240,240)');
  ptop.elementhandle.style.setProperty('background-color', 'rgb(220, 224, 233)'); //'rgb(220, 224, 233)');
    ph.elementhandle.style.setProperty('background-color', 'rgb(220, 224, 233)'); //'rgb(220, 224, 233)');

  ph.elementhandle.style.setProperty('background-color', 'rgb(220, 224, 233)');

  pd.ElementHandle.style.setProperty('border-left', '1px solid rgb(192, 192, 192)');
  ptv.ElementHandle.style.setProperty('border-right', '1px solid rgb(192, 192, 192)');
  ptvr.ElementHandle.style.setProperty('border-right', '1px solid rgb(192, 192, 192)');
  ptvr.ElementHandle.style.setProperty('border-left', '1px solid rgb(192, 192, 192)');

  sb.ElementHandle.style.setProperty('border-left', 'none');
  sb.ElementHandle.style.setProperty('border-right', 'none');

  //   userbox.ElementHandle.style.setProperty('border', 'none');

    // userbox.ElementHandle.style.setProperty('background-color', 'rgb(220, 224, 233)');

  tbl.elementhandle.style.setProperty('background-color', 'rgb(220, 224, 233)');
  tbl.ElementHandle.style.setProperty('border-right', '1px solid rgb(192, 192, 192)');
  pedt.elementhandle.style.setProperty('border-radius', '25px');
  //   pedt.elementhandle.style.setProperty('border', '1px solid black');
  pedt.elementhandle.style.setProperty('background-color', 'rgb(245, 248, 253)');

  pConnect.elementhandle.style.setProperty('border-radius', '5px');
  pConnect.elementhandle.style.setProperty('border', '2px solid black');

  pedt.tag := pEdt.Height;
  lbFirst := lb.items.items[0] ;
  edt.edit.parentcolor := false;
  edt.edit.Color := rgb(245, 248, 253);
  {if ServerURI<>'' then

  fs.uri:=ServerURI else showConnect:=true;    }


  // WebButton2Click(nil);
  pd.tag := trunc(width / 3);
  ptv.tag := trunc(width / 4);

  fstv := txfsTV.create(self);
  fstv.onStatus := fsStatus;
  fstv.PopupMenu := pmEmpty;
  fstv.onPathChanged := PathChanged;
  fstv.listview := tvr;
  fstv.ixstyle:=txixstyle.Light;
   pdIcon.BitmapContainer:=fstv.BitmapContainer;
   pdicon.bitmaps.add;

  //  fstv.parent:=ptv;
   // fstv.align:=alClient;
  userbox := txUserBox.create(self);

 // userbox.align := AlignRight;
 // userbox.Margins.right := 12;
  //userbox.AlignWithMargins := true;

  userbox.fill.kind := txBrushKind.none;
  //userbox.fill.color := clYellow;
  Userbox.fill.Hot.color := gcLightgray;
  userbox.fill.hot.kind := txBrushKind.Solid;
  userbox.parent := pTop;
  Userbox.UpdateDisplay;

  StatusBar := txStatusBar.create(sb, 4);
  StatusBar.Align := AlignLeft;
  //  xcss.xa(userbox, ['xborder-sides-all'],true);
//tjshtmlElement(Userbox.elementhandle).classList.Add('xborder-sides-all');
//  userbox.elementhandle.style.setProperty('border', '2px solid black');
//    tjshtmlElement(Userbox.elementhandle).classList.Add('xborder-color-black');

 //if xlogin.hasuser then showConnect:=true else userbox.ShowLogin;

  WebFormResize(nil);
  ShowTimerDone:=true;
  UserLogin(xlogin.user);
  console.log('SLIDE', xcSlide.mycss.text);

end;

procedure TMF.SortDirs1Click(Sender: TObject);
begin
  fstv.SortColumn := 1;
  if sm = TTMSFNCTreeViewNodesSortMode.nsmAscending then
    sm := TTMSFNCTreeViewNodesSortMode.nsmDescending
  else
    sm := TTMSFNCTreeViewNodesSortMode.nsmAscending;

  fstv.sort(1, true, false, sm, true);
end;

procedure TMF.spClick(Sender: TObject);
begin
  if showTV = false then showTV := true;

end;

procedure TMF.spDblClick(Sender: TObject);
begin
  if showTV = true then ShowTV := false;

end;

procedure TMF.spdClick(Sender: TObject);
begin
  if ShowDetails = false then showDetails := true;

end;

procedure TMF.sprMouseUp(Sender: TObject; Button: TMouseButton; Shift:
  TShiftState; X, Y: Integer);
begin
  if fshowdetails = false then
  begin
    pd.width := 0;
    exit;
  end
  else
    pd.tag := pd.width;
end;

procedure TMF.tbDetailClick(Sender: TObject);
begin
  ShowDetails := not ShowDetails;
end;

procedure TMF.tbListClick(Sender: TObject);
begin
  tblist.DownState := not tblist.DownState;
end;

procedure TMF.tbTreeClick(Sender: TObject);
begin
  tbTree.DownState := not tbTree.DownState;

end;

procedure TMF.tvAfterSelectNode(Sender: TObject; ANode:
  TTMSFNCTreeViewVirtualNode);
begin
  // console.log('After select', anode.node.text[0] );
end;

procedure TMF.tvGetNodeColor(Sender: TObject; ANode:
  TTMSFNCTreeViewVirtualNode; var AColor: TTMSFNCGraphicsColor);
begin
  if not assigned(Treemousenode) then exit;
  if not assigned(Treemousenode.VirtualNode) then exit;

  if anode = Treemousenode.VirtualNode then
  begin

    aColor := gcLightblue;

  end;

end;

procedure TMF.tvGetNodeHeight(Sender: TObject; ANode:
  TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AHeight: Double);
begin
  if assigned(anode) then
    if assigned(anode.node) then
    begin
      if anode.node.extended then
        aHeight := 50
      else
        aHeight := 30;

    end;
end;

procedure TMF.tvGetNodeTextColor(Sender: TObject; ANode:
  TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATextColor:
  TTMSFNCGraphicsColor);
begin
  //  if anode.node=tv.selectednode then aTextColor:=gcWhite else
  aTextColor := gcBlack;
end;

procedure TMF.tvMouseLeave(Sender: TObject);
begin
  Treemousenode := nil;
  tv.BeginUpdate;
  tv.EndUpdate;
end;

procedure TMF.tvMouseUp(Sender: TObject; Button: TMouseButton; Shift:
  TShiftState; X, Y: Integer);
var
  vnode: ttmsfncTreeViewVirtualnode;
begin
  if button <> mbLeft then exit;

  vnode := nil;
  vnode := tv.xytonode(x, y);

  if Assigned(vNode) then
    if assigned(vnode.node) then
      if vnode.extended = false then

      begin
        Node := vnode.node;

      end;
end;

procedure TMF.tvNodeMouseEnter(Sender: TObject; ANode:
  TTMSFNCTreeViewVirtualNode);
begin
  if assigned(anode) then
    if assigned(anode.node) then
    begin
      TreeMouseNode := anode.node;
      tv.BeginUpdate;
      tv.EndUpdate;
    end;
end;

procedure TMF.tvNodeMouseLeave(Sender: TObject; ANode:
  TTMSFNCTreeViewVirtualNode);
begin
  TreeMouseNode := nil;
  tv.BeginUpdate;
  tv.EndUpdate;
end;

procedure TMF.tvrGetNodeHeight(Sender: TObject; ANode:
  TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AHeight: Double);
begin
  aheight := 25;
end;

procedure TMF.WebFormDOMContentLoaded(Sender: TObject);
begin
  console.log('DOM CONTENT LOADED');
  //Showing:=true;

end;

procedure TMF.WebFormResize(Sender: TObject);
var
  h: integer;
  w: integer;
begin
  h := ptop.height; //+ph.height;
  if assigned(userbox) then
   begin
    userbox.left:=ptop.width-userbox.width-4;
    userbox.top:=trunc((ptop.height-userbox.height)/2);
    w := userbox.left - pedt.margins.right - 25
   end
  else
    w := pedt.margins.right;

  pedt.top := Trunc((h - pedt.height) / 2)+ptop.Top;
  pedt.left := pEdt.margins.left; //tbup.left+tbup.width+
  pEdt.Width := w;
  fm.HandleResize;
end;

procedure TMF.WebFormShow(Sender: TObject);
var
  e, e2: tjsHTMLElement;
begin
  console.log('FORM SHOW');
  Showing := true;
  //  ptop.EventStopPropagation:=[];
   // self.EventStopPropagation:=[];

  selFile := nil;

  ShowTV := true;
  // Showing:=true;

   // edt.elementhandle.style.setproperty('border', '5px solid white');

     {                  ptop.elementhandle.Properties['contenteditable'] := true;
      e2:= tjsHTMLElement(document.createElement('blockquote'));
          e2.Properties['contenteditable'] := true;
           e2.style.setProperty('display', 'block');
          ptop.elementhandle.appendChild(e2);

       e := tjsHTMLElement(document.createElement('p'));
      { if aclass <> '' then
         e.classList.add(aclass);
       if adisplay <> '' then}
      // e.innertext:='dfasdddddddd';
          //  ptop.elementhandle.Properties['contenteditable'] := true;
      //   e.style.setProperty('display', 'inline');
     //  e.Properties['contenteditable'] := true;
     //  e.Properties['contenteditable'] := true;

   //e2.appendChild(e);

   //e.style.setProperty('font-size', '22px');}

       // result:=e;

end;

procedure TMF.WebTimer1Timer(Sender: TObject);
begin
  webtimer1.Enabled := false;
  fstv.GetbsFiles;

end;

procedure TMF.wlClick(Sender: TObject);
begin

  // WL.ElementHandle.attributes['contenteditable']:=true;
end;

procedure TMF.xConnected(Sender: TObject);
begin
  console.log('Connected');
  miGetList.Enabled := true;

  if ShowCOnnect then ShowCOnnect := false;
  fServerURI := fs.uri;

  GetFiles;

end;

procedure TMF.xError(acode: integer; aMsg: string; aobject: tobject);
begin
  // console.log('Error', aMsg);
  if ShowConnect then lbcError.Caption := aMsg;
  bcOK.enabled := true;

end;

procedure TMF.xRequest(Args: TXDataWebConnectionRequest);
begin

end;

procedure TMF.xResponse(Args: TXDataWebConnectionResponse);
begin

end;

procedure TMF.LoadDFMValues;
begin
  inherited LoadDFMValues;

  pmain := THTMLDiv.Create(Self);
  ptv := THTMLDiv.Create(Self);
  tv := TTMSFNCTreeView.Create(Self);
  ptvr := THTMLDiv.Create(Self);
  tvr := TTMSFNCTreeView.Create(Self);
  pd := THTMLDiv.Create(Self);
  pnlD := TPanel.Create(Self);
  lbSel := TLabel.Create(Self);
  lbSubSel := TLabel.Create(Self);
  ix := TTMSFNCObjectInspector.Create(Self);
  pdh := TPanel.Create(Self);
  pdLink := TLinkLabel.Create(Self);
  pdIcon := TTMSFNCImage.Create(Self);
  pdBtnDetail := TTMSFNCImage.Create(Self);
  pdView := TPanel.Create(Self);
  spl := TTMSFNCSplitter.Create(Self);
  spr := TTMSFNCSplitter.Create(Self);
  tbl := THTMLDiv.Create(Self);
  tbTree := TTMSFNCToolBarButton.Create(Self);
  tbList := TTMSFNCToolBarButton.Create(Self);
  tbDetail := TTMSFNCToolBarButton.Create(Self);
  bcfg := TTMSFNCToolBarButton.Create(Self);
  ps := THTMLDiv.Create(Self);
  bUpload := TButton.Create(Self);
  fp := TFilePicker.Create(Self);
  sb := TPanel.Create(Self);
  lbBottom := TLabel.Create(Self);
  ptop := THTMLDiv.Create(Self);
  tbLeft := TTMSFNCToolBarButton.Create(Self);
  tbup := TTMSFNCToolBarButton.Create(Self);
  tbRight := TTMSFNCToolBarButton.Create(Self);
  imgUser := TTMSFNCImage.Create(Self);
  buser := TTMSFNCToolBarButton.Create(Self);
  ph := THTMLDiv.Create(Self);
  tabs := TTMSFNCTabSet.Create(Self);
  es := TEdit.Create(Self);
  bc := TTMSFNCBitmapContainer.Create(Self);
  pEdt := TPanel.Create(Self);
  edt := TTMSFNCLabelEdit.Create(Self);
  lb := TTMSFNCListBox.Create(Self);
  pConnect := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  lbcError := TLabel.Create(Self);
  lbServers := TTMSFNCListBox.Create(Self);
  bcOK := TButton.Create(Self);
  bcCancel := TButton.Create(Self);
  eServer := TTMSFNCEdit.Create(Self);
  tempdiv := THTMLDiv.Create(Self);
  pm := TPopupMenu.Create(Self);
  miConnect := TMenuItem.Create(Self);
  miGetList := TMenuItem.Create(Self);
  miTree := TMenuItem.Create(Self);
  Showtimer := TTimer.Create(Self);
  pmtvr := TPopupMenu.Create(Self);
  pmTV := TPopupMenu.Create(Self);
  miServer := TMenuItem.Create(Self);
  SortDirs1 := TMenuItem.Create(Self);
  GetFilesd1 := TMenuItem.Create(Self);
  WebTimer1 := TTimer.Create(Self);
  pmEmpty := TPopupMenu.Create(Self);

  pmain.BeforeLoadDFMValues;
  ptv.BeforeLoadDFMValues;
  tv.BeforeLoadDFMValues;
  ptvr.BeforeLoadDFMValues;
  tvr.BeforeLoadDFMValues;
  pd.BeforeLoadDFMValues;
  pnlD.BeforeLoadDFMValues;
  lbSel.BeforeLoadDFMValues;
  lbSubSel.BeforeLoadDFMValues;
  ix.BeforeLoadDFMValues;
  pdh.BeforeLoadDFMValues;
  pdLink.BeforeLoadDFMValues;
  pdIcon.BeforeLoadDFMValues;
  pdBtnDetail.BeforeLoadDFMValues;
  pdView.BeforeLoadDFMValues;
  spl.BeforeLoadDFMValues;
  spr.BeforeLoadDFMValues;
  tbl.BeforeLoadDFMValues;
  tbTree.BeforeLoadDFMValues;
  tbList.BeforeLoadDFMValues;
  tbDetail.BeforeLoadDFMValues;
  bcfg.BeforeLoadDFMValues;
  ps.BeforeLoadDFMValues;
  bUpload.BeforeLoadDFMValues;
  fp.BeforeLoadDFMValues;
  sb.BeforeLoadDFMValues;
  lbBottom.BeforeLoadDFMValues;
  ptop.BeforeLoadDFMValues;
  tbLeft.BeforeLoadDFMValues;
  tbup.BeforeLoadDFMValues;
  tbRight.BeforeLoadDFMValues;
  imgUser.BeforeLoadDFMValues;
  buser.BeforeLoadDFMValues;
  ph.BeforeLoadDFMValues;
  tabs.BeforeLoadDFMValues;
  es.BeforeLoadDFMValues;
  bc.BeforeLoadDFMValues;
  pEdt.BeforeLoadDFMValues;
  edt.BeforeLoadDFMValues;
  lb.BeforeLoadDFMValues;
  pConnect.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  lbcError.BeforeLoadDFMValues;
  lbServers.BeforeLoadDFMValues;
  bcOK.BeforeLoadDFMValues;
  bcCancel.BeforeLoadDFMValues;
  eServer.BeforeLoadDFMValues;
  tempdiv.BeforeLoadDFMValues;
  pm.BeforeLoadDFMValues;
  miConnect.BeforeLoadDFMValues;
  miGetList.BeforeLoadDFMValues;
  miTree.BeforeLoadDFMValues;
  Showtimer.BeforeLoadDFMValues;
  pmtvr.BeforeLoadDFMValues;
  pmTV.BeforeLoadDFMValues;
  miServer.BeforeLoadDFMValues;
  SortDirs1.BeforeLoadDFMValues;
  GetFilesd1.BeforeLoadDFMValues;
  WebTimer1.BeforeLoadDFMValues;
  pmEmpty.BeforeLoadDFMValues;
  try
    Name := 'MF';
    Width := 1207;
    Height := 737;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Segoe UI';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnDOMContentLoaded', 'WebFormDOMContentLoaded');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    pmain.SetParentComponent(Self);
    pmain.Name := 'pmain';
    pmain.AlignWithMargins := True;
    pmain.Left := 0;
    pmain.Top := 71;
    pmain.Width := 1204;
    pmain.Height := 636;
    pmain.Margins.Left := 0;
    pmain.Margins.Top := 0;
    pmain.Margins.Right := 3;
    pmain.Margins.Bottom := 0;
    pmain.Align := alClient;
    pmain.ChildOrder := 7;
    pmain.ElementFont := efCSS;
    pmain.Role := '';
    pmain.Visible := False;
    ptv.SetParentComponent(pmain);
    ptv.Name := 'ptv';
    ptv.Left := 38;
    ptv.Top := 0;
    ptv.Width := 137;
    ptv.Height := 636;
    ptv.Margins.Left := 3;
    ptv.Margins.Top := 3;
    ptv.Margins.Right := 3;
    ptv.Margins.Bottom := 3;
    ptv.Align := alLeft;
    ptv.ChildOrder := 7;
    ptv.ElementFont := efCSS;
    ptv.Role := '';
    tv.SetParentComponent(ptv);
    tv.Name := 'tv';
    tv.Left := 0;
    tv.Top := 0;
    tv.Width := 137;
    tv.Height := 636;
    tv.Margins.Left := 0;
    tv.Margins.Top := 4;
    tv.Margins.Right := 0;
    tv.Margins.Bottom := 0;
    tv.Align := alClient;
    tv.ParentDoubleBuffered := False;
    tv.DoubleBuffered := True;
    tv.Font.Charset := DEFAULT_CHARSET;
    tv.Font.Color := clBlack;
    tv.Font.Height := -16;
    tv.Font.Name := 'Segoe UI';
    tv.Font.Style := [];
    tv.ParentFont := False;
    tv.PopupMenu := pmTV;
    tv.TabOrder := 0;
    tv.Visible := False;
    SetEvent(tv, Self, 'OnMouseLeave', 'tvMouseLeave');
    SetEvent(tv, Self, 'OnMouseUp', 'tvMouseUp');
    tv.Stroke.Color := clDarkgray;
    tv.BitmapContainer := bc;
    tv.GroupsAppearance.TopSize := 99.479164123535160000;
    tv.GroupsAppearance.BottomSize := 99.479164123535160000;
    tv.GroupsAppearance.TopFill.Kind := gfkNone;
    tv.GroupsAppearance.BottomFill.Kind := gfkNone;
    tv.GroupsAppearance.TopFont.Charset := DEFAULT_CHARSET;
    tv.GroupsAppearance.TopFont.Color := clBlack;
    tv.GroupsAppearance.TopFont.Height := -29;
    tv.GroupsAppearance.TopFont.Name := 'Segoe UI';
    tv.GroupsAppearance.TopFont.Style := [];
    tv.GroupsAppearance.BottomFont.Charset := DEFAULT_CHARSET;
    tv.GroupsAppearance.BottomFont.Color := clBlack;
    tv.GroupsAppearance.BottomFont.Height := -29;
    tv.GroupsAppearance.BottomFont.Name := 'Segoe UI';
    tv.GroupsAppearance.BottomFont.Style := [];
    tv.Columns.Clear;
    with tv.Columns.Add do
    begin
      Name := 'Column0';
      Text := 'Column 0';
      Width := 99.479164123535160000;
      UseDefaultAppearance := False;
      Stroke.Kind := gskNone;
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clBlack;
      Font.Height := -15;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      TopStroke.Kind := gskNone;
      TopFont.Charset := DEFAULT_CHARSET;
      TopFont.Color := clWindowText;
      TopFont.Height := -11;
      TopFont.Name := 'Segoe UI';
      TopFont.Style := [];
      BottomStroke.Kind := gskNone;
      BottomFont.Charset := DEFAULT_CHARSET;
      BottomFont.Color := clWindowText;
      BottomFont.Height := -11;
      BottomFont.Name := 'Segoe UI';
      BottomFont.Style := [];
      Filtering.DropDownWidth := 99;
      Filtering.DropDownHeight := 119;
      Filtering.ButtonSize := 14.921875000000000000;
    end;
    with tv.Columns.Add do
    begin
      Name := 'Column1';
      Text := 'isFolder';
      Width := 99.479164123535160000;
      Visible := False;
      UseDefaultAppearance := False;
      Stroke.Kind := gskNone;
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -6;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      TopStroke.Kind := gskNone;
      TopFont.Charset := DEFAULT_CHARSET;
      TopFont.Color := clWindowText;
      TopFont.Height := -11;
      TopFont.Name := 'Segoe UI';
      TopFont.Style := [];
      BottomStroke.Kind := gskNone;
      BottomFont.Charset := DEFAULT_CHARSET;
      BottomFont.Color := clWindowText;
      BottomFont.Height := -11;
      BottomFont.Name := 'Segoe UI';
      BottomFont.Style := [];
      Filtering.DropDownWidth := 99;
      Filtering.DropDownHeight := 119;
      Filtering.ButtonSize := 14.921875000000000000;
    end;
    tv.ColumnsAppearance.StretchColumn := 0;
    tv.ColumnsAppearance.StretchAll := False;
    tv.ColumnsAppearance.TopSize := 23.875000000000000000;
    tv.ColumnsAppearance.BottomSize := 49.739582061767580000;
    tv.ColumnsAppearance.TopFont.Charset := DEFAULT_CHARSET;
    tv.ColumnsAppearance.TopFont.Color := clBlack;
    tv.ColumnsAppearance.TopFont.Height := -29;
    tv.ColumnsAppearance.TopFont.Name := 'Segoe UI';
    tv.ColumnsAppearance.TopFont.Style := [fsBold];
    tv.ColumnsAppearance.BottomFont.Charset := DEFAULT_CHARSET;
    tv.ColumnsAppearance.BottomFont.Color := clBlack;
    tv.ColumnsAppearance.BottomFont.Height := -29;
    tv.ColumnsAppearance.BottomFont.Name := 'Segoe UI';
    tv.ColumnsAppearance.BottomFont.Style := [];
    tv.ColumnsAppearance.TopFill.Color := 16380654;
    tv.ColumnsAppearance.BottomFill.Kind := gfkNone;
    tv.ColumnsAppearance.BottomFill.Color := 16380654;
    tv.ColumnsAppearance.TopStroke.Kind := gskNone;
    tv.NodesAppearance.ShowFocus := False;
    tv.NodesAppearance.ExpandWidth := 15.916666984558110000;
    tv.NodesAppearance.ExpandHeight := 15.916666984558110000;
    tv.NodesAppearance.LevelIndent := 11.937500000000000000;
    tv.NodesAppearance.FixedHeight := 27.854166030883790000;
    tv.NodesAppearance.VariableMinimumHeight := 27.854166030883790000;
    tv.NodesAppearance.HeightMode := tnhmVariable;
    tv.NodesAppearance.Font.Charset := DEFAULT_CHARSET;
    tv.NodesAppearance.Font.Color := clBlack;
    tv.NodesAppearance.Font.Height := -29;
    tv.NodesAppearance.Font.Name := 'Segoe UI';
    tv.NodesAppearance.Font.Style := [];
    tv.NodesAppearance.TitleFont.Charset := DEFAULT_CHARSET;
    tv.NodesAppearance.TitleFont.Color := clBlack;
    tv.NodesAppearance.TitleFont.Height := -29;
    tv.NodesAppearance.TitleFont.Name := 'Segoe UI';
    tv.NodesAppearance.TitleFont.Style := [];
    tv.NodesAppearance.SelectedFontColor := clBlack;
    tv.NodesAppearance.SelectedTitleFontColor := clBlack;
    tv.NodesAppearance.SelectedFill.Color := clSkyBlue;
    tv.NodesAppearance.SelectedStroke.Kind := gskNone;
    tv.NodesAppearance.SelectedStroke.Color := 15702829;
    tv.NodesAppearance.SelectedStroke.Width := 2.000000000000000000;
    tv.NodesAppearance.SelectionArea := tsaFull;
    tv.NodesAppearance.ExtendedFill.Kind := gfkNone;
    tv.NodesAppearance.ExtendedStroke.Kind := gskNone;
    tv.NodesAppearance.ExtendedFont.Charset := DEFAULT_CHARSET;
    tv.NodesAppearance.ExtendedFont.Color := clBlack;
    tv.NodesAppearance.ExtendedFont.Height := -29;
    tv.NodesAppearance.ExtendedFont.Name := 'Segoe UI';
    tv.NodesAppearance.ExtendedFont.Style := [fsBold];
    tv.NodesAppearance.ExpandNodeIcon.LoadFromFile('uMain.ptv.tv.NodesAppearance.ExpandNodeIcon.png');
    tv.NodesAppearance.CollapseNodeIcon.LoadFromFile('uMain.ptv.tv.NodesAppearance.CollapseNodeIcon.png');
    tv.NodesAppearance.ExpandNodeIconLarge.LoadFromFile('uMain.ptv.tv.NodesAppearance.ExpandNodeIconLarge.png');
    tv.NodesAppearance.CollapseNodeIconLarge.LoadFromFile('uMain.ptv.tv.NodesAppearance.CollapseNodeIconLarge.png');
    tv.Interaction.ExtendedSelectable := True;
    tv.Interaction.ReadOnly := True;
    tv.GlobalFont.Color := clBlack;
    tv.GlobalFont.Size := 11.000000000000000000;
    tv.GlobalFont.Name := 'Segoe UI';
    SetEvent(tv, Self, 'OnAfterSelectNode', 'tvAfterSelectNode');
    SetEvent(tv, Self, 'OnNodeMouseLeave', 'tvNodeMouseLeave');
    SetEvent(tv, Self, 'OnNodeMouseEnter', 'tvNodeMouseEnter');
    SetEvent(tv, Self, 'OnGetNodeColor', 'tvGetNodeColor');
    SetEvent(tv, Self, 'OnGetNodeTextColor', 'tvGetNodeTextColor');
    SetEvent(tv, Self, 'OnGetNodeHeight', 'tvGetNodeHeight');
    tv.DefaultViewJSONOptions.NameHTMLTemplate := '<#NAME>';
    tv.DefaultViewJSONOptions.ValueHTMLTemplate := '<#VALUE>';
    ptvr.SetParentComponent(pmain);
    ptvr.Name := 'ptvr';
    ptvr.Left := 184;
    ptvr.Top := 0;
    ptvr.Width := 671;
    ptvr.Height := 636;
    ptvr.Margins.Left := 3;
    ptvr.Margins.Top := 3;
    ptvr.Margins.Right := 3;
    ptvr.Margins.Bottom := 3;
    ptvr.Align := alClient;
    ptvr.ChildOrder := 7;
    ptvr.ElementFont := efCSS;
    ptvr.Role := '';
    tvr.SetParentComponent(ptvr);
    tvr.Name := 'tvr';
    tvr.AlignWithMargins := True;
    tvr.Left := 1;
    tvr.Top := 4;
    tvr.Width := 669;
    tvr.Height := 632;
    tvr.Margins.Left := 1;
    tvr.Margins.Top := 4;
    tvr.Margins.Right := 1;
    tvr.Margins.Bottom := 0;
    tvr.Align := alClient;
    tvr.BevelEdges := [];
    tvr.BevelInner := bvNone;
    tvr.BevelOuter := bvNone;
    tvr.ParentDoubleBuffered := False;
    tvr.DoubleBuffered := True;
    tvr.Font.Charset := DEFAULT_CHARSET;
    tvr.Font.Color := clBlack;
    tvr.Font.Height := -16;
    tvr.Font.Name := 'Segoe UI';
    tvr.Font.Style := [];
    tvr.ParentFont := False;
    tvr.PopupMenu := pmtvr;
    tvr.TabOrder := 0;
    SetEvent(tvr, Self, 'OnMouseDown', 'tvrMouseDown');
    SetEvent(tvr, Self, 'OnMouseLeave', 'tvrMouseLeave');
    SetEvent(tvr, Self, 'OnMouseUp', 'tvrMouseUp');
    tvr.Stroke.Kind := gskNone;
    tvr.Stroke.Color := clDarkgray;
    tvr.Stroke.Width := 2.000000000000000000;
    tvr.GroupsAppearance.TopSize := 99.479164123535160000;
    tvr.GroupsAppearance.BottomSize := 99.479164123535160000;
    tvr.GroupsAppearance.TopFill.Kind := gfkNone;
    tvr.GroupsAppearance.BottomFill.Kind := gfkNone;
    tvr.GroupsAppearance.TopFont.Charset := DEFAULT_CHARSET;
    tvr.GroupsAppearance.TopFont.Color := -1;
    tvr.GroupsAppearance.TopFont.Height := -29;
    tvr.GroupsAppearance.TopFont.Name := 'Segoe UI';
    tvr.GroupsAppearance.TopFont.Style := [];
    tvr.GroupsAppearance.BottomFont.Charset := DEFAULT_CHARSET;
    tvr.GroupsAppearance.BottomFont.Color := -1;
    tvr.GroupsAppearance.BottomFont.Height := -29;
    tvr.GroupsAppearance.BottomFont.Name := 'Segoe UI';
    tvr.GroupsAppearance.BottomFont.Style := [];
    tvr.ColumnStroke.Kind := gskNone;
    tvr.Columns.Clear;
    with tvr.Columns.Add do
    begin
      Name := 'Column0';
      Text := 'Name';
      Width := 99.479164123535160000;
      Stroke.Kind := gskDot;
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clBlack;
      Font.Height := -6;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      TopStroke.Kind := gskNone;
      TopFont.Charset := DEFAULT_CHARSET;
      TopFont.Color := clWindowText;
      TopFont.Height := -24;
      TopFont.Name := 'Segoe UI';
      TopFont.Style := [fsBold];
      BottomFont.Charset := DEFAULT_CHARSET;
      BottomFont.Color := clWindowText;
      BottomFont.Height := -11;
      BottomFont.Name := 'Segoe UI';
      BottomFont.Style := [];
      Sorting := tcsNormal;
      Filtering.DropDownWidth := 99;
      Filtering.DropDownHeight := 119;
      Filtering.ButtonSize := 14.921875000000000000;
    end;
    with tvr.Columns.Add do
    begin
      Name := 'Column1';
      Text := 'Kind';
      Width := 99.479164123535160000;
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -6;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      TopFont.Charset := DEFAULT_CHARSET;
      TopFont.Color := clWindowText;
      TopFont.Height := -11;
      TopFont.Name := 'Segoe UI';
      TopFont.Style := [];
      BottomFont.Charset := DEFAULT_CHARSET;
      BottomFont.Color := clWindowText;
      BottomFont.Height := -11;
      BottomFont.Name := 'Segoe UI';
      BottomFont.Style := [];
      Sorting := tcsNormal;
      Filtering.DropDownWidth := 99;
      Filtering.DropDownHeight := 119;
      Filtering.ButtonSize := 14.921875000000000000;
    end;
    with tvr.Columns.Add do
    begin
      Name := 'Column2';
      Text := 'Modified';
      Width := 99.479164123535160000;
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -6;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      TopFont.Charset := DEFAULT_CHARSET;
      TopFont.Color := clWindowText;
      TopFont.Height := -11;
      TopFont.Name := 'Segoe UI';
      TopFont.Style := [];
      BottomFont.Charset := DEFAULT_CHARSET;
      BottomFont.Color := clWindowText;
      BottomFont.Height := -11;
      BottomFont.Name := 'Segoe UI';
      BottomFont.Style := [];
      Sorting := tcsNormal;
      Filtering.DropDownWidth := 99;
      Filtering.DropDownHeight := 119;
      Filtering.ButtonSize := 14.921875000000000000;
    end;
    with tvr.Columns.Add do
    begin
      Name := 'Column3';
      Text := 'isFolder';
      Width := 99.479164123535160000;
      Visible := False;
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -6;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      TopFont.Charset := DEFAULT_CHARSET;
      TopFont.Color := clWindowText;
      TopFont.Height := -11;
      TopFont.Name := 'Segoe UI';
      TopFont.Style := [];
      BottomFont.Charset := DEFAULT_CHARSET;
      BottomFont.Color := clWindowText;
      BottomFont.Height := -11;
      BottomFont.Name := 'Segoe UI';
      BottomFont.Style := [];
      Sorting := tcsNormal;
      Filtering.DropDownWidth := 99;
      Filtering.DropDownHeight := 119;
      Filtering.ButtonSize := 14.921875000000000000;
    end;
    tvr.ColumnsAppearance.StretchColumn := 0;
    tvr.ColumnsAppearance.StretchAll := False;
    tvr.ColumnsAppearance.TopSize := 29.843750000000000000;
    tvr.ColumnsAppearance.BottomSize := 49.739582061767580000;
    tvr.ColumnsAppearance.TopFont.Charset := DEFAULT_CHARSET;
    tvr.ColumnsAppearance.TopFont.Color := clBlack;
    tvr.ColumnsAppearance.TopFont.Height := -29;
    tvr.ColumnsAppearance.TopFont.Name := 'Segoe UI';
    tvr.ColumnsAppearance.TopFont.Style := [fsBold];
    tvr.ColumnsAppearance.BottomFont.Charset := DEFAULT_CHARSET;
    tvr.ColumnsAppearance.BottomFont.Color := -1;
    tvr.ColumnsAppearance.BottomFont.Height := -29;
    tvr.ColumnsAppearance.BottomFont.Name := 'Segoe UI';
    tvr.ColumnsAppearance.BottomFont.Style := [];
    tvr.ColumnsAppearance.TopFill.Kind := gfkNone;
    tvr.ColumnsAppearance.TopFill.Color := 16380654;
    tvr.ColumnsAppearance.BottomFill.Kind := gfkNone;
    tvr.ColumnsAppearance.BottomFill.Color := 16380654;
    tvr.ColumnsAppearance.TopStroke.Kind := gskNone;
    tvr.ColumnsAppearance.TopStroke.Color := clBlack;
    tvr.ColumnsAppearance.FillEmptySpaces := False;
    tvr.NodesAppearance.ShowFocus := False;
    tvr.NodesAppearance.ExpandWidth := 11.937500000000000000;
    tvr.NodesAppearance.ExpandHeight := 11.937500000000000000;
    tvr.NodesAppearance.LevelIndent := 11.937500000000000000;
    tvr.NodesAppearance.FixedHeight := 24.869791030883790000;
    tvr.NodesAppearance.VariableMinimumHeight := 11.937500000000000000;
    tvr.NodesAppearance.ShowLines := False;
    tvr.NodesAppearance.Stroke.Width := 0.000000000000000000;
    tvr.NodesAppearance.Font.Charset := DEFAULT_CHARSET;
    tvr.NodesAppearance.Font.Color := clBlack;
    tvr.NodesAppearance.Font.Height := -29;
    tvr.NodesAppearance.Font.Name := 'Segoe UI';
    tvr.NodesAppearance.Font.Style := [];
    tvr.NodesAppearance.TitleFont.Charset := DEFAULT_CHARSET;
    tvr.NodesAppearance.TitleFont.Color := -1;
    tvr.NodesAppearance.TitleFont.Height := -29;
    tvr.NodesAppearance.TitleFont.Name := 'Segoe UI';
    tvr.NodesAppearance.TitleFont.Style := [];
    tvr.NodesAppearance.SelectedFontColor := clBlack;
    tvr.NodesAppearance.SelectedTitleFontColor := -1;
    tvr.NodesAppearance.ExtendedFontColor := -1;
    tvr.NodesAppearance.SelectedFill.Color := clSkyBlue;
    tvr.NodesAppearance.SelectedStroke.Kind := gskNone;
    tvr.NodesAppearance.SelectedStroke.Color := 15702829;
    tvr.NodesAppearance.SelectedStroke.Width := 0.000000000000000000;
    tvr.NodesAppearance.SelectionArea := tsaFull;
    tvr.NodesAppearance.ExtendedFont.Charset := DEFAULT_CHARSET;
    tvr.NodesAppearance.ExtendedFont.Color := -1;
    tvr.NodesAppearance.ExtendedFont.Height := -29;
    tvr.NodesAppearance.ExtendedFont.Name := 'Segoe UI';
    tvr.NodesAppearance.ExtendedFont.Style := [];
    tvr.NodesAppearance.ExpandNodeIcon.LoadFromFile('uMain.ptvr.tvr.NodesAppearance.ExpandNodeIcon.svg');
    tvr.NodesAppearance.CollapseNodeIcon.LoadFromFile('uMain.ptvr.tvr.NodesAppearance.CollapseNodeIcon.png');
    tvr.NodesAppearance.ExpandNodeIconLarge.LoadFromFile('uMain.ptvr.tvr.NodesAppearance.ExpandNodeIconLarge.svg');
    tvr.NodesAppearance.CollapseNodeIconLarge.LoadFromFile('uMain.ptvr.tvr.NodesAppearance.CollapseNodeIconLarge.png');
    tvr.Interaction.AutoOpenURL := False;
    tvr.Interaction.MouseEditMode := tmemDoubleClick;
    tvr.Interaction.TouchScrolling := False;
    tvr.Interaction.ColumnSizing := True;
    tvr.GlobalFont.Size := 11.000000000000000000;
    tvr.GlobalFont.Name := 'Segoe UI';
    SetEvent(tvr, Self, 'OnNodeMouseLeave', 'tvrNodeMouseLeave');
    SetEvent(tvr, Self, 'OnNodeMouseEnter', 'tvrNodeMouseEnter');
    SetEvent(tvr, Self, 'OnGetNodeColor', 'tvrGetNodeColor');
    SetEvent(tvr, Self, 'OnGetNodeHeight', 'tvrGetNodeHeight');
    tvr.DefaultViewJSONOptions.NameHTMLTemplate := '<#NAME>';
    tvr.DefaultViewJSONOptions.ValueHTMLTemplate := '<#VALUE>';
    pd.SetParentComponent(pmain);
    pd.Name := 'pd';
    pd.Left := 869;
    pd.Top := 0;
    pd.Width := 335;
    pd.Height := 636;
    pd.Margins.Left := 3;
    pd.Margins.Top := 3;
    pd.Margins.Right := 3;
    pd.Margins.Bottom := 3;
    pd.Align := alRight;
    pd.ChildOrder := 7;
    pd.Role := '';
    pnlD.SetParentComponent(pd);
    pnlD.Name := 'pnlD';
    pnlD.Left := 0;
    pnlD.Top := 0;
    pnlD.Width := 335;
    pnlD.Height := 636;
    pnlD.Margins.Left := 3;
    pnlD.Margins.Top := 3;
    pnlD.Margins.Right := 3;
    pnlD.Margins.Bottom := 3;
    pnlD.Align := alClient;
    pnlD.Color := clWhite;
    pnlD.TabOrder := 0;
    lbSel.SetParentComponent(pnlD);
    lbSel.Name := 'lbSel';
    lbSel.AlignWithMargins := True;
    lbSel.Left := 3;
    lbSel.Top := 381;
    lbSel.Width := 329;
    lbSel.Height := 23;
    lbSel.Margins.Left := 3;
    lbSel.Margins.Top := 3;
    lbSel.Margins.Right := 3;
    lbSel.Align := alTop;
    lbSel.Caption := 'Nothing Selected';
    lbSel.Font.Charset := DEFAULT_CHARSET;
    lbSel.Font.Color := clWindowText;
    lbSel.Font.Height := -16;
    lbSel.Font.Name := 'Segoe UI';
    lbSel.Font.Style := [fsBold];
    lbSel.HeightPercent := 100.000000000000000000;
    lbSel.ParentFont := False;
    lbSel.Visible := False;
    lbSel.WidthPercent := 100.000000000000000000;
    SetEvent(lbSel, Self, 'OnDblClick', 'lbSelDblClick');
    lbSubSel.SetParentComponent(pnlD);
    lbSubSel.Name := 'lbSubSel';
    lbSubSel.AlignWithMargins := True;
    lbSubSel.Left := 3;
    lbSubSel.Top := 406;
    lbSubSel.Width := 326;
    lbSubSel.Height := 19;
    lbSubSel.Cursor := crHandPoint;
    lbSubSel.Margins.Left := 3;
    lbSubSel.Margins.Top := 0;
    lbSubSel.Margins.Right := 6;
    lbSubSel.Margins.Bottom := 6;
    lbSubSel.Align := alTop;
    lbSubSel.Font.Charset := DEFAULT_CHARSET;
    lbSubSel.Font.Color := clBlue;
    lbSubSel.Font.Height := -14;
    lbSubSel.Font.Name := 'Segoe UI';
    lbSubSel.Font.Style := [fsUnderline];
    lbSubSel.HeightPercent := 100.000000000000000000;
    lbSubSel.ParentFont := False;
    lbSubSel.Visible := False;
    lbSubSel.WordWrap := True;
    lbSubSel.WidthPercent := 100.000000000000000000;
    SetEvent(lbSubSel, Self, 'OnClick', 'lbSubSelClick');
    ix.SetParentComponent(pnlD);
    ix.Name := 'ix';
    ix.Left := 0;
    ix.Top := 33;
    ix.Width := 335;
    ix.Height := 346;
    ix.Cursor := crHandPoint;
    ix.Margins.Left := 3;
    ix.Margins.Top := 3;
    ix.Margins.Right := 3;
    ix.Margins.Bottom := 3;
    ix.Align := alTop;
    ix.ParentDoubleBuffered := False;
    ix.DoubleBuffered := True;
    ix.TabOrder := 0;
    ix.Visible := False;
    ix.Stroke.Kind := gskNone;
    ix.Stroke.Color := clDarkgray;
    ix.GroupsAppearance.TopSize := 99.479164123535160000;
    ix.GroupsAppearance.BottomSize := 99.479164123535160000;
    ix.GroupsAppearance.TopFill.Kind := gfkNone;
    ix.GroupsAppearance.BottomFill.Kind := gfkNone;
    ix.GroupsAppearance.TopFont.Charset := DEFAULT_CHARSET;
    ix.GroupsAppearance.TopFont.Color := clBlack;
    ix.GroupsAppearance.TopFont.Height := -24;
    ix.GroupsAppearance.TopFont.Name := 'Segoe UI';
    ix.GroupsAppearance.TopFont.Style := [];
    ix.GroupsAppearance.BottomFont.Charset := DEFAULT_CHARSET;
    ix.GroupsAppearance.BottomFont.Color := clBlack;
    ix.GroupsAppearance.BottomFont.Height := -24;
    ix.GroupsAppearance.BottomFont.Name := 'Segoe UI';
    ix.GroupsAppearance.BottomFont.Style := [];
    ix.Columns.Clear;
    with ix.Columns.Add do
    begin
      Name := 'Column0';
      Text := 'Name';
      Width := 99.479164123535160000;
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      TopFont.Charset := DEFAULT_CHARSET;
      TopFont.Color := clWindowText;
      TopFont.Height := -22;
      TopFont.Name := 'Segoe UI';
      TopFont.Style := [];
      BottomFont.Charset := DEFAULT_CHARSET;
      BottomFont.Color := clWindowText;
      BottomFont.Height := -22;
      BottomFont.Name := 'Segoe UI';
      BottomFont.Style := [];
      Filtering.DropDownWidth := 199;
      Filtering.DropDownHeight := 239;
      Filtering.ButtonSize := 29.843750000000000000;
    end;
    with ix.Columns.Add do
    begin
      CustomEditor := True;
      Name := 'Column1';
      Text := 'Value';
      Width := 99.479164123535160000;
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      TopFont.Charset := DEFAULT_CHARSET;
      TopFont.Color := clWindowText;
      TopFont.Height := -22;
      TopFont.Name := 'Segoe UI';
      TopFont.Style := [];
      BottomFont.Charset := DEFAULT_CHARSET;
      BottomFont.Color := clWindowText;
      BottomFont.Height := -22;
      BottomFont.Name := 'Segoe UI';
      BottomFont.Style := [];
      Filtering.DropDownWidth := 199;
      Filtering.DropDownHeight := 239;
      Filtering.ButtonSize := 29.843750000000000000;
    end;
    ix.ColumnsAppearance.StretchColumn := 1;
    ix.ColumnsAppearance.StretchAll := False;
    ix.ColumnsAppearance.TopSize := 24.869791030883790000;
    ix.ColumnsAppearance.BottomSize := 49.739582061767580000;
    ix.ColumnsAppearance.TopFont.Charset := DEFAULT_CHARSET;
    ix.ColumnsAppearance.TopFont.Color := clBlack;
    ix.ColumnsAppearance.TopFont.Height := -24;
    ix.ColumnsAppearance.TopFont.Name := 'Segoe UI';
    ix.ColumnsAppearance.TopFont.Style := [fsBold];
    ix.ColumnsAppearance.BottomFont.Charset := DEFAULT_CHARSET;
    ix.ColumnsAppearance.BottomFont.Color := clBlack;
    ix.ColumnsAppearance.BottomFont.Height := -24;
    ix.ColumnsAppearance.BottomFont.Name := 'Segoe UI';
    ix.ColumnsAppearance.BottomFont.Style := [];
    ix.ColumnsAppearance.TopFill.Color := 16380654;
    ix.ColumnsAppearance.BottomFill.Kind := gfkNone;
    ix.ColumnsAppearance.BottomFill.Color := 16380654;
    ix.ColumnsAppearance.TopStroke.Kind := gskNone;
    ix.NodesAppearance.ShowFocus := False;
    ix.NodesAppearance.ExpandWidth := 3.979166746139526000;
    ix.NodesAppearance.ExpandHeight := 35.812500000000000000;
    ix.NodesAppearance.LevelIndent := 3.979166746139526000;
    ix.NodesAppearance.FixedHeight := 23.875000000000000000;
    ix.NodesAppearance.VariableMinimumHeight := 49.739582061767580000;
    ix.NodesAppearance.Font.Charset := DEFAULT_CHARSET;
    ix.NodesAppearance.Font.Color := clBlack;
    ix.NodesAppearance.Font.Height := -24;
    ix.NodesAppearance.Font.Name := 'Segoe UI';
    ix.NodesAppearance.Font.Style := [];
    ix.NodesAppearance.TitleFont.Charset := DEFAULT_CHARSET;
    ix.NodesAppearance.TitleFont.Color := clBlack;
    ix.NodesAppearance.TitleFont.Height := -24;
    ix.NodesAppearance.TitleFont.Name := 'Segoe UI';
    ix.NodesAppearance.TitleFont.Style := [];
    ix.NodesAppearance.SelectedFontColor := clBlack;
    ix.NodesAppearance.SelectedTitleFontColor := clBlack;
    ix.NodesAppearance.SelectedFill.Color := 15790320;
    ix.NodesAppearance.SelectedStroke.Color := 15790320;
    ix.NodesAppearance.SelectedStroke.Width := 2.000000000000000000;
    ix.NodesAppearance.ExtendedFont.Charset := DEFAULT_CHARSET;
    ix.NodesAppearance.ExtendedFont.Color := clBlack;
    ix.NodesAppearance.ExtendedFont.Height := -24;
    ix.NodesAppearance.ExtendedFont.Name := 'Segoe UI';
    ix.NodesAppearance.ExtendedFont.Style := [];
    ix.NodesAppearance.ExpandNodeIcon.LoadFromFile('uMain.pnlD.ix.NodesAppearance.ExpandNodeIcon.svg');
    ix.NodesAppearance.CollapseNodeIcon.LoadFromFile('uMain.pnlD.ix.NodesAppearance.CollapseNodeIcon.svg');
    ix.NodesAppearance.ExpandNodeIconLarge.LoadFromFile('uMain.pnlD.ix.NodesAppearance.ExpandNodeIconLarge.svg');
    ix.NodesAppearance.CollapseNodeIconLarge.LoadFromFile('uMain.pnlD.ix.NodesAppearance.CollapseNodeIconLarge.svg');
    ix.Interaction.MouseEditMode := tmemSingleClick;
    ix.Interaction.ColumnSizing := True;
    ix.Interaction.ColumnAutoSizeOnDblClick := True;
    ix.GlobalFont.Color := clBlack;
    ix.GlobalFont.Size := 9.000000000000000000;
    ix.GlobalFont.Name := 'Segoe UI';
    ix.DefaultViewJSONOptions.NameHTMLTemplate := '<#NAME>';
    ix.DefaultViewJSONOptions.ValueHTMLTemplate := '<#VALUE>';
    pdh.SetParentComponent(pnlD);
    pdh.Name := 'pdh';
    pdh.Left := 0;
    pdh.Top := 0;
    pdh.Width := 335;
    pdh.Height := 33;
    pdh.Margins.Left := 3;
    pdh.Margins.Top := 3;
    pdh.Margins.Right := 3;
    pdh.Margins.Bottom := 3;
    pdh.Align := alTop;
    pdh.BorderStyle := bsNone;
    pdh.ChildOrder := 4;
    pdh.TabOrder := 1;
    pdLink.SetParentComponent(pdh);
    pdLink.Name := 'pdLink';
    pdLink.Left := 45;
    pdLink.Top := 0;
    pdLink.Width := 256;
    pdLink.Height := 33;
    pdLink.Margins.Left := 3;
    pdLink.Margins.Top := 3;
    pdLink.Margins.Right := 3;
    pdLink.Margins.Bottom := 3;
    pdLink.Align := alClient;
    pdLink.AutoSize := False;
    pdLink.Font.Charset := DEFAULT_CHARSET;
    pdLink.Font.Color := clWindowText;
    pdLink.Font.Height := -15;
    pdLink.Font.Name := 'Segoe UI';
    pdLink.Font.Style := [];
    pdLink.Layout := tlCenter;
    pdLink.ParentFont := False;
    pdLink.HeightPercent := 100.000000000000000000;
    pdLink.WidthPercent := 100.000000000000000000;
    pdLink.Caption := 'pdLink';
    pdIcon.SetParentComponent(pdh);
    pdIcon.Name := 'pdIcon';
    pdIcon.AlignWithMargins := True;
    pdIcon.Left := 3;
    pdIcon.Top := 3;
    pdIcon.Width := 38;
    pdIcon.Height := 27;
    pdIcon.Margins.Left := 3;
    pdIcon.Margins.Top := 3;
    pdIcon.Margins.Right := 4;
    pdIcon.Margins.Bottom := 3;
    pdIcon.Align := alLeft;
    pdIcon.ParentDoubleBuffered := False;
    pdIcon.Color := clBtnFace;
    pdIcon.DoubleBuffered := True;
    pdIcon.TabOrder := 0;
    pdIcon.Fill.Kind := gfkNone;
    pdIcon.Stroke.Kind := gskNone;
    pdBtnDetail.SetParentComponent(pdh);
    pdBtnDetail.Name := 'pdBtnDetail';
    pdBtnDetail.AlignWithMargins := True;
    pdBtnDetail.Left := 304;
    pdBtnDetail.Top := 3;
    pdBtnDetail.Width := 28;
    pdBtnDetail.Height := 27;
    pdBtnDetail.Margins.Left := 3;
    pdBtnDetail.Margins.Top := 3;
    pdBtnDetail.Margins.Right := 4;
    pdBtnDetail.Margins.Bottom := 3;
    pdBtnDetail.Align := alRight;
    pdBtnDetail.ParentDoubleBuffered := False;
    pdBtnDetail.Color := clBtnFace;
    pdBtnDetail.DoubleBuffered := True;
    pdBtnDetail.TabOrder := 1;
    SetEvent(pdBtnDetail, Self, 'OnClick', 'pdBtnDetailClick');
    pdBtnDetail.Bitmaps.Clear;
    with pdBtnDetail.Bitmaps.Add do
    begin
      BitmapName := 'down';
    end;
    pdBtnDetail.BitmapContainer := bc;
    pdBtnDetail.Fill.Kind := gfkNone;
    pdBtnDetail.Stroke.Kind := gskNone;
    pdView.SetParentComponent(pnlD);
    pdView.Name := 'pdView';
    pdView.Left := 0;
    pdView.Top := 430;
    pdView.Width := 335;
    pdView.Height := 206;
    pdView.Margins.Left := 3;
    pdView.Margins.Top := 3;
    pdView.Margins.Right := 3;
    pdView.Margins.Bottom := 3;
    pdView.Align := alClient;
    pdView.BorderStyle := bsNone;
    pdView.Caption := 'Viewer';
    pdView.ChildOrder := 4;
    pdView.Color := clGreen;
    pdView.TabOrder := 2;
    spl.SetParentComponent(pmain);
    spl.Name := 'spl';
    spl.Left := 174;
    spl.Top := 0;
    spl.Width := 10;
    spl.Height := 636;
    spl.Cursor := crHSplit;
    spl.Margins.Left := 3;
    spl.Margins.Top := 3;
    spl.Margins.Right := 3;
    spl.Margins.Bottom := 3;
    spl.Align := alLeft;
    spl.BevelEdges := [beLeft,beRight];
    spl.ParentDoubleBuffered := False;
    spl.DoubleBuffered := True;
    spl.TabOrder := 3;
    spl.Appearance.IndicatorDownFill.Color := clBlack;
    spl.Appearance.IndicatorDownStroke.Color := clWhite;
    spl.Appearance.IndicatorFill.Color := 15395562;
    spl.Appearance.IndicatorHoverFill.Color := clBlack;
    spl.Appearance.IndicatorMargin := 2;
    spl.Appearance.IndicatorStroke.Kind := gskNone;
    spl.Appearance.SplitterDownFill.Color := 16316664;
    spl.Appearance.SplitterDownStroke.Color := clWhite;
    spl.Appearance.SplitterFill.Color := 16316664;
    spl.Appearance.SplitterHoverFill.Kind := gfkNone;
    spl.Appearance.SplitterHoverFill.Color := 16316664;
    spl.Appearance.SplitterHoverFill.ColorTo := 15461355;
    spl.Appearance.SplitterHoverStroke.Color := clWhite;
    spl.Appearance.SplitterStroke.Color := clWhite;
    spl.MinSize := 19.895833969116210000;
    spl.SplitterIndicator := siSquares;
    spr.SetParentComponent(pmain);
    spr.Name := 'spr';
    spr.Left := 855;
    spr.Top := 0;
    spr.Width := 13;
    spr.Height := 636;
    spr.Cursor := crHSplit;
    spr.Margins.Left := 3;
    spr.Margins.Top := 3;
    spr.Margins.Right := 3;
    spr.Margins.Bottom := 3;
    spr.Align := alRight;
    spr.BevelEdges := [beLeft,beRight];
    spr.ParentDoubleBuffered := False;
    spr.DoubleBuffered := True;
    spr.TabOrder := 4;
    SetEvent(spr, Self, 'OnMouseUp', 'sprMouseUp');
    spr.Appearance.IndicatorFill.Color := 15395562;
    spr.Appearance.IndicatorMargin := 2;
    spr.Appearance.IndicatorStroke.Kind := gskNone;
    spr.Appearance.SplitterDownFill.Color := 15461355;
    spr.Appearance.SplitterDownStroke.Color := clWhite;
    spr.Appearance.SplitterFill.Color := 16316664;
    spr.Appearance.SplitterHoverFill.Color := 15461355;
    spr.Appearance.SplitterHoverFill.ColorTo := 15461355;
    spr.Appearance.SplitterHoverStroke.Color := clWhite;
    spr.Appearance.SplitterStroke.Color := clWhite;
    spr.MinSize := 19.895833969116210000;
    spr.SplitterIndicator := siSquares;
    tbl.SetParentComponent(pmain);
    tbl.Name := 'tbl';
    tbl.Left := 0;
    tbl.Top := 0;
    tbl.Width := 38;
    tbl.Height := 636;
    tbl.Margins.Left := 3;
    tbl.Margins.Top := 3;
    tbl.Margins.Right := 3;
    tbl.Margins.Bottom := 3;
    tbl.Align := alLeft;
    tbl.ChildOrder := 5;
    tbl.Role := '';
    tbTree.SetParentComponent(tbl);
    tbTree.Name := 'tbTree';
    tbTree.AlignWithMargins := True;
    tbTree.Left := 0;
    tbTree.Top := 25;
    tbTree.Width := 28;
    tbTree.Height := 28;
    tbTree.Margins.Left := 0;
    tbTree.Margins.Top := 25;
    tbTree.Margins.Right := 0;
    tbTree.Margins.Bottom := 25;
    tbTree.Align := alTop;
    tbTree.ParentDoubleBuffered := False;
    tbTree.DoubleBuffered := True;
    tbTree.Font.Charset := DEFAULT_CHARSET;
    tbTree.Font.Color := clWindowText;
    tbTree.Font.Height := -11;
    tbTree.Font.Name := 'Segoe UI';
    tbTree.Font.Style := [];
    tbTree.ParentColor := True;
    tbTree.TabOrder := 0;
    SetEvent(tbTree, Self, 'OnClick', 'tbTreeClick');
    tbTree.DropDownHeight := 268.593750000000000000;
    tbTree.DropDownWidth := 268.593750000000000000;
    tbTree.Text := 'Details';
    tbTree.TextVisible := False;
    tbTree.Bitmaps.Clear;
    with tbTree.Bitmaps.Add do
    begin
      BitmapName := 'treeview';
    end;
    tbTree.Layout := bblBitmap;
    tbTree.BitmapVisible := True;
    tbTree.BitmapContainer := bc;
    tbTree.BitmapSize := 15.916666984558110000;
    tbTree.AutoBitmapSize := True;
    tbTree.LargeLayoutBitmapSize := 63.666667938232420000;
    tbTree.Appearance.ShowInnerStroke := False;
    tbTree.Appearance.Rounding := 0.000000000000000000;
    tbTree.Appearance.NormalFill.Kind := gfkNone;
    tbTree.Appearance.NormalStroke.Kind := gskNone;
    tbTree.Appearance.HoverFill.Kind := gfkSolid;
    tbTree.Appearance.HoverStroke.Kind := gskNone;
    tbTree.Appearance.DownFill.Kind := gfkSolid;
    tbTree.Appearance.DownStroke.Kind := gskNone;
    tbTree.ControlIndex := 0;
    tbList.SetParentComponent(tbl);
    tbList.Name := 'tbList';
    tbList.AlignWithMargins := True;
    tbList.Left := 0;
    tbList.Top := 78;
    tbList.Width := 28;
    tbList.Height := 28;
    tbList.Margins.Left := 0;
    tbList.Margins.Top := 0;
    tbList.Margins.Right := 0;
    tbList.Margins.Bottom := 25;
    tbList.Align := alTop;
    tbList.ParentDoubleBuffered := False;
    tbList.DoubleBuffered := True;
    tbList.Font.Charset := DEFAULT_CHARSET;
    tbList.Font.Color := clWindowText;
    tbList.Font.Height := -11;
    tbList.Font.Name := 'Segoe UI';
    tbList.Font.Style := [];
    tbList.ParentColor := True;
    tbList.TabOrder := 1;
    SetEvent(tbList, Self, 'OnClick', 'tbListClick');
    tbList.DropDownHeight := 268.593750000000000000;
    tbList.DropDownWidth := 268.593750000000000000;
    tbList.Text := 'Details';
    tbList.TextVisible := False;
    tbList.Bitmaps.Clear;
    with tbList.Bitmaps.Add do
    begin
      BitmapName := 'ListView';
    end;
    tbList.Layout := bblBitmap;
    tbList.BitmapVisible := True;
    tbList.BitmapContainer := bc;
    tbList.BitmapSize := 15.916666984558110000;
    tbList.AutoBitmapSize := True;
    tbList.LargeLayoutBitmapSize := 63.666667938232420000;
    tbList.Appearance.ShowInnerStroke := False;
    tbList.Appearance.Rounding := 0.000000000000000000;
    tbList.Appearance.NormalFill.Kind := gfkNone;
    tbList.Appearance.NormalStroke.Kind := gskNone;
    tbList.Appearance.HoverFill.Kind := gfkSolid;
    tbList.Appearance.HoverStroke.Kind := gskNone;
    tbList.Appearance.DownFill.Kind := gfkSolid;
    tbList.Appearance.DownStroke.Kind := gskNone;
    tbList.ControlIndex := 0;
    tbDetail.SetParentComponent(tbl);
    tbDetail.Name := 'tbDetail';
    tbDetail.AlignWithMargins := True;
    tbDetail.Left := 0;
    tbDetail.Top := 132;
    tbDetail.Width := 28;
    tbDetail.Height := 28;
    tbDetail.Margins.Left := 0;
    tbDetail.Margins.Top := 0;
    tbDetail.Margins.Right := 0;
    tbDetail.Margins.Bottom := 25;
    tbDetail.Align := alTop;
    tbDetail.ParentDoubleBuffered := False;
    tbDetail.DoubleBuffered := True;
    tbDetail.Font.Charset := DEFAULT_CHARSET;
    tbDetail.Font.Color := clWindowText;
    tbDetail.Font.Height := -11;
    tbDetail.Font.Name := 'Segoe UI';
    tbDetail.Font.Style := [];
    tbDetail.ParentColor := True;
    tbDetail.TabOrder := 2;
    SetEvent(tbDetail, Self, 'OnClick', 'tbDetailClick');
    tbDetail.DropDownHeight := 268.593750000000000000;
    tbDetail.DropDownWidth := 268.593750000000000000;
    tbDetail.Text := 'Details';
    tbDetail.TextVisible := False;
    tbDetail.Bitmaps.Clear;
    with tbDetail.Bitmaps.Add do
    begin
      BitmapName := 'DetailView';
    end;
    tbDetail.Layout := bblBitmap;
    tbDetail.BitmapVisible := True;
    tbDetail.BitmapContainer := bc;
    tbDetail.BitmapSize := 15.916666984558110000;
    tbDetail.AutoBitmapSize := True;
    tbDetail.LargeLayoutBitmapSize := 63.666667938232420000;
    tbDetail.Appearance.ShowInnerStroke := False;
    tbDetail.Appearance.Rounding := 0.000000000000000000;
    tbDetail.Appearance.NormalFill.Kind := gfkNone;
    tbDetail.Appearance.NormalStroke.Kind := gskNone;
    tbDetail.Appearance.HoverFill.Kind := gfkSolid;
    tbDetail.Appearance.HoverStroke.Kind := gskNone;
    tbDetail.Appearance.DownFill.Kind := gfkSolid;
    tbDetail.Appearance.DownStroke.Kind := gskNone;
    tbDetail.ControlIndex := 0;
    bcfg.SetParentComponent(tbl);
    bcfg.Name := 'bcfg';
    bcfg.AlignWithMargins := True;
    bcfg.Left := 0;
    bcfg.Top := 603;
    bcfg.Width := 28;
    bcfg.Height := 28;
    bcfg.Margins.Left := 0;
    bcfg.Margins.Top := 38;
    bcfg.Margins.Right := 0;
    bcfg.Margins.Bottom := 5;
    bcfg.Align := alBottom;
    bcfg.ParentDoubleBuffered := False;
    bcfg.DoubleBuffered := True;
    bcfg.Font.Charset := DEFAULT_CHARSET;
    bcfg.Font.Color := clWindowText;
    bcfg.Font.Height := -11;
    bcfg.Font.Name := 'Segoe UI';
    bcfg.Font.Style := [];
    bcfg.ParentColor := True;
    bcfg.TabOrder := 3;
    SetEvent(bcfg, Self, 'OnClick', 'bcfgClick');
    bcfg.DropDownHeight := 268.593750000000000000;
    bcfg.DropDownWidth := 268.593750000000000000;
    bcfg.Text := 'Details';
    bcfg.TextVisible := False;
    bcfg.Bitmaps.Clear;
    with bcfg.Bitmaps.Add do
    begin
      BitmapName := 'settings';
    end;
    bcfg.Layout := bblBitmap;
    bcfg.BitmapVisible := True;
    bcfg.BitmapContainer := bc;
    bcfg.BitmapSize := 15.916666984558110000;
    bcfg.AutoBitmapSize := True;
    bcfg.LargeLayoutBitmapSize := 63.666667938232420000;
    bcfg.Appearance.ShowInnerStroke := False;
    bcfg.Appearance.Rounding := 0.000000000000000000;
    bcfg.Appearance.NormalFill.Kind := gfkNone;
    bcfg.Appearance.NormalStroke.Kind := gskNone;
    bcfg.Appearance.HoverFill.Kind := gfkSolid;
    bcfg.Appearance.HoverStroke.Kind := gskNone;
    bcfg.Appearance.DownFill.Kind := gfkSolid;
    bcfg.Appearance.DownStroke.Kind := gskNone;
    bcfg.ControlIndex := 0;
    ps.SetParentComponent(Self);
    ps.Name := 'ps';
    ps.Left := 0;
    ps.Top := 707;
    ps.Width := 1207;
    ps.Height := 30;
    ps.Margins.Left := 3;
    ps.Margins.Top := 0;
    ps.Margins.Right := 3;
    ps.Margins.Bottom := 3;
    ps.Align := alBottom;
    ps.ChildOrder := 8;
    ps.ElementFont := efCSS;
    ps.Role := '';
    bUpload.SetParentComponent(ps);
    bUpload.Name := 'bUpload';
    bUpload.Left := 593;
    bUpload.Top := 3;
    bUpload.Width := 96;
    bUpload.Height := 40;
    bUpload.Margins.Left := 3;
    bUpload.Margins.Top := 3;
    bUpload.Margins.Right := 3;
    bUpload.Margins.Bottom := 3;
    bUpload.Caption := 'upload';
    bUpload.ChildOrder := 3;
    bUpload.HeightPercent := 100.000000000000000000;
    bUpload.Visible := False;
    bUpload.WidthPercent := 100.000000000000000000;
    SetEvent(bUpload, Self, 'OnClick', 'bUploadClick');
    fp.SetParentComponent(ps);
    fp.Name := 'fp';
    fp.Left := 571;
    fp.Top := 3;
    fp.Width := 536;
    fp.Height := 31;
    fp.Margins.Left := 3;
    fp.Margins.Top := 3;
    fp.Margins.Right := 3;
    fp.Margins.Bottom := 3;
    fp.ChildOrder := 2;
    fp.Visible := False;
    sb.SetParentComponent(ps);
    sb.Name := 'sb';
    sb.Left := 0;
    sb.Top := 0;
    sb.Width := 1207;
    sb.Height := 30;
    sb.Margins.Left := 3;
    sb.Margins.Top := 3;
    sb.Margins.Right := 3;
    sb.Margins.Bottom := 3;
    sb.Align := alClient;
    sb.ChildOrder := 3;
    sb.TabOrder := 2;
    lbBottom.SetParentComponent(sb);
    lbBottom.Name := 'lbBottom';
    lbBottom.AlignWithMargins := True;
    lbBottom.Left := 3;
    lbBottom.Top := 3;
    lbBottom.Width := 67;
    lbBottom.Height := 23;
    lbBottom.Margins.Left := 3;
    lbBottom.Margins.Top := 3;
    lbBottom.Margins.Right := 15;
    lbBottom.Margins.Bottom := 3;
    lbBottom.Align := alLeft;
    lbBottom.Alignment := taRightJustify;
    lbBottom.Caption := 'lbBottom';
    lbBottom.Font.Charset := DEFAULT_CHARSET;
    lbBottom.Font.Color := clWindowText;
    lbBottom.Font.Height := -16;
    lbBottom.Font.Name := 'Segoe UI';
    lbBottom.Font.Style := [];
    lbBottom.HeightPercent := 100.000000000000000000;
    lbBottom.Layout := tlCenter;
    lbBottom.ParentFont := False;
    lbBottom.Visible := False;
    lbBottom.WidthPercent := 100.000000000000000000;
    ptop.SetParentComponent(Self);
    ptop.Name := 'ptop';
    ptop.AlignWithMargins := True;
    ptop.Left := 0;
    ptop.Top := 18;
    ptop.Width := 1207;
    ptop.Height := 53;
    ptop.Margins.Left := 0;
    ptop.Margins.Top := 0;
    ptop.Margins.Right := 0;
    ptop.Margins.Bottom := 0;
    ptop.Align := alTop;
    ptop.ChildOrder := 8;
    ptop.ElementFont := efCSS;
    ptop.Role := '';
    tbLeft.SetParentComponent(ptop);
    tbLeft.Name := 'tbLeft';
    tbLeft.AlignWithMargins := True;
    tbLeft.Left := 6;
    tbLeft.Top := 8;
    tbLeft.Width := 28;
    tbLeft.Height := 38;
    tbLeft.Margins.Left := 6;
    tbLeft.Margins.Top := 8;
    tbLeft.Margins.Right := 1;
    tbLeft.Margins.Bottom := 8;
    tbLeft.Align := alLeft;
    tbLeft.ParentDoubleBuffered := False;
    tbLeft.DoubleBuffered := True;
    tbLeft.Font.Charset := DEFAULT_CHARSET;
    tbLeft.Font.Color := clWindowText;
    tbLeft.Font.Height := -11;
    tbLeft.Font.Name := 'Segoe UI';
    tbLeft.Font.Style := [];
    tbLeft.ParentColor := True;
    tbLeft.TabOrder := 0;
    tbLeft.Visible := False;
    SetEvent(tbLeft, Self, 'OnClick', 'tbDetailClick');
    tbLeft.DropDownHeight := 268.593750000000000000;
    tbLeft.DropDownWidth := 268.593750000000000000;
    tbLeft.Text := 'Details';
    tbLeft.TextVisible := False;
    tbLeft.Bitmaps.Clear;
    with tbLeft.Bitmaps.Add do
    begin
      BitmapName := 'Left';
    end;
    tbLeft.Layout := bblBitmap;
    tbLeft.BitmapVisible := True;
    tbLeft.BitmapContainer := bc;
    tbLeft.BitmapSize := 23.875000000000000000;
    tbLeft.AutoBitmapSize := True;
    tbLeft.LargeLayoutBitmapSize := 63.666667938232420000;
    tbLeft.Appearance.ShowInnerStroke := False;
    tbLeft.Appearance.Rounding := 0.000000000000000000;
    tbLeft.Appearance.NormalFill.Kind := gfkNone;
    tbLeft.Appearance.NormalStroke.Kind := gskNone;
    tbLeft.Appearance.HoverFill.Kind := gfkSolid;
    tbLeft.Appearance.HoverStroke.Kind := gskNone;
    tbLeft.Appearance.DownFill.Kind := gfkSolid;
    tbLeft.Appearance.DownStroke.Kind := gskNone;
    tbLeft.ControlIndex := 0;
    tbup.SetParentComponent(ptop);
    tbup.Name := 'tbup';
    tbup.AlignWithMargins := True;
    tbup.Left := 65;
    tbup.Top := 8;
    tbup.Width := 28;
    tbup.Height := 38;
    tbup.Margins.Left := 1;
    tbup.Margins.Top := 8;
    tbup.Margins.Right := 6;
    tbup.Margins.Bottom := 8;
    tbup.Align := alLeft;
    tbup.ParentDoubleBuffered := False;
    tbup.DoubleBuffered := True;
    tbup.Font.Charset := DEFAULT_CHARSET;
    tbup.Font.Color := clWindowText;
    tbup.Font.Height := -11;
    tbup.Font.Name := 'Segoe UI';
    tbup.Font.Style := [];
    tbup.ParentColor := True;
    tbup.TabOrder := 1;
    tbup.Visible := False;
    SetEvent(tbup, Self, 'OnClick', 'tbDetailClick');
    tbup.DropDownHeight := 268.593750000000000000;
    tbup.DropDownWidth := 268.593750000000000000;
    tbup.Text := 'Details';
    tbup.TextVisible := False;
    tbup.Bitmaps.Clear;
    with tbup.Bitmaps.Add do
    begin
      BitmapName := 'up';
    end;
    tbup.Layout := bblBitmap;
    tbup.BitmapVisible := True;
    tbup.BitmapContainer := bc;
    tbup.BitmapSize := 23.875000000000000000;
    tbup.AutoBitmapSize := True;
    tbup.LargeLayoutBitmapSize := 63.666667938232420000;
    tbup.Appearance.ShowInnerStroke := False;
    tbup.Appearance.Rounding := 0.000000000000000000;
    tbup.Appearance.NormalFill.Kind := gfkNone;
    tbup.Appearance.NormalStroke.Kind := gskNone;
    tbup.Appearance.HoverFill.Kind := gfkSolid;
    tbup.Appearance.HoverStroke.Kind := gskNone;
    tbup.Appearance.DownFill.Kind := gfkSolid;
    tbup.Appearance.DownStroke.Kind := gskNone;
    tbup.ControlIndex := 0;
    tbRight.SetParentComponent(ptop);
    tbRight.Name := 'tbRight';
    tbRight.AlignWithMargins := True;
    tbRight.Left := 36;
    tbRight.Top := 8;
    tbRight.Width := 28;
    tbRight.Height := 38;
    tbRight.Margins.Left := 1;
    tbRight.Margins.Top := 8;
    tbRight.Margins.Right := 1;
    tbRight.Margins.Bottom := 8;
    tbRight.Align := alLeft;
    tbRight.ParentDoubleBuffered := False;
    tbRight.DoubleBuffered := True;
    tbRight.Font.Charset := DEFAULT_CHARSET;
    tbRight.Font.Color := clWindowText;
    tbRight.Font.Height := -11;
    tbRight.Font.Name := 'Segoe UI';
    tbRight.Font.Style := [];
    tbRight.ParentColor := True;
    tbRight.TabOrder := 2;
    tbRight.Visible := False;
    SetEvent(tbRight, Self, 'OnClick', 'tbDetailClick');
    tbRight.DropDownHeight := 268.593750000000000000;
    tbRight.DropDownWidth := 268.593750000000000000;
    tbRight.Text := 'Details';
    tbRight.TextVisible := False;
    tbRight.Bitmaps.Clear;
    with tbRight.Bitmaps.Add do
    begin
      BitmapName := 'Right';
    end;
    tbRight.Layout := bblBitmap;
    tbRight.BitmapVisible := True;
    tbRight.BitmapContainer := bc;
    tbRight.BitmapSize := 23.875000000000000000;
    tbRight.AutoBitmapSize := True;
    tbRight.LargeLayoutBitmapSize := 63.666667938232420000;
    tbRight.Appearance.ShowInnerStroke := False;
    tbRight.Appearance.Rounding := 0.000000000000000000;
    tbRight.Appearance.NormalFill.Kind := gfkNone;
    tbRight.Appearance.NormalStroke.Kind := gskNone;
    tbRight.Appearance.HoverFill.Kind := gfkSolid;
    tbRight.Appearance.HoverStroke.Kind := gskNone;
    tbRight.Appearance.DownFill.Kind := gfkSolid;
    tbRight.Appearance.DownStroke.Kind := gskNone;
    tbRight.ControlIndex := 0;
    imgUser.SetParentComponent(ptop);
    imgUser.Name := 'imgUser';
    imgUser.Left := 1109;
    imgUser.Top := 3;
    imgUser.Width := 57;
    imgUser.Height := 53;
    imgUser.Margins.Left := 3;
    imgUser.Margins.Top := 3;
    imgUser.Margins.Right := 3;
    imgUser.Margins.Bottom := 3;
    imgUser.ParentDoubleBuffered := False;
    imgUser.Anchors := [akTop,akRight];
    imgUser.Color := clBtnFace;
    imgUser.DoubleBuffered := True;
    imgUser.TabOrder := 3;
    imgUser.Visible := False;
    SetEvent(imgUser, Self, 'OnClick', 'imgUserClick');
    imgUser.Bitmap.LoadFromFile('uMain.ptop.imgUser.Bitmap.png');
    imgUser.Fill.Kind := gfkNone;
    imgUser.Stroke.Kind := gskNone;
    buser.SetParentComponent(ptop);
    buser.Name := 'buser';
    buser.AlignWithMargins := True;
    buser.Left := 934;
    buser.Top := 11;
    buser.Width := 130;
    buser.Height := 29;
    buser.Margins.Left := 0;
    buser.Margins.Top := 0;
    buser.Margins.Right := 2;
    buser.Margins.Bottom := 0;
    buser.ParentDoubleBuffered := False;
    buser.DoubleBuffered := True;
    buser.Font.Charset := DEFAULT_CHARSET;
    buser.Font.Color := clWindowText;
    buser.Font.Height := -12;
    buser.Font.Name := 'Segoe UI';
    buser.Font.Style := [];
    buser.ParentColor := True;
    buser.TabOrder := 4;
    buser.Visible := False;
    SetEvent(buser, Self, 'OnClick', 'buserClick');
    buser.DropDownHeight := 268.593750000000000000;
    buser.DropDownWidth := 268.593750000000000000;
    buser.Text := 'Andrew Michael';
    buser.HorizontalTextAlign := gtaTrailing;
    buser.Bitmaps.Clear;
    with buser.Bitmaps.Add do
    begin
    end;
    buser.BitmapContainer := bc;
    buser.BitmapSize := 47.750000000000000000;
    buser.LargeLayoutBitmapSize := 63.666667938232420000;
    buser.Appearance.ShowInnerStroke := False;
    buser.Appearance.Rounding := 0.000000000000000000;
    buser.Appearance.NormalFill.Kind := gfkNone;
    buser.Appearance.NormalStroke.Kind := gskNone;
    buser.Appearance.HoverFill.Kind := gfkSolid;
    buser.Appearance.HoverStroke.Kind := gskNone;
    buser.Appearance.DownFill.Kind := gfkSolid;
    buser.Appearance.DownStroke.Kind := gskNone;
    buser.ControlIndex := 0;
    ph.SetParentComponent(Self);
    ph.Name := 'ph';
    ph.Left := 0;
    ph.Top := 0;
    ph.Width := 1207;
    ph.Height := 18;
    ph.Margins.Left := 3;
    ph.Margins.Top := 0;
    ph.Margins.Right := 3;
    ph.Margins.Bottom := 0;
    ph.Align := alTop;
    ph.ChildOrder := 8;
    ph.Role := '';
    tabs.SetParentComponent(ph);
    tabs.Name := 'tabs';
    tabs.Left := -10;
    tabs.Top := -10;
    tabs.Width := 1204;
    tabs.Height := 50;
    tabs.Margins.Left := 3;
    tabs.Margins.Top := 3;
    tabs.Margins.Right := 3;
    tabs.Margins.Bottom := 3;
    tabs.ParentDoubleBuffered := False;
    tabs.Color := clBtnFace;
    tabs.DoubleBuffered := True;
    tabs.TabOrder := 0;
    tabs.Visible := False;
    tabs.Fill.Kind := gfkNone;
    tabs.Stroke.Kind := gskNone;
    tabs.TabAppearance.Font.Charset := DEFAULT_CHARSET;
    tabs.TabAppearance.Font.Color := clWindowText;
    tabs.TabAppearance.Font.Height := -21;
    tabs.TabAppearance.Font.Name := 'Segoe UI';
    tabs.TabAppearance.Font.Style := [];
    tabs.TabAppearance.Fill.Color := 16578806;
    tabs.TabAppearance.DownFill.Color := 15776936;
    tabs.TabAppearance.DownStroke.Color := 15702829;
    tabs.TabAppearance.HoverFill.Color := 16380654;
    tabs.TabAppearance.HoverStroke.Color := 15702829;
    tabs.TabAppearance.TextSpacing := 0.000000000000000000;
    tabs.TabAppearance.ShapeOverlap := 23.875000000000000000;
    tabs.TabAppearance.ShapeSlope := 21.885416030883790000;
    tabs.TabAppearance.ShapeRounding := 9.947916984558106000;
    tabs.TabAppearance.TextColor := 8026746;
    tabs.TabAppearance.ActiveTextColor := 4539717;
    tabs.TabAppearance.HoverTextColor := 8026746;
    tabs.TabAppearance.DownTextColor := 4539717;
    tabs.TabAppearance.ShowFocus := False;
    tabs.TabAppearance.ProgressCircularSize := 29.843750000000000000;
    tabs.TabAppearance.InsertSize := 39.791667938232420000;
    tabs.TabAppearance.CloseSize := 27.854166030883790000;
    tabs.TabAppearance.CloseStroke.Width := 2.000000000000000000;
    tabs.TabAppearance.CloseDownStroke.Width := 2.000000000000000000;
    tabs.TabAppearance.CloseHoverStroke.Width := 2.000000000000000000;
    tabs.TabAppearance.BadgeFont.Charset := DEFAULT_CHARSET;
    tabs.TabAppearance.BadgeFont.Color := clDarkred;
    tabs.TabAppearance.BadgeFont.Height := -21;
    tabs.TabAppearance.BadgeFont.Name := 'Segoe UI';
    tabs.TabAppearance.BadgeFont.Style := [fsBold];
    tabs.ButtonAppearance.Rounding := 9.947916984558106000;
    tabs.ButtonAppearance.Size := 39.791667938232420000;
    tabs.ButtonAppearance.CloseIcon.LoadFromFile('uMain.ph.tabs.ButtonAppearance.CloseIcon.svg');
    tabs.ButtonAppearance.InsertIcon.LoadFromFile('uMain.ph.tabs.ButtonAppearance.InsertIcon.svg');
    tabs.ButtonAppearance.TabListIcon.LoadFromFile('uMain.ph.tabs.ButtonAppearance.TabListIcon.svg');
    tabs.ButtonAppearance.ScrollNextIcon.LoadFromFile('uMain.ph.tabs.ButtonAppearance.ScrollNextIcon.svg');
    tabs.ButtonAppearance.ScrollPreviousIcon.LoadFromFile('uMain.ph.tabs.ButtonAppearance.ScrollPreviousIcon.svg');
    tabs.BitmapContainer := bc;
    tabs.Tabs.Clear;
    with tabs.Tabs.Add do
    begin
      Text := 'fs2.am1.com';
      Bitmaps.Clear;
      with Bitmaps.Add do
      begin
        BitmapName := 'server';
      end;
      UseDefaultAppearance := True;
    end;
    with tabs.Tabs.Add do
    begin
      Text := '<b>+</b>';
      UseDefaultAppearance := True;
    end;
    tabs.TabSize.Width := 300.000000000000000000;
    tabs.TabSize.Height := 30.000000000000000000;
    tabs.TabSize.Margins.Left := 15.916666984558110000;
    tabs.TabSize.Margins.Top := 15.916666984558110000;
    tabs.TabSize.Margins.Right := 15.916666984558110000;
    tabs.TabSize.Margins.Bottom := 15.916666984558110000;
    tabs.GlobalFont.Size := 8.000000000000000000;
    es.SetParentComponent(Self);
    es.Name := 'es';
    es.AlignWithMargins := True;
    es.Left := 752;
    es.Top := 306;
    es.Width := 128;
    es.Height := 19;
    es.Margins.Left := 3;
    es.Margins.Top := 3;
    es.Margins.Right := 3;
    es.Margins.Bottom := 3;
    es.AutoSize := True;
    es.BorderStyle := bsNone;
    es.ChildOrder := 10;
    es.EditType := weSearch;
    es.Font.Charset := DEFAULT_CHARSET;
    es.Font.Color := clWindowText;
    es.Font.Height := -16;
    es.Font.Name := 'Segoe UI';
    es.Font.Style := [];
    es.HeightPercent := 100.000000000000000000;
    es.ParentFont := False;
    es.ShowFocus := False;
    es.TextHint := 'Search...';
    es.Visible := False;
    es.WidthPercent := 100.000000000000000000;
    SetEvent(es, Self, 'OnKeyUp', 'esKeyUp');
    bc.SetParentComponent(Self);
    bc.Name := 'bc';
    bc.Left := 40;
    bc.Top := -8;
    bc.Width := 13;
    bc.Height := 13;
    bc.Margins.Left := 3;
    bc.Margins.Top := 3;
    bc.Margins.Right := 3;
    bc.Margins.Bottom := 3;
    bc.Visible := True;
    bc.Items.Clear;
    with bc.Items.Add do
    begin
      Bitmap.LoadFromFile('uMain.bc.Items.Bitmap.svg');
      Name := 'settings';
      Tag := 0;
    end;
    with bc.Items.Add do
    begin
      Bitmap.LoadFromFile('uMain.bc.Items.Bitmap_1.svg');
      Name := 'server';
      Tag := 0;
    end;
    with bc.Items.Add do
    begin
      Bitmap.LoadFromFile('uMain.bc.Items.Bitmap_2.svg');
      Name := 'user';
      Tag := 0;
    end;
    with bc.Items.Add do
    begin
      Bitmap.LoadFromFile('uMain.bc.Items.Bitmap_3.svg');
      Name := 'detail';
      Tag := 0;
    end;
    with bc.Items.Add do
    begin
      Bitmap.LoadFromFile('uMain.bc.Items.Bitmap_4.svg');
      Name := 'up';
      Tag := 0;
    end;
    with bc.Items.Add do
    begin
      Bitmap.LoadFromFile('uMain.bc.Items.Bitmap_5.svg');
      Name := 'down';
      Tag := 0;
    end;
    with bc.Items.Add do
    begin
      Bitmap.LoadFromFile('uMain.bc.Items.Bitmap_6.svg');
      Name := 'Left';
      Tag := 0;
    end;
    with bc.Items.Add do
    begin
      Bitmap.LoadFromFile('uMain.bc.Items.Bitmap_7.svg');
      Name := 'Right';
      Tag := 0;
    end;
    with bc.Items.Add do
    begin
      Bitmap.LoadFromFile('uMain.bc.Items.Bitmap_8.svg');
      Name := 'search2';
      Tag := 0;
    end;
    with bc.Items.Add do
    begin
      Bitmap.LoadFromFile('uMain.bc.Items.Bitmap_9.svg');
      Name := 'folder';
      Tag := 0;
    end;
    with bc.Items.Add do
    begin
      Bitmap.LoadFromFile('uMain.bc.Items.Bitmap_10.svg');
      Name := 'treeview';
      Tag := 0;
    end;
    with bc.Items.Add do
    begin
      Bitmap.LoadFromFile('uMain.bc.Items.Bitmap_11.svg');
      Name := 'ListView';
      Tag := 0;
    end;
    with bc.Items.Add do
    begin
      Bitmap.LoadFromFile('uMain.bc.Items.Bitmap_12.svg');
      Name := 'DetailView';
      Tag := 0;
    end;
    pEdt.SetParentComponent(Self);
    pEdt.Name := 'pEdt';
    pEdt.AlignWithMargins := True;
    pEdt.Left := 31;
    pEdt.Top := 168;
    pEdt.Width := 758;
    pEdt.Height := 36;
    pEdt.Margins.Left := 20;
    pEdt.Margins.Top := 1;
    pEdt.Margins.Right := 20;
    pEdt.Margins.Bottom := 1;
    pEdt.BorderColor := clWhite;
    pEdt.ChildOrder := 6;
    pEdt.Color := clWhite;
    pEdt.Padding.Left := 14;
    pEdt.Padding.Top := 3;
    pEdt.Padding.Right := 14;
    pEdt.Padding.Bottom := 3;
    pEdt.TabOrder := 1;
    edt.SetParentComponent(pEdt);
    edt.Name := 'edt';
    edt.Left := 7;
    edt.Top := 2;
    edt.Width := 744;
    edt.Height := 28;
    edt.Margins.Left := 13;
    edt.Margins.Top := 8;
    edt.Margins.Right := 8;
    edt.Margins.Bottom := 8;
    edt.Align := alTop;
    edt.BevelEdges := [];
    edt.BevelInner := bvNone;
    edt.BevelOuter := bvNone;
    edt.ParentDoubleBuffered := False;
    edt.Color := clBtnFace;
    edt.DoubleBuffered := True;
    edt.Font.Charset := DEFAULT_CHARSET;
    edt.Font.Color := clWindowText;
    edt.Font.Height := -19;
    edt.Font.Name := 'Segoe UI';
    edt.Font.Style := [];
    edt.ParentFont := False;
    edt.TabOrder := 0;
    edt.AutoHeight := False;
    edt.AutoWidth := False;
    edt.AutoOpenURL := False;
    edt.ShadowColor := -1;
    edt.ShadowOffset := 0;
    edt.Fill.Kind := gfkNone;
    edt.Stroke.Kind := gskNone;
    edt.Stroke.Color := clAliceblue;
    edt.Text := '';
    edt.AcceptButtonStroke.Color := clBlack;
    edt.AcceptButtonStroke.Width := 2.000000000000000000;
    edt.CancelButtonStroke.Color := clBlack;
    edt.CancelButtonStroke.Width := 2.000000000000000000;
    SetEvent(edt, Self, 'OnEditStart', 'edtEditStart');
    SetEvent(edt, Self, 'OnEditEnd', 'edtEditEnd');
    lb.SetParentComponent(pEdt);
    lb.Name := 'lb';
    lb.AlignWithMargins := True;
    lb.Left := 15;
    lb.Top := 37;
    lb.Width := 729;
    lb.Height := 0;
    lb.Margins.Left := 8;
    lb.Margins.Top := 8;
    lb.Margins.Right := 8;
    lb.Margins.Bottom := 3;
    lb.Align := alClient;
    lb.ParentDoubleBuffered := False;
    lb.DoubleBuffered := True;
    lb.TabOrder := 1;
    lb.Visible := False;
    lb.Stroke.Kind := gskNone;
    lb.Stroke.Color := clLightgray;
    lb.Stroke.Width := 0.500000000000000000;
    lb.BitmapContainer := bc;
    lb.ItemsAppearance.Font.Charset := DEFAULT_CHARSET;
    lb.ItemsAppearance.Font.Color := 8026746;
    lb.ItemsAppearance.Font.Height := -28;
    lb.ItemsAppearance.Font.Name := 'Segoe UI';
    lb.ItemsAppearance.Font.Style := [];
    lb.ItemsAppearance.SelectedFill.Color := clWhitesmoke;
    lb.ItemsAppearance.SelectedStroke.Kind := gskNone;
    lb.ItemsAppearance.SelectedStroke.Color := 15702829;
    lb.ItemsAppearance.SelectedStroke.Width := 2.000000000000000000;
    lb.ItemsAppearance.FixedHeight := 29.843750000000000000;
    lb.ItemsAppearance.ShowFocus := False;
    lb.DefaultItem.BitmapWidth := 24.000000000000000000;
    lb.DefaultItem.BitmapHeight := 24.000000000000000000;
    lb.DefaultItem.TextColor := 4539717;
    lb.DefaultItem.SelectedTextColor := 4539717;
    lb.Items.Clear;
    with lb.Items.Add do
    begin
      Text := 'Current Item ';
      BitmapWidth := 24.000000000000000000;
      BitmapHeight := 24.000000000000000000;
      BitmapName := 'search2';
      TextColor := 4539717;
      SelectedTextColor := 4539717;
    end;
    lb.Header.Text := 'Header';
    lb.Header.Fill.Color := 16380654;
    lb.Header.Font.Charset := DEFAULT_CHARSET;
    lb.Header.Font.Color := 4539717;
    lb.Header.Font.Height := -26;
    lb.Header.Font.Name := 'Segoe UI';
    lb.Header.Font.Style := [fsBold];
    lb.Header.Stroke.Kind := gskNone;
    lb.Header.Size := 71.625000000000000000;
    lb.Header.SortIndicatorColor := clSteelblue;
    lb.Interaction.Filtering.DropDownWidth := 199;
    lb.Interaction.Filtering.DropDownHeight := 239;
    pConnect.SetParentComponent(Self);
    pConnect.Name := 'pConnect';
    pConnect.Left := 206;
    pConnect.Top := 306;
    pConnect.Width := 540;
    pConnect.Height := 337;
    pConnect.Margins.Left := 3;
    pConnect.Margins.Top := 3;
    pConnect.Margins.Right := 3;
    pConnect.Margins.Bottom := 3;
    pConnect.BorderColor := clBlack;
    pConnect.ChildOrder := 6;
    pConnect.TabOrder := 0;
    pConnect.Visible := False;
    WebLabel1.SetParentComponent(pConnect);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.AlignWithMargins := True;
    WebLabel1.Left := 3;
    WebLabel1.Top := 3;
    WebLabel1.Width := 534;
    WebLabel1.Height := 25;
    WebLabel1.Margins.Left := 3;
    WebLabel1.Margins.Top := 3;
    WebLabel1.Margins.Right := 3;
    WebLabel1.Margins.Bottom := 3;
    WebLabel1.Align := alTop;
    WebLabel1.Caption := 'Connect';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -19;
    WebLabel1.Font.Name := 'Segoe UI';
    WebLabel1.Font.Style := [fsBold];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    lbcError.SetParentComponent(pConnect);
    lbcError.Name := 'lbcError';
    lbcError.Left := 19;
    lbcError.Top := 296;
    lbcError.Width := 3;
    lbcError.Height := 15;
    lbcError.Margins.Left := 3;
    lbcError.Margins.Top := 3;
    lbcError.Margins.Right := 3;
    lbcError.Margins.Bottom := 3;
    lbcError.HeightPercent := 100.000000000000000000;
    lbcError.WidthPercent := 100.000000000000000000;
    lbServers.SetParentComponent(pConnect);
    lbServers.Name := 'lbServers';
    lbServers.Left := 19;
    lbServers.Top := 88;
    lbServers.Width := 505;
    lbServers.Height := 185;
    lbServers.Margins.Left := 3;
    lbServers.Margins.Top := 3;
    lbServers.Margins.Right := 3;
    lbServers.Margins.Bottom := 3;
    lbServers.ParentDoubleBuffered := False;
    lbServers.DoubleBuffered := True;
    lbServers.Font.Charset := DEFAULT_CHARSET;
    lbServers.Font.Color := clWindowText;
    lbServers.Font.Height := -11;
    lbServers.Font.Name := 'Segoe UI';
    lbServers.Font.Style := [];
    lbServers.ParentFont := False;
    lbServers.TabOrder := 0;
    lbServers.Stroke.Kind := gskNone;
    lbServers.Stroke.Color := clDarkgray;
    lbServers.ItemsAppearance.Font.Charset := DEFAULT_CHARSET;
    lbServers.ItemsAppearance.Font.Color := clBlack;
    lbServers.ItemsAppearance.Font.Height := -28;
    lbServers.ItemsAppearance.Font.Name := 'Segoe UI';
    lbServers.ItemsAppearance.Font.Style := [];
    lbServers.ItemsAppearance.SelectedFill.Color := 15453831;
    lbServers.ItemsAppearance.SelectedStroke.Kind := gskNone;
    lbServers.ItemsAppearance.SelectedStroke.Color := 15702829;
    lbServers.ItemsAppearance.SelectedStroke.Width := 2.000000000000000000;
    lbServers.ItemsAppearance.FixedHeight := 24.869791030883790000;
    lbServers.ItemsAppearance.ShowFocus := False;
    lbServers.DefaultItem.SelectedTextColor := 4539717;
    lbServers.Header.Text := 'Saved servers';
    lbServers.Header.Fill.Color := 16380654;
    lbServers.Header.Font.Charset := DEFAULT_CHARSET;
    lbServers.Header.Font.Color := 4539717;
    lbServers.Header.Font.Height := -28;
    lbServers.Header.Font.Name := 'Segoe UI';
    lbServers.Header.Font.Style := [fsBold];
    lbServers.Header.Stroke.Kind := gskNone;
    lbServers.Header.Size := 71.625000000000000000;
    lbServers.Header.SortIndicatorColor := clSteelblue;
    lbServers.Interaction.Filtering.Enabled := True;
    lbServers.Interaction.Filtering.DropDownWidth := 199;
    lbServers.Interaction.Filtering.DropDownHeight := 239;
    lbServers.GlobalFont.Size := 10.000000000000000000;
    SetEvent(lbServers, Self, 'OnItemClick', 'lbServersItemClick');
    SetEvent(lbServers, Self, 'OnItemDblClick', 'lbServersItemDblClick');
    bcOK.SetParentComponent(pConnect);
    bcOK.Name := 'bcOK';
    bcOK.Left := 428;
    bcOK.Top := 288;
    bcOK.Width := 96;
    bcOK.Height := 33;
    bcOK.Margins.Left := 3;
    bcOK.Margins.Top := 3;
    bcOK.Margins.Right := 3;
    bcOK.Margins.Bottom := 3;
    bcOK.Caption := 'Connect';
    bcOK.ChildOrder := 1;
    bcOK.Default := True;
    bcOK.Font.Charset := DEFAULT_CHARSET;
    bcOK.Font.Color := clWindowText;
    bcOK.Font.Height := -15;
    bcOK.Font.Name := 'Segoe UI';
    bcOK.Font.Style := [fsBold];
    bcOK.HeightPercent := 100.000000000000000000;
    bcOK.ParentFont := False;
    bcOK.WidthPercent := 100.000000000000000000;
    SetEvent(bcOK, Self, 'OnClick', 'bcOKClick');
    bcCancel.SetParentComponent(pConnect);
    bcCancel.Name := 'bcCancel';
    bcCancel.Left := 343;
    bcCancel.Top := 287;
    bcCancel.Width := 79;
    bcCancel.Height := 34;
    bcCancel.Margins.Left := 3;
    bcCancel.Margins.Top := 3;
    bcCancel.Margins.Right := 3;
    bcCancel.Margins.Bottom := 3;
    bcCancel.Caption := 'Cancel';
    bcCancel.ChildOrder := 1;
    bcCancel.Font.Charset := DEFAULT_CHARSET;
    bcCancel.Font.Color := clWindowText;
    bcCancel.Font.Height := -15;
    bcCancel.Font.Name := 'Segoe UI';
    bcCancel.Font.Style := [];
    bcCancel.HeightPercent := 100.000000000000000000;
    bcCancel.ParentFont := False;
    bcCancel.WidthPercent := 100.000000000000000000;
    SetEvent(bcCancel, Self, 'OnClick', 'bcCancelClick');
    eServer.SetParentComponent(pConnect);
    eServer.Name := 'eServer';
    eServer.Left := 9;
    eServer.Top := 50;
    eServer.Width := 516;
    eServer.Height := 33;
    eServer.Margins.Left := 3;
    eServer.Margins.Top := 3;
    eServer.Margins.Right := 3;
    eServer.Margins.Bottom := 3;
    eServer.AutoSize := False;
    eServer.Font.Charset := DEFAULT_CHARSET;
    eServer.Font.Color := clWindowText;
    eServer.Font.Height := -19;
    eServer.Font.Name := 'Segoe UI';
    eServer.Font.Style := [];
    eServer.ParentFont := False;
    eServer.TabOrder := 3;
    eServer.Text := 'eServer';
    eServer.Lookup.Separator := ';';
    tempdiv.SetParentComponent(Self);
    tempdiv.Name := 'tempdiv';
    tempdiv.Left := 410;
    tempdiv.Top := 619;
    tempdiv.Width := 0;
    tempdiv.Height := 0;
    tempdiv.Margins.Left := 3;
    tempdiv.Margins.Top := 3;
    tempdiv.Margins.Right := 3;
    tempdiv.Margins.Bottom := 3;
    tempdiv.ChildOrder := 8;
    tempdiv.Role := '';
    tempdiv.Visible := False;
    pm.SetParentComponent(Self);
    pm.Name := 'pm';
    SetEvent(pm, Self, 'OnPopup', 'pmPopup');
    pm.Appearance.HamburgerMenu.Caption := 'Menu';
    pm.Appearance.SubmenuIndicator := '&#9658;';
    pm.Font.Charset := DEFAULT_CHARSET;
    pm.Font.Color := clWindowText;
    pm.Font.Height := -12;
    pm.Font.Name := 'Segoe UI';
    pm.Font.Style := [];
    pm.Left := 772;
    pm.Top := 56;
    miConnect.SetParentComponent(pm);
    miConnect.Name := 'miConnect';
    miConnect.Caption := 'Connect';
    SetEvent(miConnect, Self, 'OnClick', 'bcCancelClick');
    miGetList.SetParentComponent(pm);
    miGetList.Name := 'miGetList';
    miGetList.Caption := 'Get List';
    miGetList.Enabled := False;
    SetEvent(miGetList, Self, 'OnClick', 'blistClick');
    miTree.SetParentComponent(pm);
    miTree.Name := 'miTree';
    miTree.Caption := 'Tree';
    SetEvent(miTree, Self, 'OnClick', 'miTreeClick');
    Showtimer.SetParentComponent(Self);
    Showtimer.Name := 'Showtimer';
    Showtimer.Enabled := False;
    Showtimer.Interval := 100;
    SetEvent(Showtimer, Self, 'OnTimer', 'ShowtimerTimer');
    Showtimer.Left := 667;
    Showtimer.Top := 88;
    pmtvr.SetParentComponent(Self);
    pmtvr.Name := 'pmtvr';
    pmtvr.Appearance.HamburgerMenu.Caption := 'Menu';
    pmtvr.Appearance.SubmenuIndicator := '&#9658;';
    pmtvr.Font.Charset := DEFAULT_CHARSET;
    pmtvr.Font.Color := clWindowText;
    pmtvr.Font.Height := -12;
    pmtvr.Font.Name := 'Segoe UI';
    pmtvr.Font.Style := [];
    pmtvr.Left := 338;
    pmtvr.Top := 64;
    pmTV.SetParentComponent(Self);
    pmTV.Name := 'pmTV';
    pmTV.Appearance.HamburgerMenu.Caption := 'Menu';
    pmTV.Appearance.SubmenuIndicator := '&#9658;';
    pmTV.Font.Charset := DEFAULT_CHARSET;
    pmTV.Font.Color := clWindowText;
    pmTV.Font.Height := -12;
    pmTV.Font.Name := 'Segoe UI';
    pmTV.Font.Style := [];
    pmTV.Left := 483;
    pmTV.Top := 56;
    miServer.SetParentComponent(pmTV);
    miServer.Name := 'miServer';
    miServer.Caption := 'Connect...';
    SetEvent(miServer, Self, 'OnClick', 'miServerClick');
    SortDirs1.SetParentComponent(pmTV);
    SortDirs1.Name := 'SortDirs1';
    SortDirs1.Caption := 'Sort Dirs';
    SetEvent(SortDirs1, Self, 'OnClick', 'SortDirs1Click');
    GetFilesd1.SetParentComponent(pmTV);
    GetFilesd1.Name := 'GetFilesd1';
    GetFilesd1.Caption := 'GetFilesd';
    SetEvent(GetFilesd1, Self, 'OnClick', 'GetFilesd1Click');
    WebTimer1.SetParentComponent(Self);
    WebTimer1.Name := 'WebTimer1';
    WebTimer1.Enabled := False;
    WebTimer1.Interval := 100;
    SetEvent(WebTimer1, Self, 'OnTimer', 'WebTimer1Timer');
    WebTimer1.Left := 594;
    WebTimer1.Top := 65;
    pmEmpty.SetParentComponent(Self);
    pmEmpty.Name := 'pmEmpty';
    pmEmpty.Appearance.HamburgerMenu.Caption := 'Menu';
    pmEmpty.Appearance.SubmenuIndicator := '&#9658;';
    pmEmpty.Font.Charset := DEFAULT_CHARSET;
    pmEmpty.Font.Color := clWindowText;
    pmEmpty.Font.Height := -12;
    pmEmpty.Font.Name := 'Segoe UI';
    pmEmpty.Font.Style := [];
    pmEmpty.Left := 343;
    pmEmpty.Top := 111;
  finally
    pmain.AfterLoadDFMValues;
    ptv.AfterLoadDFMValues;
    tv.AfterLoadDFMValues;
    ptvr.AfterLoadDFMValues;
    tvr.AfterLoadDFMValues;
    pd.AfterLoadDFMValues;
    pnlD.AfterLoadDFMValues;
    lbSel.AfterLoadDFMValues;
    lbSubSel.AfterLoadDFMValues;
    ix.AfterLoadDFMValues;
    pdh.AfterLoadDFMValues;
    pdLink.AfterLoadDFMValues;
    pdIcon.AfterLoadDFMValues;
    pdBtnDetail.AfterLoadDFMValues;
    pdView.AfterLoadDFMValues;
    spl.AfterLoadDFMValues;
    spr.AfterLoadDFMValues;
    tbl.AfterLoadDFMValues;
    tbTree.AfterLoadDFMValues;
    tbList.AfterLoadDFMValues;
    tbDetail.AfterLoadDFMValues;
    bcfg.AfterLoadDFMValues;
    ps.AfterLoadDFMValues;
    bUpload.AfterLoadDFMValues;
    fp.AfterLoadDFMValues;
    sb.AfterLoadDFMValues;
    lbBottom.AfterLoadDFMValues;
    ptop.AfterLoadDFMValues;
    tbLeft.AfterLoadDFMValues;
    tbup.AfterLoadDFMValues;
    tbRight.AfterLoadDFMValues;
    imgUser.AfterLoadDFMValues;
    buser.AfterLoadDFMValues;
    ph.AfterLoadDFMValues;
    tabs.AfterLoadDFMValues;
    es.AfterLoadDFMValues;
    bc.AfterLoadDFMValues;
    pEdt.AfterLoadDFMValues;
    edt.AfterLoadDFMValues;
    lb.AfterLoadDFMValues;
    pConnect.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    lbcError.AfterLoadDFMValues;
    lbServers.AfterLoadDFMValues;
    bcOK.AfterLoadDFMValues;
    bcCancel.AfterLoadDFMValues;
    eServer.AfterLoadDFMValues;
    tempdiv.AfterLoadDFMValues;
    pm.AfterLoadDFMValues;
    miConnect.AfterLoadDFMValues;
    miGetList.AfterLoadDFMValues;
    miTree.AfterLoadDFMValues;
    Showtimer.AfterLoadDFMValues;
    pmtvr.AfterLoadDFMValues;
    pmTV.AfterLoadDFMValues;
    miServer.AfterLoadDFMValues;
    SortDirs1.AfterLoadDFMValues;
    GetFilesd1.AfterLoadDFMValues;
    WebTimer1.AfterLoadDFMValues;
    pmEmpty.AfterLoadDFMValues;
  end;
end;

end.

